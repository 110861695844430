export interface IContractDto {
  id: number;
  number: string;
  compositeNumber: string;
  contractorId: number;
  contractorName: string;
  status: ContractStatus;
  startedAt: string;
  expiresAt: string | null;
  userId: number;
}

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}
