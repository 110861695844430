export interface IArticleDto {
  id: number;
  title_ru: string;
  title_en: string;
  content_ru: string;
  content_en: string;
  position: string;
  status: ArticleStatus;
  chapter_id: number;
  created_at: string;
  updated_at: string;
}

export interface IArticleSimplified {
  id: number;
  title_ru: string;
  title_en: string;
  position: number;
  status: ArticleStatus;
  chapter_id: number;
}

export enum ArticleStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}
