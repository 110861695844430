import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {ContractorsApi} from '../../../../api/contractor-api/contractors-api';
import {IContractorDto} from '../../../../api/contractor-api/IContractorDto';
import {IFinancialAccountDto} from '../../../../api/DTOs/IFinancialAccountDto';
import {IContractDto} from '../../../../api/contract-api/IContractDto';
import {FinancialAccountsTable} from '../../financial-accounts/financial-accounts/financial-accounts-table';
import {ContractsTable} from '../../contracts/contracts-page/contracts-table';
import {FormattedMessage, useIntl} from 'react-intl';
import {Preloader} from '../../../../components/preloader';
import SVG from 'react-inlinesvg';
import {useModalEditContractor} from '../../../../components/modals/edit-contractor/modal-edit-contractor-context';
import {CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';
import {TransactionsActionsToolbar} from '../../../../components/card-toolbar/transactions-actions-toolbar';
import {IAddendumDto} from '../../../../api/addendum-api/IAddendumDto';
import {AddendumsTable} from '../../contracts/contract-page/addendums-table';
import {useAddendumActionHandler} from '../../contracts/contract-page/useAddendumActionHandler';
import {useContractActionHandler} from '../../contracts/contracts-page/useContractActionHandler';
import {useTransactionActionHandler} from '../../transactions/useTransactionActionHandler';
import {IUserDto} from '../../../../api/DTOs/IUserDto';
import {AdminRoutes} from '../../../../../configs/routes';
import {ICONS} from '../../../../images/images';
import {Transaction} from '../../../../api/transaction-api/Transaction';
import {IPaginationInfo} from '../../../../api/Paginator';
import {TransactionsApi} from '../../../../api/transaction-api/transactions-api';
import {IQueryParams} from '../../../../api/DTOs/IFilterDtos';
import {TransactionsTable} from '../../transactions/transactions-table';
import {PaginationComponent} from '../../../../components/pagination';
import {useLoading} from '../../../../hooks/use-loading';
import {useFilePicker} from 'use-file-picker';
import {EntityId} from '../../../../api/base/BaseEntity';
import {toast} from 'react-toastify';
import {AddendumApi} from '../../../../api/addendum-api/addendum-api';
import {AddendumActions} from '../../contracts/contract-page/contract-page';

export const ContractorPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();

  const api = new ContractorsApi();
  const addendumApi = new AddendumApi();
  const transactionApi = new TransactionsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    contractor: true,
    transactions: true,
  });
  const [error, setError] = useState<string | null>(null);

  const [activeTemplateUploadAddendumId, setActiveTemplateUploadAddendumId] = useState<EntityId | null>(null);
  const [contracts, setContracts] = useState<Array<IContractDto>>([]);
  const [accounts, setAccounts] = useState<Array<IFinancialAccountDto>>([]);
  const [contractorInfo, setContractorInfo] = useState<IContractorDto | null>(null);
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [transactionsPage, setTransactionsPage] = useState<number>(0);
  const [transactionPaginationInfo, setTransactionPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [addendums, setAddendums] = useState<Array<IAddendumDto>>([]);
  const [user, setUser] = useState<IUserDto | null>(null);
  const {showEditContractorModal} = useModalEditContractor();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchContractorInfo().then();
  }, []);

  useEffect(() => {
    if (contractorInfo) {
      fetchTransactionsWithFilters().then();
    }
  }, [transactionsPage, contractorInfo?.id]);

  useEffect(() => {
    if (contractorInfo?.name) {
      setBreadcrumb(contractorInfo.name);
    }
  }, [contractorInfo?.name]);

  const handleEditContractorClick = async () => {
    const data = await showEditContractorModal(
      Number(match.params.id),
      contractorInfo?.name || '',
      contractorInfo?.jurisdictionId ?? null,
    );
    if (data.reason === CloseModalReason.OK) {
      await fetchContractorInfo();
    }
  };

  const fetchContractorInfo = async () => {
    try {
      startLoading('contractor');
      const result = await api.getContractorInfo(Number(match.params.id));
      setContractorInfo(result.data.item);
      setAccounts(result.data.relations.accounts);
      setContracts(result.data.relations.contracts);
      setAddendums(result.data.relations.addendums);
      setUser(result.data.relations.user);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('contractor');
    }
  };

  const fetchTransactions = async (params: IQueryParams) => {
    try {
      startLoading('transactions');
      const result = await transactionApi.getTransactions(params);
      setTransactions(result.data.items);
      setTransactionPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('transactions');
    }
  };

  const fetchTransactionsWithFilters = async () => {
    fetchTransactions({filters: {contractor_id: contractorInfo?.id}, page: transactionsPage}).then();
  };

  const {handleContractorAction} = useContractActionHandler();
  const {handleAddendumActions} = useAddendumActionHandler(fetchContractorInfo);
  const {handleTransactionAction} = useTransactionActionHandler(fetchTransactionsWithFilters);

  const {openFilePicker, plainFiles, clear} = useFilePicker({
    accept: '.docx',
  });

  useEffect(() => {
    if (plainFiles.length === 0 || activeTemplateUploadAddendumId === null) {
      return;
    }

    addendumApi
      .updateTemplateFile(activeTemplateUploadAddendumId, plainFiles[0])
      .then(() => {
        toast.success(intl.formatMessage({id: 'SUCCESS_TEMPLATE_UPLOAD'}));
        void fetchContractorInfo();
      })
      .catch(() => toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'})))
      .finally(() => {
        setActiveTemplateUploadAddendumId(null);
        clear();
      });
  }, [plainFiles, activeTemplateUploadAddendumId]);

  const handleAddendumActionsProxy = (addendum: IAddendumDto, action: AddendumActions) => {
    if (action === AddendumActions.UPLOAD_CUSTOM_TEMPLATE) {
      setActiveTemplateUploadAddendumId(addendum.id);
      openFilePicker();
    }

    return handleAddendumActions(addendum, action);
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (loadings.contractor) {
    return <Preloader />;
  }

  const renderContractsTable = () => {
    if (contracts.length === 0) {
      return (
        <div className={'text-center mb-7 mt-7'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <ContractsTable onContractAction={handleContractorAction} contracts={contracts} />;
  };

  const renderFinancialAccountsTable = () => {
    if (accounts.length === 0) {
      return (
        <div className={'text-center mb-7 mt-7'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <FinancialAccountsTable onSelectAction={handleTransactionAction} accounts={accounts} />;
  };

  const renderAddendumTable = () => {
    if (addendums.length === 0) {
      return (
        <div className={'text-center mb-7 mt-7'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <AddendumsTable onSelect={handleAddendumActionsProxy} addendums={addendums} />;
  };

  const renderTransactionsTable = () => {
    if (transactions.length === 0) {
      return (
        <div className={'text-center mb-7 mt-7'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }
    return (
      <>
        {transactionPaginationInfo && (
          <>
            <TransactionsTable
              transactions={transactions}
              aggregationValues={transactionPaginationInfo.aggregationValues}
              onSelectAction={handleTransactionAction}
            />
            <PaginationComponent
              threshold={4}
              paginator={transactionPaginationInfo}
              aggregationValues={{
                [intl.formatMessage({id: 'TOTAL_TRANSACTIONS_SUM'})]:
                  transactionPaginationInfo?.aggregationValues?.total_transactions_sum != null
                    ? intl.formatNumber(Number(transactionPaginationInfo.aggregationValues.total_transactions_sum), {
                        currency: 'USD',
                        style: 'currency',
                      })
                    : 'N/A',
              }}
              onChange={setTransactionsPage}
            />
          </>
        )}
      </>
    );
  };

  const renderWithLoadingOrEmpty = (text: string | null | undefined) => {
    if (loadings.contractor) {
      return <Preloader />;
    }

    if (!text) {
      return 'N/A';
    }

    return text;
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className={'d-flex align-items-center justify-content-between'}>
            <div>
              <div className={'font-size-h4 font-weight-boldest'}>
                <span>{renderWithLoadingOrEmpty(contractorInfo?.name)}</span>
              </div>
              <div className={'font-size-h4 font-weight-boldest'}>
                <span>
                  {intl.formatMessage({id: 'JURISDICTION'})}:{' '}
                  {renderWithLoadingOrEmpty(contractorInfo?.jurisdictionTitle)}
                </span>
              </div>
              {user && (
                <div className={'font-size-h5 font-size-sm-h5 font-weight-boldest mt-3 text-muted'}>
                  <Link to={AdminRoutes.getSpecifyUserManagementRoute(user.id)}>{user.email}</Link>
                </div>
              )}
            </div>
            <div className={'d-flex align-items-center'}>
              <button
                onClick={handleEditContractorClick}
                className='btn font-weight-bolder text-uppercase btn-secondary'>
                <span className={`svg-icon svg-icon-secondary svg-icon-2x mr-2`}>
                  <SVG src={ICONS.EDIT} />
                </span>
                <FormattedMessage id={'EDIT'} />
              </button>
            </div>
          </div>
          <div className={'separator separator-solid my-7'} />
          {
            <TransactionsActionsToolbar
              showManualCreateTransactionButtons
              filters={{contractor_id: (contractorInfo as Required<IContractorDto>).id}}
              partOfContractor={{
                id: (contractorInfo as Required<IContractorDto>).id,
                name: (contractorInfo as Required<IContractorDto>).name,
              }}
              advancedToolBarItems={[
                {
                  type: 'LINK',
                  visible: contractorInfo?.hasActivePaymentRequest,
                  to: AdminRoutes.getPaymentRequestsRoute({user_id: contractorInfo?.userId}),
                  title: intl.formatMessage({id: 'PAYMENT_REQUESTS'}),
                  icon: ICONS.MONEY,
                  className: 'btn btn-light-primary font-weight-bolder',
                },
              ]}
            />
          }
          <div className={'separator separator-solid my-7'} />
          <div className={'font-size-h4 font-weight-boldest mb-1'}>
            <FormattedMessage id={'ACCOUNTS'} />:
          </div>
          {loadings.contractor ? <Preloader /> : renderFinancialAccountsTable()}
          <div className={'separator separator-solid my-7'} />
          <div className={'font-size-h4 font-weight-boldest mb-1'}>
            <FormattedMessage id={'CONTRACTS'} />:
          </div>
          {loadings.contractor ? <Preloader /> : renderContractsTable()}
          <div className={'separator separator-solid my-7'} />
          <div className={'font-size-h4 font-weight-boldest mb-1'}>
            <FormattedMessage id={'ADDENDUMS'} />:
          </div>
          {loadings.contractor ? <Preloader /> : renderAddendumTable()}
          <div className={'separator separator-solid my-7'} />
          <div className={'font-size-h4 font-weight-boldest mb-1'}>
            <FormattedMessage id={'TRANSACTIONS'} />:
          </div>
          {loadings.transactions ? <Preloader /> : renderTransactionsTable()}
        </div>
      </div>
    </>
  );
};
