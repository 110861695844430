import moment from 'moment';
import {IYoutubeThumbnailsDto} from '../api/DTOs/IYoutubeThumbnailsDto';
import {IYoutubeThumbnailDto} from '../api/DTOs/IYoutubeThumbnailDto';
import LodashIsEqual from 'lodash.isequal';
import LodashIsObject from 'lodash.isobject';
import LodashIsEmpty from 'lodash.isempty';

export function tryGetPreviewOrStub(previews: IYoutubeThumbnailsDto | null | undefined): IYoutubeThumbnailDto {
  const stub = {height: 180, width: 320, url: 'https://via.placeholder.com/320x180?text=NOT+FOUND'};

  if (!previews) {
    return stub;
  }

  if (previews.medium) {
    return previews.medium;
  } else if (previews.high) {
    return previews.high;
  } else if (previews.default) {
    return previews.default;
  }

  return stub;
}

export function capitalizeFirstLetter(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function trimStringWithEllipsis(str: string, length: number): string {
  if (str.length > length) {
    return str.trim().substring(0, Math.min(str.length, length)) + '...';
  }
  return str;
}

export function prepareDate(date: Date | string | undefined | null, format = 'YYYY-MM-DD'): string | null {
  if (date == null) {
    return null;
  }
  const d = moment(date);
  return d.isValid() ? d.format(format) : null;
}

export function tryGetDateOrNull(date: string | Date | undefined | null): Date | null {
  if (!date) {
    return null;
  }

  const d = moment(date);
  return d.isValid() ? d.toDate() : null;
}

export function getDateWithStartDay(date: Date | null): Date | null {
  const d = moment(date);
  return d.isValid() ? new Date(d.year(), d.month(), 1) : null;
}

export function getDateWithEndDay(date: Date | null): Date | null {
  const d = moment(date);
  return d.isValid() ? new Date(d.year(), d.month(), d.daysInMonth()) : null;
}

export type ValidationErrorsType<T = any> = Record<keyof T, Array<string> | string> | null | undefined;

export function getValidationErrorMessage(
  key: string | Array<string>,
  validationErrors: ValidationErrorsType,
): null | string | Array<string> {
  if (!validationErrors) {
    return null;
  }

  const keys = Array.isArray(key) ? key : [key];
  const validationMessage = keys
    .filter(inputKey => inputKey in validationErrors)
    .map(inputKey => validationErrors[inputKey as any]);

  if (validationMessage.length === 0) {
    return null;
  } else if (validationMessage.length === 1) {
    return validationMessage[0];
  } else {
    return validationMessage.flat(2);
  }
}

export function changeArrayPosition<T>(fromIndex: number, toIndex: number, array: Array<T>): Array<T> {
  const copyArrayForImmutable = [...array];
  copyArrayForImmutable.splice(fromIndex, 1);
  copyArrayForImmutable.splice(toIndex, 0, array[fromIndex]);
  return copyArrayForImmutable;
}

export function isEqual(value: any, compareWith: any) {
  return LodashIsEqual(value, compareWith);
}

export function isObject(value: any) {
  return LodashIsObject(value);
}

export function isEmpty(value: any) {
  return LodashIsEmpty(value);
}
