import React from 'react';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';
import {LocalizationKey} from "../../hooks/use-intl";

type Props = {
  title: LocalizationKey | string;
  value: any;
  className?: string;
};

export const ValueWithLabel: React.FC<Props> = ({value, title, className}) => {
  return (
    <p className={cn('font-size-md font-weight-bolder', className)}>
      <FormattedMessage id={title} />: <span>{value}</span>
    </p>
  );
};
