import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {ModalEditContract} from './modal-edit-contract';
import {ContractsApi} from '../../../api/contract-api/contracts-api';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {prepareDate} from '../../../utils/utils';
import {IValidationException} from '../../../api/exceptions/IValidationException';

interface IModalEditContractContext {
  modalEditContractVisible: boolean;

  showEditContractModal(contractId: number, contractor: ISelectValueDto): Promise<any>;
}

// @ts-ignore
const ModalEditContractContext = createContext<IModalEditContractContext>();

let closeResolver: any = null;
export const ModalEditContractProvider = ({children}: any) => {
  const intl = useIntl();

  const contractsApi = new ContractsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [contractorId, setContractorId] = useState<number | null>(null);
  const [contractors, setContractors] = useState<Array<ISelectValueDto>>([]);
  const [contractId, setContractId] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const showModal = async (contractId: number, contractor: ISelectValueDto) => {
    setVisible(true);
    setContractId(contractId);
    setContractorId(Number(contractor.id));
    setContractors([contractor]);
    return new Promise((resolve) => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setEndDate(null);
    setStartDate(null);
    setContractorId(null);
    setError(null);
    setValidationError(null);
  };

  const handleChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      await contractsApi.editContract(
        contractId,
        prepareDate(startDate),
        prepareDate(endDate),
      );
      if (closeResolver) {
        closeResolver();
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalEditContractContext = {
    modalEditContractVisible: visible,
    showEditContractModal: showModal,
  };

  return <ModalEditContractContext.Provider value={value}>
    {children}
    <ModalEditContract
      visible={visible}
      startDate={startDate}
      endDate={endDate}
      contractorId={contractorId}
      contractors={contractors}

      error={error}
      validationErrors={validationErrors}

      onHide={handleHideModal}
      onOkClick={handleOkClick}
      onChangeStartDate={handleChangeStartDate}
      onChangeEndDate={handleChangeEndDate}
    />
  </ModalEditContractContext.Provider>;
};

export const useModalEditContract = () => {
  return useContext(ModalEditContractContext);
};
