import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {AdsStatus, IAdvertisementRequestDto} from '../../api/advertisement-api/IAdvertisementRequestDto';
import {DefaultTable} from '../../components/default-table';
import {AdminRoutes, Routes} from '../../../configs/routes';
import {BadgeCustom} from '../../components/badge-custom';
import {TableUtils} from '../../components/table-utils';

interface AdvertisementsProps {
  ads: Array<IAdvertisementRequestDto>;
  isAdmin?: boolean;
}

export const AdvertisementsTable = ({ads, isAdmin}: AdvertisementsProps) => {
  const intl = useIntl();
  const data = ads;

  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'ID'})),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          return TableUtils.renderNumericContent(
            <>
              <Link
                to={
                  isAdmin
                    ? AdminRoutes.getSpecifyAdvertisementRequestRoute(value.id)
                    : Routes.getSpecifyAdvertisementRequestRoute(value.id)
                }>
                {value.id}
              </Link>
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'CHANNEL'}),
        maxWidth: 300,
        minWidth: 250,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          return TableUtils.renderBaseContent(
            <>
              <Link to={Routes.getYoutubeSpecifyChannelRoute(value.youtube_channel_id)}>
                {value.youtube_channel_title}
              </Link>
            </>,
          );
        },
      },
      isAdmin === true && {
        Header: intl.formatMessage({id: 'USER'}),
        maxWidth: 250,
        minWidth: 200,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          return TableUtils.renderBaseContent(
            <>
              <Link to={AdminRoutes.getSpecifyUserManagementRoute(value.user_id)}>{value.user_name}</Link>
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          const statusColor =
            value.status === AdsStatus.REJECTED ? 'danger' : value.status === AdsStatus.DONE ? 'success' : 'primary';
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={statusColor}>
              <FormattedMessage id={'ADS_REQUEST_STATUS_' + value.status} />
            </BadgeCustom>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'RELEASE_DATE'}),
        maxWidth: 120,
        minWidth: 100,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.release_at)} {intl.formatTime(value.release_at)}
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 120,
        minWidth: 100,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.created_at)} {intl.formatTime(value.created_at)}
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 120,
        minWidth: 100,
        accessor: (br: IAdvertisementRequestDto) => br,
        Cell: ({value}: {value: IAdvertisementRequestDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.updated_at)} {intl.formatTime(value.updated_at)}
            </>,
          );
        },
      },
    ];
  }, []);

  //TODO: HACK с фильтрацией столбцов.
  // @ts-ignore
  return <DefaultTable data={data} columns={columns.filter(i => !(typeof i === 'boolean'))} />;
};
