import {BaseListPage} from '../base/base-list-page';
import {StackedColumnsChart} from '../../components/charts/stacked-columns-chart';
import {RevenueByMonthsTable} from '../youtube-channel/channel-page/revenue-by-months-table';
import React, {useMemo} from 'react';
import {useIntl} from '../../hooks/use-intl';
import {RevenueByMonths, YoutubeFinancialRevenueByMonthsReport} from '../../api/DTOs/RevenueByMonths';
import {CustomFormatter} from '../../../localization/custom-formatter';
import {ToolBarItem} from '../../components/card-toolbar/Toolbar';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {ISelectValueDto} from '../../api/DTOs/ISelectValueDto';

type Props = {
  revenues: YoutubeFinancialRevenueByMonthsReport | null;
  loading?: boolean;
  toolbarConfig?: Array<ToolBarItem>;
  showFilters?: boolean;
  channels?: ISelectValueDto[];
};

export const RevenuesDetails: React.FC<Props> = ({loading, revenues, toolbarConfig, channels, showFilters}) => {
  const intl = useIntl();
  const filtersConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    if (!showFilters) {
      return [];
    }
    return FilterBuilder.buildFilter({
      allSelectValues: {channels: channels || []},
      filters: [
        {
          name: 'channels',
          placeholder: intl.formatMessage({id: 'CHANNEL'}),
          type: FilterType.SELECT,
        },
      ],
    });
  }, [showFilters, channels]);

  const getSeriesDataByKey = (key: keyof RevenueByMonths) => {
    return (revenues?.items.map(r => r[key]) as Array<number>) || [];
  };

  const categories = useMemo(
    () => revenues?.items.map(r => CustomFormatter.formatDate_MM_YYYY(new Date(r.year, r.month - 1))) || [],
    [revenues],
  );
  const series = useMemo(() => {
    if (!revenues) {
      return [];
    }

    return [
      {
        name: intl.formatMessage({id: 'ADS'}),
        data: getSeriesDataByKey('revenue_advertisement'),
      },
      {
        name: intl.formatMessage({id: 'ADS_copies'}),
        data: getSeriesDataByKey('revenue_advertisement_copies'),
      },
      {
        name: intl.formatMessage({id: 'YOUTUBE_PREMIUM'}),
        data: getSeriesDataByKey('revenue_subscription'),
      },
      {
        name: intl.formatMessage({id: 'COMPENSATIONS'}),
        data: getSeriesDataByKey('revenue_compensation'),
      },
      {
        name: intl.formatMessage({id: 'PAID_FEATURES'}),
        data: getSeriesDataByKey('revenue_paid_features'),
      },
      {
        name: intl.formatMessage({id: 'YOUTUBE_SHORTS'}),
        data: getSeriesDataByKey('revenue_shorts'),
      },
      {
        name: intl.formatMessage({id: 'OTHER_REVENUES'}),
        data: getSeriesDataByKey('revenue_others'),
      },
    ];
  }, [revenues]);

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filtersConfig}
      toolbarConfig={toolbarConfig}
      pageTitle={intl.formatMessage({id: 'REVENUE_DYNAMIC'})}>
      <StackedColumnsChart height={'70%'} series={series} categories={categories} />
      <div className={'separator separator-solid my-7'} />
      <RevenueByMonthsTable items={revenues?.items || []} />
    </BaseListPage>
  );
};
