import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import AuthInit from './modules/Auth/auth-init';
import {I18nProvider} from '../localization';
import {LayoutSplashScreen} from '../_metronic/layout';
import {AuthRouter} from './auth-router';
import {ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe} from 'use-query-params';
import {BreadcrumbsProvider} from './components/breadcrumbs/breadcrumbs-context';
import {ModalConfirmActionProvider} from './components/modals/confirm-modal/modal-confirm-action-context';
import {CookieConfirmation} from './components/cookie-confirmation';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

export default function App({store, persistor, basename}: any) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <DndProvider backend={HTML5Backend}>
              <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
                <I18nProvider>
                  <AuthInit>
                    <BreadcrumbsProvider>
                      <ModalConfirmActionProvider>
                        <AuthRouter />
                        <CookieConfirmation />
                      </ModalConfirmActionProvider>
                    </BreadcrumbsProvider>
                  </AuthInit>
                </I18nProvider>
              </QueryParamProvider>
            </DndProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
