import React, {createContext, useContext, useEffect, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {ModalCreateEditChapter} from './modal-create-edit-chapter';
import {IChapterDto} from '../../../api/knowledge-base-api/IChapterDto';
import {KnowledgeBaseApi} from '../../../api/knowledge-base-api/knowledge-base-api';
import {ValidationErrorsType} from '../../../utils/utils';
import {useIntl} from '../../../hooks/use-intl';

type OpenPayload = CreatePayload | EditPayload;

type CreatePayload = {
  type: 'CREATE';
};
type EditPayload = {
  type: 'EDIT';
  data: {
    chapter: IChapterDto;
  };
};

interface IModalCreateEditChapterContext {
  modalCreateEditChapterVisible: boolean;

  showCreateEditChapterModal(payload: OpenPayload): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalCreateEditChapterContext = createContext<IModalCreateEditChapterContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalCreateEditChapterProvider = ({children}: any) => {
  const intl = useIntl();
  const api = new KnowledgeBaseApi(true);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<ValidationErrorsType | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [chapter, setChapter] = useState<Partial<IChapterDto>>({});
  const [openPayload, setOpenPayload] = useState<OpenPayload | null>(null);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setError(null);
        setChapter({});
        setOpenPayload(null);
        setValidationError(null);
      }, 1000);
    }
  }, [visible]);

  const showModal = async (payload: OpenPayload) => {
    setOpenPayload(payload);
    if (payload.type === 'EDIT') {
      setChapter(payload.data.chapter);
    }
    setVisible(true);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      if (openPayload === null) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Initially open data not be null');
      }

      setError(null);
      if (openPayload.type === 'CREATE') {
        await api.createChapter({...(chapter as IChapterDto)});
      } else if (openPayload.type === 'EDIT') {
        await api.updateChapter(chapter.id as number, {...(chapter as IChapterDto)});
      }
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalCreateEditChapterContext = {
    modalCreateEditChapterVisible: visible,
    showCreateEditChapterModal: showModal,
  };

  return (
    <ModalCreateEditChapterContext.Provider value={value}>
      {children}
      <ModalCreateEditChapter
        visible={visible}
        error={error}
        options={{
          title:
            openPayload?.type === 'CREATE'
              ? intl.formatMessage({id: 'CREATE_CHAPTER'})
              : intl.formatMessage({id: 'EDIT_CHAPTER'}),
        }}
        validationErrors={validationErrors}
        title_ru={chapter.title_ru ?? ''}
        title_en={chapter.title_en ?? ''}
        onChangeTitle={(lang, updatedTitle) =>
          setChapter(prevState => ({
            ...prevState,
            [`title_${lang}`]: updatedTitle,
          }))
        }
        onSuccess={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateEditChapterContext.Provider>
  );
};

export const useModalCreateEditChapter = () => {
  return useContext(ModalCreateEditChapterContext);
};
