import React from 'react';
import {NavLink} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {checkIsActive} from '../../../_metronic/_helpers';
import {useLocation} from 'react-router';
import SubMenuItem from './sub-menu-item';
import {FormattedMessage} from 'react-intl';

const defaultPath = '/';

export interface MenuItem {
  to: string;
  title: string;
  is_protected?: boolean;
  exact?: boolean;
  subMenuItems?: Array<MenuItem>;
  icon?: string;
}

export interface MenuItemProps {
  item: MenuItem;
}

const MenuItemComponent: React.FC<MenuItemProps> = ({item}: MenuItemProps) => {
  const location = useLocation();
  const getClassForActiveItem = (url: string, hasSubmenu = false) => {
    return checkIsActive(location, url, item.exact)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const classNames = getClassForActiveItem(item.to, item.subMenuItems != undefined && item.subMenuItems.length > 0);
  const renderMenuItemWithSubElements = () => {
    return (
      <li
        className={`menu-item menu-item-submenu ${classNames}`}
        aria-haspopup='true'
        data-menu-toggle='hover'>
        <NavLink className='menu-link menu-toggle' to={item.to}>
          {item.icon && item.icon.length > 0 && <span className='svg-icon menu-icon'>
              <SVG src={item.icon} />
          </span>}
          <span className='menu-text'><FormattedMessage id={item.title} /></span>
          <i className='menu-arrow' />
        </NavLink>
        <div className='menu-submenu'>
          <ul className='menu-subnav'>
            {item.subMenuItems?.map(subItem =>
              <SubMenuItem
                key={subItem.to}
                to={subItem.to}
                title={subItem.title}
                classNames={getClassForActiveItem(subItem.to)} />)}
          </ul>
        </div>
      </li>
    );
  };

  const renderMenuItem = () => {
    return (<li aria-haspopup='true' className={`menu-item ${classNames}`}>
      <NavLink className='menu-link' to={item.to || defaultPath}>
        {item.icon && item.icon.length > 0
        && <span className='svg-icon menu-icon'>
              <SVG src={item.icon} />
            </span>}
        <span className='menu-text'><FormattedMessage id={item.title} /></span>
      </NavLink>
    </li>);
  };

  return (
    <>
      {item.subMenuItems?.length
        ? renderMenuItemWithSubElements()
        : renderMenuItem()}
    </>
  );
};

export default MenuItemComponent;


