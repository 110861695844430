import {useCallback, useState} from 'react';

type InitialValueType<T extends string> = {[key in T]: boolean};

export function useLoading<T extends string>(
  initialValue: InitialValueType<T>,
): [
  InitialValueType<T>,
  (key: keyof InitialValueType<T>) => void,
  (key: keyof InitialValueType<T>) => void,
] {
  const [loadings, setLoadings] = useState<InitialValueType<T>>(initialValue);
  const start = useCallback((field: keyof InitialValueType<T>) => {
    setLoadings((prevState) => ({...prevState, [field]: true}));
  }, []);

  const stop = useCallback((field: keyof InitialValueType<T>) => {
    setLoadings((prevState) => ({...prevState, [field]: false}));
  }, []);

  return [loadings, start, stop];
}
