import React, {useMemo} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, FormattedTime, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {
  IPaymentRequestDto,
  PaymentRequestDestination,
  PaymentRequestStatus,
} from '../../../api/payment-api/IPaymentRequestDto';
import {DefaultTable} from '../../../components/default-table';
import {AdminRoutes} from '../../../../configs/routes';
import {BadgeCustom} from '../../../components/badge-custom';
import {TableUtils} from '../../../components/table-utils';

interface IUsersTableProps {
  paymentRequests: Array<IPaymentRequestDto>;
}

export const PaymentRequestsTable = ({paymentRequests}: IUsersTableProps) => {
  const intl = useIntl();
  const data = paymentRequests;
  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'ID'})),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: IPaymentRequestDto) => br.id,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(
            <Link to={AdminRoutes.getSpecifyPaymentRequestRoute(value)}>{value}</Link>,
          );
        },
      },
      {
        id: 'User',
        Header: intl.formatMessage({id: 'USER'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IPaymentRequestDto) => br,
        Cell: ({value}: {value: IPaymentRequestDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyUserManagementRoute(value.user_id)}>{value.user_name}</Link>,
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IPaymentRequestDto) => br,
        Cell: ({value}: {value: IPaymentRequestDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>{value.contractor_name}</Link>,
          );
        },
      },
      {
        id: 'Value',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUM'})),
        maxWidth: 200,
        minWidth: 100,
        accessor: (br: IPaymentRequestDto) => br,
        Cell: ({value}: {value: IPaymentRequestDto}) => {
          return TableUtils.renderNumericContent(
            <FormattedNumber value={value.value} style={'currency'} currency={value.currency} />,
          );
        },
      },
      {
        id: 'Type',
        Header: intl.formatMessage({id: 'ACCOUNT'}),
        maxWidth: 100,
        accessor: (br: IPaymentRequestDto) => br.destination,
        Cell: ({value}: {value: PaymentRequestDestination}) => {
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={value === PaymentRequestDestination.TO_MCPAY ? 'info' : 'primary'}>
              <FormattedMessage id={'PAYMENT_REQUEST_DESTINATION_' + value} />
            </BadgeCustom>,
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 100,
        accessor: (br: IPaymentRequestDto) => br.status,
        Cell: ({value}: {value: PaymentRequestStatus}) => {
          const colorVariant =
            value === PaymentRequestStatus.REJECT
              ? 'danger'
              : value === PaymentRequestStatus.COMPLETE
              ? 'success'
              : 'primary';
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={colorVariant}>
              <FormattedMessage id={'PAYMENT_REQUEST_STATUS_' + value} />
            </BadgeCustom>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        accessor: (br: IPaymentRequestDto) => br.created_at,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(
            <>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </>,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
