import React from 'react';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

interface Props {
  items: Array<YoutubeMetricsPanelItem>;
}

type Svg = string;
export type YoutubeMetricsPanelItem = {
  title: string;
  value: number;
  className?: string;
  icon?: Svg;
  iconClassName?: string;
};

export const YoutubeMetricsPanel: React.FC<Props> = ({items}) => {
  const renderMetrics = (title: string, value: number, svg?: string, iconClassName?: string) => {
    return (
      <div className={'d-flex align-items-center my-1'}>
        {svg && (
          <span className={cn('svg-icon svg-icon-3x mr-4', iconClassName)}>
            <SVG src={svg} />
          </span>
        )}

        <div className={'d-flex flex-column text-dark-75'}>
          <span className={'font-weight-bolder font-size-sm'}>
            <FormattedMessage id={title} />
          </span>
          <div className={'d-flex align-items-center'}>
            <span className={'font-size-h4'}>
              <FormattedNumber value={value} />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {items.map((item, index) => (
        <div key={index} className={cn(item.className)}>
          {renderMetrics(item.title, item.value, item.icon, item.iconClassName)}
        </div>
      ))}
    </>
  );
};
