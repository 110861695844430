import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {IApplicationStore} from '../redux/rootReducer';
import {IUserDto, UserType} from './api/DTOs/IUserDto';
import {Redirect} from 'react-router-dom';
import {ContentRoute} from '../_metronic/layout';
import {Routes} from '../configs/routes';

interface PrivateRouteProps {
  path: string;
  component?: any;
  render?: (args: any)=> any;
}

export const AdminRoute: React.FC<PrivateRouteProps> = ({component, path,  render, ...props}) => {
  const user = useSelector<IApplicationStore, IUserDto | null>(({auth}) => auth.user, shallowEqual);

  const hasAccess = user === null || user.type === UserType.ADMIN;
  if (!hasAccess) {
    return <Redirect to={Routes.getNotFoundRoute()} />;
  }

  return (
    <>
      <ContentRoute path={path} component={component} render={render} {...props} />
    </>
  );
};
