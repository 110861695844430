import React, {createContext, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalPlayVideo} from './modal-play-video';

interface IModalPlayVideoContext {
  showPlayVideoModal(videoId: string, title: string): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalPlayVideoContext = createContext<IModalPlayVideoContext>();

let closeResolver: (((data: CloseModalEvent<null>) => any) | null) = null;
export const ModalPlayVideoProvider = ({children}: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [title, setVideoTitle] = useState<string | null>(null);

  const showModal = async (videoId: string, title: string) => {
    setVisible(true);
    setVideoTitle(title);
    setVideoId(videoId);
    return new Promise<CloseModalEvent<null>>((resolve) => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setVideoId(null);
    setVideoTitle(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const value: IModalPlayVideoContext = {
    showPlayVideoModal: showModal,
  };

  return <ModalPlayVideoContext.Provider value={value}>
    {children}
    <ModalPlayVideo
      visible={visible}
      title={title}
      videoId={videoId}

      onHide={handleHideModal}
    />
  </ModalPlayVideoContext.Provider>;
};

export const useModalPlayVideo = () => {
  return useContext(ModalPlayVideoContext);
};
