import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {AuthApi} from '../../../api/auth-api';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {AlertCustom} from '../component/alert';
import {ApiRequestException} from '../../../api/axios-instance';
import {Routes} from '../../../../configs/routes';
import {IValidationException} from '../../../api/exceptions/IValidationException';

function ForgotPassword() {
  const intl = useIntl();

  const api = new AuthApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [email, setEmail] = useState<string>('');

  const handleSendForgotPasswordRequest = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setError(null);
      setValidationError(null);
      setSuccess(null);
      await api.resetRequestPassword(email);
      setSuccess(intl.formatMessage({id: 'SUCCESS_SENT_RESET_PASSWORD_EMAIL'}));
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };


  return <>
    <div className='login-form login-forgot' style={{display: 'block'}}>
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'><FormattedMessage id='AUTH.FORGOT.TITLE' /></h3>
        <div className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.FORGOT.DESC' />
        </div>
      </div>

      <form className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
        <AlertCustom
          text={error}
          type={'light-danger'}
          visible={error != null}
        />

        <AlertCustom
          text={success}
          type={'light-info'}
          visible={success != null}
        />

        <ValidateErrorWrapper message={validationErrors && validationErrors['email']}>
          <InputText
            name={'email'}
            type={'email'}
            value={email}
            hasError={validationErrors?.email != undefined}
            onChange={(e) => setEmail(e.currentTarget.value)}
            label={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>

        <div className='form-group d-flex flex-wrap flex-center'>
          <button
            onClick={handleSendForgotPasswordRequest}
            type='submit'
            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
            disabled={loading}>
            <FormattedMessage id={'SUBMIT'} />
            {loading && <span className='ml-3 spinner spinner-white' />}
          </button>
          <Link to={Routes.getAuthRoute()}>
            <button type='button' className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'>
              <FormattedMessage id={'CANCEL'} />
            </button>
          </Link>
        </div>
      </form>
    </div>
  </>;
}

export default connect(null, {})(ForgotPassword);
