import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../configs/routes';

const ILLUSTRATIONS = {
  SEARCH: require('../../../images/illustration/Search.png'),
};

export const KnowledgeBaseStubSection: React.FC<any> = () => {
  return (
    <div className='card'>
      <div className='card-body p-0'>
        <div className='card-px text-center pt-20 mt-10'>
          <h2 className='font-weight-boldest mt-10'>
            <FormattedMessage id={'KNOWLEDGE_BASE'} />
          </h2>
          <p className='text-gray-400 font-weight-bolder mb-10 save-line-and-tabulation'>
            <FormattedMessage
              id={'KNOWLEDGE_BASE_DESCRIPTION'}
              values={{supportlink: (content: string) => <Link to={Routes.getSupportRoute()}>{content}</Link>}}
            />
          </p>
        </div>
        <div className='text-center px-4'>
          <img className={'mw-100 mh-45vh'} src={ILLUSTRATIONS.SEARCH} alt='nones' />
        </div>
      </div>
    </div>
  );
};
