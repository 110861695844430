import React, {useMemo, useState} from 'react';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {FormattedMessage, useIntl} from 'react-intl';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {MailingsApi} from '../../../api/mailing-api/mailings-api';
import {IMailingDto} from '../../../api/mailing-api/IMailingDto';
import {AdminRoutes} from '../../../../configs/routes';
import {MailingsTable} from './mailings-table';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';

const ICONS = {
  PLUS: require('../../../images/svg/Plus.svg'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const MailingsPage: React.FC<any> = () => {
  const intl = useIntl();
  const api = new MailingsApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [mailings, setMailings] = useState<Array<IMailingDto>>();
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'mailings_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      filters: [
        {
          type: FilterType.TEXT,
          name: 'subject',
          placeholder: intl.formatMessage({id: 'SUBJECT'}),
        },
      ],
    });
  }, []);

  const fetchMailings = async (queryParams?: IQueryParams) => {
    try {
      setLoading(true);
      const result = await api.getMailingList(queryParams);
      setMailings(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderMailings = () => {
    if (!mailings || mailings?.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <MailingsTable mailings={mailings} />;
  };

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchMailings}
      toolbarConfig={[
        {
          title: intl.formatMessage({id: 'CREATE'}),
          type: 'LINK',
          to: AdminRoutes.getCreateMailingRoute(),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
        },
      ]}>
      {renderMailings()}
    </BaseListPage>
  );
};
