export interface IAdvertisementRequestDto {
  id: number;
  user_id: number;
  user_name: string;

  youtube_channel_id: string;
  youtube_channel_title: string;
  expected_views_count: number;

  has_tax_on_profits: boolean;
  tax_value: number;

  integration_enable: boolean;
  integration_price: number | null;

  advanced_integration_enable: boolean;
  advanced_integration_price: number | null;

  pre_roll_enable: boolean;
  pre_roll_price: number | null;

  post_enable: boolean;
  post_price: number | null;

  product_placement_enable: boolean;
  product_placement_price: number | null;

  special_project_enable: boolean;
  special_project_price: number | null;

  status: AdsStatus;
  comment: string | null;

  canDone: boolean;
  canReject: boolean;

  release_at: string;
  created_at: string;
  updated_at: string;
}

export enum AdsStatus {
  IN_WORK = 'IN_WORK',
  REJECTED = 'REJECTED',
  DONE = 'DONE',
}
