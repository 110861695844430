import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {CmsRevenueDataDto, DashboardApi} from '../../../api/dashboard-api/dashboard-api';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import Chart from 'react-apexcharts';
import {BaseListPage} from '../../base/base-list-page';

export const CmsRevenueAdminPage = () => {
  const intl = useIntl();
  const api = new DashboardApi();
  const [loading, setLoading] = useState(true);
  const [cmsRevenues, setCmsRevenues] = useState<CmsRevenueDataDto | null>(null);

  useEffect(() => {
    api
      .getCmsRevenues()
      .then(response => {
        setCmsRevenues(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <BaseListPage pageTitle={intl.formatMessage({id: 'DASHBOARD_CMS1_CLEAN_REVENUE'})} loading={loading}>
        <Chart
          type={'bar'}
          options={{
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return CustomFormatter.formatMoney(val);
                },
              },
            },
            dataLabels: {
              formatter: function (val) {
                return CustomFormatter.formatMoney(val as string);
              },
            },
            legend: {
              position: 'right',
              fontWeight: 'bold',
            },
            xaxis: {
              categories: cmsRevenues?.cmsRevenue1?.map(item =>
                intl.formatDate(new Date(item.year, item.month), {
                  month: 'long',
                  year: 'numeric',
                }),
              ),
              labels: {
                style: {
                  fontWeight: 'bolder',
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return CustomFormatter.formatMoney(val);
                },
              },
            },
          }}
          height={'300px'}
          series={[
            {
              name: 'Revenue',
              data: cmsRevenues?.cmsRevenue1?.map(item => item.revenue),
            },
          ]}
        />
      </BaseListPage>

      <BaseListPage pageTitle={intl.formatMessage({id: 'DASHBOARD_CMS2_CLEAN_REVENUE'})} loading={loading}>
        <Chart
          type={'bar'}
          options={{
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return CustomFormatter.formatMoney(val);
                },
              },
            },
            dataLabels: {
              formatter: function (val) {
                return CustomFormatter.formatMoney(val as string);
              },
            },
            legend: {
              position: 'right',
              fontWeight: 'bold',
            },
            xaxis: {
              categories: cmsRevenues?.cmsRevenue2?.map(item =>
                intl.formatDate(new Date(item.year, item.month), {
                  month: 'long',
                  year: 'numeric',
                }),
              ),
              labels: {
                style: {
                  fontWeight: 'bolder',
                },
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return CustomFormatter.formatMoney(val);
                },
              },
            },
          }}
          height={'300px'}
          series={[
            {
              name: 'Revenue',
              data: cmsRevenues?.cmsRevenue2?.map(item => item.revenue),
            },
          ]}
        />
      </BaseListPage>
    </>
  );
};
