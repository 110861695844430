import React from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {Transaction, TransactionStatus} from '../../../api/transaction-api/Transaction';
import {TransactionAction} from './transactions-page';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {ITransactionsAggregation} from '../../../api/transaction-api/transactions-response-contracts';
import {BadgeCustom} from '../../../components/badge-custom';
import {RowActionButton} from '../../../components/table-actions-button';
import {HandleTransactionActionPayload, HandleTransactionManagementPayload} from './useTransactionActionHandler';
import {TableUtils} from '../../../components/table-utils';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {Table} from '../../../components/table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/free-solid-svg-icons/faBan';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import {faX} from '@fortawesome/free-solid-svg-icons/faX';
import {faRegistered} from '@fortawesome/free-solid-svg-icons/faRegistered';
import {faCopyright} from '@fortawesome/free-solid-svg-icons';
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile';

interface ITransactionTableProps {
  transactions: Array<Transaction>;
  aggregationValues: ITransactionsAggregation | null;

  onSelectAction?(payload: HandleTransactionActionPayload): Promise<any>;
}

export const TransactionsTable = ({transactions, aggregationValues, onSelectAction}: ITransactionTableProps) => {
  const intl = useIntl();
  const data = transactions;
  const handleTransactionAction = async (rowDto: Transaction, action: TransactionAction) => {
    if (onSelectAction) {
      await onSelectAction({
        type: action,
        data: {transactionId: rowDto.id},
      } as HandleTransactionManagementPayload);
    }
  };

  return (
    <Table
      data={data}
      columns={[
        {
          id: 'id',
          forceApplySize: true,
          Header: intl.formatMessage({id: 'ID'}),
          maxWidth: 60,
          minWidth: 60,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <Link to={AdminRoutes.getSpecifyTransactionRoute(value.id)}>{value.id}</Link>,
            );
          },
        },
        {
          id: 'FROM',
          Header: intl.formatMessage({id: 'FROM'}),
          maxWidth: 250,
          minWidth: 50,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.fromContractorId)} className={'m-0 p-0'}>
                {value.fromContractorName}
              </Link>,
            );
          },
        },
        {
          id: 'TO',
          Header: intl.formatMessage({id: 'TO'}),
          maxWidth: 250,
          minWidth: 50,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.toContractorId)} className={'m-0 p-0'}>
                {value.toContractorName}
              </Link>,
            );
          },
        },
        {
          id: 'CHANNEL',
          Header: intl.formatMessage({id: 'CHANNEL'}),
          maxWidth: 150,
          minWidth: 100,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              value.youtubeChannelTitle != null ? (
                <Link to={Routes.getYoutubeSpecifyChannelRoute(value.youtubeChannelId as any)}>
                  {value.youtubeChannelTitle}
                </Link>
              ) : (
                'N/A'
              ),
            );
          },
        },
        {
          id: 'SOURCE',
          Header: intl.formatMessage({id: 'SOURCE'}),
          maxWidth: 100,
          minWidth: 100,
          Cell: ({value}) => {
            // noinspection RequiredAttributes
            return TableUtils.renderBaseContent(
              <div className={'text-center d-flex  align-items-center'}>
                {value.youtubeFinancialReportUploadHistoryItemReportType && (
                  <OverlayTrigger
                    placement='bottom'
                    trigger={['hover', 'focus']}
                    overlay={
                      <Popover id={''}>
                        <Popover.Title as='h3' className={'font-weight-bolder'}>
                          {intl.formatMessage({
                            id: 'YOUTUBE_REPORT_TYPE_' + value.youtubeFinancialReportUploadHistoryItemReportType,
                          })}
                        </Popover.Title>
                      </Popover>
                    }>
                    <div>
                      <FontAwesomeIcon icon={faFile} size={'xl'} className={'text-muted mr-1'} />
                    </div>
                  </OverlayTrigger>
                )}
                {value.is_for_copies && (
                  <OverlayTrigger
                    placement='top-end'
                    trigger={['hover', 'focus']}
                    overlay={
                      <Popover id={value.id.toString()}>
                        <Popover.Title as='h3' className={'text-primary font-weight-bolder'}>
                          <FormattedMessage id={'IS_COPY'} />
                        </Popover.Title>
                      </Popover>
                    }>
                    <div>
                      <FontAwesomeIcon icon={faCopyright} size={'xl'} className={'text-primary mr-1'} />
                    </div>
                  </OverlayTrigger>
                )}
                {value.is_for_referral && (
                  <OverlayTrigger
                    placement='top-end'
                    trigger={['hover', 'focus']}
                    overlay={
                      <Popover id={value.id.toString()}>
                        <Popover.Title as='h3' className={'text-primary font-weight-bolder'}>
                          <FormattedMessage id={'IS_REFERRAL'} />
                        </Popover.Title>
                      </Popover>
                    }>
                    <div>
                      <FontAwesomeIcon icon={faRegistered} size={'xl'} className={'text-primary'} />
                    </div>
                  </OverlayTrigger>
                )}
              </div>,
            );
          },
        },
        {
          id: 'SUM',
          Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUM'})),
          Footer: () => {
            if (!aggregationValues?.current_page_transactions_sum) {
              return null;
            }

            const transactionsSum = intl.formatNumber(Number(aggregationValues?.current_page_transactions_sum), {
              style: 'currency',
              currency: 'USD',
            });
            return TableUtils.renderNumericContent(transactionsSum);
          },
          maxWidth: 80,
          minWidth: 80,
          Cell: ({value}: {value: Transaction}) => {
            // noinspection RequiredAttributes
            return TableUtils.renderNumericContent(
              <p className={'m-0 p-0'}>
                <OverlayTrigger
                  placement='top-end'
                  trigger={['hover', 'focus']}
                  overlay={
                    <Popover id={value.id.toString()}>
                      <Popover.Title as='h3' className={'text-primary font-weight-bolder'}>
                        {value.localizedDescription}
                      </Popover.Title>
                    </Popover>
                  }>
                  <div>
                    <FormattedNumber value={value.value} style={'currency'} currency={value.currency} />
                  </div>
                </OverlayTrigger>
              </p>,
            );
          },
        },
        {
          id: 'Type',
          Header: intl.formatMessage({id: 'TYPE'}),
          maxWidth: 150,
          minWidth: 150,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <BadgeCustom variant={'secondary'}>
                <FormattedMessage id={'TRANSACTION_TYPE_' + value.type} />
              </BadgeCustom>,
            );
          },
        },
        {
          id: 'Status',
          Header: intl.formatMessage({id: 'STATUS'}),
          maxWidth: 50,
          minWidth: 50,
          accessor: (br: Transaction) => br.status,
          Cell: ({value}) => {
            let colorVariant;
            let icon;
            switch (value.status) {
              case TransactionStatus.CANCELED:
                icon = faBan;
                colorVariant = 'warning';
                break;
              case TransactionStatus.CONFIRMED:
                icon = faCheck;
                colorVariant = 'success';
                break;
              case TransactionStatus.WAITING_CONFIRM:
                icon = faClock;
                colorVariant = 'primary';
                break;
              case TransactionStatus.REJECTED:
                icon = faX;
                colorVariant = 'danger';
                break;
            }
            return TableUtils.renderBaseContent(
              <FontAwesomeIcon icon={icon} className={`text-${colorVariant}`} size={'xl'} />,
            );
          },
        },
        {
          id: 'Created_At',
          Header: intl.formatMessage({id: 'CREATED_AT'}),
          maxWidth: 100,
          minWidth: 100,
          accessor: (br: Transaction) => br,
          Cell: ({value}) => {
            return TableUtils.renderBaseContent(
              <>
                {intl.formatDate(value.createdAt)} {intl.formatTime(value.createdAt)}
              </>,
            );
          },
        },
        {
          id: 'Action',
          maxWidth: 50,
          visible: onSelectAction != null,
          Cell: ({value}) => {
            const actions = [
              {
                title: intl.formatMessage({id: 'APPROVE'}),
                value: TransactionAction.CONFIRM,
                disabled: !value.canConfirm,
              },
              {title: intl.formatMessage({id: 'REJECT'}), value: TransactionAction.REJECT, disabled: !value.canReject},
              {title: intl.formatMessage({id: 'CANCEL'}), value: TransactionAction.CANCEL, disabled: !value.canCancel},
            ];
            return TableUtils.renderActionButton(
              <RowActionButton rowDto={value} actions={actions} onSelect={handleTransactionAction} />,
            );
          },
        },
      ]}
    />
  );
};
