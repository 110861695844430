import React, {createContext, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {useIntl} from 'react-intl';
import {
  ModalChannelTransactionsIncomeReport,
  ModalChannelTransactionsIncomeReportFormState,
} from './modal-channel-transactions-income-report';
import {toast} from 'react-toastify';
import {prepareDate, ValidationErrorsType} from '../../../utils/utils';
import {ApiRequestException} from '../../../api/axios-instance';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {TransactionsApi} from '../../../api/transaction-api/transactions-api';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';

interface IModalChannelTransactionsIncomeReport {
  showModalIncomeReport(): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalChannelTransactionsIncomeReportContext = createContext<IModalChannelTransactionsIncomeReport>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalChannelTransactionsIncomeReportProvider = ({children}: any) => {
  const intl = useIntl();
  const api = new TransactionsApi();

  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>();
  const [entity, , updateEntity] = useAdvancedState<ModalChannelTransactionsIncomeReportFormState>({});

  const handleOpenModal = () => {
    setVisible(true);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleOkClick = async () => {
    try {
      setLoading(true);
      await api.generateChannelTransactionsIncomeReport({
        ...entity,
        start_date: prepareDate(entity.start_date) as any,
        end_date: prepareDate(entity.end_date) as any,
      });
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      handleHideClick();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err?.innerException as any).error_data.messages);
      } else {
        toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleHideClick = () => {
    setVisible(false);
    setValidationErrors(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
    }
  };

  const value: IModalChannelTransactionsIncomeReport = {
    showModalIncomeReport: handleOpenModal,
  };

  return (
    <ModalChannelTransactionsIncomeReportContext.Provider value={value}>
      {children}
      <ModalChannelTransactionsIncomeReport
        visible={visible}
        entity={entity}
        updateEntity={updateEntity}
        loading={loading}
        onHide={handleHideClick}
        onOkClick={handleOkClick}
        validationErrors={validationErrors}
      />
    </ModalChannelTransactionsIncomeReportContext.Provider>
  );
};

export const useModalIncomeReport = () => {
  return useContext(ModalChannelTransactionsIncomeReportContext);
};
