import React, {useEffect, useMemo, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {IYoutubeVideoDto} from '../../../api/DTOs/IYoutubeVideoDto';
import {YoutubeVideosApi} from '../../../api/youtube-videos-api';
import {Preloader} from '../../../components/preloader';
import {useIntl} from 'react-intl';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useModalPlayVideo} from '../../../components/modals/play-video/modal-play-video-context';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';

import './youtube-video-page.scss';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {Routes} from '../../../../configs/routes';
import {YoutubeMetricsPanelItem} from '../../../components/youtube-metrics-panel';
import {PreviewImage, YoutubeChannelOrVideoPreview} from '../../../components/YoutubeChannelOrVideoPreview';

const ICONS = {
  VISIBLE: require('../../../images/svg/Visible.svg'),
  SUBSCRIBER: require('../../../images/svg/Group.svg'),
  VIDEO: require('../../../images/svg/Video-Lane.svg'),
  COMMENTS: require('../../../images/svg/comments.svg'),
  DISLIKE: require('../../../images/svg/Dislike.svg'),
  LIKE: require('../../../images/svg/Like.svg'),
  PLAY: require('../../../images/svg/Play.svg'),
};

export const YoutubeVideoPage: React.FC = () => {
  const intl = useIntl();
  const match = useRouteMatch<{videoId: string}>();
  const videoId = match.params.videoId;

  const api = new YoutubeVideosApi();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);

  const [videoInfo, setVideoInfo] = useState<IYoutubeVideoDto | null>(null);
  const metrics = useMemo<YoutubeMetricsPanelItem[]>(() => {
    if (!videoInfo) {
      return [];
    }

    return [
      {
        title: 'VIEWS',
        value: videoInfo?.viewCount,
        icon: ICONS.VISIBLE,
        className: 'col-3',
        iconClassName: 'svg-icon-primary',
      },
      {
        title: 'LIKES',
        value: videoInfo.likeCount,
        icon: ICONS.LIKE,
        className: 'col-3',
        iconClassName: 'svg-icon-success',
      },
      {
        title: 'DISLIKES',
        value: videoInfo.dislikeCount,
        icon: ICONS.DISLIKE,
        className: 'col-3',
        iconClassName: 'svg-icon-danger',
      },
      {
        title: 'COMMENTS',
        value: videoInfo.commentCount,
        icon: ICONS.COMMENTS,
        className: 'col-3',
        iconClassName: 'svg-icon-secondary',
      },
    ];
  }, [videoInfo]);

  const {showPlayVideoModal} = useModalPlayVideo();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    const allFetch = async () => {
      await fetchVideoInfo();
    };
    allFetch();
  }, []);

  useEffect(() => {
    if (videoInfo?.title) {
      setBreadcrumb(videoInfo.title, 40);
    }
  }, [videoInfo?.title]);

  const fetchVideoInfo = async () => {
    try {
      setLoading(true);
      const response = await api.getVideoInfo(match.params.videoId);
      setVideoInfo(response.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePlayVideo = async () => {
    await showPlayVideoModal(videoId, videoInfo?.title || '');
  };

  if (loading) {
    return <Preloader />;
  }

  if (error || videoInfo === null) {
    return <ErrorStub error={error} />;
  }

  const renderVideoThumbnail = () => {
    return (
      <>
        <div className='mr-7' style={{maxWidth: '320px'}}>
          <div className={'preview-overlay'}>
            <img
              src={tryGetPreviewOrStub(videoInfo.thumbnails).url}
              alt='preview'
              className={'h-100 overlay-wrapper rounded'}
            />
            <span
              className={`overlay-layer cursor-pointer svg-icon svg-icon-8x svg-icon-white`}
              onClick={handlePlayVideo}>
              <SVG src={ICONS.PLAY} />
            </span>
          </div>
        </div>
      </>
    );
  };

  const getAlertMessage = () => {
    if (!videoInfo) {
      return null;
    }

    const {hiddenFromYoutubeAt, channelHiddenFromYoutubeAt} = videoInfo;
    let message: string | null = null;
    if (hiddenFromYoutubeAt !== null && channelHiddenFromYoutubeAt !== null) {
      message = 'HIDDEN_CHANNELS_AND_VIDEO_FROM_YOUTUBE';
    } else if (hiddenFromYoutubeAt !== null) {
      message = 'VIDEO_HIDDEN_FROM_YOUTUBE';
    } else if (channelHiddenFromYoutubeAt !== null) {
      message = 'CHANNEL_HIDDEN_FROM_YOUTUBE';
    }

    return message !== null
      ? intl.formatMessage(
          {id: message},
          {
            redirect: str => (
              <Link to={Routes.getFinancialReportsRoute()} className={'text-danger text-underline'}>
                {str}
              </Link>
            ),
          },
        )
      : null;
  };

  const renderPreview = () => {
    return (
      <YoutubeChannelOrVideoPreview
        title={videoInfo.title}
        redirectTo={Routes.getExternalYoutubeVideoRoute(videoInfo.id)}
        description={videoInfo.description}
        metrics={metrics}
        notice={getAlertMessage()}>
        <PreviewImage>{renderVideoThumbnail()}</PreviewImage>
      </YoutubeChannelOrVideoPreview>
    );
  };

  return (
    <div className={'row'}>
      <div className={'col-12'}>{renderPreview()}</div>
      <div className={'col-12'}>
        <iframe
          style={{borderRadius: '15px', minHeight: '750px'}}
          frameBorder='0'
          scrolling='no'
          width='100%'
          height='100%'
          src={`https://www.youtube.com/embed/${videoId}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com`}
        />
      </div>
    </div>
  );
};
