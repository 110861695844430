import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {ErrorStub} from '../../components/error-stub';
import {ApiRequestException} from '../../api/axios-instance';
import {SelectApi} from '../../api/select-api';
import {IPaginationInfo} from '../../api/Paginator';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import {AdvertisementsApi} from '../../api/advertisement-api/advertisements-api';
import {Routes} from '../../../configs/routes';
import {Link} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {AdvertisementsTable} from './avdertisements-table';
import {IAdvertisementRequestDto} from '../../api/advertisement-api/IAdvertisementRequestDto';
import {toast} from 'react-toastify';
import {useLoading} from '../../hooks/use-loading';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {SelectsValuesDTOs} from '../../components/Inputs/InputSelect';
import {BaseListPage} from '../base/base-list-page';

interface IProps {
  isAdmin?: boolean;
}

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

const ICONS = {
  PLUS: require('../../images/svg/Plus.svg'),
};

const ILLUSTRATIONS = {
  COMPLETE_LIST: require('../../images/illustration/Sketchy-2.png'),
};

export const AdvertisementsPage: React.FC<IProps> = ({isAdmin}) => {
  const intl = useIntl();

  const api = new AdvertisementsApi();
  const selectApi = new SelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [error, setError] = useState<string | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [advertisementsRequests, setAdvertisementsRequests] = useState<Array<IAdvertisementRequestDto> | null>(null);

  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({});

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'advertisement_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      allSelectValues: selectValues,
      loadingSelect: loadings.select,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          visible: isAdmin,
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);

  useEffect(() => {
    if (isAdmin) {
      fetchUsers().then();
    }
  }, []);

  const fetchUsers = async () => {
    try {
      startLoading('select');
      const result = await selectApi.getUsers();
      setSelectValues({user_id: result.data.items});
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const fetchAdvertisementsRequests = async (params?: IQueryParams) => {
    try {
      startLoading('page');
      const result = isAdmin
        ? await api.getUsersAdvertisementsRequests(params)
        : await api.getSelfAdvertisementsRequests(params);
      setAdvertisementsRequests(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderMarketingBlock = () => {
    return (
      <div className='card'>
        <div className='card-body p-0'>
          <div className='card-px text-center pt-20 mt-10'>
            <h2 className='font-weight-boldest mb-10'>
              <FormattedMessage id={'ADS_REQUESTS_NOT_FOUND'} />
            </h2>
            <p className='text-gray-400 font-weight-bolder mb-10' style={{whiteSpace: 'pre-wrap'}}>
              <FormattedMessage
                id={'CREATE_ADS_REQUEST_MARKETING_DESCRIPTION'}
                values={{
                  playersteamlink: (str: any) => (
                    <a href={'https://playersagency.ru/'} target={'_blank'} rel='noreferrer'>
                      {str}
                    </a>
                  ),
                  playerscontentbotlink: (str: any) => (
                    <a href={'https://t.me/PlayersContentBot'} target={'_blank'} rel='noreferrer'>
                      {str}
                    </a>
                  ),
                }}
              />
            </p>
            {!isAdmin && (
              <Link
                to={Routes.getCreateAdvertisementRequestRoute()}
                className='btn font-weight-boldest text-uppercase btn-primary'>
                <span className={`svg-icon svg-icon-light-success svg-icon-2x mr-2`}>
                  <SVG src={ICONS.PLUS} />
                </span>
                <FormattedMessage id={'CREATE'} />
              </Link>
            )}
          </div>
          <div className='text-center px-4 pb-10'>
            <img className={'mw-100 mh-45vh '} src={ILLUSTRATIONS.COMPLETE_LIST} alt='nones' />
          </div>
        </div>
      </div>
    );
  };

  const renderRequests = () => {
    if (advertisementsRequests === null || advertisementsRequests.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <AdvertisementsTable isAdmin={isAdmin} ads={advertisementsRequests} />;
  };

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      toolbarConfig={[
        {
          type: 'LINK',
          icon: ICONS.PLUS,
          visible: !isAdmin,
          title: intl.formatMessage({id: 'CREATE'}),
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          to: Routes.getCreateAdvertisementRequestRoute(),
        },
      ]}
      pagination={{info: paginationInfo}}
      fetchData={fetchAdvertisementsRequests}
      showMarketing={advertisementsRequests === null || (advertisementsRequests.length === 0 && !isAdmin)}
      renderMarketing={renderMarketingBlock}>
      {renderRequests()}
    </BaseListPage>
  );
};
