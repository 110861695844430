import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ContractStatus, IContractDto} from '../../../../api/contract-api/IContractDto';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {AdminRoutes} from '../../../../../configs/routes';
import {BadgeCustom} from '../../../../components/badge-custom';
import {RowActionButton} from '../../../../components/table-actions-button';
import {ContractAction} from '../contract-page/contract-page';
import {TableUtils} from '../../../../components/table-utils';

interface IContractsTableProps {
  contracts: Array<IContractDto>;
  onContractAction: (contractId: IContractDto, action: ContractAction) => Promise<any>;
}

export const ContractsTable = ({contracts, onContractAction}: IContractsTableProps) => {
  const intl = useIntl();
  const data = contracts;
  const handleAction = async (value: IContractDto, action: ContractAction) => {
    await onContractAction(value, action);
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'ContractId',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: IContractDto) => br,
        Cell: ({value}: {value: IContractDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractRoute(value.id)}>{value.id}</Link>,
          );
        },
      },
      {
        id: 'Contract_Number',
        Header: intl.formatMessage({id: 'CONTRACT_NUMBER'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: IContractDto) => br,
        Cell: ({value}: {value: IContractDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractRoute(value.id)}>{value.compositeNumber}</Link>,
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 500,
        minWidth: 350,
        accessor: (br: IContractDto) => br,
        Cell: ({value}: {value: IContractDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractorId)}>{value.contractorName}</Link>,
          );
        },
      },
      {
        id: 'Validity period',
        Header: intl.formatMessage({id: 'VALIDITY_PERIOD'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: IContractDto) => br,
        Cell: ({value}: {value: IContractDto}) => {
          return TableUtils.renderBaseContent(
            <>
              <span>{intl.formatDate(value.startedAt)}</span>&nbsp;-&nbsp;
              {value.expiresAt !== null ? <span>{intl.formatDate(value.expiresAt)}</span> : <span>N/A</span>}
            </>,
          );
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: IContractDto) => br.status,
        Cell: ({value}: {value: ContractStatus}) => {
          const colorVariant = value === ContractStatus.ACTIVE ? 'success' : 'danger';
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={colorVariant}>
              <FormattedMessage id={'CONTRACT_STATUS_' + value} />
            </BadgeCustom>,
          );
        },
      },
      {
        id: 'Button',
        maxWidth: 80,
        minWidth: 80,
        accessor: (br: IContractDto) => br,
        Cell: ({value}: {value: IContractDto}) => {
          const actions = [
            {
              title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
              value: ContractAction.DOWNLOAD,
            },
          ];
          return TableUtils.renderActionButton(
            <RowActionButton rowDto={value} actions={actions} onSelect={handleAction} />,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
