import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {ModalGenerateFinancialReport} from './modal-generate-financial-report';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';
import {IFinancialReportPeriodDto} from '../../../api/financial-report-api/IFinancialReportPeriodDto';
import {ContractsApi} from '../../../api/contract-api/contracts-api';
import {toast} from 'react-toastify';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useLoading} from '../../../hooks/use-loading';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';

interface IModalGenerateFinancialReportContext {
  modalGenerateFinancialReportVisible: boolean;

  showGenerateFinancialReportModal(userId: number, selectedContract?: ISelectValueDto): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalGenerateFinancialReportContext = createContext<IModalGenerateFinancialReportContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalGenerateFinancialReportProvider = ({children}: any) => {
  const intl = useIntl();
  const [userId, setUserId] = useState<number | null>(null);

  const api = new FinancialReportApi();
  const contractApi = new ContractsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    action: false,
    periods: true,
    contracts: true,
  });
  const [error, setError] = useState<string | null>(null);
  const [blockOperationMessage, setBlockOperationMessage] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [contracts, setContracts] = useState<Array<ISelectValueDto>>([]);
  const [selectedContract, setSelectedContract] = useState<ISelectValueDto | null>(null);
  const [availablePeriods, setAvailablePeriods] = useState<Array<IFinancialReportPeriodDto>>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<Array<IFinancialReportPeriodDto>>([]);

  const showModal = async (userId: number, selectedContract?: ISelectValueDto) => {
    setUserId(userId);
    if (selectedContract) {
      setSelectedContract(selectedContract);
    }

    setVisible(true);
    if (availablePeriods.length === 0) {
      await fetchAvailableMonth();
    }

    if (contracts.length === 0) {
      await fetchContracts(userId, selectedContract);
    }

    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const fetchAvailableMonth = async () => {
    try {
      startLoading('periods');
      const result = await api.getReportPeriods();
      setSelectedPeriods([result.data.items[0]]);
      setAvailablePeriods(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('periods');
    }
  };

  const fetchContracts = async (userId: number, selectedContract: ISelectValueDto | null = null) => {
    try {
      startLoading('contracts');
      const result = await contractApi.getContracts({filters: {user_id: userId}});
      const contractsAsSelectValues = result.data.items.map(
        c =>
          ({
            id: c.id.toString(),
            title: c.compositeNumber,
          } as ISelectValueDto),
      );

      setContracts(contractsAsSelectValues);
      if (result.data.items.length === 0) {
        setBlockOperationMessage(intl.formatMessage({id: 'EMPTY_REPORT_CANNOT_CREATE_FINANCIAL_REPORT'}));
      }

      if (result.data.items.length > 0 && selectedContract == null) {
        setSelectedContract(contractsAsSelectValues[0]);
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('contracts');
    }
  };

  const handleCheckboxPeriodClick = (period: IFinancialReportPeriodDto) => {
    if (selectedPeriods.includes(period)) {
      const excludedPeriods = selectedPeriods.filter(p => p.id !== period.id);
      setSelectedPeriods(excludedPeriods);
    } else {
      setSelectedPeriods([...selectedPeriods, period]);
    }
  };

  const handleCheckboxContractClick = (contract: ISelectValueDto) => {
    if (contract === selectedContract) {
      setSelectedContract(null);
    } else {
      setSelectedContract(contract);
    }
  };

  const handleHideModal = () => {
    setUserId(null);
    setVisible(false);
    setError(null);
    setSelectedPeriods([]);
    setAvailablePeriods([]);
    setSelectedContract(null);
    setContracts([]);
    setValidationErrors(null);
    setBlockOperationMessage(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      if (userId === null) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('User id should be not null');
      }
      setError(null);
      setValidationErrors(null);
      setError(null);
      startLoading('action');
      await api.sendUserGenerateReportRequest(
        userId,
        selectedContract?.id ?? null,
        selectedPeriods.map(i => i.id),
      );
      toast.info(intl.formatMessage({id: 'SUCCESS_SEND_GENERATE_REPORT_REQUEST'}));
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('action');
    }
  };

  const value: IModalGenerateFinancialReportContext = {
    modalGenerateFinancialReportVisible: visible,
    showGenerateFinancialReportModal: showModal,
  };

  return (
    <ModalGenerateFinancialReportContext.Provider value={value}>
      {children}
      <ModalGenerateFinancialReport
        loading={loadings.action}
        visible={visible}
        loadingContracts={loadings.contracts}
        loadingPeriods={loadings.periods}
        availableMonths={availablePeriods}
        selectedMonths={selectedPeriods}
        availableContracts={contracts}
        error={error}
        blockedOperationMessage={blockOperationMessage}
        validationErrors={validationErrors}
        selectedContract={selectedContract}
        onSelectContract={handleCheckboxContractClick}
        onSelectPeriod={handleCheckboxPeriodClick}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalGenerateFinancialReportContext.Provider>
  );
};

export const useModalGenerateFinancialReport = () => {
  return useContext(ModalGenerateFinancialReportContext);
};
