import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {IResponseContractor, IResponseContractors} from './contractors-response-contracts';
import {IResponseBase} from '../response-contracts/base-response';

export class ContractorsApi extends BaseEndpoint {
  public async getContractors(filters?: IQueryParams): Promise<IResponseContractors> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IResponseContractors>(`${config.BASE_API_URL}/admin/contractors?${query}`);
    return response.data;
  }

  public async getContractorInfo(contractorId: number): Promise<IResponseContractor> {
    const response = await this.get<IResponseContractor>(`${config.BASE_API_URL}/admin/contractors/${contractorId}`);
    return response.data;
  }

  public async editContractor(
    contractorId: number | null,
    name: string | null,
    jurisdiction_id: string | number | null,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/contractors/${contractorId}`, {
      fields: {name, jurisdiction_id},
    });

    return response.data;
  }
}
