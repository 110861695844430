import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import objectPath from 'object-path';
import {useHtmlClassService} from '../../../_metronic/layout';
import {DropdownTopbarItemToggler} from '../../../_metronic/_partials/dropdowns';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../../configs/routes';
import {useUserRole} from '../../hooks/use-user-role';

export function UserProfileDropdown() {
  const [isAdmin, user] = useUserRole();
  const uiService = useHtmlClassService();
  useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light',
    };
  }, [uiService]);
  return (
    <Dropdown drop='down' alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id='dropdown-toggle-user-profile'>
        <div className={'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'}>
          <span className='text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3'>{user?.email}</span>
          <span className='symbol symbol-35 symbol-light-success'>
            <span className='symbol-label font-size-h5 font-weight-bold'>{user?.email.charAt(0)}</span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
        <div className='navi navi-spacer-x-0 pt-5'>
          <Link to={Routes.getDownloadsRoute()} className='navi-item px-8'>
            <div className='navi-link'>
              <div className='navi-icon mr-2'>
                <i className='flaticon2-download-2 text-primary' />
              </div>
              <div className='navi-text'>
                <div className='font-weight-bold'>
                  <FormattedMessage id={'DOWNLOADS'} />
                </div>
              </div>
            </div>
          </Link>
          {!isAdmin && (
            <Link to={Routes.getSupportRoute()} className='navi-item px-8'>
              <div className='navi-link'>
                <div className='navi-icon mr-2'>
                  <i className='flaticon2-help text-danger' />
                </div>
                <div className='navi-text'>
                  <div className='font-weight-bold'>
                    <FormattedMessage id={'SUPPORT'} />
                  </div>
                </div>
              </div>
            </Link>
          )}
          <div className='navi-separator mt-3' />
          <div className='navi-footer  px-8 py-5'>
            <Link to={Routes.getLogoutRoute()} className='btn btn-light-primary font-weight-bold'>
              <FormattedMessage id={'LOGOUT'} />
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
