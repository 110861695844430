import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {IUserDto, UserType} from '../../../api/DTOs/IUserDto';
import {ModalEditUser} from './modal-edit-user';
import {UsersApi} from '../../../api/user-api/users-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';

interface IModalEditUserContext {
  modalEditUserVisible: boolean;

  showEditUserModal(user: IUserDto): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalEditUserContext = createContext<IModalEditUserContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalEditUserProvider = ({children}: any) => {
  const intl = useIntl();

  const api = new UsersApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [, setUser] = useState<IUserDto | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [email, setEmail] = useState<string>('');
  const [isAdmin, setAdminMode] = useState<boolean>(false);

  const showModal = async (user: IUserDto) => {
    setVisible(true);
    setUser(user);
    setUserId(user.id);
    setEmail(user.email);
    setAdminMode(user.type === UserType.ADMIN);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setEmail('');
    setUser(null);
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      if (userId === null) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('User ID not be null');
      }

      setError(null);
      await api.editUser({
        id: userId,
        email: email,
      });
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalEditUserContext = {
    modalEditUserVisible: visible,
    showEditUserModal: showModal,
  };

  return (
    <ModalEditUserContext.Provider value={value}>
      {children}
      <ModalEditUser
        visible={visible}
        email={email}
        type={isAdmin ? UserType.ADMIN : UserType.USER}
        error={error}
        validationErrors={validationErrors}
        onEmailChange={email => setEmail(email)}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalEditUserContext.Provider>
  );
};

export const useModalEditUser = () => {
  return useContext(ModalEditUserContext);
};
