import React, {MouseEvent, useCallback, useRef, useState} from 'react';
import {IChapterDto} from '../../../../api/knowledge-base-api/IChapterDto';
import {IArticleSimplified} from '../../../../api/knowledge-base-api/IArticleDto';
import {ActiveSidebarSectionInfo} from './sidebar';
import cn from 'classnames';
import SVG from 'react-inlinesvg';
import {Accordion} from 'react-bootstrap';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {useIntl} from '../../../../hooks/use-intl';
import {ArticleSidebarItem} from './article-sidebar-item';
import {AdminRoutes, Routes} from '../../../../../configs/routes';
import {useHistory} from 'react-router-dom';
import {KnowledgeBaseApi} from '../../../../api/knowledge-base-api/knowledge-base-api';
import {changeArrayPosition} from '../../../../utils/utils';
import {useDeepCompareEffect} from '../../../../hooks/use-deep-compare-effect';
import {ApiRequestException} from '../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useDrag, useDrop} from 'react-dnd';
import {useDebouncedCallback} from 'use-debounce';
import {AppLocale} from '../../../../../localization';
import './chapter-sidebar-item.scss';
import {useUserRole} from '../../../../hooks/use-user-role';

type Props = {
  chapter: IChapterDto;
  articles: Array<IArticleSimplified>;
  isExpanded: boolean;
  activeSidebarSectionInfo: ActiveSidebarSectionInfo | null;
  setActiveSidebarSectionInfo: (selectedItemInfo: ActiveSidebarSectionInfo) => void;
  onChapterClick: (accordionId: number) => void;
  onEditChapter: (chapterId: number) => void;
  onDeleteChapter: (chapterId: number) => void;
  onDragChapter: (dragIndex: number, hoverIndex: number) => void;
  onDropChapter: (dragIndex: number, hoverIndex: number) => void;
  blockSidebar: (blockStatus: boolean) => void;
  fetchSidebarInfo: () => Promise<any>;
};

const ICONS = {
  ARROW: require('../../../../images/svg/Angle-right.svg'),
  DRAGGABLE: require('../../../../images/svg/VerticalDots.svg'),
  BIN: require('../../../../images/svg/Trash.svg'),
  EDIT: require('../../../../images/svg/Edit.svg'),
  PLUS: require('../../../../images/svg/Plus.svg'),
};

const ChapterDropdownCustomToggle = React.forwardRef<any>(({children, onClick}: any, ref) => {
  return (
    <>
      <span
        className={'svg-icon'}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }}>
        <SVG src={ICONS.DRAGGABLE} />
      </span>
      {children}
    </>
  );
});
ChapterDropdownCustomToggle.displayName = 'ChapterDropdownCustomToggle';

export const ChapterSidebarItem: React.FC<Props> = ({
  activeSidebarSectionInfo,
  articles: articlesFromProps,
  chapter,
  isExpanded,
  setActiveSidebarSectionInfo,
  onChapterClick,
  onEditChapter,
  onDeleteChapter,
  onDragChapter,
  onDropChapter,
  fetchSidebarInfo,
  blockSidebar,
}) => {
  const intl = useIntl();
  const [isAdmin] = useUserRole();
  const api = new KnowledgeBaseApi(isAdmin);
  const history = useHistory();
  const [articles, setArticles] = useState<Array<IArticleSimplified>>(articlesFromProps);
  const ref = useRef(null);

  useDeepCompareEffect(() => {
    if (articlesFromProps) {
      setArticles(articlesFromProps);
    }
  }, [articlesFromProps]);

  const handleChapterDrag = useDebouncedCallback((dragIndex: number, hoverIndex: number) => {
    onDragChapter(dragIndex, hoverIndex);
  }, 300);

  const handleChapterDrop = useDebouncedCallback((dragIndex: number, hoverIndex: number) => {
    onDropChapter(dragIndex, hoverIndex);
  }, 300);

  const [{isDragging}, drag, dragPreview] = useDrag(() => ({
    type: 'CHAPTER_SIDEBAR_ITEM',
    item: () => {
      return {chapterId: chapter.id};
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: 'CHAPTER_SIDEBAR_ITEM',
    drop(item: any) {
      handleChapterDrop(item.chapterId, item.insteadChapterId);
    },
    hover: function (item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const chapterId = item.chapterId;
      const insteadChapterId = chapter.id;
      if (chapterId === insteadChapterId) {
        return;
      }
      const hoverBoundingRect = (ref.current as any).getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (chapterId < insteadChapterId && hoverClientY < hoverMiddleY) {
        return;
      }
      if (chapterId > insteadChapterId && hoverClientY > hoverMiddleY) {
        return;
      }

      handleChapterDrag(chapterId, insteadChapterId);
      item.insteadChapterId = insteadChapterId;
    },
  }));

  const isCanDrop = (articleId: number, insteadArticleId: number) => {
    return articles.filter(article => article.id === articleId || article.id === insteadArticleId).length === 2;
  };

  const changeArticlePosition = useCallback(
    (articleId: number, toArticleId: number) => {
      const fromIndex = articles.findIndex(article => article.id === articleId);
      const toIndex = articles.findIndex(article => article.id === toArticleId);
      setArticles(changeArrayPosition(fromIndex, toIndex, articles));
    },
    [articles],
  );

  const handleDragArticle = async (articleId: number, insteadArticleId: number) => {
    if (isCanDrop(articleId, insteadArticleId)) {
      changeArticlePosition(articleId, insteadArticleId);
    }
  };

  const handleDropArticle = useCallback(
    async (articleId: number, insteadArticleId: number) => {
      if (!isCanDrop(articleId, insteadArticleId)) {
        return;
      }
      try {
        blockSidebar(true);
        await api.updateArticlePosition(
          articleId,
          articles.findIndex(a => a.id === articleId),
        );
        await fetchSidebarInfo();
      } catch (e) {
        const err = e as ApiRequestException;
        toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      } finally {
        blockSidebar(false);
      }
    },
    [articles],
  );

  const handleChapterClick = (e: MouseEvent<HTMLDivElement>) => {
    onChapterClick(Number(e.currentTarget.id.split('_')[1]));
  };

  const handleSelectArticle = (articleId: number) => {
    setActiveSidebarSectionInfo({type: 'view', payload: {chapterId: chapter.id, articleId}});
    history.push(Routes.getSpecifyArticleRoute(articleId));
  };

  const handleCreateArticle = () => {
    setActiveSidebarSectionInfo({type: 'create', payload: {chapterId: chapter.id}});
    history.push(AdminRoutes.getCreateArticleRoute(chapter.id));
  };

  const handleChapterAction = (actionName: 'EDIT' | 'DELETE') => {
    if (actionName === 'EDIT') {
      onEditChapter(chapter.id);
      return;
    }

    if (actionName === 'DELETE') {
      onDeleteChapter(chapter.id);
      return;
    }
  };

  const renderCreateArticleButton = () => {
    return (
      <Toolbar
        visible={isAdmin}
        className={'w-100'}
        items={[
          {
            title: intl.formatMessage({id: 'CREATE_ARTICLE'}),
            type: 'BUTTON',
            className: 'btn btn-xs btn-link w-100 font-weight-bold',
            onClick: handleCreateArticle,
          },
        ]}
      />
    );
  };

  drop(drag(dragPreview(ref)));
  return (
    <Accordion className={'chapter'} activeKey={isExpanded ? chapter.id.toString() : undefined}>
      <div {...(isAdmin ? {ref} : {})} className={'card'} style={{opacity: isDragging ? 0 : 1}}>
        <div id={`accordion_${chapter.id}`} className={cn('card-header chapter-header')} onClick={handleChapterClick}>
          <div className={cn('card-title')}>
            <Toolbar
              visible={isAdmin}
              className={'chapter-toolbar'}
              items={[
                {
                  type: 'DROPDOWN',
                  asToggle: ChapterDropdownCustomToggle,
                  actions: [
                    {
                      title: intl.formatMessage({id: 'EDIT'}),
                      value: 'EDIT',
                    },
                    {title: intl.formatMessage({id: 'DELETE'}), value: 'DELETE'},
                  ],
                  onSelect: handleChapterAction,
                },
              ]}
            />
            <div className={'ml-1 text-dark font-weight-bold'}>
              {intl.locale === AppLocale.RU ? chapter.title_ru : chapter.title_en}
            </div>
          </div>
          <div className={cn('card-toolbar', 'p-1', {'svg-rotate-90': isExpanded})}>
            <span className={'svg-icon svg-icon-primary'}>
              <SVG src={ICONS.ARROW} />
            </span>
          </div>
        </div>
        <div className={'card-body'}>
          <Accordion.Collapse eventKey={chapter.id.toString()}>
            <div className={'articles-block'}>
              {articles.map(article => (
                <ArticleSidebarItem
                  key={article.id}
                  isActive={
                    (activeSidebarSectionInfo?.type === 'view' || activeSidebarSectionInfo?.type === 'edit') &&
                    activeSidebarSectionInfo?.payload?.articleId === article.id
                  }
                  article={article}
                  onArticleClick={handleSelectArticle}
                  onDragArticle={handleDragArticle}
                  onDropArticle={handleDropArticle}
                />
              ))}
              {renderCreateArticleButton()}
            </div>
          </Accordion.Collapse>
        </div>
      </div>
    </Accordion>
  );
};
