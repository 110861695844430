import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {AccountApi} from '../../../../api/account-api';
import {IFinancialAccountDto} from '../../../../api/DTOs/IFinancialAccountDto';
import {ApiRequestException} from '../../../../api/axios-instance';
import {Preloader} from '../../../../components/preloader';
import {ErrorStub} from '../../../../components/error-stub';
import {ITransactionOperationDto} from '../../../../api/DTOs/ITransactionOperationDto';
import {toast} from 'react-toastify';
import {IContractorDto} from '../../../../api/contractor-api/IContractorDto';
import {TransactionOperationsTable} from '../../../revenues/transaction-operations-table';
import {IPaginationInfo} from '../../../../api/Paginator';
import {PaginationComponent} from '../../../../components/pagination';
import {ContractorInfo} from '../../../../components/contractor-info';
import {TransactionsActionsToolbar} from '../../../../components/card-toolbar/transactions-actions-toolbar';
import {useLoading} from '../../../../hooks/use-loading';
import {TIMESTAMP_CONFIGS, Timestamps} from '../../../../components/timestamps';
import {BootstrapColor} from '../../../../styles/styles';

export const FinancialAccountPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const accountId = match.params.id;

  const api = new AccountApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    operations: true,
  });

  const [account, setAccount] = useState<IFinancialAccountDto | null>(null);
  const [operations, setOperations] = useState<Array<ITransactionOperationDto>>([]);
  const [contractor, setContractor] = useState<IContractorDto | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  useEffect(() => {
    fetchAccount();
    fetchOperations();
  }, []);

  const fetchAccount = async () => {
    try {
      startLoading('page');
      setError(null);
      const result = await api.getFinancialAccount(Number(accountId));
      setAccount(result.data.item);
      setContractor(result.data.relations.contractor);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchOperations = async (page?: number) => {
    try {
      startLoading('operations');
      const result = await api.getSpecifyAccountTransactionOperations(Number(accountId), {page: page});
      setOperations(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('operations');
    }
  };

  const handlePageChange = async (page: number) => {
    await fetchOperations(page);
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (account === null) {
    return null;
  }

  const renderOperations = () => {
    if (operations.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <TransactionOperationsTable operations={operations} />;
  };

  const renderOperationsBlock = () => {
    return (
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header flex-wrap'}>
          <div className={'card-title'}>
            <FormattedMessage id={'ACCOUNT_OPERATIONS'} />
          </div>
        </div>
        <div className={`card-body`}>{loadings.operations ? <Preloader /> : renderOperations()}</div>
        <div className={'card-footer'}>
          {paginationInfo && (
            <PaginationComponent threshold={4} paginator={paginationInfo} onChange={handlePageChange} />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                <FormattedMessage id={'BALANCE'} />:{' '}
                <FormattedNumber value={account.balance} currency={'USD'} style={'currency'} />
              </p>
              <p className={'font-size-h4 font-weight-boldest mb-0'}>
                <FormattedMessage id={'UNCONFIRMED_BALANCE'} />:{' '}
                <FormattedNumber value={account.waitingConfirmBalance} currency={'USD'} style={'currency'} />
              </p>
              <p className={'font-size-h5 font-weight-bolder mt-3 mb-1 text-muted'}>
                <FormattedMessage id={'TITLE'} />: <span>{account.name}</span>
              </p>
              <p className={'font-size-h5 font-weight-bolder text-muted'}>
                <FormattedMessage id={'TYPE'} />: <span>{account.type}</span>
              </p>
            </div>
            <Timestamps
              items={[
                {
                  title: intl.formatMessage({id: 'CREATED_AT'}),
                  date: account.createdAt,
                  format: TIMESTAMP_CONFIGS.DEFAULT,
                  color: BootstrapColor.SUCCESS,
                },
                {
                  title: intl.formatMessage({id: 'UPDATED_AT'}),
                  date: account.updatedAt,
                  format: TIMESTAMP_CONFIGS.DEFAULT,
                  color: BootstrapColor.PRIMARY,
                },
              ]}
            />
          </div>
          <div className={'separator separator-solid my-7'} />
          <TransactionsActionsToolbar
            showManualCreateTransactionButtons
            filters={{account_id: Number(accountId)}}
            partOfContractor={{
              id: account.contractorId,
              name: account.contractorName,
            }}
          />
          <div className={'separator separator-solid my-7'} />
          {<ContractorInfo contractor={contractor} />}
        </div>
      </div>

      {renderOperationsBlock()}
    </>
  );
};
