import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Link, useHistory} from 'react-router-dom';
import {ErrorStub} from '../../../components/error-stub';
import {DashboardApi} from '../../../api/dashboard-api/dashboard-api';
import {WidgetWithActionButton} from '../../../components/dashboard-widgets/widget-with-action-button';
import {ApiRequestException} from '../../../api/axios-instance';
import {Preloader} from '../../../components/preloader';
import {WidgetSecond} from '../../../components/dashboard-widgets/widget-second';
import {IDashboardAdminDataDto} from '../../../api/dashboard-api/IDashboardAdminDataDto';
import {WidgetMultiData} from '../../../components/dashboard-widgets/widget-multi-data';
import {TransactionStatus} from '../../../api/transaction-api/Transaction';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {CustomFormatter} from '../../../../localization/custom-formatter';

const ICONS = {
  VIEWS: require('../../../images/svg/Visible.svg'),
  VIDEOS: require('../../../images/svg/Video-Lane.svg'),
  YOUTUBE: require('../../../images/png/youtube_social_icon_red.png'),
  DOLLAR: require('../../../images/svg/Dollar.svg'),
  MONEY: require('../../../images/svg/Money.svg'),
  CHECK: require('../../../images/svg/Check.svg'),
  CROSS: require('../../../images/svg/Close.svg'),
  FORWARD: require('../../../images/svg/Forward.svg'),
  USERS: require('../../../images/svg/Group.svg'),
};

const BACKGROUNDS = {
  HEADER: require('../../../images/backgrounds/bg-10.jpg'),
  USER_MANAGEMENT: require('../../../images/svg/patterns/rhone.svg'),
  TRANSACTIONS_MANAGEMENT: require('../../../images/svg/patterns/taieri.svg'),
  PAYMENTS_MANAGEMENT: require('../../../images/svg/patterns/rhone-2.svg'),
};

export const DashboardAdminPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();

  const api = new DashboardApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dashboardData, setDashboardData] = useState<IDashboardAdminDataDto | null>(null);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchDashboardsData();
  }, []);

  const fetchDashboardsData = async () => {
    try {
      setLoading(true);
      const result = await api.getAdminDashboard();
      setDashboardData(result.data);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClickOnWidget = (redirectTo: string) => {
    history.push(redirectTo);
  };

  if (loading) {
    return <Preloader />;
  }

  if (dashboardData === null || error !== null) {
    return <ErrorStub error={error || intl.formatMessage({id: 'NOT_FOUND'})} />;
  }

  const renderInfo = () => {
    return (
      <>
        <div className={'row'}>
          <div className={'col-xl-12 col-md-12'}>
            <WidgetSecond
              icon={ICONS.MONEY}
              title={intl.formatMessage({id: 'DASHBOARD_CONTRACTORS_INNER_ACCOUNTS_BALANCE_AMOUNT'})}
              value={dashboardData.contractsInnerAccountsBalanceAmount}
              style={'warning'}
              onClick={() => handleClickOnWidget(AdminRoutes.getFinancialAccountsRoute())}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-xl-12 col-md-12'}>
            <WidgetSecond
              icon={ICONS.MONEY}
              onClick={() => handleClickOnWidget(AdminRoutes.getCMSRevenueRoute())}
              title={
                intl.formatMessage({id: 'DASHBOARD_CLEAN_REVENUE'}) +
                ': ' +
                dashboardData.cmsRevenue?.year +
                '.' +
                (dashboardData.cmsRevenue?.month + 1)
              }
              value={dashboardData.cmsRevenue.totalRevenue}
              style={'warning'}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-xl-6 col-md-12'}>
            <WidgetSecond
              icon={ICONS.MONEY}
              onClick={() => handleClickOnWidget(AdminRoutes.getCMSRevenueRoute())}
              title={
                intl.formatMessage({id: 'DASHBOARD_CMS1_CLEAN_REVENUE'}) +
                ': ' +
                dashboardData.cmsRevenue?.year +
                '.' +
                (dashboardData.cmsRevenue?.month + 1)
              }
              value={dashboardData.cmsRevenue.cms1Revenue ?? 0}
              style={'warning'}
            />
          </div>
          <div className={'col-xl-6 col-md-12'}>
            <WidgetSecond
              icon={ICONS.MONEY}
              title={
                intl.formatMessage({id: 'DASHBOARD_CMS2_CLEAN_REVENUE'}) +
                ': ' +
                dashboardData.cmsRevenue?.year +
                '.' +
                (dashboardData.cmsRevenue?.month + 1)
              }
              value={dashboardData.cmsRevenue.cms2Revenue ?? 0}
              onClick={() => handleClickOnWidget(AdminRoutes.getCMSRevenueRoute())}
              style={'warning'}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-xl-6 col-md-12'}>
            <WidgetSecond
              icon={ICONS.CROSS}
              title={intl.formatMessage({id: 'DASHBOARD_ERRORS_COUNT'})}
              value={dashboardData.errorsCountToday}
              style={'danger'}
              onClick={() => handleClickOnWidget(AdminRoutes.getUsersActionsRoute())}
            />
          </div>
          <div className={'col-xl-6 col-md-12'}>
            <WidgetSecond
              icon={ICONS.DOLLAR}
              title={intl.formatMessage({id: 'DASHBOARD_TRANSACTION_WAITING_CONFIRM_COUNT'})}
              value={dashboardData.transactionWaitingConfirmCount}
              style={'success'}
              onClick={() =>
                handleClickOnWidget(AdminRoutes.getTransactionsRoute({status: TransactionStatus.WAITING_CONFIRM}))
              }
            />
          </div>
        </div>
      </>
    );
  };

  const renderHelloBlock = () => {
    return (
      <div
        className={'card card-custom gutter-b bgi-no-repeat bgi-size-cover'}
        style={{backgroundImage: `url('${BACKGROUNDS.HEADER}')`, height: '200px'}}>
        <div className={'card-body'}>
          <h1 className={'text-uppercase font-weight-boldest text-white'}>{intl.formatMessage({id: 'DASHBOARD'})}</h1>
        </div>
      </div>
    );
  };

  const renderChannelsInfo = () => {
    return (
      <>
        <div className={'row'}>
          <div className={'col-xl-3'}>
            <WidgetSecond
              icon={ICONS.VIDEOS}
              title={intl.formatMessage({id: 'DASHBOARD_VIDEOS_COUNT'})}
              value={dashboardData.youtubeVideosCount}
              style={'warning'}
              onClick={() => handleClickOnWidget(Routes.getYoutubeVideosRoute())}
            />
          </div>
          <div className={'col-xl-3'}>
            <WidgetSecond
              icon={() => <img src={ICONS.YOUTUBE} width={52} alt={'YouTube-logo'} />}
              title={intl.formatMessage({id: 'DASHBOARD_CHANNELS_COUNT'})}
              value={dashboardData.youtubeChannelsCount}
              style={'danger'}
              onClick={() => handleClickOnWidget(Routes.getYoutubeChannelsRoute())}
            />
          </div>
          <div className={'col-xl-6'}>
            <WidgetSecond
              icon={() => <img src={ICONS.YOUTUBE} width={52} alt={'YouTube-logo'} />}
              title={intl.formatMessage({id: 'DASHBOARD_CHANNELS_WITHOUT_ADDENDUM'})}
              value={() => (
                <div>
                  {dashboardData?.youtubeChannelsWithoutAddendum.length > 0
                    ? dashboardData?.youtubeChannelsWithoutAddendum.map((ch, index) => (
                        <Link
                          key={index}
                          className={'font-weight-boldest font-size-h6'}
                          to={Routes.getYoutubeSpecifyChannelRoute(ch.id)}>
                          {ch.title} {index !== dashboardData?.youtubeChannelsWithoutAddendum.length - 1 ? ', ' : ''}
                        </Link>
                      ))
                    : intl.formatMessage({id: 'NOT_FOUND'})}
                </div>
              )}
              style={'danger'}
            />
          </div>
        </div>
      </>
    );
  };

  const renderBlockWithLink = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-4'}>
          <WidgetWithActionButton
            title={intl.formatMessage({id: 'USER_MANAGEMENT'})}
            background={BACKGROUNDS.USER_MANAGEMENT}
            actionButton={
              <Link to={AdminRoutes.getUserManagementRoute()} className={'btn btn-outline-primary'}>
                {intl.formatMessage({id: 'GO'})}
              </Link>
            }
          />
        </div>
        <div className={'col-xl-4'}>
          <WidgetWithActionButton
            title={intl.formatMessage({id: 'DASHBOARD_TRANSACTION_MANAGEMENT'})}
            background={BACKGROUNDS.TRANSACTIONS_MANAGEMENT}
            actionButton={
              <Link to={AdminRoutes.getTransactionsRoute()} className={'btn btn-outline-primary'}>
                {intl.formatMessage({id: 'GO'})}
              </Link>
            }
          />
        </div>
        <div className={'col-xl-4'}>
          <WidgetWithActionButton
            title={intl.formatMessage({id: 'DASHBOARD_PAYMENTS_MANAGEMENT'})}
            background={BACKGROUNDS.PAYMENTS_MANAGEMENT}
            actionButton={
              <Link to={AdminRoutes.getPaymentRequestsRoute()} className={'btn btn-outline-primary'}>
                {intl.formatMessage({id: 'GO'})}
              </Link>
            }
          />
        </div>
      </div>
    );
  };

  const renderUsersInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.USERS}
            title={intl.formatMessage({id: 'DASHBOARD_USERS_COUNT'})}
            value={dashboardData.usersCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUserManagementRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.USERS}
            title={intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'})}
            value={dashboardData.usersRegisteredTodayCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUserManagementRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetSecond
            icon={ICONS.USERS}
            title={intl.formatMessage({id: 'DASHBOARD_USERS_CURRENT_MONTH_COUNT'})}
            value={dashboardData.usersRegisteredCurrentMonthCount}
            style={'primary'}
            onClick={() => handleClickOnWidget(AdminRoutes.getUserManagementRoute())}
          />
        </div>
      </div>
    );
  };

  const renderRequestsInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-12'}>
          <WidgetMultiData
            title={intl.formatMessage({id: 'PAYMENT_REQUESTS'})}
            data={[
              {
                title: intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'}),
                value: dashboardData.paymentRequestsTodayCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_IN_WORK_COUNT'}),
                value: dashboardData.paymentRequestsInWorkCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_PAYMENT_REQUEST_IN_WORK_SUM'}),
                value: CustomFormatter.formatMoney(dashboardData.paymentRequestsInWorkSum),
              },
            ]}
            onClick={() => handleClickOnWidget(AdminRoutes.getPaymentRequestsRoute())}
          />
        </div>
        <div className={'col-12'}>
          <WidgetMultiData
            title={intl.formatMessage({id: 'ADD_CHANNEL_REQUESTS'})}
            data={[
              {
                title: intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'}),
                value: dashboardData.youtubeChannelRequestsTodayCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_IN_WORK_COUNT'}),
                value: dashboardData.youtubeChannelRequestsInWorkCount,
              },
            ]}
            onClick={() => handleClickOnWidget(AdminRoutes.getConnectChannelRequestsRoute())}
          />
        </div>
      </div>
    );
  };

  const renderAdvertisementRequestsInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-12'}>
          <WidgetMultiData
            title={intl.formatMessage({id: 'ADVERTISEMENT_REQUESTS'})}
            data={[
              {
                title: intl.formatMessage({id: 'DASHBOARD_TODAY_COUNT'}),
                value: dashboardData.advertisementRequestsTodayCount,
              },
              {
                title: intl.formatMessage({id: 'DASHBOARD_IN_WORK_COUNT'}),
                value: dashboardData.advertisementRequestsInWorkCount,
              },
            ]}
            onClick={() => handleClickOnWidget(AdminRoutes.getAdvertisementRoute())}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHelloBlock()}
      <div className={'mt-n35 px-5'}>
        {renderInfo()}
        {renderChannelsInfo()}
        {renderUsersInfo()}
        {renderBlockWithLink()}
        {renderRequestsInfo()}
        {renderAdvertisementRequestsInfo()}
      </div>
    </>
  );
};
