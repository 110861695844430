import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {prepareDate} from '../../../utils/utils';
import {AddendumApi} from '../../../api/addendum-api/addendum-api';
import {IAddendumDto} from '../../../api/addendum-api/IAddendumDto';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {SelectApi} from '../../../api/select-api';
import {ModalEditAddendum} from './modal-edit-addendum';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {IValidationException} from '../../../api/exceptions/IValidationException';

interface IModalEditAddendumContext {
  modalEditAddendumVisible: boolean;

  showEditAddendumModal(addendumId: number, dto: IAddendumDto): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalEditAddendumContext = createContext<IModalEditAddendumContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalEditAddendumProvider = ({children}: any) => {
  const intl = useIntl();

  const selectorApi = new SelectApi();
  const addendumApi = new AddendumApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [visible, setVisible] = useState<boolean>(false);
  const [fullEditMode, setFullEditMode] = useState<boolean>(false);
  const [addendumId, setAddendumId] = useState<number | null>(null);
  const [channelId, setChannelId] = useState<string | null>(null);
  const [channels, setChannels] = useState<Array<ISelectValueDto>>([]);
  const [ratePercent, setRatePercent] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [isReferral, setIsReferral] = useState<boolean | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const fetchChannels = async () => {
    try {
      const result = await selectorApi.getYoutubeChannels();
      setChannels(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const showModal = async (addendumId: number, addendum: IAddendumDto) => {
    setAddendumId(addendumId);
    setLoading(true);
    setVisible(true);
    setStartDate(new Date(addendum.startedAt));
    setEndDate(addendum.expiresAt ? new Date(addendum.expiresAt) : null);
    setChannelId(addendum.youtubeChannelId);
    setIsReferral(addendum?.isReferral);
    setRatePercent((addendum.ratePercent ?? 0) * 100);

    if (channels.length === 0) {
      await fetchChannels();
    }

    try {
      const result = await addendumApi.checkHasTransactions(addendumId);
      setFullEditMode(!result.data.item);
    } catch (e) {
      // Даём возможность полного редактирования, если не получилось получить данные о наличии транзакций.
      setFullEditMode(true);
    } finally {
      setLoading(false);
    }

    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setTimeout(() => {
      setFullEditMode(false);
      setEndDate(null);
      setRatePercent(0);
      setStartDate(null);
      setAddendumId(null);
      setFullEditMode(false);
      setError(null);
      setValidationError(null);
    }, 500);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
    }
    closeResolver = null;
  };

  const handleChangeChannel = (channelId: string | null) => {
    setChannelId(channelId);
  };

  const handleChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const handleChangeRatePercent = (rate: number) => {
    setRatePercent(rate);
  };

  const handleOkClick = async () => {
    try {
      if (addendumId === null) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Addendum id should be filled');
      }

      setError(null);
      if (fullEditMode) {
        await addendumApi.editAddendum(
          addendumId,
          prepareDate(startDate),
          prepareDate(endDate),
          channelId,
          (ratePercent ?? 0) / 100,
          isReferral,
        );
      } else {
        await addendumApi.editExpiresAt(addendumId, prepareDate(endDate));
      }

      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: null});
        closeResolver = null;
      }

      handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalEditAddendumContext = {
    modalEditAddendumVisible: visible,
    showEditAddendumModal: showModal,
  };

  return (
    <ModalEditAddendumContext.Provider value={value}>
      {children}
      <ModalEditAddendum
        visible={visible}
        loading={loading}
        error={error}
        isReferral={isReferral ?? false}
        channels={channels}
        startDate={startDate}
        endDate={endDate}
        channelId={channelId}
        ratePercent={ratePercent}
        fullEditMode={fullEditMode}
        validationErrors={validationErrors}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
        onChangeReferral={value => setIsReferral(value)}
        onChangeChannel={handleChangeChannel}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        onChangeRatePercent={handleChangeRatePercent}
      />
    </ModalEditAddendumContext.Provider>
  );
};

export const useModalEditAddendum = () => {
  return useContext(ModalEditAddendumContext);
};
