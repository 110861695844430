import React from 'react';
import './preloader.scss';
import {Spinner} from 'react-bootstrap';

export const Preloader = () => {
  return <div className={'preloader p-5'}>
    <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
    </Spinner>
  </div>;
};
