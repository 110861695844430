import {Currency} from '../transaction-api/Transaction';

export interface IPaymentRequestDto {
  id: number;
  status: PaymentRequestStatus;
  reject_reason: string | null;
  user_id: number;
  user_name: string;
  contractor_id: number;
  contractor_name: string;
  from_account_id: number;
  destination: PaymentRequestDestination;

  value: number;
  currency: Currency;

  created_at: string;
  updated_at: string;
}

export enum PaymentRequestStatus {
  IN_WORK = 'IN_WORK',
  COMPLETE = 'COMPLETE',
  REJECT = 'REJECTED',
}

export enum PaymentRequestDestination {
  BY_REQUISITES = 'BY_REQUISITES',
  TO_MCPAY = 'TO_MCPAY',
}
