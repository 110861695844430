import React, {useRef} from 'react';
import {ArticleStatus, IArticleSimplified} from '../../../../api/knowledge-base-api/IArticleDto';
import cn from 'classnames';
import './article-sidebar-item.scss';
import {useDrag, useDrop} from 'react-dnd';
import {useDebouncedCallback} from 'use-debounce';
import SVG from 'react-inlinesvg';
import {useIntl} from '../../../../hooks/use-intl';
import {AppLocale} from '../../../../../localization';
import {useUserRole} from '../../../../hooks/use-user-role';

type Props = {
  isActive: boolean;
  article: IArticleSimplified;
  onArticleClick: (articleId: number) => void;
  onDragArticle: (dragIndex: number, hoverIndex: number) => void;
  onDropArticle: (dragIndex: number, hoverIndex: number) => void;
};

const ICONS = {
  HIDDEN: require('../../../../images/svg/Hidden.svg'),
};

export const ArticleSidebarItem: React.FC<Props> = ({
  article,
  isActive,
  onArticleClick,
  onDragArticle,
  onDropArticle,
}) => {
  const intl = useIntl();
  const [isAdmin] = useUserRole();
  const ref = useRef<any>(null);
  const handleDragArticle = useDebouncedCallback((dragIndex: number, hoverIndex: number) => {
    onDragArticle(dragIndex, hoverIndex);
  }, 100);

  const handleDropArticle = useDebouncedCallback((dragIndex: number, hoverIndex: number) => {
    onDropArticle(dragIndex, hoverIndex);
  }, 100);
  const [{isDragging}, drag, dragPreview] = useDrag(() => ({
    type: 'ARTICLE_SIDEBAR_ITEM',
    item: () => {
      return {articleId: article.id};
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: 'ARTICLE_SIDEBAR_ITEM',
    drop(item: any) {
      handleDropArticle(item.articleId, item.insteadArticleId);
    },
    hover: function (item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const articleId = item.articleId;
      const insteadArticleId = article.id;
      if (articleId === insteadArticleId) {
        return;
      }
      const hoverBoundingRect = (ref.current as any).getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (articleId < insteadArticleId && hoverClientY < hoverMiddleY) {
        return;
      }
      if (articleId > insteadArticleId && hoverClientY > hoverMiddleY) {
        return;
      }

      handleDragArticle(articleId, insteadArticleId);
      item.insteadArticleId = insteadArticleId;
    },
  }));

  const handleArticleClick = () => {
    onArticleClick(article.id);
  };

  drag(drop(dragPreview(ref)));
  return (
    <div
      {...(isAdmin ? {ref} : {})}
      key={article.id}
      style={{opacity: isDragging ? 0 : 1}}
      className={cn('article-block', {
        'text-primary active': isActive,
      })}
      onClick={handleArticleClick}>
      <div className={'article-title'}>{intl.locale === AppLocale.RU ? article.title_ru : article.title_en}</div>
      <div className={'article-toolbar p-1'}>
        {article.status === ArticleStatus.DRAFT && (
          <div>
            <span title={intl.formatMessage({id: 'DRAFT'})} className={'svg-icon svg-icon-sm'}>
              <SVG src={ICONS.HIDDEN} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
