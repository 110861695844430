export const ICONS = {
  EDIT: require('./svg/Edit.svg'),
  DOLLAR: require('./svg/Dollar.svg'),
  MONEY: require('./svg/Money.svg'),
  DOWNLOAD: require('./svg/Download.svg'),
  SETTINGS: require('./svg/Settings.svg'),
  MEDIA: require('./svg/Media.svg'),
  YOUTUBE: require('./svg/Youtube.svg'),
  FILE: require('./svg/File.svg'),
  PEN_TOOL: require('./svg/Pen-tool.svg'),
  UPLOADED_FILE: require('./svg/Uploaded-file.svg'),
  USERS: require('./svg/Group.svg'),
  USER: require('./svg/User.svg'),
  WALLET: require('./svg/Wallet3.svg'),
  CONTRACTS: require('./svg/Selected-file.svg'),
  CONTRACTORS: require('./svg/Shield-user.svg'),
  CURSOR: require('./svg/Cursor.svg'),
  CREDIT_CARD: require('./svg/Credit-card.svg'),
  FORWARD: require('./svg/Forward.svg'),
  LAYOUT: require('./svg/Layout.svg'),
  CLIPBOARD: require('./svg/Clipboard.svg'),
  SUPPORT: require('./svg/Headphone.svg'),
  PERCENT: require('./svg/Percent.svg'),
  MAIL: require('./svg/Mail.svg'),
  GLOBE: require('./svg/Globe.svg'),
  SPINNER: require('./svg/Spinner.svg'),
  CHECK: require('./svg/Check.svg'),
  CROSS: require('./svg/Close.svg'),
  PLUS: require('./svg/Plus.svg'),
  MINUS: require('./svg/Minus.svg'),
  INFO: require('./svg/Info-circle.svg'),

  RESET: require('./svg/Update.svg'),
  BLOCK: require('./svg/Lock.svg'),
  UNBLOCK: require('./svg/Unlock.svg'),
  DELETE: require('./svg/Delete-user.svg'),
};

export const ILLUSTRATIONS = {
  CONTRACTS: require('./illustration/Contracts.png'),
  COMPLETE_LIST: require('./illustration/Sketchy-2.png'),
  CREDIT_CARD: require('./illustration/CreditCard.png'),
  MONEY_BOX: require('./illustration/MoneyBox.png'),
  INVITE_USER: require('./illustration/InviteUsers.png'),
  MC_PAY_CARD: require('./illustration/McPayCard.png'),
};
