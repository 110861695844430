import React, {useCallback, useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {useIntl} from '../../../hooks/use-intl';
import {useLoading} from '../../../hooks/use-loading';
import {Card, CardBody} from '../../../components/card';
import {Preloader} from '../../../components/preloader';
import {FormattedMessage} from 'react-intl';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {ICONS} from '../../../images/images';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {toast} from 'react-toastify';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {MediaCubeConfirmationOperation, useMediaCubeStatesApi} from '../../../hooks/apis/useMediaCubeStatesApi';
import {TransactionsTable} from '../transactions/transactions-table';

export const MediaCubeOperationsConfirmationPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {id: contractorId} = useParams<{id: string}>();
  const api = useMediaCubeStatesApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    confirm: false,
  });
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const {showConfirmActionModal} = useModalConfirmAction();

  const [data, setData] = useState<MediaCubeConfirmationOperation | null>(null);

  useEffect(() => {
    startLoading('page');
    api
      .getUnconfirmedOperationsByContractor(contractorId)
      .then(res => {
        console.log(res);
        setData(res.data.item);
        setBreadcrumb(res.data.item.contractor.name);
      })
      .finally(() => stopLoading('page'));
  }, [contractorId]);

  const handleConfirmOperations = useCallback(async () => {
    if (data == null || data?.operations.length === 0) {
      return;
    }

    if (!(await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_MC_PAY_OPERATIONS_CONFIRMATION_MESSAGE'})))) {
      return;
    }

    try {
      startLoading('confirm');
      await api.postConfirmOperations(contractorId);
      toast.success(intl.formatMessage({id: 'SUCCESS_CONFIRM_MC_PAY_OPERATIONS'}));
      history.replace(AdminRoutes.getMediaCubeConfirmationRoute());
    } catch (e) {
      toast.success(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('confirm');
    }
  }, [data]);

  return (
    <Card hideFooter hideHeader>
      <CardBody>
        {loadings.page ? (
          <Preloader />
        ) : (
          <>
            {data != null && (
              <div className={'my-3 d-flex justify-content-between'}>
                <div className={'font-size-h6 font-weight-boldest'}>
                  <Link to={AdminRoutes.getSpecifyContractorRoute(data.contractor.id)}>
                    <FormattedMessage id={'CONTRACTOR'} />: {data?.contractor.name}
                  </Link>
                  <div>
                    <FormattedMessage id={'SUM'} />:{' '}
                    {CustomFormatter.customFormatMoney({
                      sum: data?.calculated_sum,
                    })}
                  </div>
                </div>
                <div>
                  <Toolbar
                    items={[
                      {
                        type: 'BUTTON',
                        icon: ICONS.CHECK,
                        title: intl.formatMessage({id: 'CONFIRM'}),
                        className: 'btn btn-success text-uppercase font-weight-bolder',
                        loading: loadings.confirm,
                        disabled: loadings.confirm,
                        onClick: handleConfirmOperations,
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            <div className={'font-size-h6 font-weight-boldest my-3'}>{intl.formatMessage({id: 'TRANSACTIONS'})}</div>
            <TransactionsTable transactions={data?.operations ?? []} aggregationValues={null} />
          </>
        )}
      </CardBody>
    </Card>
  );
};
