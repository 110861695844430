import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Accordion, useAccordionToggle} from 'react-bootstrap';

const items = [
  {
    title: 'Появился новый канал, который мы добавили вручную (без регистрации на сайте)',
    html: `<ul>
            <li>Идем в раздел <strong>"Пользователи"</strong>. Создаем нового пользователя.</li>
            <li>Идем в раздел <strong>"Договоры"</strong>. Создаем новый договор. В поле <strong>"Контрагент"</strong> выбираем контрагента, который был автоматически создан при создании нового пользователя (В название контрагента будет содержаться email пользователя, указанный при создании пользователя)</li>
            <li>После создания нового договора откроется "карточка" договора. В карточке договора необходимо нажать на кнопку <strong>"Создать приложение"</strong>.</li>
            <li>В модальной форме создания нового приложения вам необходимо выбрать новый канал, указать дату начала действия ставки и ее размер, дату окончания заполнять необязательно.</li>
            <li>После выполнения этих действий в панели управления должно исчезнуть названия канала из раздела <strong>"Каналы не связанные с приложением"</strong></li>
         </ul>`,
  },
];

export const InstructionsPage: React.FC<any> = () => {

  const renderAccordionItem = () => {
    return <>
      <div className='card'>
        {items.map((item, index) => {
          return (<div key={index}>
            <CustomToggle eventKey={index.toString()}>
              <div className='card-header'>
                <div className='card-title font-weight-bolder'>
                  {item.title}
                </div>
              </div>
            </CustomToggle>
            <Accordion.Collapse eventKey={index.toString()}>
              <div className='card-body instruction-content' dangerouslySetInnerHTML={{__html: item.html}} />
            </Accordion.Collapse>
          </div>);
        })}
      </div>
    </>;
  };

  return <>
    <div className={'card card-custom gutter-b'}>
      <div className={'card-header flex-wrap'}>
        <div className={'card-title'}>
          <FormattedMessage id={'INSTRUCTIONS'} />
        </div>
      </div>
      <div className={`card-body`}>
        <Accordion>
          <div className='accordion accordion-toggle-arrow'>
            {renderAccordionItem()}
          </div>
        </Accordion>
      </div>
    </div>
  </>;
};

export const CustomToggle = ({eventKey, children}: any) => {
  const click = useAccordionToggle(eventKey);
  return <>
    <div className={'m-0 p-0'} onClick={click}>
      {children}
    </div>
  </>;
};
