import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {IUserDto} from '../../api/DTOs/IUserDto';
import {LogoutAction, SetUserAction} from './_redux/auth-redux';
import {LayoutSplashScreen} from '../../../_metronic/layout';
import {AuthApi} from '../../api/auth-api';

interface MapDispatchToProps {
  logout(): void;

  setUser(user: IUserDto): void;
}

const AuthInit = ({children, logout, setUser}: MapDispatchToProps & PropsWithChildren<any>) => {
  const didRequest = useRef(false);
  const [authApi] = useState(new AuthApi());
  const [showSplashScreen, setShowSplashScreen] = useState(true);


  /**
   * Если есть персистентный токен, то с ним нужно сходить на сервер и забрать текущего пользователя.
   */
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await authApi.getCurrentUser();
          setUser(data.user);
        }
      } catch (error) {
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    requestUser();
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

const mapDispatchToProps = {
  setUser: SetUserAction,
  logout: LogoutAction,
};

export default connect(null, mapDispatchToProps)(AuthInit);
