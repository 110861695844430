import React, {useEffect, useMemo, useState} from 'react';
import {StringParam, withDefault} from 'use-query-params';
import {YoutubeChannelsTable} from './youtube-channels-table';
import {FormattedMessage, useIntl} from 'react-intl';
import {IPaginationInfo} from '../../../api/Paginator';
import {IYoutubeChannelDto} from '../../../api/DTOs/IYoutubeChannelDto';
import {YoutubeChannelsApi} from '../../../api/youtube-channels-api';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {YoutubeChannelCard} from './youtube-channel-card';
import {ErrorStub} from '../../../components/error-stub';
import {ConnectYoutubeChannelWidget} from './connect-youtube-channel-widget';
import {ApiRequestException} from '../../../api/axios-instance';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import cn from 'classnames';
import {useUserRole} from '../../../hooks/use-user-role';
import {toast} from "react-toastify";
import {SelectApi} from "../../../api/select-api";
import {useLoading} from "../../../hooks/use-loading";
import {SelectsValuesDTOs} from "../../../components/Inputs/InputSelect";

const defaultSortOptions: ISortDto = {
  field: SortField.TITLE,
  direction: SortDirection.ASC,
};

export const YoutubeChannelsPage = () => {
  const intl = useIntl();

  const [currentUserIsAdmin] = useUserRole();
  const [enabledCardMode, setEnableCardMode] = useState(true);

  const selectApi = new SelectApi();

  const api = new YoutubeChannelsApi();
  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [channels, setChannels] = useState<Array<IYoutubeChannelDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const [loadings, startLoading, stopLoading] = useLoading({
    select: true,
  });
  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'channels_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: A-z`,
          value: {field: SortField.TITLE, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'TITLE'})}: Z-a`,
          value: {field: SortField.TITLE, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'VIEWS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.VIEW, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'VIEWS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.VIEW, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'VIDEOS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.VIDEO, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'VIDEOS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.VIDEO, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'SUBSCRIBERS'})} ${intl.formatMessage({id: 'ASC'})}`,
          value: {field: SortField.SUBSCRIBER, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'SUBSCRIBERS'})} ${intl.formatMessage({id: 'DESC'})}`,
          value: {field: SortField.SUBSCRIBER, direction: SortDirection.DESC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'title',
          placeholder: intl.formatMessage({id: 'TITLE'}),
        },
        {
          type: FilterType.SELECT,
          name: 'cms_id',
          placeholder: 'CMS',
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          queryConfig: {
            name: 'status',
            type: withDefault(StringParam, 'ACTIVE'),
          },
          placeholder: intl.formatMessage({id: 'ACCESSIBILITY'}),
          options: [
            {value: 'ACTIVE', label: intl.formatMessage({id: 'ACTIVE'})},
            {
              value: 'INACTIVE',
              label: intl.formatMessage({id: 'INACTIVE'}),
            },
          ],
        },
      ],
    });
  }, [selectValues, loadings]);

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        cms_id: (await selectApi.getAllCMS()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };


  const fetchChannels = async (opt?: IQueryParams) => {
    try {
      setLoading(true);
      const response = await api.getChannelList(opt);
      setChannels(response.data.items);
      setPaginationInfo(response.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const renderChannels = () => {
    if (channels.length === 0)
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );

    if (enabledCardMode)
      return (
        <div className='row'>
          {channels.map(channel => (
            <div key={channel.id} className='col-xl-3 col-lg-12 col-md-12 col-sm-12'>
              <YoutubeChannelCard channel={channel} />
            </div>
          ))}
        </div>
      );

    return <YoutubeChannelsTable channels={channels} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <>
      {!currentUserIsAdmin && <ConnectYoutubeChannelWidget />}
      <BaseListPage
        loading={loading}
        filtersConfig={filterConfig}
        sortsConfig={sortConfig}
        pagination={{info: paginationInfo}}
        fetchData={fetchChannels}
        toolbarConfig={[
          {
            type: 'SWITCH',
            size: 'sm',
            titles: [intl.formatMessage({id: 'CARDS'}), intl.formatMessage({id: 'TABLE'})],
            checked: !enabledCardMode,
            onChange: () => setEnableCardMode(prevState => !prevState),
          },
        ]}
        className={{cardBody: cn({'bg-light': enabledCardMode})}}>
        {renderChannels()}
      </BaseListPage>
    </>
  );
};
