import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {LayoutSplashScreen} from '../../../../_metronic/layout';
import {IApplicationStore} from '../../../../redux/rootReducer';
import {LogoutAction} from '../_redux/auth-redux';
import {Routes} from '../../../../configs/routes';
import {IUserDto} from '../../../api/DTOs/IUserDto';
import {AuthApi} from '../../../api/auth-api';
import {toast} from 'react-toastify';
import {injectIntl} from 'react-intl';

interface MapStateProps {
  intl: any;
  user: IUserDto | null;
}

interface MapDispatchProps {
  logout(): void;
}

class Logout extends Component<MapDispatchProps & MapStateProps> {
  private authApi = new AuthApi();

  async componentDidMount() {
    try {
      this.props.logout();
      await this.authApi.logout();
    } catch (e) {
      toast.error(this.props.intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }

  render() {
    const {user} = this.props;
    return user
      ? <LayoutSplashScreen />
      : <Redirect to={Routes.getLoginRoute()} />;
  }
}

const mapStateToProps = ({auth}: IApplicationStore) => {
  return {
    user: auth.user,
  };
};

const mapDispatchToProps = {
  logout: LogoutAction,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Logout));
