/* eslint-disable react/jsx-key */
import React, {CSSProperties} from 'react';
import {Column, Row, useFlexLayout, useTable} from 'react-table';
import './default-table.scss';
import cn from 'classnames';

interface IDefaultTableProps {
  data: any;
  columns: Array<Column<any & {forceApplySize?: boolean}>>;
  hasFooter?: boolean;
  className?: string;
  style?: CSSProperties;
  renderRowSubComponent?: (row: Row<any>) => JSX.Element;
}

export const DefaultTable: React.FC<IDefaultTableProps> = ({
  data,
  columns,
  hasFooter,
  style,
  className,
  renderRowSubComponent,
}) => {
  const table = useTable({columns, data}, useFlexLayout);
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups} = table;
  const renderHeader = () => {
    return (
      <thead>
        {headerGroups.map(headerGroup => {
          return (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={Object.assign(headerGroup.getHeaderGroupProps().style, {})}
              className={cn(headerGroup.getHeaderGroupProps().className, 'table-header')}>
              {headerGroup.headers.map(column => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    style={Object.assign(
                      column.getHeaderProps().style,
                      // HACK: для форсированного указания значений.
                      // @ts-ignore
                      column.forceApplySize && column.maxWidth ? {maxWidth: column.maxWidth} : {},
                    )}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
    );
  };

  const renderBody = () => {
    return (
      <tbody {...getTableBodyProps}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <React.Fragment key={row.getRowProps().key}>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={Object.assign(
                        cell.column.getHeaderProps().style,
                        // @ts-ignore
                        cell.column.forceApplySize && cell.column.maxWidth ? {maxWidth: cell.column.maxWidth} : {},
                      )}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {renderRowSubComponent && renderRowSubComponent(row)}
            </React.Fragment>
          );
        })}
      </tbody>
    );
  };

  const renderFooter = () => {
    if (!hasFooter) {
      return null;
    }

    return (
      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => {
              return (
                <td {...column.getFooterProps()} className={'font-weight-boldest'}>
                  {column.render('Footer')}
                </td>
              );
            })}
          </tr>
        ))}
      </tfoot>
    );
  };

  return (
    <div className={cn('table-responsive-xl', className)} style={style}>
      <table className='table rounded default-table' {...getTableProps}>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </table>
    </div>
  );
};
