export enum BootstrapColor {
  SUCCESS = 'success',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',

  LIGHT_SUCCESS = 'light-success',
  LIGHT_PRIMARY = 'light-primary',
  LIGHT_SECONDARY = 'light-secondary',
  LIGHT_DANGER = 'light-danger',
  LIGHT_WARNING = 'light-warning',
  LIGHT_INFO = 'light-info',
}

export enum BootstrapSizes {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',

  X2 = '2x',
  X3 = '3x',
  X4 = '4x',
  X5 = '5x',
  X6 = '6x',
  X7 = '7x',
  X8 = '8x',
  X9 = '9x',
}
