import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ReportFileUploader} from '../../../components/report-file-uploader';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {REPORT_TYPE} from '../../../api/financial-report-api/IYoutubeFinancialBulkReportsDto';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {ReportsInfo} from './reports-info';
import {useHistory} from 'react-router-dom';
import {FinancialReportApi} from '../../../api/financial-report-api/financial-report-api';
import {IFinancialReportPeriodDto} from '../../../api/financial-report-api/IFinancialReportPeriodDto';
import {AdminRoutes} from '../../../../configs/routes';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {getValidationErrorMessage} from '../../../utils/utils';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../hooks/use-loading';
import {SelectApi} from '../../../api/select-api';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';

export const ReportsUploadPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();
  const api = new FinancialReportApi();
  const selectApi = new SelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    upload: false,
    periods: true,
    cms: false,
  });

  const [files, setFiles] = useState<{[key: string]: File}>({});

  // Показываем Alert только если ошибка не связана с валидацией.
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: string[]}>({});
  const [alertVisible, setAlertVisible] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [periods, setPeriods] = useState<Array<IFinancialReportPeriodDto>>([]);
  const [selectedCMS, setSelectedCMS] = useState<number | null>(null);
  const [CMSes, setCMSes] = useState<Array<ISelectValueDto>>([]);

  useEffect(() => {
    resetFieldValidation('period');
  }, [selectedPeriod]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    Promise.all([fetchPeriods(), fetchCMS()]);
  }, []);

  const fetchPeriods = async () => {
    try {
      startLoading('periods');
      const response = await api.getYoutubeFinancialPeriods();
      setPeriods(response.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('periods');
    }
  };

  const fetchCMS = async () => {
    try {
      startLoading('cms');
      const response = await selectApi.getAllCMS();
      setCMSes(response.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('cms');
    }
  };

  const handleSubmit = async () => {
    try {
      startLoading('upload');
      setError(null);
      setValidationError({});
      setAlertVisible(false);
      await api.sendYoutubeFinancialReport(selectedCMS, selectedPeriod, files);
      setFiles({});
      setAlertVisible(true);
      setTimeout(() => {
        history.push(AdminRoutes.getYoutubeFinancialReportsRoute());
      }, 1000);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        setAlertVisible(true);
      }
    } finally {
      stopLoading('upload');
    }
  };

  const resetFieldValidation = (type: REPORT_TYPE | string) => {
    if (validationErrors[type]) {
      const errorWithoutDeleted = {...validationErrors};
      delete errorWithoutDeleted[type];
      setValidationError(errorWithoutDeleted);
    }
  };

  const handleFileSelected = (type: REPORT_TYPE, selectedFile: File) => {
    setFiles({...files, [type]: selectedFile});
    resetFieldValidation(type);
  };

  const handleDeleteFile = (type: REPORT_TYPE) => {
    const copyFiles = {...files};
    delete copyFiles[type];
    setFiles({...copyFiles});
    resetFieldValidation(type);
  };

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b`}>
        <div className={'card-header'}>
          <div className={'card-title'}>
            <h3 className='card-label font-weight-bolder text-dark'>
              <FormattedMessage id={'REPORTS_UPLOAD'} />
            </h3>
          </div>
        </div>

        <div className={'card-body'}>
          <AlertCustom
            dismissible
            visible={alertVisible}
            type={error != null ? 'light-danger' : 'light-success'}
            iconClassName={error != null ? 'svg-icon-danger' : 'svg-icon-success'}
            onClose={() => setAlertVisible(false)}
            text={intl.formatMessage({
              id: !error ? 'SUCCESS_UPLOAD_REPORTS' : 'ERROR_UPLOAD_REPORTS',
            })}
          />

          <ValidateErrorWrapper message={getValidationErrorMessage('cms_id', validationErrors)}>
            <InputSelect
              required
              value={selectedCMS}
              label={intl.formatMessage({id: 'LABEL_CMS'})}
              hasError={!!getValidationErrorMessage('cms_id', validationErrors)}
              isClearable={true}
              ariaLabel={'arial-label'}
              isLoading={loadings.cms}
              onChange={(value: any) => setSelectedCMS(value)}
              options={CMSes.map(p => ({value: p.id, label: p.title}))}
              placeholder={intl.formatMessage({id: 'LABEL_CMS'})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={getValidationErrorMessage('month_id', validationErrors)}>
            <InputSelect
              required
              value={selectedPeriod}
              label={intl.formatMessage({id: 'LABEL_PERIOD'})}
              hasError={!!getValidationErrorMessage('month_id', validationErrors)}
              isClearable={true}
              ariaLabel={'arial-label'}
              isLoading={loadings.periods}
              onChange={(value: any) => setSelectedPeriod(value)}
              options={periods.map(p => ({value: p.id, label: `${p.month < 10 ? '0' : ''}${p.month}.${p.year}`}))}
              placeholder={intl.formatMessage({id: 'REPORT_PERIOD'})}
            />
          </ValidateErrorWrapper>

          <div className={'my-10'}>
            {Object.keys(ReportsInfo).map(reportGroupKey => {
              return (
                <div key={reportGroupKey} className={'my-4'}>
                  <div className={'font-weight-bolder h3 text-muted'}>
                    {intl.formatMessage({id: 'REPORT_GROUP_' + reportGroupKey})}
                  </div>
                  <div className={'row'}>
                    {
                      // @ts-ignore
                      ReportsInfo[reportGroupKey].map(reportInfo => {
                        return (
                          <ReportFileUploader
                            key={reportInfo.type}
                            acceptFileExtension={reportInfo.acceptFileExtension}
                            type={reportInfo.type}
                            file={files[reportInfo.type]}
                            required={reportInfo.isRequired}
                            reportFileMask={reportInfo.fileMask}
                            reportDescription={intl.formatMessage({id: reportInfo.humanDescription})}
                            serverError={validationErrors[reportInfo.type]?.join(', ')}
                            className={'col-lg-6 col-md-12 col-sm-12'}
                            onDelete={handleDeleteFile}
                            onSelect={handleFileSelected}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              );
            })}
          </div>

          <div className='form-group d-flex flex-wrap flex-center'>
            <Toolbar
              items={[
                {
                  type: 'BUTTON',
                  loading: loadings.upload,
                  title: intl.formatMessage({id: 'SUBMIT'}),
                  className: 'btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4',
                  onClick: handleSubmit,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
