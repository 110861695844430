import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {
  ConnectChannelsApi,
  CreateConnectYoutubeChannelRequestFields,
} from '../../../api/connect-channel-api/connect-channels-api';
import {PartialNullable} from '../../../../types';
import {ModalConnectYoutubeChannel} from './connect-youtube-channel-modal';
import {useLoading} from '../../../hooks/use-loading';
import {AuthApi} from '../../../api/auth-api';
import {toast} from 'react-toastify';

type ModalConnectYoutubeChannelProps = {
  modalConnectYoutubeChannelVisible: boolean;
  showConnectYoutubeChannelModal: () => Promise<CloseModalEvent>;
};

const ModalConnectYoutubeChannelContext = createContext<ModalConnectYoutubeChannelProps>({
  modalConnectYoutubeChannelVisible: false,
  showConnectYoutubeChannelModal: (() => {
    console.log(123);
  }) as any,
});

let closeResolver: ((data: CloseModalEvent) => any) | null = null;
export const ModalConnectYoutubeChannelProvider: React.FC = ({children}) => {
  const intl = useIntl();
  const [loading, startLoading, stopLoading] = useLoading({
    loadPaymentRequisites: true,
  });
  const api = useMemo(() => new ConnectChannelsApi(), []);
  const authApi = useMemo(() => new AuthApi(), []);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [hasPaymentRequisites, setHasPaymentRequisites] = useState(true);
  const [fields, setField, updateFields] = useAdvancedState<PartialNullable<CreateConnectYoutubeChannelRequestFields>>(
    {},
  );

  useEffect(() => {
    if (visible) {
      startLoading('loadPaymentRequisites');
      authApi
        .getCurrentUser()
        .then(res => setHasPaymentRequisites(res?.data?.user?.hasPaymentRequisites ?? false))
        .catch(() => toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'})))
        .finally(() => stopLoading('loadPaymentRequisites'));
    }
  }, [visible]);

  const handleCloseModal = async () => {
    setVisible(false);
    setError(null);
    setValidationError(null);
    setField({});
    setHasPaymentRequisites(true);
    if (closeResolver != null) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const showModal = async () => {
    startLoading('loadPaymentRequisites');
    setVisible(true);

    return new Promise<CloseModalEvent>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      await api.createConnectChannelRequest(fields);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
      }
      await handleCloseModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: ModalConnectYoutubeChannelProps = {
    modalConnectYoutubeChannelVisible: visible,
    showConnectYoutubeChannelModal: showModal,
  };

  return (
    <ModalConnectYoutubeChannelContext.Provider value={value}>
      {children}
      <ModalConnectYoutubeChannel
        loadingPaymentRequisites={loading.loadPaymentRequisites}
        hasPaymentRequisites={hasPaymentRequisites}
        visible={visible}
        error={error}
        fields={fields}
        validationErrors={validationErrors}
        onHide={handleCloseModal}
        onChange={updateFields}
        onOkClick={handleOkClick}
      />
    </ModalConnectYoutubeChannelContext.Provider>
  );
};

export const useModalConnectChannel = () => {
  return useContext(ModalConnectYoutubeChannelContext);
};
