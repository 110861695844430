import React from 'react';
import {useIntl} from 'react-intl';
import {IPaymentRequisitesDto} from '../api/payment-requisite-api/IPaymentRequisitesDto';
import {FormattedMessage} from 'react-intl/lib';
import {useUserRole} from '../hooks/use-user-role';

interface IProps {
  paymentRequisites: IPaymentRequisitesDto | null;
}

export const PaymentRequisitesInfo: React.FC<IProps> = ({paymentRequisites}) => {
  const intl = useIntl();
  const [, user] = useUserRole();
  const renderPaymentRequisitesItem = (title: string | null, text?: string | null, columnsCount?: number | null) => {
    return (
      <div className={columnsCount ? 'col-' + columnsCount : 'col-6'}>
        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
            <span className={'font-weight-bolder font-size-sm'}>{title}</span>
            <span className={'font-size-h5'}>{text ?? 'N/A'}</span>
          </div>
        </div>
      </div>
    );
  };

  if (!paymentRequisites) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  return (
    <>
      <div className={'row'}>
        <div className={'col-12 mb-2'}>
          <div className={'row'}>
            {user.isJurisdictionWithTwiceLanguageContract &&
              renderPaymentRequisitesItem(
                intl.formatMessage({id: 'NAME'}) + ' (ru)',
                paymentRequisites?.ru_beneficiary_name,
              )}
            {renderPaymentRequisitesItem(
              intl.formatMessage({id: 'NAME'}) + ' (en)',
              paymentRequisites?.en_beneficiary_name,
            )}
          </div>
        </div>
        <div className={'col-12 mb-2'}>
          <div className={'row'}>
            {user.isJurisdictionWithTwiceLanguageContract &&
              renderPaymentRequisitesItem(
                intl.formatMessage({id: 'ADDRESS'}) + ' (ru)',
                paymentRequisites?.ru_beneficiary_address,
              )}
            {renderPaymentRequisitesItem(
              intl.formatMessage({id: 'ADDRESS'}) + ' (en)',
              paymentRequisites?.en_beneficiary_address,
            )}
          </div>
        </div>
        <div className={'col-12 mb-2'}>
          <div className={'row'}>
            {renderPaymentRequisitesItem(
              intl.formatMessage({id: 'SWIFT_NUMBER'}),
              paymentRequisites?.beneficiary_bank_swift_number,
              12,
            )}
          </div>
        </div>
        <div className={'col-12'}>
          <div className={'row'}>
            {renderPaymentRequisitesItem(
              intl.formatMessage({id: 'ACCOUNT_NUMBER'}),
              paymentRequisites?.beneficiary_account_number,
              12,
            )}
          </div>
        </div>
      </div>
    </>
  );
};
