import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {PartialNullable} from '../../../../types';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {ApproveConnectChannelRequestFields} from '../../../api/connect-channel-api/connect-channels-api';
import {
  getDateWithStartDay,
  getValidationErrorMessage,
  tryGetDateOrNull,
  ValidationErrorsType,
} from '../../../utils/utils';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputDatepicker} from '../../Inputs/InputDatepicker';
import {InputRange} from '../../Inputs/InputRange';
import {InputSelect} from '../../Inputs/InputSelect';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';

interface IModalCreateContractProps {
  isCmsLoading: boolean;
  visible: boolean;
  fields: PartialNullable<ApproveConnectChannelRequestFields>;
  onUpdate: UpdateFields<ApproveConnectChannelRequestFields>;
  error?: string | null;
  validationErrors?: ValidationErrorsType<ApproveConnectChannelRequestFields>;
  CMSes: ISelectValueDto[];

  onHide(): void;

  onOkClick(): any;
}

export const ModalApproveConnectChannelRequest: React.FC<IModalCreateContractProps> = ({
  visible,
  fields,
  error,
  validationErrors,
  onUpdate,
  onHide,
  onOkClick,
  isCmsLoading,
  CMSes,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        successButtonTitle={intl.formatMessage({id: 'APPROVE'})}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'APPROVE_CONNECT_CHANNEL_REQUEST'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />

          <AlertCustom
            visible
            text={intl.formatMessage({id: 'NOTICE_APPROVE_CONNECT_CHANNEL_REQUEST'})}
            type={'light-info'}
            iconClassName={'svg-icon-info'}
          />

          <form className={'form'}>
            <ValidateErrorWrapper message={getValidationErrorMessage('cms_id', validationErrors)}>
              <InputSelect
                required
                value={fields.cms_id}
                label={intl.formatMessage({id: 'LABEL_CMS'})}
                hasError={!!getValidationErrorMessage('cms_id', validationErrors)}
                isClearable={true}
                ariaLabel={'arial-label'}
                isLoading={isCmsLoading}
                onChange={(value: any) => onUpdate({cms_id: value})}
                options={CMSes.map(p => ({value: p.id, label: p.title}))}
                placeholder={intl.formatMessage({id: 'LABEL_CMS'})}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('contract_started_at', validationErrors)}>
              <InputDatepicker
                required
                showMonthYearPicker
                hasError={!!getValidationErrorMessage('contract_started_at', validationErrors)}
                selected={tryGetDateOrNull(fields.contract_started_at)}
                label={`${intl.formatMessage({id: 'CONTRACT'})}: ${intl.formatMessage({id: 'CONTRACT_START_DATE'})}`}
                className={'mb-2'}
                onChange={date => onUpdate({contract_started_at: getDateWithStartDay(date)})}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('rate_percent', validationErrors)}>
              <InputRange
                required
                tooltip={'auto'}
                value={fields.rate_percent ?? 0}
                tooltipLabel={v => intl.formatNumber(v, {style: 'percent'})}
                label={`${intl.formatMessage({id: 'RATE_PERCENT'})}: ${intl.formatNumber(fields.rate_percent ?? 0, {
                  style: 'percent',
                })}`}
                onChange={value => onUpdate({rate_percent: value})}
              />
            </ValidateErrorWrapper>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
