import React from 'react';
import {Badge, BadgeProps} from 'react-bootstrap';
import './badge-custom.scss';
import cn from 'classnames';

export const BadgeCustom: React.FC<BadgeProps> = ({variant, pill, className, as, children}) => {
  return (
    <>
      <Badge as={as} className={cn('font-weight-boldest', className)} variant={variant} pill={pill}>
        {children}
      </Badge>
    </>
  );
};
