import React, {useEffect, useMemo, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {IYoutubeChannelDto} from '../../../api/DTOs/IYoutubeChannelDto';
import {YoutubeChannelsApi} from '../../../api/youtube-channels-api';
import './youtube-channel.scss';
import {YoutubeVideos} from '../../youtube-video/videos-page/youtube-videos';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {Preloader} from '../../../components/preloader';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {Routes} from '../../../../configs/routes';
import {YoutubeMetricsPanelItem} from '../../../components/youtube-metrics-panel';
import {PreviewImage, YoutubeChannelOrVideoPreview} from '../../../components/YoutubeChannelOrVideoPreview';
import {YoutubeFinancialRevenueByMonthsReport} from '../../../api/DTOs/RevenueByMonths';
import {useIntl} from '../../../hooks/use-intl';
import {useLoading} from '../../../hooks/use-loading';
import {RevenuesDetails} from '../../revenues/revenues-details';
import {ContractCard} from './contract-card';
import {IContractDto} from '../../../api/contract-api/IContractDto';
import {useUserRole} from '../../../hooks/use-user-role';

interface IMatchParams {
  channelId: string;
}

const SUBSCRIBER_SVG = require('../../../images/svg/Group.svg');
const VIDEO_LANE_SVG = require('../../../images/svg/Video-Lane.svg');
const VISIBLE_SVG = require('../../../images/svg/Visible.svg');

export const YoutubeChannelPage = () => {
  const intl = useIntl();
  const match = useRouteMatch<IMatchParams>();
  const channelId = match.params.channelId;
  const [isAdmin] = useUserRole();

  const api = new YoutubeChannelsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    channelInfo: true,
    revenueInfo: true,
  });
  const [error, setError] = useState<string | null>(null);
  const [channelInfo, setChannelInfo] = useState<IYoutubeChannelDto>();
  const [contract, setContract] = useState<IContractDto | null>(null);
  const [referralContract, setReferralContract] = useState<IContractDto | null>(null);
  const [revenues, setRevenues] = useState<YoutubeFinancialRevenueByMonthsReport | null>(null);
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const metrics = useMemo<YoutubeMetricsPanelItem[]>(() => {
    if (!channelInfo) {
      return [];
    }

    return [
      {
        title: 'VIEWS',
        value: channelInfo?.viewCount,
        icon: VISIBLE_SVG,
        className: 'col-4',
        iconClassName: 'svg-icon-success',
      },
      {
        title: 'SUBSCRIBERS',
        value: channelInfo.subscriberCount,
        icon: SUBSCRIBER_SVG,
        className: 'col-4',
        iconClassName: 'svg-icon-primary',
      },
      {
        title: 'VIDEOS',
        value: channelInfo.videoCount,
        icon: VIDEO_LANE_SVG,
        className: 'col-4',
        iconClassName: 'svg-icon-warning',
      },
    ];
  }, [channelInfo]);

  useEffect(() => {
    const fetchAllStats = async () => {
      await Promise.all([fetchChannelInfo(), fetchRevenues()]);
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchAllStats();
  }, []);

  useEffect(() => {
    if (channelInfo?.title) {
      setBreadcrumb(channelInfo.title, 40);
    }
  }, [channelInfo?.title]);

  const fetchRevenues = async () => {
    try {
      startLoading('revenueInfo');
      const result = await api.getRevenues(channelId);
      setRevenues(result.data.item);
    } catch (e) {
      setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('revenueInfo');
    }
  };

  const fetchChannelInfo = async () => {
    try {
      startLoading('channelInfo');
      const result = await api.getChannelInfo(match.params.channelId);
      setChannelInfo(result.data.item);
      setContract(result.data.relations.contract ?? null);
      setReferralContract(result.data.relations.referralContract ?? null);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('channelInfo');
    }
  };

  if (loadings.channelInfo) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderPreview = () => {
    if (!channelInfo) {
      return null;
    }

    return (
      <YoutubeChannelOrVideoPreview
        title={channelInfo.title}
        redirectTo={Routes.getExternalYoutubeChannelRoute(channelInfo.id)}
        description={channelInfo.description}
        metrics={metrics}
        notice={
          channelInfo?.hiddenFromYoutubeAt &&
          intl.formatMessage(
            {id: 'CHANNEL_HIDDEN_FROM_YOUTUBE'},
            {
              redirect: str => (
                <Link to={Routes.getFinancialReportsRoute()} className={'text-danger text-underline'}>
                  {str}
                </Link>
              ),
            },
          )
        }>
        <PreviewImage>
          <div className='symbol symbol-md-100 symbol-lg-150 mr-7'>
            <img src={tryGetPreviewOrStub(channelInfo.thumbnails).url} alt='image' className={'w-100 rounded'} />
          </div>
        </PreviewImage>
      </YoutubeChannelOrVideoPreview>
    );
  };

  const renderRevenue = () => {
    return <RevenuesDetails revenues={revenues} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <>
      <div className={'row'}>
        <div className={'col-12'}>{renderPreview()}</div>
        <div className={'col-12'}>{isAdmin && contract && <ContractCard contract={contract} />}</div>
        <div className={'col-12'}>
          {isAdmin && referralContract && <ContractCard isReferral contract={referralContract} />}
        </div>
        <div className={'col-12'}>{renderRevenue()}</div>
      </div>
    </>
  );
};
