import {ICONS} from '../app/images/images';
import {MenuItem} from '../app/layout/aside/menu-item-component';
import {MenuSection} from '../app/layout/aside/menu-section-component';
import {AdminRoutes, Routes} from './routes';

type AsideMenuItemType = 'section' | 'simple';

export interface IAsideMenuAbstractItem {
  type: AsideMenuItemType;
  item: MenuItem | MenuSection;
  is_protected?: boolean;
  hide_for_admin?: boolean;
}

const asideRouteLinks: Array<IAsideMenuAbstractItem> = [
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'DASHBOARD', to: AdminRoutes.getDashboardRoute(), icon: ICONS.LAYOUT},
  },
  {
    type: 'simple',
    hide_for_admin: true,
    item: {title: 'DASHBOARD', to: Routes.getDashboardRoute(), icon: ICONS.LAYOUT},
  },
  {
    type: 'simple',
    item: {title: 'PROFILE', to: Routes.getProfileRoute(), icon: ICONS.USER},
  },
  {
    type: 'simple',
    item: {title: 'YOUTUBE_CHANNELS', to: Routes.getYoutubeChannelsRoute(), icon: ICONS.YOUTUBE},
  },
  {
    type: 'simple',
    hide_for_admin: true,
    item: {title: 'FINANCIAL_REPORTS', to: Routes.getFinancialReportsRoute(), icon: ICONS.CONTRACTS},
  },
  {
    type: 'simple',
    hide_for_admin: true,
    item: {title: 'REVENUES', to: Routes.getRevenuesPageRoute(), icon: ICONS.DOLLAR},
  },
  {
    type: 'simple',
    hide_for_admin: true,
    item: {title: 'ADVERTISEMENT', to: Routes.getAdvertisementsRoute(), icon: ICONS.PERCENT},
  },
  // TODO: После заполнения базы знаний вернуть раздел для пользователей.
  // {
  //   type: 'simple',
  //   hide_for_admin: true,
  //   item: {title: 'KNOWLEDGE_BASE', to: Routes.getKnowledgeBaseRoute(), icon: ICONS.GLOBE},
  // },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'USER_MANAGEMENT', to: AdminRoutes.getUserManagementRoute(), icon: ICONS.USERS},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'CONTRACTORS', to: AdminRoutes.getContractorsRoute(), icon: ICONS.CONTRACTORS},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'CONTRACTS', to: AdminRoutes.getContractsRoute(), icon: ICONS.CONTRACTS},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'FINANCIAL_ACCOUNTS', to: AdminRoutes.getFinancialAccountsRoute(), icon: ICONS.WALLET},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'PAYMENT_REQUESTS', to: AdminRoutes.getPaymentRequestsRoute(), icon: ICONS.CREDIT_CARD},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'ADD_CHANNEL_REQUESTS', to: AdminRoutes.getConnectChannelRequestsRoute(), icon: ICONS.FORWARD},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'TRANSACTIONS', to: AdminRoutes.getTransactionsRoute(), icon: ICONS.DOLLAR},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'USERS_ACTIONS', to: AdminRoutes.getUsersActionsRoute(), icon: ICONS.CURSOR},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {
      title: 'YOUTUBE_FINANCIAL_REPORTS',
      to: AdminRoutes.getYoutubeFinancialReportsRoute(),
      icon: ICONS.UPLOADED_FILE,
    },
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'ADVERTISEMENT_REQUESTS', to: AdminRoutes.getAdvertisementRoute(), icon: ICONS.PERCENT},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'YOUTUBE_INTEGRATION', to: AdminRoutes.getYoutubeIntegrationRoute(), icon: ICONS.YOUTUBE},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'MAILINGS', to: AdminRoutes.getMailingsRoute(), icon: ICONS.MAIL},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'INSTRUCTIONS', to: AdminRoutes.getInstructionsRoute(), icon: ICONS.CLIPBOARD},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'SUPPORT', to: AdminRoutes.getSupportRoute(), icon: ICONS.SUPPORT},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'KNOWLEDGE_BASE', to: Routes.getKnowledgeBaseRoute(), icon: ICONS.GLOBE},
  },
  {
    type: 'simple',
    is_protected: true,
    item: {title: 'APPLICATION_SETTINGS', to: AdminRoutes.getApplicationSettingsRoute(), icon: ICONS.SETTINGS},
  },
];

export default asideRouteLinks;
