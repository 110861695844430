import React, {useEffect, useState} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {Link, useRouteMatch} from 'react-router-dom';
import {ConnectChannelsApi} from '../../../api/connect-channel-api/connect-channels-api';
import {IConnectChannelRequestDto} from '../../../api/connect-channel-api/IConnectChannelRequestDto';
import {ErrorStub} from '../../../components/error-stub';
import {Preloader} from '../../../components/preloader';
import SVG from 'react-inlinesvg';
import {IUserDto} from '../../../api/DTOs/IUserDto';
import {useModalRejectReasonConnectRequest} from '../../../components/modals/reject-reason/modal-reject-reason-connect-request-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {FilterRequestStatus} from '../../../api/connect-channel-api/FilterRequestStatus';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {useLoading} from '../../../hooks/use-loading';
import {useModalApproveConnectChannelRequest} from '../../../components/modals/approve-connect-channel-request-modal/approve-connect-channel-requests-modal-context';
import {toast} from 'react-toastify';

const ICONS = {
  VIEWS: require('../../../images/svg/Visible.svg'),
  SUBSCRIBERS: require('../../../images/svg/Group.svg'),
  VIDEO_LANE: require('../../../images/svg/Video-Lane.svg'),
  CHECK: require('../../../images/svg/Check.svg'),
  CROSS: require('../../../images/svg/Close.svg'),
};

export const ConnectChannelRequestPage: React.FC = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const requestId = Number(match.params.id);

  const api = new ConnectChannelsApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    action: false,
  });

  const [error, setError] = useState<string | null>(null);

  const [connectRequest, setConnectRequest] = useState<IConnectChannelRequestDto | null>(null);
  const [user, setUser] = useState<IUserDto | null>(null);

  const {showApproveConnectChannelRequestModal} = useModalApproveConnectChannelRequest();
  const {showRejectReasonConnectRequestModal} = useModalRejectReasonConnectRequest();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchConnectChanelRequest().then();
  }, []);

  useEffect(() => {
    if (connectRequest?.title) {
      setBreadcrumb(connectRequest.title);
    }
  }, [connectRequest]);

  const fetchConnectChanelRequest = async () => {
    try {
      setError(null);
      startLoading('page');
      const result = await api.getUserConnectChannelRequest(requestId);
      setConnectRequest(result.data.item);
      setUser(result.data.relations.user || null);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const handleApproveClick = async () => {
    const closeModalResult = await showApproveConnectChannelRequestModal({connectChannelRequestId: requestId});
    if (closeModalResult.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_APPROVE_CONNECT_CHANNEL'}));
      fetchConnectChanelRequest().then();
    }
  };

  const handleRejectClick = async () => {
    const result = await showRejectReasonConnectRequestModal(requestId);
    if (result.reason === CloseModalReason.OK) {
      await fetchConnectChanelRequest();
    }
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderMetrics = () => {
    if (connectRequest === null) {
      return <Preloader />;
    }

    return (
      <>
        {user && (
          <div className={'col-xl-3 col-md-12'}>
            <div className={'d-flex align-items-center mr-5 my-1'}>
              <div className={'d-flex flex-column text-dark-75'}>
                <span className={'font-weight-bolder font-size-sm'}>
                  <FormattedMessage id={'EMAIL'} />
                </span>
                <div className={'d-flex align-items-center'}>
                  <Link to={AdminRoutes.getSpecifyUserManagementRoute(user.id)}>
                    <span className={'font-size-h3'}> {user.email}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={'col-xl-3 col-md-12'}>
          <div className={'d-flex align-items-center mr-5 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'VIEWS'} />
              </span>
              <div className={'d-flex align-items-center'}>
                <span className='svg-icon svg-icon-3x svg-icon-primary mr-1'>
                  <SVG src={ICONS.VIEWS} />
                </span>
                <span className={'font-size-h3'}>
                  <FormattedNumber value={connectRequest.viewCount} />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={'col-xl-3 col-md-12'}>
          <div className={'d-flex align-items-center mr-5 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'SUBSCRIBERS'} />
              </span>
              <div className={'d-flex align-items-center'}>
                <span className='svg-icon svg-icon-3x svg-icon-success mr-1'>
                  <SVG src={ICONS.SUBSCRIBERS} />
                </span>
                <span className={'font-size-h3'}>
                  <FormattedNumber value={connectRequest.subscriberCount} />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={'col-xl-3 col-md-12'}>
          <div className={'d-flex align-items-center mr-5 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'VIDEOS'} />
              </span>
              <div className={'d-flex align-items-center'}>
                <span className='svg-icon svg-icon-3x svg-icon-info mr-1'>
                  <SVG src={ICONS.VIDEO_LANE} />
                </span>
                <span className={'font-size-h3'}>
                  <FormattedNumber value={connectRequest.videoCount} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderStatus = () => {
    if (connectRequest === null) {
      return <></>;
    }

    const textStyle =
      connectRequest.requestStatus === FilterRequestStatus.REJECTED
        ? 'text-danger'
        : connectRequest.requestStatus === FilterRequestStatus.APPROVED
        ? 'text-success'
        : 'text-primary';

    return (
      <>
        <div className={'col-12'}>
          <div className={'d-flex align-items-center mr-5 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'STATUS'} />
              </span>
              <div className={'d-flex align-items-center'}>
                <span className={'font-size-h3 ' + textStyle}>
                  <FormattedMessage id={'FILTER_REQUEST_STATUS_' + connectRequest.requestStatus} />
                </span>
              </div>
            </div>
          </div>
        </div>

        {connectRequest.rejectReason && (
          <div className={'col-12'}>
            <div className={'d-flex align-items-center mr-5 my-1'}>
              <div className={'d-flex flex-column text-dark-75'}>
                <span className={'font-weight-bolder font-size-sm'}>
                  <FormattedMessage id={'REJECT_REASON'} />
                </span>
                <div className={'d-flex align-items-center'}>
                  <span className={'font-size-h6'}>{connectRequest.rejectReason}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderButtons = () => {
    return (
      <div className={'d-flex align-items-center flex-wrap'}>
        <div className={'item d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column'}>
            <button
              disabled={loadings.action || !connectRequest?.canConfirm}
              className={'btn btn-light-success font-weight-bolder'}
              onClick={handleApproveClick}>
              <span className={`svg-icon svg-icon-light-danger svg-icon-2x mr-2`}>
                <SVG src={ICONS.CHECK} />
              </span>
              <FormattedMessage id={'APPROVE'} />
            </button>
          </div>
        </div>

        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
            <button
              disabled={loadings.action || !connectRequest?.canReject}
              onClick={handleRejectClick}
              className={'btn btn-light-danger font-weight-bolder'}>
              <span className={`svg-icon svg-icon-light-danger svg-icon-2x mr-2`}>
                <SVG src={ICONS.CROSS} />
              </span>
              <FormattedMessage id={'REJECT'} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='col-xxl-12 order-2 order-xxl-1'>
      <div className={`card card-custom card-stretch gutter-b`}>
        <div className='card-header d-flex border-0'>
          <div className={'d-flex flex-row mt-6'}>
            <div className='symbol symbol-sm-150 symbol-lg-150'>
              <img
                src={tryGetPreviewOrStub(connectRequest?.thumbnailsDTO).url}
                alt='image'
                className={'w-100 rounded'}
              />
            </div>
            <div className='ml-4'>
              <div className='d-flex align-items-start flex-column'>
                <a
                  href={Routes.getExternalYoutubeChannelRoute(connectRequest?.channelId ?? '')}
                  target={'_blank'}
                  className='font-weight-bolder font-size-h1'
                  rel='noreferrer'>
                  {connectRequest?.title}
                </a>
                {connectRequest?.description && (
                  <span className='text-muted mt-3 pr-5 font-weight-bold font-size-lg'>
                    <span className={'font-weight-boldest'}>{intl.formatMessage({id: 'DESCRIPTION'})}</span>:{' '}
                    {connectRequest?.description}
                  </span>
                )}
                {connectRequest?.comment && (
                  <span className='text-muted mt-3 pr-5 font-weight-bold font-size-lg'>
                    <span className={'font-weight-boldest'}>{intl.formatMessage({id: 'COMMENT'})}</span>:{' '}
                    {connectRequest?.comment}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className={'row'}>{renderMetrics()}</div>
          <div className={'row'}>{renderStatus()}</div>
          <div className={'separator separator-solid my-7'} />
          {renderButtons()}
        </div>
      </div>
    </div>
  );
};
