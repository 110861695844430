import React, {useLayoutEffect, useMemo} from 'react';
import objectPath from 'object-path';
import {getBreadcrumbsAndTitle, useHtmlClassService, useSubheader} from '../../../_metronic/layout';
import AnimateLoading from '../../../_metronic/_partials/controls/AnimateLoading';
import {BreadCrumbs} from '../../components/breadcrumbs/bread-crumbs';
import {useLocation} from 'react-router-dom';
import {LanguageSelectorDropdown} from './language-selector-dropdown';
import {UserProfileDropdown} from './user-profile-dropdown';

export function Header() {
  const uiService = useHtmlClassService();
  const subheader = useSubheader();
  const location = useLocation();
  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname);
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname);
    const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
  }, [location.pathname]);

  return (
    <div className={`header ${layoutProps.headerClasses}`} id='kt_header' {...layoutProps.headerAttributes}>
      <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
        <AnimateLoading />
        <div className='header-menu-wrapper header-menu-wrapper-left' id='kt_header_menu_wrapper'>
          <div className={'header-menu'}>
            <BreadCrumbs />
          </div>
        </div>
        <div className={'topbar'}>
          <LanguageSelectorDropdown />
          <UserProfileDropdown />
        </div>
      </div>
    </div>
  );
}
