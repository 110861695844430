import React from 'react';
import {Link, Redirect, Switch, useHistory} from 'react-router-dom';
import {ContentRoute} from '../../../../_metronic/layout';
import Registration from '../pages/registration';
import Login from '../pages/login';
import AuthMobileFooter from './mobile-footer';
import {FormattedMessage} from 'react-intl';
import {LanguageSelectorDropdown} from '../../../layout/header/language-selector-dropdown';
import ForgotPassword from '../pages/forgot-password';
import {ResetPassword} from '../pages/reset-password';
import {Routes} from '../../../../configs/routes';
import {UserAgreement} from "../pages/user-agreement";

interface AuthContentProps {
  redirectUrl?: string | null;
}

export const AuthContent: React.FC<AuthContentProps> = ({redirectUrl}) => {
  const history = useHistory();

  const renderContentHeader = () => {
    const pathContainsLogin = history.location.pathname.includes('login');
    return (
      <div
        className='position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10'>
        <div className='mb-1'>
          <LanguageSelectorDropdown />
        </div>
        <span className='font-weight-bold text-dark-50'>
          {
            pathContainsLogin
              ? <FormattedMessage id='AUTH.GENERAL.NO_ACCOUNT' />
              : <FormattedMessage id='AUTH.GENERAL.ALREADY_HAVE_ACCOUNT' />
          }
        </span>
        <Link
          to={pathContainsLogin ? Routes.getRegistrationRoute() : Routes.getLoginRoute()}
          className='font-weight-bold ml-2'
          id='kt_login_signup'>
          {
            pathContainsLogin
              ? <FormattedMessage id='AUTH.GENERAL.SIGNUP_BUTTON' />
              : <FormattedMessage id='AUTH.LOGIN.BUTTON' />
          }
        </Link>
      </div>);
  };

  const renderContentBody = () => {
    return (
      <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
        <Switch>
          <ContentRoute path={Routes.getLoginRoute()} component={Login} />
          <ContentRoute
            path={Routes.getRegistrationRoute()}
            component={Registration}
          />
          <ContentRoute
            path={Routes.getForgotPasswordRoute()}
            component={ForgotPassword}
          />
          <ContentRoute
            path={Routes.getResetPasswordRoute(':token')}
            component={ResetPassword}
          />
          <ContentRoute
            path={Routes.getUserAgreementRoute()}
            component={UserAgreement}
          />
          <Redirect
            exact
            from={Routes.getAuthRoute()}
            to={{pathname: Routes.getLoginRoute(), search: redirectUrl ? `?redirect_url=${redirectUrl}` : ''}}
          />
          <Redirect
            to={{pathname: Routes.getLoginRoute(), search: redirectUrl ? `?redirect_url=${redirectUrl}` : ''}} />
        </Switch>
      </div>
    );
  };

  return (
    <div className='d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden'>
      {renderContentHeader()}
      {renderContentBody()}
      <AuthMobileFooter />
    </div>
  );
};

