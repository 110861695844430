import React, {PropsWithChildren} from 'react';
import cn from 'classnames';
import {createSlot} from 'react-slotify';

export const CardTitle = createSlot();
export const CardToolbar = createSlot();
export const CardBody = createSlot();
export const CardFooter = createSlot();

type Props = {
  hideHeader?: boolean;
  hideFooter?: boolean;
  className?: {
    card?: string;
    header?: string;
    body?: string;
    footer?: string;
  };
};

export const Card = ({children, className, hideFooter, hideHeader}: PropsWithChildren<Props>) => {
  return (
    <>
      <div className={cn('card card-custom gutter-b', className?.card)}>
        {!hideHeader && (
          <div className={cn('card-header', className?.header)} style={{flexWrap: 'nowrap'}}>
            <div className={'card-title'}>
              <div className={'mr-4'}>
                <CardTitle.Renderer childs={children} />
              </div>
            </div>
            <CardToolbar.Renderer childs={children} />
          </div>
        )}

        <div className={cn('card-body', className?.body)}>
          <CardBody.Renderer childs={children} />
        </div>
        {(hideFooter === undefined || !hideFooter) && (
          <div className={cn('card-footer', className?.footer)}>
            <CardFooter.Renderer childs={children} />
          </div>
        )}
      </div>
    </>
  );
};
