import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {ContractorsApi} from '../../../api/contractor-api/contractors-api';
import {ModalEditContractor} from './modal-edit-contractor';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useJurisdictionsApi} from '../../../hooks/apis/use-jurisdictions-api';
import {toast} from 'react-toastify';

interface IModalEditContractorContext {
  modalEditContractorVisible: boolean;

  showEditContractorModal(
    contractorId: number,
    name: string,
    jurisdictionId: string | number | null,
  ): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalEditContractorContext = createContext<IModalEditContractorContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalEditContractorProvider = ({children}: any) => {
  const intl = useIntl();

  const api = new ContractorsApi();
  const jurisdictionsApi = useJurisdictionsApi();

  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [name, setName] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [contractorId, setContractorId] = useState<number | null>(null);

  const [jurisdiction_id, setJurisdiction_id] = useState<string | number | null>(null);
  const [jurisdictions, setJurisdictions] = useState<any>([]);

  useEffect(() => {
    if (visible) {
      fetchSelectValues().then();
    }
  }, [visible]);

  const showModal = async (contractorId: number, name: string, jurisdictionId: string | number | null) => {
    setVisible(true);
    setName(name);
    setJurisdiction_id(jurisdictionId);
    setContractorId(contractorId);
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const fetchSelectValues = useCallback(async () => {
    try {
      const res = (await jurisdictionsApi.select())?.data?.items ?? [];
      setJurisdictions(res.map(r => ({label: r.title, value: r.id})));
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  const handleChangeName = (name: string) => {
    setName(name);
  };

  const handleHideModal = () => {
    setVisible(false);
    setName('');
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      await api.editContractor(contractorId, name, jurisdiction_id);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalEditContractorContext = {
    modalEditContractorVisible: visible,
    showEditContractorModal: showModal,
  };

  return (
    <ModalEditContractorContext.Provider value={value}>
      {children}
      <ModalEditContractor
        visible={visible}
        jurisdictions={jurisdictions}
        jurisdiction_id={jurisdiction_id}
        onJurisdictionChange={setJurisdiction_id}
        contractorName={name}
        error={error}
        validationErrors={validationErrors}
        onChangeContractorName={handleChangeName}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalEditContractorContext.Provider>
  );
};

export const useModalEditContractor = () => {
  return useContext(ModalEditContractorContext);
};
