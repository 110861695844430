import React, {useMemo} from 'react';
import SVG from 'react-inlinesvg';
import {FormattedNumber, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {IYoutubeChannelDto} from '../../../api/DTOs/IYoutubeChannelDto';
import {DefaultTable} from '../../../components/default-table';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {Routes} from '../../../../configs/routes';
import {TableUtils} from '../../../components/table-utils';

const VISIBLE_SVG = require('../../../images/svg/Visible.svg');
const SUBSCRIBER_SVG = require('../../../images/svg/Group.svg');
const VIDEO_LANE = require('../../../images/svg/Video-Lane.svg');

interface IYoutubeChannelsTableProps {
  channels: Array<IYoutubeChannelDto>;
}

export const YoutubeChannelsTable = ({channels}: IYoutubeChannelsTableProps) => {
  const intl = useIntl();
  const data = channels;
  const columns = useMemo(() => {
    return [
      {
        id: 'Title',
        Header: intl.formatMessage({id: 'TITLE'}),
        maxWidth: 50,
        minWidth: 100,
        accessor: (br: IYoutubeChannelDto) => br,
        Cell: ({value}: {value: IYoutubeChannelDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={Routes.getYoutubeSpecifyChannelRoute(value.id)}>
              <img src={tryGetPreviewOrStub(value.thumbnails).url} alt='image' className={'w-100 rounded'} />
            </Link>,
          );
        },
      },
      {
        id: 'Header',
        Header: '',
        maxWidth: 250,
        accessor: (br: IYoutubeChannelDto) => br,
        Cell: ({value}: {value: IYoutubeChannelDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={Routes.getYoutubeSpecifyChannelRoute(value.id)}>
              <span className={'font-weight-bolder'}>{value.title}</span>
            </Link>,
          );
        },
      },
      {
        id: 'Subscribers',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUBSCRIBERS'})),
        maxWidth: 100,
        accessor: (br: IYoutubeChannelDto) => br.subscriberCount,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(
            <>
              <span className='svg-icon svg-icon-lg svg-icon-success mr-1'>
                <SVG src={SUBSCRIBER_SVG} />
              </span>
              <span className={'font-weight-bolder'}>
                <FormattedNumber value={value} />
              </span>
            </>,
          );
        },
      },
      {
        id: 'Views',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'VIEWS'})),
        maxWidth: 100,
        accessor: (br: IYoutubeChannelDto) => br.viewCount,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(
            <>
              <span className='svg-icon svg-icon-lg svg-icon-primary mr-1'>
                <SVG src={VISIBLE_SVG} />
              </span>
              <span className={'font-weight-bolder'}>
                <FormattedNumber value={value} />
              </span>
            </>,
          );
        },
      },
      {
        id: 'Videos',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'VIDEOS'})),
        maxWidth: 100,
        accessor: (br: IYoutubeChannelDto) => br.videoCount,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(
            <>
              <span className='svg-icon svg-icon-lg svg-icon-secondary mr-1'>
                <SVG src={VIDEO_LANE} />
              </span>
              <span className={'font-weight-bolder'}>
                <FormattedNumber value={value} />
              </span>
            </>,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
