import React, {Ref} from 'react';
import './InputText.scss';
import cn from 'classnames';

interface InputCustomProps {
  id?: string;
  value?: string | number | null;
  defaultValue?: string | number | null;
  name?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  classNames?: string;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  innerRef?: Ref<any>;
  maxLength?: number;
  maxNumber?: number;
  minNumber?: number;
  pattern?: string;
  step?: number;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';

  onPaste?(event: React.ClipboardEvent): void;

  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;

  onChange(event: React.SyntheticEvent<any>): void;
}

export const InputText = ({
  id,
  classNames,
  name,
  onChange,
  placeholder,
  type,
  label,
  value,
  maxLength,
  maxNumber,
  minNumber,
  defaultValue,
  required,
  hasError,
  disabled,
  innerRef,
  pattern,
  step,
  inputMode,
  onKeyDown,
  onPaste,
}: InputCustomProps) => {
  const props = defaultValue != undefined || defaultValue != null ? {defaultValue: defaultValue} : {value: value || ''};
  return (
    <>
      {label && (
        <label className={'font-weight-bolder'}>
          {label} {required && <span className={'text-danger'}>*</span>}
        </label>
      )}
      <input
        ref={innerRef}
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        type={type}
        className={cn('input-text', classNames, {
          'is-invalid': hasError,
        })}
        name={name}
        inputMode={inputMode}
        maxLength={maxLength}
        max={maxNumber}
        min={minNumber}
        step={step}
        pattern={pattern}
        onChange={onChange}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        {...props}
      />
    </>
  );
};
