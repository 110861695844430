import React, {useMemo} from 'react';
import {FormattedNumber, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {IAddendumForUserDto} from '../../api/addendum-api/IAddendumDto';
import {DefaultTable} from '../../components/default-table';
import {Routes} from '../../../configs/routes';
import {tryGetPreviewOrStub} from '../../utils/utils';
import {TableUtils} from '../../components/table-utils';

interface Props {
  addendums: Array<IAddendumForUserDto>;
}

export const SimplifiedAddendumsTable = ({addendums}: Props) => {
  const intl = useIntl();
  const data = addendums;

  const columns = useMemo(() => {
    return [
      {
        id: 'Title',
        Header: intl.formatMessage({id: 'TITLE'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: IAddendumForUserDto) => br,
        Cell: ({value}: {value: IAddendumForUserDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={Routes.getYoutubeSpecifyChannelRoute(value.youtubeChannelId)}>
              <img
                src={tryGetPreviewOrStub(value.youtubeChannelThumbnails).url}
                alt='image'
                className={'w-50 rounded'}
              />
            </Link>,
          );
        },
      },
      {
        id: 'Header',
        Header: '',
        accessor: (br: IAddendumForUserDto) => br,
        Cell: ({value}: {value: IAddendumForUserDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={Routes.getYoutubeSpecifyChannelRoute(value.youtubeChannelId)}>
              <span className={'font-weight-bolder'}>{value.youtubeChannelTitle}</span>
            </Link>,
          );
        },
      },
      {
        id: 'Rate',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'RATE_PERCENT_SHORT'})),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IAddendumForUserDto) => br,
        Cell: ({value}: {value: IAddendumForUserDto}) => {
          return TableUtils.renderNumericContent(
            <FormattedNumber value={value.ratePercent} style={'percent'} />,
          );
        },
      },
      {
        id: 'Validity',
        Header: intl.formatMessage({id: 'VALIDITY_PERIOD'}),
        maxWidth: 200,
        minWidth: 200,
        accessor: (br: IAddendumForUserDto) => br,
        Cell: ({value}: {value: IAddendumForUserDto}) => {
          return TableUtils.renderBaseContent(
            <>{`${intl.formatDate(value?.startedAt)} - ${value?.expiresAt ? intl.formatDate(value.expiresAt) : 'N/A'}`}</>,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
