import React from 'react';
import {Link} from 'react-router-dom';

const AuthMobileFooter = () => {
  return (
    <div
      className='d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
      <div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>
        &copy; 2022 Playnetwork
      </div>
      <div className='d-flex order-1 order-sm-2 my-2'>
        <Link to='/terms' className='text-dark-75 text-hover-primary'>
          Privacy
        </Link>
        <Link
          to='/terms'
          className='text-dark-75 text-hover-primary ml-4'>
          Legal
        </Link>
        <Link
          to='/terms'
          className='text-dark-75 text-hover-primary ml-4'>
          Contact
        </Link>
      </div>
    </div>);
};

export default AuthMobileFooter;
