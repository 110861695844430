import React, {createContext, useContext, useState} from 'react';
import {ModalCreateAddendum} from './modal-create-addendum';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {AddendumApi} from '../../../api/addendum-api/addendum-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {prepareDate, ValidationErrorsType} from '../../../utils/utils';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {EntityId} from '../../../api/base/BaseEntity';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {PartialNullable} from '../../../../types';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {SelectApi} from '../../../api/select-api';

interface IModalCreateAddendumContext {
  modalCreateAddendumVisible: boolean;

  showCreateAddendumModal(contractId: EntityId): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalCreateAddendumContext = createContext<IModalCreateAddendumContext>();

type State = {
  visible: boolean;
  contractId: EntityId;
  channels: Array<ISelectValueDto>;
  error: string | null;
};

export type CreateAddendumFormState = {
  y_channel_id: EntityId | null;
  rate_percent: number;
  started_at: Date | null;
  expires_at: Date | null;
  is_referral: boolean;
};

const InitialModalState: PartialNullable<State> = {
  visible: false,
};

const InitialFormState: PartialNullable<CreateAddendumFormState> = {
  rate_percent: 0,
};

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalCreateAddendumProvider = ({children}: any) => {
  const intl = useIntl();
  const selectApi = new SelectApi();
  const addendumApi = new AddendumApi();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>();
  const [modalState, setState, updateState] = useAdvancedState<State>(InitialModalState);
  const [file, setFile] = useState<File | null>(null);
  const [fields, setFields, updateFields] = useAdvancedState<CreateAddendumFormState>(
    InitialFormState,
    setValidationErrors,
  );

  const fetchChannels = async () => {
    try {
      const result = await selectApi.getYoutubeChannels();
      updateState({channels: result.data.items});
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        updateState({error: err.errorMessage});
      } else {
        updateState({error: e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'})});
      }
    }
  };

  const showModal = async (contractId: EntityId) => {
    updateState({visible: true, contractId});
    await fetchChannels();
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setState(InitialModalState);
    setFields(InitialFormState);
    setValidationErrors(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      updateState({error: null});
      await addendumApi.createAddendum({
        contract_id: modalState.contractId,
        started_at: prepareDate(fields.started_at),
        expires_at: prepareDate(fields.expires_at),
        y_channel_id: fields.y_channel_id,
        rate_percent: (fields.rate_percent ?? 0) / 100,
        is_referral: fields.is_referral ?? false,
      });

      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }

      handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as IValidationException).error_data.messages);
      } else {
        updateState({error: err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'})});
      }
    }
  };

  const value: IModalCreateAddendumContext = {
    modalCreateAddendumVisible: modalState.visible ?? false,
    showCreateAddendumModal: showModal,
  };

  return (
    <ModalCreateAddendumContext.Provider value={value}>
      {children}
      <ModalCreateAddendum
        visible={modalState.visible ?? false}
        error={modalState.error}
        channels={modalState.channels ?? []}
        fields={fields}
        file={file}
        onSelectFile={setFile}
        validationErrors={validationErrors}
        onChange={updateFields}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalCreateAddendumContext.Provider>
  );
};

export const useModalCreateAddendum = () => {
  return useContext(ModalCreateAddendumContext);
};
