import React from 'react';
import {useIntl} from '../hooks/use-intl';
import {FormatDateOptions} from '@formatjs/intl/src/types';
import cn from 'classnames';
import {BootstrapColor} from '../styles/styles';

export const TIMESTAMP_CONFIGS = {
  DEFAULT: {month: 'long', year: 'numeric', day: 'numeric'},
} as Record<string, Partial<FormatDateOptions>>;

type Props = {
  items: Array<{
    title: string;
    color?: BootstrapColor;
    date: string | Date | null;
    format?: Partial<FormatDateOptions>;
  }>;
};
export const Timestamps: React.FC<Props> = ({items}) => {
  const intl = useIntl();
  return (
    <>
      <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
        <div className={'d-flex flex-wrap align-items-center py-2'}>
          <div className={'d-flex align-items-center flex-wrap'}>
            {items.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>{i.title}</div>
                    <span
                      className={cn('text-uppercase font-weight-bolder', {
                        [`text-${i.color}`]: i.color !== undefined,
                      })}>
                      {i.date ? intl.formatDate(i.date, i.format) : intl.formatMessage({id: 'NOT_SPECIFIED'})}
                    </span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
