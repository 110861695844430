import React, {useMemo} from 'react';
import {FormattedNumber} from 'react-intl';
import {IFinancialAccountDto} from '../../../../api/DTOs/IFinancialAccountDto';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {AdminRoutes} from '../../../../../configs/routes';
import {RowActionButton} from '../../../../components/table-actions-button';
import {
  HandleTransactionActionPayload,
  HandleTransactionCreatePayload,
} from '../../transactions/useTransactionActionHandler';
import {TransactionAction} from '../../transactions/transactions-page';
import {useIntl} from '../../../../hooks/use-intl';
import {TableUtils} from '../../../../components/table-utils';

interface FinancialAccountsTableProps {
  accounts: Array<IFinancialAccountDto>;

  onSelectAction(payload: HandleTransactionActionPayload): Promise<any>;
}

export const FinancialAccountsTable = ({accounts, onSelectAction}: FinancialAccountsTableProps) => {
  const intl = useIntl();
  const data = accounts;

  const handleAction = async (account: IFinancialAccountDto, action: TransactionAction) => {
    account.contractorName;
    await onSelectAction({
      type: action,
      data: {contractorId: account.contractorId, contractorName: account.contractorName},
    } as HandleTransactionCreatePayload);
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: IFinancialAccountDto) => br,
        Cell: ({value}: {value: IFinancialAccountDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyFinancialAccountsRoute(value.id)}>{value.id}</Link>,
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 450,
        minWidth: 300,
        accessor: (br: IFinancialAccountDto) => br,
        Cell: ({value}: {value: IFinancialAccountDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractorId)}>
              {value.contractorName}&nbsp;<span className={'text-muted font-weight-normal'}>({value.type})</span>
            </Link>,
          );
        },
      },
      {
        id: 'BALANCE',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'BALANCE'})),
        maxWidth: 200,
        minWidth: 100,
        accessor: (br: IFinancialAccountDto) => br,
        Cell: ({value}: {value: IFinancialAccountDto}) => {
          const balanceColor = value.balance < 0 ? 'text-danger' : value.balance > 0 ? 'text-success' : '';

          return TableUtils.renderNumericContent(
            <p className={'m-0 p-0 ' + balanceColor}>
              <FormattedNumber value={value.balance} style={'currency'} currency={value.currency} />
            </p>,
          );
        },
      },
      {
        id: 'UnconfirmedBalance',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'UNCONFIRMED_BALANCE'})),
        maxWidth: 300,
        minWidth: 100,
        accessor: (br: IFinancialAccountDto) => br,
        Cell: ({value}: {value: IFinancialAccountDto}) => {
          const balanceColor =
            value.waitingConfirmBalance < 0 ? 'text-danger' : value.balance > 0 ? 'text-success' : '';

          return TableUtils.renderNumericContent(
            <p className={'m-0 p-0 ' + balanceColor}>
              <FormattedNumber value={value.waitingConfirmBalance} style={'currency'} currency={value.currency} />
            </p>,
          );
        },
      },
      {
        id: 'Created_At',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IFinancialAccountDto) => br,
        Cell: ({value}: {value: IFinancialAccountDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.createdAt)} {intl.formatTime(value.createdAt)}
            </>,
          );
        },
      },
      {
        id: 'Updated_at',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IFinancialAccountDto) => br,
        Cell: ({value}: {value: IFinancialAccountDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.updatedAt)} {intl.formatTime(value.updatedAt)}
            </>,
          );
        },
      },
      // {
      //   id: 'Button',
      //   maxWidth: 80,
      //   minWidth: 80,
      //   accessor: (br: IFinancialAccountDto) => br,
      //   Cell: ({value}: {value: IFinancialAccountDto}) => {
      //     const actions = [
      //       {title: intl.formatMessage({id: 'CREATE_ACTION_TAX_TRANSACTION'}), value: TransactionAction.TAX},
      //       {title: intl.formatMessage({id: 'CREATE_ACTION_DEBIT_TRANSACTION'}), value: TransactionAction.DEBIT},
      //       {
      //         title: intl.formatMessage({id: 'CREATE_ACTION_WITHDRAWING_TRANSACTION'}),
      //         value: TransactionAction.WITHDRAWING,
      //       },
      //       {title: intl.formatMessage({id: 'CREATE_ACTION_INCOME_TRANSACTION'}), value: TransactionAction.INCOME},
      //     ];
      //     return TableUtils.renderActionButton(
      //       <RowActionButton rowDto={value} actions={actions} onSelect={handleAction} />,
      //     );
      //   },
      // },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
