import React, {useEffect, useMemo, useState} from 'react';
import {IUserDto} from '../../../api/DTOs/IUserDto';
import {IPaginationInfo} from '../../../api/Paginator';
import {UsersTable} from './users-table';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {EXCEPTION_TYPE, IBaseException} from '../../../api/exceptions/IBaseException';
import {IBadRequestException} from '../../../api/exceptions/IBadRequestException';
import {useModalCreateUser} from '../../../components/modals/create-user/modal-create-user-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useModalEditUser} from '../../../components/modals/edit-user/modal-edit-user-context';
import {UsersApi} from '../../../api/user-api/users-api';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {useModalGenerateFinancialReport} from '../../../components/modals/generate-financial-report/modal-generate-financial-report-context';
import {useHistory} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import {useBaseListPage} from '../../base/base-list-page-context';
import {SelectApi} from '../../../api/select-api';
import {SelectsValuesDTOs} from '../../../components/Inputs/InputSelect';
import {useLoading} from '../../../hooks/use-loading';
import {toast} from 'react-toastify';

export enum UserAction {
  DELETE = 'DELETE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  EDIT = 'EDIT',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
}

const ICONS = {
  PLUS: require('../../../images/svg/Plus.svg'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const UserManagementPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const api = new UsersApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const selectApi = new SelectApi();
  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({});
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [alertVisible, setAlertVisible] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [users, setUsers] = useState<Array<IUserDto>>([]);

  const {showCreateUserModal} = useModalCreateUser();
  const {showEditUserModal} = useModalEditUser();
  const {showGenerateFinancialReportModal} = useModalGenerateFinancialReport();
  const {showConfirmActionModal} = useModalConfirmAction();

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'users_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.TEXT,
          name: 'email',
          placeholder: intl.formatMessage({id: 'EMAIL'}),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);

  const {appliedQueryParams} = useBaseListPage();

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        contractor_id: (await selectApi.getContractors()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const fetchUsers = async (opt?: IQueryParams) => {
    try {
      setLoading(true);
      setActionError(null);
      setAlertVisible(false);
      const result = await api.getUserList(opt);
      setUsers(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const doAction = async (confirmMessage: string, cb: () => Promise<void>) => {
    if (await showConfirmActionModal(intl.formatMessage({id: confirmMessage}))) {
      await cb();
      await fetchUsers(appliedQueryParams);
      setAlertVisible(true);
    }
  };

  const handleCreateUserClick = async () => {
    const result = await showCreateUserModal();
    if (result.reason === CloseModalReason.OK && result.payload != undefined) {
      history.push(AdminRoutes.getSpecifyUserManagementRoute(result.payload));
    }
  };

  const handleEditUserClick = async (userId: number) => {
    const user = users.find(u => u?.id === userId);
    if (user == undefined) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('User should be exists');
    }
    const result = await showEditUserModal(user);
    if (result.reason === CloseModalReason.OK) {
      await fetchUsers(appliedQueryParams);
      setAlertVisible(true);
    }
  };

  const handleActionSelect = async (userId: number, action: UserAction): Promise<any> => {
    try {
      setActionError(null);
      setAlertVisible(false);
      switch (action) {
        case UserAction.DELETE: {
          await doAction('CONFIRM_DELETE_USER', async () => {
            await api.deleteUser(userId);
          });
          return;
        }
        case UserAction.BLOCK: {
          await doAction('CONFIRM_BLOCK_USER', async () => {
            await api.blockUser(userId);
          });
          return;
        }
        case UserAction.UNBLOCK: {
          await doAction('CONFIRM_UNBLOCK_USER', async () => {
            await api.unblockUser(userId);
          });
          return;
        }
        case UserAction.RESET_PASSWORD: {
          await doAction('CONFIRM_RESET_PASSWORD_USER', async () => {
            await api.resetPasswordForUser(userId);
          });
          return;
        }
        case UserAction.EDIT: {
          await handleEditUserClick(userId);
          return;
        }
        case UserAction.DOWNLOAD_REPORT: {
          await showGenerateFinancialReportModal(userId);
          return;
        }
        default: {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error();
        }
      }
    } catch (e) {
      if ((e as IBaseException).error_type === EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION) {
        setActionError((e as IBadRequestException).exception.message);
      } else {
        setActionError(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
      setAlertVisible(true);
    }

    return true;
  };

  const renderUsers = () => {
    if (users.length === 0)
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );

    return (
      <>
        <AlertCustom
          dismissible
          visible={alertVisible}
          type={actionError != null ? 'light-danger' : 'light-success'}
          iconClassName={actionError != null ? 'svg-icon-danger' : 'svg-icon-success'}
          onClose={() => setAlertVisible(false)}
          text={
            actionError != null
              ? `${intl.formatMessage({id: 'ERROR_COMPLETE_ACTION_WITH_USER'})}: ${actionError}`
              : intl.formatMessage({id: 'SUCCESS_COMPLETE_ACTION_WITH_USER'})
          }
        />
        <UsersTable onSelect={handleActionSelect} users={users} />
      </>
    );
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchUsers}
      toolbarConfig={[
        {
          type: 'BUTTON',
          title: intl.formatMessage({id: 'CREATE_USER'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: handleCreateUserClick,
        },
      ]}>
      {renderUsers()}
    </BaseListPage>
  );
};
