import React, {useRef} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import './base-modal.scss';
import {createSlot} from 'react-slotify';
import {FormattedMessage} from 'react-intl';

export interface BaseModalProps {
  children: any;
  visible: boolean;

  animation?: boolean;
  centered?: boolean;
  size?: 'sm' | 'lg' | 'md' | 'xl';
  dialogClassName?: string;
  fullscreen?: boolean;

  successButtonTitle?: string;
  loading?: boolean;
  disabledButtons?: boolean;
  closeButton?: boolean;
  backdrop?: 'static' | boolean;
  hideHeader?: boolean;
  hideDismissButton?: boolean;

  onSuccess?(): void;

  onHide?(): void;

  onExited?(): void;
}

const modalPortal = document.getElementById('modal-portal');
export const ModalHeaderSlot = createSlot();
export const ModalBodySlot = createSlot();
export const ModalFooterButtonsSlot = createSlot();

export const BaseModal: React.FC<BaseModalProps> = ({
  children,
  visible,
  animation,
  centered,
  size,
  dialogClassName,
  fullscreen,
  successButtonTitle,
  closeButton,
  disabledButtons,
  loading,
  backdrop,
  hideHeader,
  hideDismissButton,

  onHide,
  onSuccess,
  onExited,
}) => {
  const bodyRef = useRef<HTMLDivElement | any>(null);
  const handleSuccessButtonClick = () => {
    if (bodyRef?.current) {
      bodyRef.current.scrollTo(0, 0);
    }

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Modal
          scrollable
          backdrop={backdrop}
          size={size as any}
          show={visible}
          onHide={onHide}
          animation={animation}
          centered={centered}
          fullscreen={fullscreen}
          dialogClassName={dialogClassName}
          onExited={onExited}>
          {!hideHeader && (
            <Modal.Header closeButton={closeButton ?? true}>
              <ModalHeaderSlot.Renderer childs={children} />
            </Modal.Header>
          )}

          <Modal.Body ref={bodyRef}>
            <ModalBodySlot.Renderer childs={children} />
          </Modal.Body>

          <Modal.Footer>
            {!hideDismissButton && (
              <button onClick={onHide} className={'btn btn-secondary'}>
                <FormattedMessage id={'CLOSE'} />
              </button>
            )}
            <ModalFooterButtonsSlot.Renderer childs={children} />
            {onSuccess != undefined && (
              <button disabled={disabledButtons} onClick={handleSuccessButtonClick} className={'btn btn-success'}>
                {successButtonTitle ?? <FormattedMessage id={'SEND'} />}
                {loading && <Spinner className={'ml-1'} size={'sm'} animation={'border'} />}
              </button>
            )}
          </Modal.Footer>
        </Modal>,
        modalPortal as Element,
      )}
    </>
  );
};
