import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {BaseItemsResponse} from '../../api/base/base-crud-api-response';
import {useRef} from 'react';
import {EntityApiHookParams} from '../use-entity-api';
import {EntityId} from '../../api/base/BaseEntity';
import {Download} from '../../api/DTOs/Download';
import config from '../../../configs/config';

export function useDownloadsApi() {
  const config = useRef({prefixUrl: 'downloads', isAdmin: false});
  return useRoutes(config.current);
}

function useRoutes(params: EntityApiHookParams) {
  const api = useBaseEndpointApi();
  const get = async (id: EntityId) => {
    return await api.getFile(`${config.BASE_API_URL}/${params.prefixUrl}/${id}`);
  };

  const getAll = async () => {
    return await api.get<BaseItemsResponse<Download>>(`${params.prefixUrl}`);
  };

  return {
    get,
    getAll,
  };
}
