import {FinancialAccountType} from './IFinancialAccountDto';
import {FilterRequestStatus} from '../connect-channel-api/FilterRequestStatus';
import {TransactionStatus} from '../transaction-api/Transaction';

export interface IQueryParams extends Record<string, any>{
  filters?: Record<string, any>;
  sort?: Record<string, any>;
  page?: number;
  mode?: string;
  itemsPerPage?: number;
}

export interface IFilterDto {
  title?: string | null;
  email?: string | null;
  channel_id?: string | null;
  period?: SimpleStatisticPeriod | null;
  user_id?: number | null;
  account_id?: number | null;
  contractor_id?: number | null;
  type?: FinancialAccountType | null;
  status_code?: number | null;
  status?: string | TransactionStatus | null;
  request_status?: FilterRequestStatus | null;
  range?: {
    start_date: string | Date | null;
    end_date: string | Date | null;
  };
}

export interface ISortDto {
  field: SortField;
  direction: SortDirection;
}

export enum SortField {
  ID = 'id',
  LIKE = 'likeCount',
  DISLIKE = 'dislikeCount',
  VIEW = 'viewCount',
  COMMENT = 'commentCount',
  VIDEO = 'videoCount',
  SUBSCRIBER = 'subscriberCount',
  TRANSACTION_OPERATION_COUNT = 'transaction',
  TITLE = 'title',
  TYPE = 'type',
  PUBLISHED_AT = 'publishedAt',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SimpleStatisticPeriod {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_28_DAYS = 'LAST_28_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',

  CURRENT_WEEK = 'CURRENT_WEEK',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_YEAR = 'CURRENT_YEAR',
}

