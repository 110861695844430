import {BaseEndpoint} from '../base-endpoint';
import {IResponseBase} from '../response-contracts/base-response';
import config from '../../../configs/config';
import {IQueryParams} from '../DTOs/IFilterDtos';
import {IResponsePaymentRequest, IResponsePaymentRequests} from './payments-response-contracts';
import {ModalPaymentRequestField} from '../../components/modals/create-payment-request-modal/payment-request-modal-context';
import {PartialNullable} from '../../../types';

export class PaymentsApi extends BaseEndpoint {
  public async createPaymentRequest(fields: PartialNullable<ModalPaymentRequestField>): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/payment-requests`, {
      fields,
    });

    return response.data;
  }

  public async createAdminPaymentRequest(fields: PartialNullable<ModalPaymentRequestField>): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/payment-requests`, {
      fields,
    });

    return response.data;
  }

  public async getUserPaymentRequests(filters?: IQueryParams): Promise<IResponsePaymentRequests> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IResponsePaymentRequests>(`${config.BASE_API_URL}/payment-requests?${query}`);
    return response.data;
  }

  public async getPaymentRequests(filters?: IQueryParams): Promise<IResponsePaymentRequests> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IResponsePaymentRequests>(`${config.BASE_API_URL}/admin/payment-requests?${query}`);
    return response.data;
  }

  public async getPaymentRequest(requestId: number): Promise<IResponsePaymentRequest> {
    const response = await this.get<IResponsePaymentRequest>(
      `${config.BASE_API_URL}/admin/payment-requests/${requestId}`,
    );
    return response.data;
  }

  public async performPaymentRequest(requestId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/payment-requests/${requestId}/perform`,
      {},
    );
    return response.data;
  }

  public async rejectPaymentRequest(requestId: number, reject_reason: string): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/payment-requests/${requestId}/reject`,
      {
        fields: {
          reject_reason,
        },
      },
    );
    return response.data;
  }
}
