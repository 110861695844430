import React from 'react';
import Select from 'react-select';
import './InputSelect.scss';

interface Props {
  options: Array<SelectOption>;
  defaultValues: Array<string | number> | null;
  id?: string;
  label?: string;
  descriptionLabel?: string;
  ariaLabel?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;

  onChange(items: Array<SelectOption>): void;
}

export type SelectOption<T = string | number> = {label: string; value: T; icon?: string | null};

export const InputMultiSelect = ({
  options,
  defaultValues,
  isClearable,
  isLoading,
  placeholder,
  ariaLabel,
  onChange,
  className,
  hasError,
  label,
  required,
  disabled,
}: Props) => {
  const onChangeInternal = (e: any) => {
    const result = e as Array<SelectOption>;
    onChange(result);
  };

  return (
    <div className={className + ' select-container'}>
      {label && (
        <label id='aria-label' htmlFor='aria-example-input' className={'font-weight-bolder'}>
          {label} {required && <span className={'text-danger'}>*</span>}
        </label>
      )}
      <Select
        isMulti
        closeMenuOnSelect={false}
        classNamePrefix={'react-select_2'}
        aria-label={ariaLabel}
        value={
          defaultValues?.map(value => {
            return {
              value: value,
              label: options.find(o => o.value == value)?.label ?? 'loading...',
            };
          }) ?? null
        }
        options={options as any}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        isDisabled={disabled}
        menuPosition={'fixed'}
        onChange={onChangeInternal}
        styles={{
          control: styles => ({
            ...styles,
            ...(hasError
              ? {
                  borderColor: '#DC3545 !important',
                  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
                }
              : {}),
          }),
        }}
      />
    </div>
  );
};
