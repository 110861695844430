import config from '../../configs/config';
import {
  ICurrentUserResponse,
  ILoginResponse,
  ILogoutResponse,
  IRegisterResponse,
} from './response-contracts/auth-response-contracts';
import {BaseEndpoint} from './base-endpoint';
import {IResponseBase} from './response-contracts/base-response';

export class AuthApi extends BaseEndpoint {
  public async register(
    email: string,
    password: string,
    password_confirmation: string,
    code: string,
    jurisdiction_id: string | number | null,
  ): Promise<IRegisterResponse> {
    const response = await this.post<IRegisterResponse>(`${config.BASE_API_URL}/register`, {
      fields: {
        email,
        password,
        password_confirmation,
        code,
        jurisdiction_id,
      },
    });

    return response.data;
  }

  public async sendRegistrationRequest(
    email: string,
    password: string,
    password_confirmation: string,
    jurisdiction_id: string | number | null,
  ): Promise<IResponseBase> {
    const response = await this.post(`${config.BASE_API_URL}/check-can-register`, {
      fields: {
        email,
        password,
        password_confirmation,
        jurisdiction_id,
      },
    });

    return response.data;
  }

  public async logout(): Promise<ILogoutResponse> {
    const response = await this.post<ILogoutResponse>(`${config.BASE_API_URL}/logout`, {});

    return response.data;
  }

  public async login(email: string, password: string): Promise<ILoginResponse> {
    const response = await this.post<ILoginResponse>(`${config.BASE_API_URL}/login`, {
      fields: {
        email,
        password,
      },
    });

    return response.data;
  }

  public async resetRequestPassword(email: string): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/request_reset_password`, {
      fields: {
        email,
      },
    });

    return response.data;
  }

  public async resetPassword(
    resetPasswordToken: string,
    newPassword: string,
    confirmPassword: string,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/reset_password`, {
      fields: {
        reset_password_token: resetPasswordToken,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      },
    });

    return response.data;
  }

  public async changePassword(
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/user/change_password`, {
      fields: {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      },
    });

    return response.data;
  }

  public async getCurrentUser(): Promise<ICurrentUserResponse> {
    const response = await this.get<ICurrentUserResponse>(`${config.BASE_API_URL}/user`);
    return response.data;
  }

  public async loadCsrfInCookie() {
    await this.get(`${config.BASE_API_URL}/csrf`);
  }
}
