import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {UserType} from '../../../api/DTOs/IUserDto';
import {InputText} from '../../Inputs/InputText';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';

interface IModalEditUserProps {
  visible: boolean;
  email: string;
  type: UserType;
  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  onEmailChange(email: string): void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalEditUser: React.FC<IModalEditUserProps> = ({
  email,
  visible,
  type,
  error,
  validationErrors,
  onHide,
  onEmailChange,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  const handleCheckboxClick = () => {
    // Nothing
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        successButtonTitle={intl.formatMessage({id: 'EDIT'})}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'EDIT_USER'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('email', validationErrors)}>
                <InputText
                  required
                  value={email}
                  label={intl.formatMessage({id: 'EMAIL'})}
                  hasError={!!getValidationErrorMessage('email', validationErrors)}
                  classNames={'form-control py-3 px-6 mr-2'}
                  onChange={e => onEmailChange(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>

              <InputCheckbox
                disabled
                label={intl.formatMessage({id: 'ADMIN_PRIVILEGES'})}
                value={type === UserType.ADMIN}
                onChange={handleCheckboxClick}
              />
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
