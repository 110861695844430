import React from 'react';
import MenuItemComponent, {MenuItem} from './menu-item-component';
import MenuSectionComponent, {MenuSection} from './menu-section-component';
import asideRouteLinks from '../../../configs/aside-route-links';
import {useIntl} from 'react-intl';
import {shallowEqual, useSelector} from 'react-redux';
import {IApplicationStore} from '../../../redux/rootReducer';
import {UserType} from '../../api/DTOs/IUserDto';

export function AsideMenuList(props: any) {
  const intl = useIntl();
  const isAdmin = useSelector<IApplicationStore, boolean>(({auth}) => auth.user?.type === UserType.ADMIN, shallowEqual);
  return (<>
      <ul className={`menu-nav ${props.layoutProps.ulClasses}`}>
        {asideRouteLinks.map(({item, type, hide_for_admin = false, is_protected = false}) => {
          if (is_protected && !isAdmin || hide_for_admin && isAdmin) {
            return null;
          }

          if (type === 'section')
            return <MenuSectionComponent key={intl.formatMessage({id: item.title})} item={item as MenuSection} />;
          else
            return <MenuItemComponent key={intl.formatMessage({id: item.title})} item={item as MenuItem} />;
        })}
      </ul>
    </>
  );
}
