import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputSelect} from '../../Inputs/InputSelect';
import {InputTextArea} from '../../Inputs/InputTextArea';
import {InputText} from '../../Inputs/InputText';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {getValidationErrorMessage} from '../../../utils/utils';
import {DateFormat, InputDatepicker} from '../../Inputs/InputDatepicker';
import {CreateTransactionRequest} from '../../../api/transaction-api/transactions-api';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {PartialNullable} from '../../../../types';

interface IModalCreateTransactionProps {
  title: string;
  visible: boolean;
  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;
  disabledContractorSelect: boolean;
  contractors: Array<ISelectValueDto>;

  hideDatepicker: boolean;
  hideAddendumSelect: boolean;

  loadingAddendums: boolean;
  addendums: Array<ISelectValueDto>;
  loadingBalance: boolean;
  balance: number | null;
  unconfirmedBalance: number | null;

  entity: PartialNullable<CreateTransactionRequest>;

  onChange(key: keyof CreateTransactionRequest, value: any): void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateTransaction: React.FC<IModalCreateTransactionProps> = ({
  loadingBalance,
  balance,
  unconfirmedBalance,
  hideAddendumSelect,
  hideDatepicker,
  title,
  visible,
  entity,
  loadingAddendums,
  disabledContractorSelect,
  addendums,
  contractors,
  error,
  validationErrors,
  onChange,
  onOkClick,
  onHide,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  const renderBalance = () => {
    if (balance === null && !loadingBalance) {
      return;
    }

    return (
      <p className={'font-weight-bolder mb-7'}>
        {loadingBalance ? (
          <span className='ml-2 spinner spinner-secondary' />
        ) : (
          <>
            <FormattedMessage id={'BALANCE'} />: {CustomFormatter.formatMoney(balance)}
            <br />
            <FormattedMessage id={'UNCONFIRMED_BALANCE'} />: {CustomFormatter.formatMoney(unconfirmedBalance)}
          </>
        )}
      </p>
    );
  };

  return (
    <BaseModal visible={visible} loading={loading} disabledButtons={loading} onSuccess={handleOkClick} onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>{title}</Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <div className='form-group'>
            <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', validationErrors)}>
              <InputSelect
                required
                disabled={disabledContractorSelect}
                isClearable
                id={'contractor_id'}
                label={intl.formatMessage({id: 'CONTRACTOR'})}
                placeholder={''}
                hasError={!!getValidationErrorMessage('contractor_id', validationErrors)}
                className={'mb-2'}
                value={entity.contractor_id}
                options={contractors.map(contractor => ({
                  value: contractor.id,
                  label: contractor.title,
                }))}
                onChange={value => onChange('contractor_id', value)}
              />
            </ValidateErrorWrapper>

            {renderBalance()}

            {!hideAddendumSelect && (
              <ValidateErrorWrapper message={getValidationErrorMessage('addendum_id', validationErrors)}>
                <InputSelect
                  required
                  isClearable
                  isLoading={loadingAddendums}
                  id={'addendum_id'}
                  label={intl.formatMessage({id: 'ADDENDUM'})}
                  placeholder={''}
                  hasError={!!getValidationErrorMessage('addendum_id', validationErrors)}
                  className={'mb-2'}
                  value={entity.addendum_id}
                  options={
                    addendums?.map(addendum => ({
                      value: addendum.id,
                      label: addendum.title,
                    })) || []
                  }
                  onChange={value => onChange('addendum_id', value)}
                />
              </ValidateErrorWrapper>
            )}

            <ValidateErrorWrapper message={getValidationErrorMessage('value', validationErrors)}>
              <InputText
                required
                type={'number'}
                defaultValue={0}
                label={intl.formatMessage({id: 'SUM'}) + ' ($)'}
                hasError={!!getValidationErrorMessage('value', validationErrors)}
                classNames={'form-control'}
                onChange={e => onChange('value', e.currentTarget.value)}
              />
            </ValidateErrorWrapper>

            {!hideDatepicker && (
              <ValidateErrorWrapper
                message={getValidationErrorMessage(['period_month', 'period_year'][0], validationErrors)}>
                <InputDatepicker
                  required
                  showMonthYearPicker
                  format={DateFormat.MM_YYYY}
                  hasError={!!getValidationErrorMessage(['period_month', 'period_year'], validationErrors)}
                  selected={
                    entity?.period_year != null &&
                    entity?.period_month != null
                      ? new Date(entity.period_year, entity.period_month)
                      : null
                  }
                  label={intl.formatMessage({id: 'PERIOD'})}
                  className={'mb-2'}
                  onChange={value => {
                    onChange('period_month', value?.getMonth() ?? null);
                    onChange('period_year', value?.getFullYear() ?? null);
                  }}
                />
              </ValidateErrorWrapper>
            )}

            <ValidateErrorWrapper message={getValidationErrorMessage('description', validationErrors)}>
              <InputTextArea
                required
                defaultValue={''}
                label={intl.formatMessage({id: 'DESCRIPTION'})}
                hasError={!!getValidationErrorMessage('description', validationErrors)}
                className={'form-control'}
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </ValidateErrorWrapper>
          </div>
        </form>
      </ModalBodySlot>
    </BaseModal>
  );
};
