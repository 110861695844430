import React, {useEffect, useState} from 'react';
import {DashboardApi} from '../../api/dashboard-api/dashboard-api';
import {IDashboardDataDto} from '../../api/dashboard-api/IDashboardDataDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {Preloader} from '../../components/preloader';
import {WidgetFirst} from '../../components/dashboard-widgets/widget-first';
import {WidgetBoolean} from '../../components/dashboard-widgets/widget-boolean';
import {WidgetSecond} from '../../components/dashboard-widgets/widget-second';
import {ApiRequestException} from '../../api/axios-instance';
import {ErrorStub} from '../../components/error-stub';
import {WidgetWithActionButton} from '../../components/dashboard-widgets/widget-with-action-button';
import {Link, useHistory} from 'react-router-dom';
import {Routes} from '../../../configs/routes';
import {CustomFormatter} from '../../../localization/custom-formatter';

const ICONS = {
  VIEWS: require('../../images/svg/Visible.svg'),
  VIDEOS: require('../../images/svg/Video-Lane.svg'),
  YOUTUBE: require('../../images/png/youtube_social_icon_red.png'),
  DOLLAR: require('../../images/svg/Dollar.svg'),
  MONEY: require('../../images/svg/Money.svg'),
  CHECK: require('../../images/svg/Check.svg'),
  CROSS: require('../../images/svg/Close.svg'),
  FORWARD: require('../../images/svg/Forward.svg'),
};

const BACKGROUNDS = {
  HEADER: require('../../images/backgrounds/bg-10.jpg'),
  GENERATE_REPORT: require('../../images/svg/patterns/taieri.svg'),
};

export const DashboardPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();

  const api = new DashboardApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [dashboardData, setDashboardData] = useState<IDashboardDataDto | null>(null);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchDashboardsData();
  }, []);

  const fetchDashboardsData = async () => {
    try {
      setLoading(true);
      const result = await api.getUserDashboard();
      setDashboardData(result.data);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClickOnWidget = (redirectTo: string) => {
    history.push(redirectTo);
  };

  if (loading) {
    return <Preloader />;
  }

  if (dashboardData == null || error != null) {
    return <ErrorStub error={error || intl.formatMessage({id: 'NOT_FOUND'})} />;
  }

  const renderHelloBlock = () => {
    return (
      <div
        className={'card card-custom gutter-b bgi-no-repeat bgi-size-cover'}
        style={{backgroundImage: `url('${BACKGROUNDS.HEADER}')`, height: '200px'}}>
        <div className={'card-body'}>
          <h1 className={'text-uppercase font-weight-boldest text-white'}>
            <FormattedMessage id={'DASHBOARD'} />
          </h1>
        </div>
      </div>
    );
  };

  const renderPaymentInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetFirst
            icon={ICONS.MONEY}
            value={CustomFormatter.formatMoney(dashboardData.balance)}
            subtitle={intl.formatMessage({id: 'BALANCE'})}
            style={'success'}
            onClick={() => handleClickOnWidget(Routes.getRevenuesPageRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetFirst
            icon={ICONS.DOLLAR}
            value={CustomFormatter.formatMoney(dashboardData.currentMonthTransactionSum)}
            subtitle={intl.formatMessage({id: 'TRANSACTION_SUM_CURRENT_MONTH'})}
            style={'success'}
            onClick={() => handleClickOnWidget(Routes.getRevenuesPageRoute())}
          />
        </div>
        <div className={'col-xl-4 col-md-12'}>
          <WidgetFirst
            icon={ICONS.DOLLAR}
            value={CustomFormatter.formatMoney(dashboardData.previousMonthTransactionSum)}
            subtitle={intl.formatMessage({id: 'TRANSACTION_SUM_PREVIOUS_MONTH'})}
            style={'success'}
            onClick={() => handleClickOnWidget(Routes.getRevenuesPageRoute())}
          />
        </div>
      </div>
    );
  };

  const renderChannelsInfo = () => {
    return (
      <>
        <div className={'row'}>
          <div className={'col-xl-6'}>
            <WidgetSecond
              icon={ICONS.VIDEOS}
              title={intl.formatMessage({id: 'DASHBOARD_VIDEOS_COUNT'})}
              value={dashboardData.youtubeVideosCount}
              style={'warning'}
              onClick={() => handleClickOnWidget(Routes.getYoutubeVideosRoute())}
            />
          </div>
          <div className={'col-xl-6'}>
            <WidgetSecond
              icon={() => <img src={ICONS.YOUTUBE} width={52} alt={'YouTube-logo'} />}
              title={intl.formatMessage({id: 'DASHBOARD_CHANNELS_COUNT'})}
              value={dashboardData.youtubeChannelsCount}
              style={'danger'}
              onClick={() => handleClickOnWidget(Routes.getYoutubeChannelsRoute())}
            />
          </div>
        </div>
      </>
    );
  };

  const renderRequestsInfo = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-6'}>
          <WidgetSecond
            icon={ICONS.FORWARD}
            title={intl.formatMessage({id: 'DASHBOARD_CHANNEL_CONNECT_REQUESTS'})}
            value={dashboardData.youtubeChannelsRequestCount}
            style={'info'}
            onClick={() => handleClickOnWidget(Routes.getYoutubeChannelsRoute())}
          />
        </div>
        <div className={'col-xl-6'}>
          <WidgetSecond
            icon={ICONS.FORWARD}
            title={intl.formatMessage({id: 'ADVERTISEMENT_REQUESTS'})}
            value={dashboardData.youtubeChannelsRequestCount}
            style={'info'}
            onClick={() => handleClickOnWidget(Routes.getAdvertisementsRoute())}
          />
        </div>
      </div>
    );
  };

  const renderBooleanValues = () => {
    return (
      <div className={'row'}>
        <div className={'col-xl-6 col-md-12'}>
          <WidgetBoolean
            isSuccess={dashboardData.hasPaymentRequisites}
            title={intl.formatMessage({
              id: dashboardData.hasPaymentRequisites
                ? 'DASHBOARD_HAS_PAYMENT_REQUISITES'
                : 'DASHBOARD_HASNT_PAYMENT_REQUISITES',
            })}
            onClick={() => handleClickOnWidget(Routes.getProfileRoute())}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHelloBlock()}
      <div className={'mt-n35 px-5'}>
        {renderPaymentInfo()}
        {renderChannelsInfo()}
        {renderRequestsInfo()}
        {renderBooleanValues()}
        <div className={'row'}>
          <div className={'col-12'}>
            <WidgetWithActionButton
              title={intl.formatMessage({id: 'FINANCIAL_REPORT'})}
              background={BACKGROUNDS.GENERATE_REPORT}
              actionButton={
                <Link to={Routes.getFinancialReportsRoute()} className={'btn btn-outline-primary'}>
                  <FormattedMessage id={'GENERATE'} />
                </Link>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
