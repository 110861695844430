import React from 'react';
import {useDropzone} from 'react-dropzone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons/faUpload';
import {useIntl} from '../hooks/use-intl';

type Props = {
  title?: string;
  maxFiles?: number;
  accept?: string[];
  disabled?: boolean;
  onFileChange: ((files: File[]) => void) | null;
};
export const FileDropzone: React.FC<Props> = ({title, accept, maxFiles, disabled, onFileChange}) => {
  const intl = useIntl();
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles,
    disabled,
    accept,
    onDrop:
      onFileChange != null
        ? onFileChange
        : () => {
            null;
          },
  });

  return (
    <div
      {...getRootProps()}
      className={'rounded text-center cursor-pointer flex flex-col justify-center align-items-center'}
      style={{height: 150, border: '1px dashed gray'}}>
      <input {...getInputProps()} />
      {title && <div className={'p-0 m-0 text-muted'}>{title}</div>}
      <div className={'p-0 m-0 text-muted'}>{intl.formatMessage({id: 'DROP_FILES'})}</div>
      <div className={'p-0 mt-3 text-muted'}>
        <FontAwesomeIcon icon={faUpload} size={'3x'} />
      </div>
    </div>
  );
};
