import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {SelectApi} from '../../../api/select-api';
import {IPaginationInfo} from '../../../api/Paginator';
import {UsersApi} from '../../../api/user-api/users-api';
import {IUserActionDto} from '../../../api/user-api/IUserActionDto';
import {UserAction} from './user-action';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useLoading} from '../../../hooks/use-loading';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {SelectsValuesDTOs} from '../../../components/Inputs/InputSelect';
import {BaseListPage} from '../../base/base-list-page';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const UserActionsPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = new UsersApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [usersActions, setUsersActions] = useState<Array<IUserActionDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({
    status_code: [200, 204, 400, 401, 500].map(s => ({id: s.toString(), title: s.toString()})),
  });

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'user_actions_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status_code',
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchUsersActions = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getUsersActions(filters);
      setUsersActions(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        user_id: (await selectApi.getUsers()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const renderUsersActions = () => {
    if (usersActions.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return (
      <>
        <div className={'row'}>
          {usersActions.map(action => {
            return (
              <div key={action.id} className={'col-12'}>
                <UserAction action={action} />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchUsersActions}>
      {renderUsersActions()}
    </BaseListPage>
  );
};
