import React from 'react';
import {BaseModal, BaseModalProps, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {Modal} from 'react-bootstrap';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {InputText} from '../../Inputs/InputText';
import {useIntl} from '../../../hooks/use-intl';

type Props = {
  error: string | null;
  validationErrors: ValidationErrorsType;
  title_ru: string;
  title_en: string;
  onChangeTitle: (lang: 'ru' | 'en', updatedTitle: string) => void;
  options?: ModalCreateEditChapterOptions;
} & Omit<BaseModalProps, 'children'>;

export type ModalCreateEditChapterOptions = {
  title?: string;
};

export const ModalCreateEditChapter: React.FC<Props> = ({
  error,
  loading,
  onChangeTitle,
  onHide,
  onSuccess,
  title_ru,
  title_en,
  validationErrors,
  visible,
  options,
}) => {
  const intl = useIntl();

  return (
    <BaseModal
      visible={visible}
      loading={loading}
      successButtonTitle={intl.formatMessage({id: 'SAVE'})}
      disabledButtons={loading}
      onSuccess={onSuccess}
      onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>{options?.title ?? intl.formatMessage({id: 'EDIT_CHAPTER'})}</Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <div className='form-group'>
            <ValidateErrorWrapper message={getValidationErrorMessage('title_ru', validationErrors)}>
              <InputText
                required
                value={title_ru}
                label={intl.formatMessage({id: 'TITLE'}) + ' (RU)'}
                hasError={!!getValidationErrorMessage('title', validationErrors)}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={e => onChangeTitle('ru', e.currentTarget.value)}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('title_en', validationErrors)}>
              <InputText
                required
                value={title_en}
                label={intl.formatMessage({id: 'TITLE'}) + ' (EN)'}
                hasError={!!getValidationErrorMessage('title', validationErrors)}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={e => onChangeTitle('en', e.currentTarget.value)}
              />
            </ValidateErrorWrapper>
          </div>
        </form>
      </ModalBodySlot>
    </BaseModal>
  );
};
