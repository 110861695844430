import {BaseEndpoint} from './base-endpoint';
import config from '../../configs/config';
import {
  IResponseBalance,
  IResponseFinancialAccounts,
  IResponseMcPayBalance,
  IResponseTransactionsOperations,
} from './response-contracts/accounts-response-contracts';
import {IQueryParams} from './DTOs/IFilterDtos';
import {IFinancialAccountDto} from './DTOs/IFinancialAccountDto';
import {IResponseBase} from './response-contracts/base-response';
import {IContractorDto} from './contractor-api/IContractorDto';

export class AccountApi extends BaseEndpoint {
  public async getBalance(): Promise<IResponseBalance> {
    const response = await this.get<IResponseBalance>(`${config.BASE_API_URL}/account/balance`);
    return response.data;
  }

  public async getMyMCPayBalance(): Promise<IResponseMcPayBalance> {
    const response = await this.get<IResponseMcPayBalance>(`${config.BASE_API_URL}/account/mc-pay-balance`);
    return response.data;
  }

  public async getTransactionOperations(filters?: IQueryParams): Promise<IResponseTransactionsOperations> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IResponseTransactionsOperations>(
      `${config.BASE_API_URL}/transactions/operations?${query}`,
    );
    return response.data;
  }

  public async getFinancialAccounts(params?: IQueryParams): Promise<IResponseFinancialAccounts> {
    const query = this.applyQueryParams(params);
    const response = await this.get<IResponseFinancialAccounts>(`${config.BASE_API_URL}/admin/accounts?${query}`);
    return response.data;
  }

  public async getFinancialAccount(
    accountId: number,
  ): Promise<IResponseBase<{item: IFinancialAccountDto; relations: {contractor: IContractorDto}}>> {
    const response = await this.get<IResponseBase>(`${config.BASE_API_URL}/admin/accounts/${accountId}`);
    return response.data;
  }

  public async getSpecifyAccountTransactionOperations(
    accountId: number,
    params?: IQueryParams,
  ): Promise<IResponseTransactionsOperations> {
    const query = this.applyQueryParams(params);
    const response = await this.get<IResponseBase>(
      `${config.BASE_API_URL}/admin/accounts/${accountId}/operations?${query}`,
    );
    return response.data;
  }

  public async downloadReport(params?: IQueryParams) {
    const query = this.applyQueryParams(params);
    const response = await this.getFile(`${config.BASE_API_URL}/admin/accounts/excel-report?${query}`);
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }
}
