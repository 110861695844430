import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {useCallback} from 'react';
import {BaseItemResponse, BaseItemsResponse} from '../../api/base/base-crud-api-response';
import {DateAsString, EntityId} from '../../api/base/BaseEntity';
import {IUserDto} from '../../api/DTOs/IUserDto';
import {IContractorDto} from '../../api/contractor-api/IContractorDto';
import {Transaction} from '../../api/transaction-api/Transaction';
import {ITransactionOperationDto} from '../../api/DTOs/ITransactionOperationDto';

export enum MediaCubeAuthStateStatus {
  INIT = 'INIT',
  PARSE_URL_STATE = 'PARSE_URL_STATE',
  FETCH_USER_FROM_DB = 'FETCH_USER_FROM_DB',
  VALIDATE_PASSPHRASE_STATE = 'VALIDATE_PASSPHRASE_STATE',
  INIT_MC_PAY_USER_CREDENTIALS = 'INIT_MC_PAY_USER_CREDENTIALS',
  AUTHORIZE_USER_ON_MC_PAY = 'AUTHORIZE_USER_ON_MC_PAY',
  FETCH_MC_PAY_USER_INFORMATION = 'FETCH_MC_PAY_USER_INFORMATION',
  CREATE_INIT_BALANCE_TRANSACTION = 'CREATE_INIT_BALANCE_TRANSACTION',
  SUCCESS = 'SUCCESS',
}

export enum MediaCubeTransactionStateType {
  INIT = 'INIT',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  CALCULATE_INITIAL_BALANCE_TRANSACTION_SUM = 'CALCULATE_INITIAL_BALANCE_TRANSACTION_SUM',
  CHECK_TRANSACTION_SUM_FAILED = 'CHECK_TRANSACTION_SUM_FAILED',
  CREATE_MC_PAY_TRANSACTION_REQUEST = 'CREATE_MC_PAY_TRANSACTION_REQUEST',
  CREATE_INTERNAL_WITHDRAWAL_TO_MC_PAY_TRANSACTION = 'CREATE_INTERNAL_WITHDRAWAL_TO_MC_PAY_TRANSACTION',
  SUCCESS = 'SUCCESS',
}

export enum MediaCubeCreateTransactionStatus {
  WAITING_SEND_TO_MC_PAY = 0,
  PROCESS = 1,
  SUCCESS = 2,
  ERROR = 3,
}

export type MediaCubeAuthState = {
  id: EntityId;
  state: MediaCubeAuthStateStatus;
  query_code: string | null;
  query_state: string | null;
  request_url: string | null;
  mc_pay_access_token: string | null;
  mc_pay_id: string | null;
  mc_pay_email: string | null;
  mc_pay_wallet_id: string | null;
  mc_pay_request_create_transaction_id: string | null;
  error_message: string | null;
  user: IUserDto | null;
  created_at: DateAsString;
  updated_at: DateAsString;
};

export type MediaCubeTransactionState = {
  id: EntityId;
  state: MediaCubeTransactionStateType;
  mc_pay_user_id: string | null;
  mc_pay_request_id: string | null;
  mc_pay_request_status: MediaCubeCreateTransactionStatus | null;
  mc_pay_error_message: string | null;
  error_message: string | null;
  amount: number | null;
  confirmed_balance_before_withdrawal: number | null;
  description: string | null;
  contractor: IContractorDto | null;
  user: IUserDto | null;
  transaction: Transaction | null;
  operation: ITransactionOperationDto | null;
  is_balance_init_transaction: boolean | null;
};

export type MediaCubeGroupedConfirmationOperation = {
  contractor_id: EntityId;
  contractor_calculated_name: string;
  calculated_sum: number;
  operations_count: number;
};

export type MediaCubeConfirmationOperation = {
  calculated_sum: number;
  operations_count: number;
  contractor: IContractorDto;
  operations: Transaction[];
};

export function useMediaCubeStatesApi() {
  const api = useBaseEndpointApi();

  const getUnconfirmedOperations = useCallback(async () => {
    return await api.get<BaseItemsResponse<MediaCubeGroupedConfirmationOperation>>(`admin/mc-pay-confirmation`);
  }, []);

  const getUnconfirmedOperationsByContractor = useCallback(async (contractorId: EntityId) => {
    return await api.get<BaseItemResponse<MediaCubeConfirmationOperation>>(`admin/mc-pay-confirmation/${contractorId}`);
  }, []);

  const postConfirmOperations = useCallback(async (contractorId: EntityId) => {
    return await api.post(`admin/mc-pay-confirmation/confirm`, {
      fields: {
        contractor_id: contractorId,
      },
    });
  }, []);

  const postConfirmAllOperations = useCallback(async () => {
    return await api.post(`admin/mc-pay-confirmation/confirm/all`);
  }, []);

  return {
    getUnconfirmedOperations,
    getUnconfirmedOperationsByContractor,
    postConfirmOperations,
    postConfirmAllOperations,
  };
}
