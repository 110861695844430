import React, {useEffect, useState} from 'react';
import {YoutubeIntegrationWidget} from './youtube-integration-widget';
import {AdministrateApi} from '../../../api/administrate-api';
import {IYoutubeTokenInfoDto} from '../../../api/DTOs/IYoutubeTokenInfoDto';
import SVG from 'react-inlinesvg';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {Preloader} from '../../../components/preloader';

const ICONS = {
  TIMER: require('../../../images/svg/Timer.svg'),
  PLUS: require('../../../images/svg/Plus.svg'),
  THUNDER: require('../../../images/svg/Thunder.svg'),
  CHECK: require('../../../images/svg/Check.svg'),
  WARNING: require('../../../images/svg/Warning.svg'),
};

export const YoutubeIntegrationPage: React.FC<any> = () => {
  const api = new AdministrateApi();
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenInfo, setTokenInfo] = useState<IYoutubeTokenInfoDto | null>(null);
  const [alertVisible, setAlert] = useState(false);

  useEffect(() => {
    fetchTokenInfo();
  }, []);

  const fetchTokenInfo = async () => {
    try {
      setLoading(true);
      const response = await api.getYoutubeAuthTokenInfo();
      setTokenInfo(response.data.item);
    } catch (e) {
      setTokenInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = async () => {
    await fetchTokenInfo();
    setAlert(true);
  };

  if (loading) {
    return <Preloader />;
  }

  const renderTokenInfo = (title: string, value: string, icon: string, type: string) => {
    return <div className={'col-lg-6 col-xl-4 mb-5'}>
      <div className={`card card-custom wave wave-animate wave-${type} mb-8 mb-lg-0`}>
        <div className='card-body'>
          <div className='d-flex align-items-center p-5'>
            <div className='mr-6'>
              <span className={`svg-icon svg-icon-${type} svg-icon-4x`}>
               <SVG src={icon} />
              </span>
            </div>
            <div className='d-flex flex-column'>
              <div className='text-dark text-hover-primary font-weight-bold font-size-h4 mb-3'>
                <FormattedMessage id={title} />
              </div>
              <div className='text-dark-75'>
                {value}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  };

  // FIXME: Сейчас считает неправильно, нужно будет разобраться в чём проблема.
  const countExpiredTime = (created: string, expired: string) => {
    const ms = new Date(created).getTime() + Number(expired);
    return `${intl.formatTime(ms)}  ${intl.formatDate(ms)}`;
  };

  return <>
    <AlertCustom
      dismissible
      visible={alertVisible}
      type={tokenInfo != null ? 'light-success' : 'danger'}
      iconClassName={tokenInfo != null ? 'svg-icon-success' : 'svg-icon-danger'}
      icon={tokenInfo != null ? ICONS.CHECK : ICONS.WARNING}
      onClose={() => setAlert(false)}
      text={intl.formatMessage({
        id: tokenInfo != null
          ? 'SUCCESS_COMPLETE_YOUTUBE_INTEGRATION'
          : 'ERROR_COMPLETE_YOUTUBE_INTEGRATION',
      })}
    />

    <YoutubeIntegrationWidget
      onConnect={handleConnect}
      integrated={tokenInfo !== null && Number(tokenInfo.expires_in) + new Date(tokenInfo.created_at).getTime() < Date.now()}
    />

    {tokenInfo !== null
    && <div className={'row'}>
      {renderTokenInfo('CONNECTED', `${intl.formatTime(tokenInfo.created_at)}  ${intl.formatDate(tokenInfo.created_at)}`, ICONS.PLUS, 'success')}
      {renderTokenInfo('EXPIRED', countExpiredTime(tokenInfo.created_at, tokenInfo.expires_in), ICONS.TIMER, 'danger')}
      {renderTokenInfo('TOKEN_TYPE', tokenInfo.token_type, ICONS.THUNDER, 'primary')}
    </div>}
  </>;
};
