import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputTextArea} from '../../Inputs/InputTextArea';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';

const DEFAULT_FIELD_KEY = 'reject_reason';
const DEFAULT_MODAL_TITLE = 'DESCRIBE_REJECT_REASON';
const DEFAULT_LABEL = 'REJECT_REASON';

export type ModalRejectReasonConfig = {
  title?: string | typeof DEFAULT_MODAL_TITLE;
  reasonInputKey?: string | typeof DEFAULT_FIELD_KEY;
  reasonInputLabel?: string | typeof DEFAULT_LABEL;
};

interface IModalRejectReasonProps {
  error?: string | null;
  reason: string;
  visible: boolean;
  validationErrors?: {[key: string]: Array<string>} | null;
  modalConfiguration?: ModalRejectReasonConfig | null;

  onReasonChange(reason: string): void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalRejectReason: React.FC<IModalRejectReasonProps> = ({
  error,
  reason,
  visible,
  validationErrors,
  modalConfiguration,
  onHide,
  onReasonChange,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={modalConfiguration?.title ?? DEFAULT_MODAL_TITLE} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className='form-group'>
              <ValidateErrorWrapper
                message={getValidationErrorMessage(
                  modalConfiguration?.reasonInputKey ?? DEFAULT_FIELD_KEY,
                  validationErrors,
                )}>
                <InputTextArea
                  required
                  hasError={
                    !!getValidationErrorMessage(
                      modalConfiguration?.reasonInputKey ?? DEFAULT_FIELD_KEY,
                      validationErrors,
                    )
                  }
                  value={reason}
                  label={intl.formatMessage({id: modalConfiguration?.reasonInputLabel ?? DEFAULT_LABEL})}
                  className={'form-control py-3 px-6 mr-2'}
                  onChange={e => onReasonChange(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
