import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {IFilterDto} from '../../api/DTOs/IFilterDtos';
import {IContractorDto} from '../../api/contractor-api/IContractorDto';
import {Toolbar, ToolBarItem} from './Toolbar';
import {
  HandleTransactionCreatePayload,
  useTransactionActionHandler,
} from '../../pages/admin/transactions/useTransactionActionHandler';
import {TransactionAction} from '../../pages/admin/transactions/transactions-page';

interface IProps {
  filters: IFilterDto;
  partOfContractor: Pick<IContractorDto, 'id' | 'name'>;
  advancedToolBarItems?: Array<ToolBarItem>;
  showManualCreateTransactionButtons: boolean;
}

const ICONS = {
  DOWNLOAD: require('../../images/svg/Download.svg'),
  DOLLAR: require('../../images/svg/Dollar.svg'),
  MONEY: require('../../images/svg/Money.svg'),
};

export const TransactionsActionsToolbar: React.FC<IProps> = ({
  filters,
  showManualCreateTransactionButtons,
  partOfContractor,
  advancedToolBarItems,
}) => {
  const intl = useIntl();
  const {handleTransactionAction} = useTransactionActionHandler();
  const handleDownloadClick = async () => {
    await handleTransactionAction({type: TransactionAction.REPORT, data: {filters}});
  };

  const handleCreateTransaction = async (transactionType: TransactionAction) => {
    await handleTransactionAction({
      type: transactionType,
      data: {contractorId: partOfContractor.id, contractorName: partOfContractor.name},
    } as HandleTransactionCreatePayload);
  };

  const toolBarItems = useMemo<ToolBarItem[]>(() => {
    const items = [
      {
        title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT_ON_TRANSACTIONS'}),
        type: 'BUTTON',
        icon: ICONS.DOWNLOAD,
        className: 'btn btn-light-success font-weight-bolder',
        onClick: handleDownloadClick,
      },
    ];

    if (showManualCreateTransactionButtons) {
      items.push(
        {
          title: intl.formatMessage({id: 'CREATE_ACTION_WITHDRAWING_TRANSACTION'}),
          type: 'BUTTON',
          icon: ICONS.DOLLAR,
          className: 'btn btn-light-primary font-weight-bolder',
          onClick: () => handleCreateTransaction(TransactionAction.WITHDRAWING),
        },
        {
          title: intl.formatMessage({id: 'CREATE_ACTION_DEBIT_TRANSACTION'}),
          type: 'BUTTON',
          icon: ICONS.DOLLAR,
          className: 'btn btn-light-primary font-weight-bolder',
          onClick: () => handleCreateTransaction(TransactionAction.DEBIT),
        },
        {
          title: intl.formatMessage({id: 'CREATE_ACTION_TAX_TRANSACTION'}),
          type: 'BUTTON',
          icon: ICONS.MONEY,
          className: 'btn btn-light-primary font-weight-bolder',
          onClick: () => handleCreateTransaction(TransactionAction.TAX),
        },
        {
          title: intl.formatMessage({id: 'CREATE_ACTION_INCOME_TRANSACTION'}),
          type: 'BUTTON',
          icon: ICONS.MONEY,
          className: 'btn btn-light-primary font-weight-bolder',
          onClick: () => handleCreateTransaction(TransactionAction.INCOME),
        },
      );
    }
    return items as ToolBarItem[];
  }, [showManualCreateTransactionButtons, handleCreateTransaction]);

  return (
    <>
      <Toolbar items={[...toolBarItems, ...(advancedToolBarItems || [])]} />
    </>
  );
};
