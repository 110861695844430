import {toast} from 'react-toastify';
import {AccountApi} from '../../../../api/account-api';
import {saveAs} from 'file-saver';
import {useIntl} from '../../../../hooks/use-intl';
import {ApiRequestException} from '../../../../api/axios-instance';
import {IQueryParams} from '../../../../api/DTOs/IFilterDtos';

export enum FinancialAccountAction {
  DOWNLOAD = 'DOWNLOAD',
}

export type HandleFinancialAccountActionPayload = | HandleFinancialAccountReportsDownloadPayload

export type HandleFinancialAccountReportsDownloadPayload = {
  type: FinancialAccountAction.DOWNLOAD;
  data: {
    queryParams?: IQueryParams
  };
};

export function useFinancialAccountActionHandler() {
  const intl = useIntl();
  const api = new AccountApi();
  const handle = async (payload: HandleFinancialAccountActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case FinancialAccountAction.DOWNLOAD: {
          const result = await api.downloadReport(payload.data?.queryParams);
          saveAs(result.content, result.fileName);
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {
    handleFinancialAccountActions: handle,
  };
}
