import React, {createContext, PropsWithChildren, useCallback, useContext, useMemo, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {PaymentsApi} from '../../../api/payment-api/payments-api';
import {ModalPaymentRequest} from './payment-request-modal';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {ValidationErrorsType} from '../../../utils/utils';
import {PaymentRequestDestination} from '../../../api/payment-api/IPaymentRequestDto';
import {useLoading} from '../../../hooks/use-loading';
import {toast} from 'react-toastify';
import {UsersApi} from '../../../api/user-api/users-api';
import {MediaCubePayOAuthData} from '../../../api/user-api/users-response-contracts';
import {PartialNullable} from '../../../../types';
import {useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';

interface IModalPaymentRequestProps {
  modalPaymentRequestVisible: boolean;

  showPaymentRequestModal(props: {
    mcPayWithdrawalAmount?: number | string | null;
    totalAvailableWithdrawalAmount: number | string | null;
  }): Promise<CloseModalEvent<null>>;
}

export type ModalPaymentRequestField = {
  visible: boolean;
  error: string;
  validationErrors: ValidationErrorsType;
  value: string | number;
  destination: PaymentRequestDestination;
};

// @ts-ignore
const ModalPaymentRequestContext = createContext<IModalPaymentRequestProps>();

let closeResolver: ((data: CloseModalEvent<null>) => unknown) | null = null;
export const ModalPaymentRequestProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const userApi = new UsersApi();
  const api = useMemo(() => new PaymentsApi(), []);
  const [loadings, startLoading, stopLoading] = useLoading({mcPayInformation: false, createRequest: false});
  const [oauthInfo, setOauthInfo] = useState<MediaCubePayOAuthData | null>(null);
  const [state, , updateState, resetState] = useAdvancedState<ModalPaymentRequestField>({
    visible: false,
  });

  const location = useLocation();
  const history = useHistory();

  const showModal = async ({
    mcPayWithdrawalAmount,
    totalAvailableWithdrawalAmount,
  }: {
    mcPayWithdrawalAmount?: number | null;
    totalAvailableWithdrawalAmount: number;
  }) => {
    const updatedState: PartialNullable<ModalPaymentRequestField> = {
      visible: true,
    };

    if (mcPayWithdrawalAmount != null) {
      updatedState.value = Number(mcPayWithdrawalAmount);
      updatedState.destination = PaymentRequestDestination.TO_MCPAY;
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('mc_pay_withdrawal_amount');
      history.replace({
        search: queryParams.toString(),
      });
    } else if (totalAvailableWithdrawalAmount != null) {
      updatedState.value = Number(totalAvailableWithdrawalAmount);
    }

    updateState(updatedState);
    fetchMcPayOauthInfo();
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const fetchMcPayOauthInfo = useCallback(() => {
    startLoading('mcPayInformation');
    userApi
      .getMediaCubePayOAuthData()
      .then(res => {
        setOauthInfo(res.data.item);
      })
      .catch(() => {
        toast.error(intl.formatMessage({id: 'MC_PAY_ERROR_CANT_GET_OAUTH_USER_INFORMATION'}));
      })
      .finally(() => stopLoading('mcPayInformation'));
  }, [state]);

  const handleAuthToMcPay = useCallback(async () => {
    if (!oauthInfo) {
      return;
    }

    const oAuthUrl = new URL(oauthInfo.authorize_url);
    oAuthUrl.searchParams.set('response_type', 'code');
    oAuthUrl.searchParams.set('client_id', oauthInfo.client_id);
    oAuthUrl.searchParams.set('scope', oauthInfo.scope);
    oAuthUrl.searchParams.set('redirect_uri', oauthInfo.redirect_uri);
    const urlState =
      state.value != null ? oauthInfo.state + `|mc_pay_withdrawal_amount=${state.value}` : oauthInfo.state;

    oAuthUrl.searchParams.set('state', urlState);

    //Хак, для того, чтобы сразу перейти на страницу
    window.open(oAuthUrl.toString(), '_blank');
    window.opener = null;
    window.open('about:blank', '_self')?.close();
  }, [state?.value, oauthInfo]);

  const handleHideModal = () => {
    resetState();
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      updateState({error: null, validationErrors: null});
      await api.createPaymentRequest(state);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        updateState({
          validationErrors: (err.innerException as IValidationException).error_data.messages,
        });
      } else {
        updateState({error: err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'})});
      }
    }
  };

  const value: IModalPaymentRequestProps = {
    modalPaymentRequestVisible: state.visible ?? false,
    showPaymentRequestModal: showModal,
  };

  return (
    <ModalPaymentRequestContext.Provider value={value}>
      {children}
      <ModalPaymentRequest
        loadings={loadings}
        state={state}
        updateState={updateState}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
        onAuthMcPayClick={handleAuthToMcPay}
        mcPayOauthInfo={oauthInfo}
      />
    </ModalPaymentRequestContext.Provider>
  );
};

export const useModalPaymentRequest = () => {
  return useContext(ModalPaymentRequestContext);
};
