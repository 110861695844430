import React from 'react';
import cn from 'classnames';

type Size = 'lg' | 'sm';

interface InputSwitchProps {
  id?: string;
  checked?: boolean;
  size: Size;
  type: string;
  classNames?: string;
  titles?: [string, string];

  handleChange(e?: any): void;
}

export const InputSwitch = ({id, checked, size, type, handleChange, classNames, titles}: InputSwitchProps) => {
  return (
    <div className={cn('d-flex align-items-center', classNames)}>
      {titles && titles.length > 0 && titles[0].length > 0 && (
        <span className={'mr-2 font-weight-bolder'}>{titles[0]}</span>
      )}
      <span className={`switch switch-${type} switch-${size}`}>
        <label>
          <input id={id} type='checkbox' checked={checked} onChange={handleChange} />
          <span />
        </label>
      </span>
      {titles && titles.length > 1 && <span className={'ml-2 font-weight-bolder'}>{titles[1]}</span>}
    </div>
  );
};
