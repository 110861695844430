import React from 'react';
import cn from 'classnames';

export const TableUtils = {
  renderHeaderForNumericContent: (content: string | JSX.Element, conf?: {className?: string}) => {
    return <div className={cn('text-right', conf?.className)}>{content}</div>;
  },

  renderNumericContent: (content: string | number | JSX.Element, conf?: {className?: string}) => {
    return <div className={cn('font-weight-bolder text-right', conf?.className)}>{content}</div>;
  },

  renderBaseContent: (content: string | number | JSX.Element, conf?: {className?: string}) => {
    return <div className={cn('font-weight-bolder', conf?.className)}>{content}</div>;
  },

  renderActionButton: (content: JSX.Element, conf?: {className: string}) => {
    return <div className={cn('text-right', conf?.className)}>{content}</div>;
  },
};
