import React, {useMemo} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {ITransactionOperationDto} from '../../api/DTOs/ITransactionOperationDto';
import {DefaultTable} from '../../components/default-table';
import {Row} from 'react-table';
import {TableUtils} from '../../components/table-utils';
import {Transaction} from '../../api/transaction-api/Transaction';

interface ITransactionOperationsTable {
  operations: Array<ITransactionOperationDto>;
}

export const TransactionOperationsTable: React.FC<ITransactionOperationsTable> = ({operations}) => {
  const intl = useIntl();
  const data = operations;

  const columns = useMemo(() => {
    return [
      {
        id: 'DESCRIPTION',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        minWidth: 350,
        accessor: (br: ITransactionOperationDto) => br,
        Cell: ({value}: {value: ITransactionOperationDto}) => {
          return TableUtils.renderBaseContent(value.description);
        },
      },
      {
        id: 'SUM',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUM'})),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: ITransactionOperationDto) => br,
        Cell: ({value}: {value: ITransactionOperationDto}) => {
          const balanceColor = value.value < 0 ? 'text-danger' : value.value > 0 ? 'text-success' : '';

          return TableUtils.renderNumericContent(
            <p className={'m-0 p-0'}>
              <span className={balanceColor}>
                <FormattedNumber
                  signDisplay={'always'}
                  value={value.value}
                  style={'currency'}
                  currency={value.currency}
                />
              </span>
            </p>,
          );
        },
      },
      {
        id: 'Created_At',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        minWidth: 100,
        accessor: (br: ITransactionOperationDto) => br,
        Cell: ({value}: {value: ITransactionOperationDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.date)} {intl.formatTime(value.date)}
            </>,
          );
        },
      },
    ];
  }, []);

  const renderCancelReason = ({original: transaction}: Row<Transaction>) => {
    if (transaction.cancelReason === null || !transaction.isCanceled) {
      return <></>;
    }

    return TableUtils.renderBaseContent(
      <>
        <p className={'text-warning mb-1'}>
          <FormattedMessage id={'OPERATION_WAS_CANCELED'} />
        </p>
        <span className={'text-muted'}>
          <FormattedMessage id={'CANCEL_REASON'} />: {transaction.cancelReason}
        </span>
      </>,
      {className: 'p-3 font-weight-bolder'},
    );
  };

  return <DefaultTable data={data} columns={columns} renderRowSubComponent={renderCancelReason} />;
};
