import {Ref, useRef, useState} from 'react';

export const useFitIframe = (): [Ref<any>, () => void, number] => {
  const ref = useRef<any>({});
  const [height, setHeight] = useState<number>(0);
  const handleFrameLoaded = () => {
    if (ref.current) {
      const documentBody = ref.current.contentWindow.document.body;
      const currentIframeContentHeight = documentBody.offsetHeight;
      setHeight(currentIframeContentHeight);
    }
  };
  return [ref, handleFrameLoaded, height];
};
