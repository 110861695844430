import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {
  CreateTransactionRequest,
  TRANSACTION_TYPE_ROUTE_MAP,
  TransactionsApi,
} from '../../../api/transaction-api/transactions-api';
import {SelectApi} from '../../../api/select-api';
import {ModalCreateTransaction} from './modal-create-transaction';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {AccountApi} from '../../../api/account-api';
import {FinancialAccountType} from '../../../api/DTOs/IFinancialAccountDto';
import {IContractorDto} from '../../../api/contractor-api/IContractorDto';
import {IResponseBase} from '../../../api/response-contracts/base-response';
import {useLoading} from '../../../hooks/use-loading';
import {TransactionAction} from '../../../pages/admin/transactions/transactions-page';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {PartialNullable} from '../../../../types';
import {useAdvancedState} from '../../../hooks/use-advanced-state';

type TransactionType = TransactionAction;

type PartOfContractor = Pick<IContractorDto, 'id' | 'name'>;

interface IModalCreateTransactionContext {
  showCreateTransactionModal(
    type: TransactionType,
    partOfContractor?: PartOfContractor,
  ): Promise<CloseModalEvent<number>>;
}

// @ts-ignore
const ModalCreateTransactionContext = createContext<IModalCreateTransactionContext>();

let closeResolver: ((data: CloseModalEvent<number>) => unknown) | null = null;
export const ModalCreateTransactionProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const [type, setType] = useState<TransactionType | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    contractors: true,
    addendums: true,
    balance: false,
  });

  const api = new TransactionsApi();
  const accountsApi = new AccountApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [entity, , updateEntityFields] = useAdvancedState<PartialNullable<CreateTransactionRequest>>({});
  const [addendums, setAddendums] = useState<Array<ISelectValueDto>>([]);
  const [disabledContractorSelect, setDisableContractorSelect] = useState<boolean>(false);
  const [contractors, setContractors] = useState<Array<ISelectValueDto>>([]);
  const [balance, setBalance] = useState<{balance: number; unconfirmedBalance: number} | null>(null);

  useEffect(() => {
    if (entity?.contractor_id) {
      Promise.all([fetchBalance(), fetchAddendums()]).then();
    }
  }, [entity?.contractor_id]);

  const fetchAddendums = async () => {
    try {
      startLoading('addendums');
      const result = await selectApi.getAddendums({filters: {contractor_id: entity?.contractor_id}});
      setAddendums(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('addendums');
    }
  };

  const fetchBalance = async () => {
    try {
      startLoading('balance');
      const {
        data: {items},
      } = await accountsApi.getFinancialAccounts({
        filters: {
          contractor_id: entity?.contractor_id,
          type: FinancialAccountType.INNER,
        },
      });

      if (items.length > 0) {
        setBalance({
          balance: items[0].balance,
          unconfirmedBalance: items[0].waitingConfirmBalance,
        });
      }
    } catch (e) {
      setBalance(null);
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('balance');
    }
  };

  const fetchContractors = async () => {
    try {
      startLoading('contractors');
      const result = await selectApi.getContractors();
      setContractors(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('contractors');
    }
  };

  const showModal = async (type: TransactionType, partOfContractor: PartOfContractor) => {
    setType(type);
    setBalance(null);
    setVisible(true);
    if (partOfContractor) {
      setDisableContractorSelect(true);
      updateEntityFields({contractor_id: partOfContractor?.id});
      setContractors([{id: partOfContractor.id.toString(), title: partOfContractor.name}]);
    } else {
      await fetchContractors();
    }

    return new Promise<CloseModalEvent<number>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    setValidationError(null);
    setDisableContractorSelect(false);
    updateEntityFields({
      description: null,
      value: 0,
      period_month: null,
      period_year: null,
      contractor_id: null,
      addendum_id: null,
    });
    setContractors([]);
    setAddendums([]);
    setBalance(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      setValidationError(null);
      let result: IResponseBase;
      switch (type) {
        case TransactionAction.DEBIT:
        case TransactionAction.TAX:
        case TransactionAction.INCOME: {
          result = await api.createTransaction(TRANSACTION_TYPE_ROUTE_MAP[type], {
            ...entity,
            period_month:
              entity.period_month != null ? entity.period_month + 1 : null,
          });
          break;
        }
        case TransactionAction.WITHDRAWING:
          result = await api.createTransaction(TRANSACTION_TYPE_ROUTE_MAP[type], entity);
          break;
        default:
          // noinspection ExceptionCaughtLocallyJS
          throw new Error(`Unknown type transaction: ${type}`);
      }

      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: result.data.item.id});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const contextValue: IModalCreateTransactionContext = {
    showCreateTransactionModal: showModal,
  };

  return (
    <ModalCreateTransactionContext.Provider value={contextValue}>
      {children}
      <ModalCreateTransaction
        hideDatepicker={type === TransactionAction.WITHDRAWING}
        hideAddendumSelect={entity?.contractor_id === null || type === TransactionAction.WITHDRAWING}
        addendums={addendums}
        loadingAddendums={loadings.addendums}
        disabledContractorSelect={disabledContractorSelect}
        balance={balance?.balance ?? null}
        unconfirmedBalance={balance?.unconfirmedBalance ?? null}
        loadingBalance={loadings.balance}
        title={intl.formatMessage({id: `CREATE_ACTION_${type ?? TransactionAction.DEBIT}_TRANSACTION`})}
        visible={visible}
        contractors={contractors}
        error={error}
        validationErrors={validationErrors}
        onChange={(key, value) => updateEntityFields({[key]: value})}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
        entity={entity}
      />
    </ModalCreateTransactionContext.Provider>
  );
};

export const useModalCreateTransaction = (): IModalCreateTransactionContext => {
  return useContext(ModalCreateTransactionContext);
};
