import React, {useMemo} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, FormattedTime, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {IConnectChannelRequestDto} from '../../../api/connect-channel-api/IConnectChannelRequestDto';
import SVG from 'react-inlinesvg';
import {FilterRequestStatus} from '../../../api/connect-channel-api/FilterRequestStatus';
import {DefaultTable} from '../../../components/default-table';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {BadgeCustom} from '../../../components/badge-custom';
import {TableUtils} from '../../../components/table-utils';

interface ConnectChannelRequestsProps {
  connectRequests: Array<IConnectChannelRequestDto>;
}

const ICONS = {
  VIEWS: require('../../../images/svg/Visible.svg'),
  SUBSCRIBERS: require('../../../images/svg/Group.svg'),
  VIDEO_LANE: require('../../../images/svg/Video-Lane.svg'),
};

export const ConnectChannelRequestsTable = ({connectRequests}: ConnectChannelRequestsProps) => {
  const intl = useIntl();
  const data = connectRequests;
  const columns = useMemo(() => {
    return [
      {
        id: 'Preview',
        Header: intl.formatMessage({id: 'TITLE'}),
        maxWidth: 50,
        minWidth: 100,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyConnectChannelRequestRoute(value.id)}>
              <img src={tryGetPreviewOrStub(value.thumbnailsDTO).url} alt='image' className={'w-100 rounded'} />
            </Link>,
          );
        },
      },
      {
        id: 'Title',
        maxWidth: 300,
        minWidth: 300,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            <a href={Routes.getExternalYoutubeChannelRoute(value.channelId)} target={'_blank'} rel='noreferrer'>
              {value.title}
            </a>,
          );
        },
      },
      {
        id: 'User',
        Header: intl.formatMessage({id: 'USER'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyUserManagementRoute(value.user_id)}>{value.user_name}</Link>,
          );
        },
      },
      {
        id: 'Contractor',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderBaseContent(
            value.contractor_id != null ? (
              <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>{value.contractor_name}</Link>
            ) : (
              '--'
            ),
          );
        },
      },
      {
        id: 'SubscriberCount',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'SUBSCRIBERS'})),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderNumericContent(
            <>
              <span className='svg-icon svg-icon-lg svg-icon-success mr-1'>
                <SVG src={ICONS.SUBSCRIBERS} />
              </span>
              <FormattedNumber value={value.subscriberCount} />
            </>,
          );
        },
      },
      {
        id: 'ViewCount',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'VIEWS'})),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderNumericContent(
            <>
              <span className='svg-icon svg-icon-lg svg-icon-primary mr-1'>
                <SVG src={ICONS.VIEWS} />
              </span>
              <FormattedNumber value={value.viewCount} />
            </>,
          );
        },
      },
      {
        id: 'VideoCount',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'VIDEOS'})),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IConnectChannelRequestDto) => br,
        Cell: ({value}: {value: IConnectChannelRequestDto}) => {
          return TableUtils.renderNumericContent(
            <>
              <span className='svg-icon svg-icon-lg svg-icon-warning mr-1'>
                <SVG src={ICONS.VIDEO_LANE} />
              </span>
              <FormattedNumber value={value.videoCount} />
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'PUBLISHED_AT'}),
        maxWidth: 150,
        accessor: (br: IConnectChannelRequestDto) => br.publishedAt,
        Cell: ({value}: {value: string}) => {
          return TableUtils.renderBaseContent(
            <>
              <FormattedDate value={value} /> <FormattedTime value={value} />
            </>,
          );
        },
      },
      {
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 100,
        accessor: (br: IConnectChannelRequestDto) => br.requestStatus,
        Cell: ({value}: {value: FilterRequestStatus}) => {
          const colorVariant =
            value === FilterRequestStatus.REJECTED
              ? 'danger'
              : value === FilterRequestStatus.APPROVED
              ? 'success'
              : 'primary';
          return TableUtils.renderBaseContent(
            <BadgeCustom variant={colorVariant}>
              <FormattedMessage id={'FILTER_REQUEST_STATUS_' + value} />
            </BadgeCustom>,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
