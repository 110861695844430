import {useEffect} from 'react';

export const useTitle = (windowTitle: string | null | undefined) => {
  useEffect(() => {
    if (!windowTitle) {
      return;
    }

    document.title = windowTitle;
  }, [windowTitle]);

  return document.title;
};
