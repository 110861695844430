import {BaseEndpoint} from './base-endpoint';
import config from '../../configs/config';
import {
  ISelectResponse,
  IYoutubeChannelResponse,
  IYoutubeChannelsResponse,
  IYoutubeChannelStatisticsResponse,
  IYoutubeVideosResponse,
} from './response-contracts/yotutube-channel-contracts';
import {IQueryParams} from './DTOs/IFilterDtos';
import {IResponseBase} from './response-contracts/base-response';
import {YoutubeFinancialRevenueByMonthsReport} from './DTOs/RevenueByMonths';

export class YoutubeChannelsApi extends BaseEndpoint {
  public async getChannelList(filters?: IQueryParams): Promise<IYoutubeChannelsResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IYoutubeChannelsResponse>(`${config.BASE_API_URL}/youtube-channels?${query}`);
    return response.data;
  }

  public async getChannelListForSelect(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/youtube-channels/select`);
    return response.data;
  }

  public async getChannelInfo(channelId: string): Promise<IYoutubeChannelResponse> {
    const response = await this.get<IYoutubeChannelResponse>(`${config.BASE_API_URL}/youtube-channels/${channelId}`);
    return response.data;
  }

  public async getChannelVideos(channelId: string, pageNumber?: number): Promise<IYoutubeVideosResponse> {
    let query = '';
    if (pageNumber)
      query += '&page=' + pageNumber;

    const response = await this.get<IYoutubeVideosResponse>(`${config.BASE_API_URL}/youtube-channels/${channelId}/videos?${query}`);
    return response.data;
  }

  public async getSimpleStatistics(channelId: string, filters?: IQueryParams): Promise<IYoutubeChannelStatisticsResponse> {
    const query = this.applyQueryParams(filters);
    const res = await this.get<IYoutubeChannelStatisticsResponse>(`${config.BASE_API_URL}/youtube-channels/${channelId}/simpleStatistics?${query}`);
    return res.data;
  }

  public async getRevenues(channelId: string, filters?: IQueryParams): Promise<IResponseBase<{item: YoutubeFinancialRevenueByMonthsReport}>> {
    const query = this.applyQueryParams(filters);
    const res = await this.get(`${config.BASE_API_URL}/youtube-channels/${channelId}/revenue-by-months?${query}`);
    return res.data;
  }
}

