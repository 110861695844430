import {Currency} from '../transaction-api/Transaction';

export interface IFinancialAccountDto {
  id: number;
  name: string;
  type: FinancialAccountType;
  balance: number;
  waitingConfirmBalance: number;
  currency: Currency;
  contractorId: number;
  contractorName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export enum FinancialAccountType {
  INNER = 'INNER',
  CACHE = 'CACHE',
  MC_PAY = 'MC_PAY'
}
