import SVG from 'react-inlinesvg';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useState} from 'react';
import config from '../../../../configs/config';
import {toast} from 'react-toastify';
import {ApiRequestException} from '../../../api/axios-instance';

const ICONS = {
  YOUTUBE: require('../../../images/svg/Youtube.svg'),
};

interface IYoutubeIntegrationWidgetProps {
  integrated: boolean;

  onConnect(): void;
}

let CheckWindowWasClosedInterval: NodeJS.Timeout;

export const YoutubeIntegrationWidget: React.FC<IYoutubeIntegrationWidgetProps> = (
  {
    integrated,
    onConnect,
  }: IYoutubeIntegrationWidgetProps) => {
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseChildWindow = () => {
    clearInterval(CheckWindowWasClosedInterval);
    setLoading(false);
    onConnect();
  };

  const checkWindowWasClosed = (window: Window) => {
    CheckWindowWasClosedInterval = setInterval(() => {
      if (window && window.closed) {
        handleCloseChildWindow();
      }
    }, 1000);
  };

  const connectToYoutube = async () => {
    try {
      setLoading(true);
      const childWindow = window.open(config.GOOGLE_OAUTH_ADMIN_API_URL, 'blank', 'width=500,height=800');
      if (childWindow !== null) {
        setLoading(true);
        checkWindowWasClosed(childWindow);
      }
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  return <>
    <div className='card card-custom gutter-b bg-danger'>
      <div className='card-body'>
        <div className='d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap'>
          <div className='d-flex flex-column mr-5'>
            <div className='h4 text-light mb-1'>
              <span className='svg-icon svg-icon-6x svg-icon-light mr-2'><SVG src={ICONS.YOUTUBE} /></span>
              <FormattedMessage id={'INTEGRATE_WITH_YOUTUBE'} />
            </div>
            <p className='text-light'>
              <FormattedMessage id={'INTEGRATE_WITH_YOUTUBE_DESCRIPTION'} />
            </p>
          </div>
          <div className='ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0'>
            <button
              disabled={loading}
              onClick={connectToYoutube}
              className='btn font-weight-bolder text-uppercase btn-light-danger py-4 px-6'>
              <FormattedMessage id={integrated ? 'CONNECTED' : 'CONNECT'} />
              {loading && <span className='ml-3 spinner spinner-white' />}
            </button>
          </div>
        </div>
      </div>
    </div>
  </>;
};
