import React, {CSSProperties} from 'react';

interface InputCustomProps {
  value?: string | number | null;
  name?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  style?: CSSProperties,
  required?: boolean;
  hasError?: boolean;
  defaultValue?: string;
  disabled?: boolean;

  onChange(e: React.SyntheticEvent<any>): any;
}

export const InputTextArea = (
  {
    className,
    name,
    hasError,
    onChange,
    placeholder,
    label,
    value,
    defaultValue,
    style,
    disabled,
    required,
  }: InputCustomProps) => {
  const errorClassName = hasError ? 'is-invalid' : '';
  const inputClassName = `input-text ${errorClassName} ${className}`;
  const props = defaultValue != undefined || defaultValue != null ? {defaultValue: defaultValue} : {value: value || ''};
  return <>
    {label
    && <label className={'font-weight-bolder'}>
      {label} {required && <span className={'text-danger'}>*</span>}
    </label>}
    <textarea
      disabled={disabled}
      style={style}
      placeholder={placeholder}
      className={inputClassName}
      name={name}
      onChange={onChange}
      {...props}
    />
  </>;
};
