import React, {useCallback, useEffect, useState} from 'react';
import {Download} from '../../api/DTOs/Download';
import {BaseListPage} from '../base/base-list-page';
import {useIntl} from '../../hooks/use-intl';
import {useLoading} from '../../hooks/use-loading';
import {toast} from 'react-toastify';
import {useDownloadsApi} from '../../hooks/apis/use-downloads-api';
import {IPaginationInfo} from '../../api/Paginator';
import {EntityId} from '../../api/base/BaseEntity';
import {DownloadCard} from './download-card';
import {useParams} from 'react-router-dom';
import {useModalDownloadFile} from './download-file-modal-context';

export const DownloadsPage: React.FC = () => {
  const intl = useIntl();
  const {id: downloadId} = useParams<{id?: string}>();
  const [loadings, startLoading, stopLoading] = useLoading({downloads: true});
  const api = useDownloadsApi();
  const [downloads, setDownloads] = useState<Array<Download>>([]);
  const [pagination, setPagination] = useState<IPaginationInfo | null>(null);
  const {showDownloadFileModal} = useModalDownloadFile();
  useEffect(() => {
    if (downloadId) {
      showDownloadFileModal(downloadId);
    }
  }, [downloadId]);

  const fetchDownloads = useCallback(async () => {
    try {
      startLoading('downloads');
      const {
        data: {items, paginator},
      } = await api.getAll();
      setDownloads(items);
      setPagination(paginator);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('downloads');
    }
  }, []);

  const downloadFile = useCallback(async (id: EntityId) => {
    try {
      const {content, fileName} = await api.get(id);
      saveAs(content, fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  return (
    <BaseListPage
      loading={loadings.downloads}
      fetchData={fetchDownloads}
      pagination={{info: pagination}}
      pageTitle={intl.formatMessage({id: 'DOWNLOADS'})}
      className={{cardBody: 'bg-light'}}>
      <>
        {downloads.length === 0 && <div className={'text-center'}>{intl.formatMessage({id: 'NOT_FOUND'})}</div>}
        {downloads.map(download => {
          return <DownloadCard key={download.id} item={download} onDownload={downloadFile} />;
        })}
      </>
    </BaseListPage>
  );
};
