import {IAddendumDto} from '../../../../api/addendum-api/IAddendumDto';
import {AddendumActions} from './contract-page';
import {AddendumApi} from '../../../../api/addendum-api/addendum-api';
import {useMemo} from 'react';
import {CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {saveAs} from 'file-saver';
import {ApiRequestException} from '../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useIntl} from '../../../../hooks/use-intl';
import {useModalEditAddendum} from '../../../../components/modals/edit-addendum/modal-edit-addendum-context';

export function useAddendumActionHandler(fetch: () => Promise<any>) {
  const {showEditAddendumModal} = useModalEditAddendum();
  const intl = useIntl();
  const addendumApi = useMemo(() => new AddendumApi(), []);
  const handle = async (addendum: IAddendumDto, action: AddendumActions) => {
    try {
      switch (action) {
        case AddendumActions.EDIT: {
          const result = await showEditAddendumModal(addendum.id, addendum);
          if (result.reason === CloseModalReason.OK) {
            await fetch();
          }
          break;
        }
        case AddendumActions.DOWNLOAD: {
          const result = await addendumApi.download(addendum.id);
          saveAs(result.content, result.fileName);
          break;
        }
        case AddendumActions.DOWNLOAD_CUSTOM_TEMPLATE: {
          const result = await addendumApi.getTemplateFile(addendum.id);
          saveAs(result.content, result.fileName);
          break;
        }
        case AddendumActions.DELETE_CUSTOM_TEMPLATE: {
          await addendumApi.deleteTemplateFile(addendum.id);
          await fetch();
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {handleAddendumActions: handle};
}
