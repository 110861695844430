import {BaseEndpoint} from './base-endpoint';
import config from '../../configs/config';
import {IQueryParams} from './DTOs/IFilterDtos';

export class RevenueApi extends BaseEndpoint {
  public async getRevenuesByMonths(params?: IQueryParams) {
    const query = this.applyQueryParams(params);
    const result = await this.get(`${config.BASE_API_URL}/revenue-by-months?${query}`);
    return result.data;
  }
}
