import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../components/default-table';
import {AdminRoutes, Routes} from '../../../configs/routes';
import {
  ISupportTicketDto,
  SupportTicketStatus,
  SupportTicketType,
} from '../../api/support-tickets-api/ISupportTicketDto';
import {BadgeCustom} from '../../components/badge-custom';
import {TableUtils} from '../../components/table-utils';

interface ITicketsTableProps {
  isAdmin?: boolean;
  tickets: Array<ISupportTicketDto>;
}

export const TicketsTable = ({tickets, isAdmin}: ITicketsTableProps) => {
  const intl = useIntl();
  const data = tickets;

  const columns = useMemo(() => {
    return [
      {
        id: 'ID',
        Header: TableUtils.renderHeaderForNumericContent('ID'),
        maxWidth: 50,
        minWidth: 50,
        accessor: (br: ISupportTicketDto) => br.id,
        Cell: ({value: id}: {value: number}) => {
          return TableUtils.renderNumericContent(<>
            <Link to={isAdmin
              ? AdminRoutes.getSupportTicketRoute(id)
              : Routes.getSupportTicketRoute(id)
            }>
              {id}
            </Link>
          </>);
        },
      },
      {
        id: 'Topic',
        Header: intl.formatMessage({id: 'TICKET_TOPIC'}),
        maxWidth: 300,
        minWidth: 350,
        accessor: (br: ISupportTicketDto) => br,
        Cell: ({value}: {value: ISupportTicketDto}) => {
          return TableUtils.renderBaseContent(<>
            <Link to={isAdmin
              ? AdminRoutes.getSupportTicketRoute(value.id)
              : Routes.getSupportTicketRoute(value.id)
            }>
              {value.topic}
            </Link>
          </>);
        },
      },
      {
        id: 'Type',
        Header: intl.formatMessage({id: 'TYPE'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: ISupportTicketDto) => br.type,
        Cell: ({value: type}: {value: SupportTicketType}) => {
          const colorVariant = type === SupportTicketType.QUESTION
            ? 'warning'
            : type === SupportTicketType.PROBLEM
              ? 'danger'
              : 'primary';
          return TableUtils.renderBaseContent(<>
            <BadgeCustom variant={colorVariant}>
              <FormattedMessage id={'SUPPORT_TICKET_TYPE_' + type} />
            </BadgeCustom>
          </>);
        },
      },
      {
        id: 'Author',
        Header: intl.formatMessage({id: 'TICKET_AUTHOR'}),
        maxWidth: 200,
        minWidth: 200,
        accessor: (br: ISupportTicketDto) => br,
        Cell: ({value}: {value: ISupportTicketDto}) => {
          return TableUtils.renderBaseContent(value.authorName);
        },
      },
      {
        id: 'Status',
        Header: intl.formatMessage({id: 'STATUS'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: ISupportTicketDto) => br.status,
        Cell: ({value: status}: {value: SupportTicketStatus}) => {
          const colorVariant = status === SupportTicketStatus.CLOSED ? 'success' : 'danger';
          return TableUtils.renderBaseContent(<>
            <BadgeCustom variant={colorVariant}>
              <FormattedMessage id={'SUPPORT_TICKET_STATUS_' + status} />
            </BadgeCustom>
          </>);
        },
      },
      {
        id: 'CreatedAt',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: ISupportTicketDto) => br.status,
        Cell: ({value}: {value: ISupportTicketDto}) => {
          return TableUtils.renderBaseContent(<>
            {intl.formatDate(value.updatedAt)} {intl.formatTime(value.updatedAt)}
          </>);
        },
      },
      {
        id: 'UpdatedAt',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: ISupportTicketDto) => br.status,
        Cell: ({value}: {value: ISupportTicketDto}) => {
          return TableUtils.renderBaseContent(<> {intl.formatDate(value.createdAt)} {intl.formatTime(value.createdAt)}</>);
        },
      },
    ];
  }, []);


  return <DefaultTable data={data} columns={columns} />;
};
