import React, {createContext} from 'react';
import {ModalEditUserProvider} from '../edit-user/modal-edit-user-context';
import {ModalCreateUserProvider} from '../create-user/modal-create-user-context';
import {ModalEditContractProvider} from '../edit-contract/modal-edit-contract-context';
import {ModalPaymentRequestProvider} from '../create-payment-request-modal/payment-request-modal-context';
import {ModalPlayVideoProvider} from '../play-video/modal-play-video-context';
import {ModalRejectReasonTransactionProvider} from '../reject-reason/modal-reject-reason-transaction-context';
import {ModalCreateContractProvider} from '../create-contract/modal-create-contract-context';
import {ModalEditContractorProvider} from '../edit-contractor/modal-edit-contractor-context';
import {ModalCreateAddendumProvider} from '../create-addendum/modal-create-addendum-context';
import {ModalRejectReasonConnectRequestProvider} from '../reject-reason/modal-reject-reason-connect-request-context';
import {ModalSignatureProvider} from '../signature-modal/modal-signature-context';
import {ModalRejectReasonProvider} from '../reject-reason/modal-reject-reason-payment-request-context';
import {ModalAdminEditPaymentRequisitesProvider} from '../edit-requisites/modal-edit-requisites-admin-context';
import {ModalEditAddendumProvider} from '../edit-addendum/modal-edit-addendum-context';
import {ModalGenerateFinancialReportProvider} from '../generate-financial-report/modal-generate-financial-report-context';
import {ModalCreateTransactionProvider} from '../create-transaction/modal-create-transaction-context';
import {ModalCreateSupportTicketProvider} from '../create-support-ticket-modal/modal-create-support-ticket-context';
import {ModalChooseUsersProvider} from '../choose-users/modal-choose-user-context';
import {ModalPreviewHtmlProvider} from '../preview-html-content/modal-preview-html-content-context';
import {ModalRejectReasonAdsRequestProvider} from '../reject-reason/modal-reject-reason-ads-request-context';
import {ModalCreateEditChapterProvider} from '../create-edit-chapter-modal/modal-create-edit-chapter-context';
import {ModalDownloadFileProvider} from '../../../pages/downloads/download-file-modal-context';
import {ModalChannelTransactionsIncomeReportProvider} from '../channel-transaction-income-report-modal/modal-channel-transactions-income-report-context';
import {ModalConnectYoutubeChannelProvider} from '../connect-youtube-channel/connect-youtube-channel-modal-context';
import {ModalApproveConnectChannelRequestsProvider} from '../approve-connect-channel-request-modal/approve-connect-channel-requests-modal-context';
import {ModalAdminPaymentRequestProvider} from '../create-admin-payment-request-modal/admin-payment-request-modal-context';

const ModalsContext = createContext({});

export const ModalsProvider = ({children}: any) => {
  return (
    <ModalsContext.Provider value={{}}>
      <ModalCreateContractProvider>
        <ModalCreateAddendumProvider>
          <ModalEditContractProvider>
            <ModalEditContractorProvider>
              <ModalCreateUserProvider>
                <ModalEditUserProvider>
                  <ModalPaymentRequestProvider>
                    <ModalRejectReasonProvider>
                      <ModalRejectReasonConnectRequestProvider>
                        <ModalPlayVideoProvider>
                          <ModalRejectReasonTransactionProvider>
                            <ModalSignatureProvider>
                              <ModalAdminEditPaymentRequisitesProvider>
                                <ModalEditAddendumProvider>
                                  <ModalGenerateFinancialReportProvider>
                                    <ModalCreateTransactionProvider>
                                      <ModalRejectReasonAdsRequestProvider>
                                        <ModalCreateSupportTicketProvider>
                                          <ModalChooseUsersProvider>
                                            <ModalPreviewHtmlProvider>
                                              <ModalCreateEditChapterProvider>
                                                <ModalDownloadFileProvider>
                                                  <ModalChannelTransactionsIncomeReportProvider>
                                                    <ModalConnectYoutubeChannelProvider>
                                                      <ModalApproveConnectChannelRequestsProvider>
                                                        <ModalAdminPaymentRequestProvider>
                                                          {children}
                                                        </ModalAdminPaymentRequestProvider>
                                                      </ModalApproveConnectChannelRequestsProvider>
                                                    </ModalConnectYoutubeChannelProvider>
                                                  </ModalChannelTransactionsIncomeReportProvider>
                                                </ModalDownloadFileProvider>
                                              </ModalCreateEditChapterProvider>
                                            </ModalPreviewHtmlProvider>
                                          </ModalChooseUsersProvider>
                                        </ModalCreateSupportTicketProvider>
                                      </ModalRejectReasonAdsRequestProvider>
                                    </ModalCreateTransactionProvider>
                                  </ModalGenerateFinancialReportProvider>
                                </ModalEditAddendumProvider>
                              </ModalAdminEditPaymentRequisitesProvider>
                            </ModalSignatureProvider>
                          </ModalRejectReasonTransactionProvider>
                        </ModalPlayVideoProvider>
                      </ModalRejectReasonConnectRequestProvider>
                    </ModalRejectReasonProvider>
                  </ModalPaymentRequestProvider>
                </ModalEditUserProvider>
              </ModalCreateUserProvider>
            </ModalEditContractorProvider>
          </ModalEditContractProvider>
        </ModalCreateAddendumProvider>
      </ModalCreateContractProvider>
    </ModalsContext.Provider>
  );
};
