import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {ValidationErrorsType} from '../utils/utils';
import {PartialNullable} from '../../types';

export type UpdatedFields<T> = {[P in keyof PartialNullable<T>]?: PartialNullable<T>[P]};
export type UpdatedFieldsCallback<T> = (prevState: PartialNullable<T>) => {
  [P in keyof PartialNullable<T>]?: PartialNullable<T>[P];
};
export type UpdateFields<T> = (fields: UpdatedFields<T> | UpdatedFieldsCallback<T>) => void;

export function useAdvancedState<T>(
  initValue?: PartialNullable<T>,
  setValidationErrors?: Dispatch<SetStateAction<ValidationErrorsType>>,
  changeCallback?: (updatedValue: PartialNullable<T>) => void,
): [PartialNullable<T>, Dispatch<PartialNullable<T>>, UpdateFields<T>, () => void] {
  const initialStateRef = useRef<PartialNullable<T>>(initValue ?? {});
  const [value, setValue] = useState<PartialNullable<T>>(initValue || {});
  const updateState = (updatedFields: {[P in keyof PartialNullable<T>]?: PartialNullable<T>[P]}) => {
    setValue(prevState => ({...prevState, ...updatedFields}));
  };

  useEffect(() => {
    if (changeCallback) {
      changeCallback(value);
    }
  }, [value]);

  const resetValidation = useCallback(
    (props: {[P in keyof PartialNullable<T>]?: PartialNullable<T>[P]}) => {
      if (!setValidationErrors) {
        return;
      }

      setValidationErrors(prev => ({
        ...prev,
        ...Object.keys(props).reduce((acc, updatedKey) => {
          return {...acc, [updatedKey]: null};
        }, {}),
      }));
    },
    [setValidationErrors],
  );

  const handleEntityChange = (props: UpdatedFields<T> | UpdatedFieldsCallback<T>) => {
    let val: {[P in keyof PartialNullable<T>]?: PartialNullable<T>[P]} = props as any;
    if (props instanceof Function) {
      val = props(value);
    }

    updateState(val);
    resetValidation(val);
  };

  const resetState = () => {
    setValue(initialStateRef.current);
  };

  return [value, setValue, handleEntityChange, resetState];
}
