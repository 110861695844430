import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalRejectReason} from './modal-reject-reason';
import {PaymentsApi} from '../../../api/payment-api/payments-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';

interface IModalRejectReasonPaymentRequestContext {
  modalRejectReasonVisible: boolean;

  showRejectReasonModal(requestId: number): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalRejectReasonPaymentRequestContext = createContext<IModalRejectReasonPaymentRequestContext>();

let closeResolver: (((data: CloseModalEvent<null>) => any) | null) = null;
export const ModalRejectReasonProvider = ({children}: any) => {
  const intl = useIntl();

  const api = new PaymentsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [requestId, setRequestId] = useState<number | null>(null);

  const showModal = async (reqId: number) => {
    setRequestId(reqId);
    setVisible(true);
    return new Promise<CloseModalEvent<null>>((resolve) => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setReason('');
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    if (requestId === null) {
      throw new Error('Request Id should be not null');
    }

    try {
      setError(null);
      await api.rejectPaymentRequest(requestId, reason);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalRejectReasonPaymentRequestContext = {
    modalRejectReasonVisible: visible,
    showRejectReasonModal: showModal,
  };

  return <ModalRejectReasonPaymentRequestContext.Provider value={value}>
    {children}
    <ModalRejectReason
      error={error}
      reason={reason}
      visible={visible}
      validationErrors={validationErrors}

      onReasonChange={(r) => setReason(r)}
      onOkClick={handleOkClick}
      onHide={handleHideModal}
    />
  </ModalRejectReasonPaymentRequestContext.Provider>;
};

export const useModalRejectReasonPaymentRequest = () => {
  return useContext(ModalRejectReasonPaymentRequestContext);
};
