import React from 'react';
import {FileError, useDropzone} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import {UploadedFile} from './uploaded-file';
import {REPORT_TYPE} from '../api/financial-report-api/IYoutubeFinancialBulkReportsDto';

interface IFileUploaderProps {
  file?: File;
  type: REPORT_TYPE;
  acceptFileExtension: string;
  className?: string;
  serverError?: string;
  required?: boolean;
  reportDescription?: string;
  reportFileMask?: string;

  onSelect(type: REPORT_TYPE, file: File): void;

  onDelete(type: REPORT_TYPE): void;

  validateFile?(file: File): FileError;
}

export const ReportFileUploader = ({
  validateFile,
  onSelect,
  file,
  acceptFileExtension,
  onDelete,
  type,
  className,
  serverError,
  required,
  reportDescription,
  reportFileMask,
}: IFileUploaderProps) => {
  const fileValidator = (file: File): FileError | null => {
    if (validateFile) {
      return validateFile(file);
    }

    return null;
  };

  const handleDropAccepted = (files: Array<File>) => {
    onSelect(type, files[0]);
  };

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: acceptFileExtension,
    validator: fileValidator,
    onDropAccepted: handleDropAccepted,
  });

  const fileInfo: any = {
    SIZE: file?.size,
    REPORT_TYPE: type,
  };

  return (
    <>
      <div className={className} style={{marginBottom: '30px', marginTop: '10px'}}>
        <div className={'col-lg-12 col-sm-12 font-weight-bolder mx-0 my-2 px-0'}>
          <div className={'my-2 px-0 font-weight-boldest'}>
            {reportDescription && (
              <>
                <span>{reportDescription}</span>
                {required && <span className={'text-danger mx-1'}>*</span>}
                <span className={'text-muted font-weight-bold ml-1'} />
              </>
            )}
          </div>
          <div className={'my-2 px-0'}>{reportFileMask && <span className={'font-italic'}>{reportFileMask}</span>}</div>
        </div>
        <div
          {...getRootProps()}
          className={`dropzone dropzone-default dropzone-${serverError ? 'danger' : 'primary'} ribbon ribbon-top`}>
          {!file ? (
            <div className='dropzone-msg dz-message needsclick d-flex align-items-center justify-content-center'>
              <span className='dropzone-msg-title'>
                <FormattedMessage id={'DROP_FILES'} />
              </span>
              <input {...getInputProps()} />
            </div>
          ) : (
            <div>
              <UploadedFile
                type={type}
                title={file?.name}
                fileInfo={fileInfo}
                onDelete={onDelete}
                className={'col-lg-12 col-xl-12'}
              />
            </div>
          )}
        </div>
        <div className={'text-center'}>{serverError && <span className={'text-danger'}>{serverError}</span>}</div>
      </div>
    </>
  );
};
