import React, {useState} from 'react';
import {InputText} from '../../../components/Inputs/InputText';
import {RichTextEditor} from '../../../components/rich-text-editor/rich-text-editor';
import {ArticleStatus, IArticleDto} from '../../../api/knowledge-base-api/IArticleDto';
import {useIntl} from '../../../hooks/use-intl';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {AppLocale} from '../../../../localization';
import {InputTextArea} from '../../../components/Inputs/InputTextArea';
import {InputSwitch} from '../../../components/Inputs/InputSwitch';

type Props = {
  article: Partial<IArticleDto>;
  update: (key: keyof IArticleDto, value: any) => void;
  validationErrors: ValidationErrorsType;
};

export const EditArticleForm: React.FC<Props> = ({article, validationErrors, update}) => {
  const intl = useIntl();
  const [enableRawMode, setEnableRawMode] = useState<Record<AppLocale, boolean>>({
    [AppLocale.RU]: false,
    [AppLocale.EN]: false,
  });

  const renderContent = (language: AppLocale) => {
    return (
      <>
        <ValidateErrorWrapper message={getValidationErrorMessage(`title_${language}`, validationErrors)}>
          <InputText
            required
            value={language === AppLocale.RU ? article.title_ru : article.title_en}
            label={intl.formatMessage({id: 'TITLE'}) + ` (${language.toUpperCase()})`}
            classNames={'form-control py-3 px-6 mb-2'}
            onChange={e => update(language === AppLocale.RU ? 'title_ru' : 'title_en', e.currentTarget.value)}
          />
        </ValidateErrorWrapper>
        <InputSwitch
          size={'sm'}
          type={'primary'}
          classNames={'mb-2'}
          titles={['HTML', 'TEXT']}
          handleChange={() =>
            setEnableRawMode(prevState => ({
              ...prevState,
              [language]: !prevState[language],
            }))
          }
        />
        <ValidateErrorWrapper message={getValidationErrorMessage(`content_${language}`, validationErrors)}>
          {enableRawMode[language] ? (
            <InputTextArea
              value={(language === AppLocale.RU ? article.content_ru : article.content_en) ?? ''}
              className={'form-control min-h-300px mb-5'}
              onChange={e => update(language === AppLocale.RU ? 'content_ru' : 'content_en', e.currentTarget.value)}
            />
          ) : (
            <RichTextEditor
              content={(language === AppLocale.RU ? article.content_ru : article.content_en) ?? ''}
              onChange={content => update(language === AppLocale.RU ? 'content_ru' : 'content_en', content)}
            />
          )}
        </ValidateErrorWrapper>
      </>
    );
  };

  return (
    <>
      <div className={'mb-5'}>
        {renderContent(AppLocale.RU)}
        {renderContent(AppLocale.EN)}
        <ValidateErrorWrapper message={getValidationErrorMessage('status', validationErrors)}>
          <InputSelect
            required
            label={intl.formatMessage({id: 'STATUS'})}
            value={article.status}
            options={[
              {label: intl.formatMessage({id: 'ARTICLE_STATUS_DRAFT'}), value: ArticleStatus.DRAFT},
              {
                label: intl.formatMessage({id: 'ARTICLE_STATUS_PUBLISHED'}),
                value: ArticleStatus.PUBLISHED,
              },
            ]}
            onChange={value => update('status', value)}
          />
        </ValidateErrorWrapper>
      </div>
    </>
  );
};
