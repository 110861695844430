import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {isEqual, trimStringWithEllipsis} from '../../utils/utils';
import {AdminRoutes, Routes} from '../../../configs/routes';
import {useIntl} from '../../hooks/use-intl';

interface IBreadcrumbsContext {
  routes: Array<any>;
  excludePaths: Array<string>;

  setBreadcrumb(breadcrumbParams: string | Array<string>, maxLength?: number): void;

  resetBreadcrumb(): void;
}

// @ts-ignore
const BreadcrumbsContext = createContext<IBreadcrumbsContext>();

export const BreadcrumbsProvider = ({children}: any) => {
  const intl = useIntl();
  const history = useHistory();

  const [breadcrumbParams, setBreadcrumbParams] = useState<Array<string | null>>([]);
  const staticRoutes = useMemo(
    () => [
      {path: Routes.getYoutubeVideosRoute(), breadcrumb: intl.formatMessage({id: 'VIDEOS'})},
      {path: Routes.getYoutubeChannelsRoute(), breadcrumb: intl.formatMessage({id: 'CHANNELS'})},
      {path: '*/videos', breadcrumb: intl.formatMessage({id: 'VIDEOS'})},
      {path: '*/create', breadcrumb: intl.formatMessage({id: 'CREATE'})},
      {path: '*/edit', breadcrumb: intl.formatMessage({id: 'EDIT'})},
      {path: Routes.getProfileRoute(), breadcrumb: intl.formatMessage({id: 'PROFILE'})},
      {path: Routes.getRevenuesPageRoute(), breadcrumb: intl.formatMessage({id: 'REVENUES'})},
      {path: Routes.getDashboardRoute(), breadcrumb: intl.formatMessage({id: 'DASHBOARD'})},
      {path: Routes.getFinancialReportsRoute(), breadcrumb: intl.formatMessage({id: 'FINANCIAL_REPORTS'})},
      {path: Routes.getNotFoundRoute(), breadcrumb: intl.formatMessage({id: 'PAGE_NOT_FOUND'})},
      {path: AdminRoutes.getContractsRoute(), breadcrumb: intl.formatMessage({id: 'CONTRACTS'})},
      {path: AdminRoutes.getContractorsRoute(), breadcrumb: intl.formatMessage({id: 'CONTRACTORS'})},
      {path: AdminRoutes.getYoutubeFinancialReportsRoute(), breadcrumb: intl.formatMessage({id: 'FINANCIAL_REPORTS'})},
      {path: AdminRoutes.getUploadYoutubeFinancialReports(), breadcrumb: intl.formatMessage({id: 'REPORTS_UPLOAD'})},
      {path: AdminRoutes.getUserManagementRoute(), breadcrumb: intl.formatMessage({id: 'USER_MANAGEMENT'})},
      {path: AdminRoutes.getPaymentRequestsRoute(), breadcrumb: intl.formatMessage({id: 'PAYMENT_REQUESTS'})},
      {path: AdminRoutes.getUsersActionsRoute(), breadcrumb: intl.formatMessage({id: 'USERS_ACTIONS'})},
      {path: Routes.getDownloadsRoute(), breadcrumb: intl.formatMessage({id: 'DOWNLOADS'})},
      {path: AdminRoutes.getApplicationSettingsRoute(), breadcrumb: intl.formatMessage({id: 'APPLICATION_SETTINGS'})},
      {
        path: AdminRoutes.getConnectChannelRequestsRoute(),
        breadcrumb: intl.formatMessage({id: 'ADD_CHANNEL_REQUESTS'}),
      },
      {path: AdminRoutes.getYoutubeIntegrationRoute(), breadcrumb: intl.formatMessage({id: 'YOUTUBE_INTEGRATION'})},
      {path: AdminRoutes.getFinancialAccountsRoute(), breadcrumb: intl.formatMessage({id: 'FINANCIAL_ACCOUNTS'})},
      {path: '*/videos', breadcrumb: intl.formatMessage({id: 'VIDEOS'})},
      {path: '*/create', breadcrumb: intl.formatMessage({id: 'CREATE'})},
      {path: '*/edit', breadcrumb: intl.formatMessage({id: 'EDIT'})},
      {path: Routes.getProfileRoute(), breadcrumb: intl.formatMessage({id: 'PROFILE'})},
      {path: Routes.getRevenuesPageRoute(), breadcrumb: intl.formatMessage({id: 'REVENUES'})},
      {path: Routes.getDashboardRoute(), breadcrumb: intl.formatMessage({id: 'DASHBOARD'})},
      {path: AdminRoutes.getTransactionsRoute(), breadcrumb: intl.formatMessage({id: 'TRANSACTIONS'})},
      {path: AdminRoutes.getDashboardRoute(), breadcrumb: intl.formatMessage({id: 'DASHBOARD'})},
      {path: AdminRoutes.getCMSRevenueRoute(), breadcrumb: intl.formatMessage({id: 'CMS_REVENUE'})},
      {path: AdminRoutes.getMailingsRoute(), breadcrumb: intl.formatMessage({id: 'MAILINGS'})},
      {path: AdminRoutes.getInstructionsRoute(), breadcrumb: intl.formatMessage({id: 'INSTRUCTIONS'})},
      {path: Routes.getFinancialReportsRoute(), breadcrumb: intl.formatMessage({id: 'FINANCIAL_REPORTS'})},
      {path: Routes.getNotFoundRoute(), breadcrumb: intl.formatMessage({id: 'PAGE_NOT_FOUND'})},
      {path: Routes.getAdvertisementsRoute(), breadcrumb: intl.formatMessage({id: 'ADVERTISEMENT'})},
      {path: AdminRoutes.getAdvertisementRoute(), breadcrumb: intl.formatMessage({id: 'ADVERTISEMENT'})},
      {path: Routes.getKnowledgeBaseRoute(), breadcrumb: intl.formatMessage({id: 'KNOWLEDGE_BASE'})},
      {path: Routes.getSupportRoute(), breadcrumb: intl.formatMessage({id: 'SUPPORT'})},
      {path: AdminRoutes.getSupportRoute(), breadcrumb: intl.formatMessage({id: 'SUPPORT'})},
    ],
    [],
  );

  const dynamicRoutes = useMemo(() => {
    const firstLevelParams = breadcrumbParams[0];
    return [
      {path: Routes.getYoutubeSpecifyVideoRoute(':id'), breadcrumb: firstLevelParams},
      {path: Routes.getYoutubeSpecifyChannelRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyContractRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyContractorRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyUserManagementRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyPaymentRequestRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyConnectChannelRequestRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyTransactionRoute(':id'), breadcrumb: firstLevelParams},
      {path: Routes.getSpecifyAdvertisementRequestRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyAdvertisementRequestRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyTransactionRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSpecifyMailingRoute(':id'), breadcrumb: firstLevelParams},
      {path: Routes.getSupportTicketRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getSupportTicketRoute(':id'), breadcrumb: firstLevelParams},
      {path: Routes.getSpecifyArticleRoute(':id'), breadcrumb: firstLevelParams},
      {path: AdminRoutes.getCreateArticleRoute(':id'), breadcrumb: firstLevelParams},
    ];
  }, [breadcrumbParams]);

  const excludesPaths = useMemo(() => ['/admin'], []);
  const routes = useMemo(() => [...staticRoutes, ...dynamicRoutes], [dynamicRoutes]);
  const staticRoutesPaths = useMemo(() => [...staticRoutes.map(r => r.path)], [staticRoutes]);

  useEffect(() => {
    const currentRouteIsStatic = staticRoutesPaths.includes(history.location.pathname);
    if (currentRouteIsStatic) {
      resetBreadcrumbs();
    }
  }, [history.location.pathname]);

  const resetBreadcrumbs = () => {
    setBreadcrumbParams([null]);
  };

  const value: IBreadcrumbsContext = {
    routes: routes,
    excludePaths: excludesPaths,
    setBreadcrumb(params, maxLength) {
      const paramsAsArray = Array.isArray(params) ? params : [params];
      if (!isEqual(paramsAsArray, breadcrumbParams)) {
        if (maxLength !== undefined) {
          setBreadcrumbParams(paramsAsArray.map(p => trimStringWithEllipsis(p, maxLength)));
        } else {
          setBreadcrumbParams(paramsAsArray);
        }
      }
    },
    resetBreadcrumb: resetBreadcrumbs,
  };

  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};

export const useCustomBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
};
