import {BaseEndpoint} from './base-endpoint';
import config from '../../configs/config';
import {IYoutubeVideoResponse, IYoutubeVideosResponse} from './response-contracts/yotutube-channel-contracts';
import {IYoutubeVideoStatisticsResponse} from './response-contracts/youtube-video-contracts';
import {IQueryParams} from './DTOs/IFilterDtos';

export class YoutubeVideosApi extends BaseEndpoint {
  public async getVideoList(filters?: any): Promise<IYoutubeVideosResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IYoutubeVideosResponse>(`${config.BASE_API_URL}/youtube-videos?${query}`);
    return response.data;
  }

  public async getVideoInfo(videoId: string): Promise<IYoutubeVideoResponse> {
    const response = await this.get<IYoutubeVideoResponse>(`${config.BASE_API_URL}/youtube-videos/${videoId}`);
    return response.data;
  }

  public async getSimpleStatistics(videoId: string, filters?: IQueryParams): Promise<IYoutubeVideoStatisticsResponse> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IYoutubeVideoStatisticsResponse>(`${config.BASE_API_URL}/youtube-videos/${videoId}/simpleStatistics?${query}`);
    return response.data;
  }
}

