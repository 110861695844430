import React from 'react';
import {Link} from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {useCustomBreadcrumbs} from './breadcrumbs-context';

export const BreadCrumbs = () => {
  const {routes, excludePaths} = useCustomBreadcrumbs();
  const breadcrumbs = useBreadcrumbs(routes, {excludePaths: excludePaths});

  breadcrumbs.splice(0, 1);
  return (
    <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bolder p-0 my-2'>
      <li className='breadcrumb-item'>
        <Link to='/'>
          <i className='flaticon2-shelter text-muted icon-1x' />
        </Link>
      </li>
      {breadcrumbs.map(({match, breadcrumb}) => {
        return <li key={match.url} className={'breadcrumb-item'}>
          <Link to={match.url} className='text-muted'>
            {breadcrumb}
          </Link>
        </li>;
      })}
    </ul>
  );
};
