import React, {useEffect, useState} from 'react';
import {AdvertisementsApi} from '../../api/advertisement-api/advertisements-api';
import {IAdvertisementRequestDto} from '../../api/advertisement-api/IAdvertisementRequestDto';
import {Spinner} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {ApiRequestException} from '../../api/axios-instance';
import {InputCheckbox} from '../../components/Inputs/InputCheckbox';
import {InputText} from '../../components/Inputs/InputText';
import {InputTextArea} from '../../components/Inputs/InputTextArea';
import {SelectApi} from '../../api/select-api';
import {ISelectValueDto} from '../../api/DTOs/ISelectValueDto';
import {toast} from 'react-toastify';
import {InputSelect} from '../../components/Inputs/InputSelect';
import {InputDatepicker} from '../../components/Inputs/InputDatepicker';
import {prepareDate, tryGetDateOrNull} from '../../utils/utils';
import {ValidateErrorWrapper} from '../../components/Inputs/ValidateErrorWrapper';
import {EXCEPTION_TYPE} from '../../api/exceptions/IBaseException';
import {Routes} from '../../../configs/routes';
import {Preloader} from '../../components/preloader';
import {InputRange} from '../../components/Inputs/InputRange';
import {IValidationException} from '../../api/exceptions/IValidationException';
import {ErrorStub} from '../../components/error-stub';

interface IProps {
  mode: 'EDIT' | 'CREATE';
}

export const CreateOrEditAdvertisementRequest: React.FC<IProps> = ({mode}) => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const adRequestId = match?.params?.id ? Number(match.params.id) : null;

  const api = new AdvertisementsApi();
  const selectApi = new SelectApi();
  const [loading, setLoading] = useState(mode === 'EDIT');
  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [channels, setChannels] = useState<Array<ISelectValueDto>>([]);
  const [adRequest, setAdRequest] = useState<Partial<IAdvertisementRequestDto>>({
    has_tax_on_profits: false,
    integration_enable: false,
    product_placement_enable: false,
    pre_roll_enable: false,
    special_project_enable: false,
    post_enable: false,
    advanced_integration_enable: false,
    release_at: Date.now().toString(),
  });

  useEffect(() => {
    const fetchAdRequest = async (id: number) => {
      try {
        setLoading(true);
        setError(null);
        const result = await api.getSelfAdvertisementsRequest(id);
        setAdRequest(result.data.item);
      } catch (e) {
        const err = e as ApiRequestException;
        if (err.errorMessage) {
          setError(err.errorMessage);
        } else {
          setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
        }
      } finally {
        setLoading(false);
      }
    };

    if (mode === 'EDIT' && adRequestId !== null) {
      // noinspection JSIgnoredPromiseFromCall
      fetchAdRequest(adRequestId);
    }
    // noinspection JSIgnoredPromiseFromCall
    fetchSelectValues();
  }, []);

  const fetchSelectValues = async () => {
    try {
      const result = await selectApi.getYoutubeChannels();
      setChannels(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const updateAdRequest = (partOfAdRequest: Partial<IAdvertisementRequestDto>) => {
    setError(null);
    setValidationError(null);
    setAdRequest({...adRequest, ...partOfAdRequest});
  };

  const handleSaveClick = async () => {
    try {
      setLoadingSave(true);
      if (mode === 'EDIT' && !adRequestId) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('The ads request ID must be filled in when editing');
      }

      const result =
        mode === 'CREATE'
          ? await api.createAdvertisementsRequest(adRequest as IAdvertisementRequestDto)
          : await api.updateSelfAdvertisementsRequest(
              adRequestId as NonNullable<number>,
              adRequest as IAdvertisementRequestDto,
            );
      history.push(Routes.getSpecifyAdvertisementRequestRoute(result.data.item.id.toString()));
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoadingSave(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderBooleanDependencyField = (
    toggleKey: keyof IAdvertisementRequestDto,
    fieldKey: keyof IAdvertisementRequestDto,
    toggleLabel: string,
  ) => {
    return (
      <tr>
        <td className={'table-center vertical-align-middle'} style={{verticalAlign: 'middle'}}>
          <InputCheckbox
            className={'mb-0'}
            label={intl.formatMessage({id: toggleLabel})}
            value={adRequest[toggleKey] || false}
            onChange={() => updateAdRequest({[toggleKey]: !adRequest[toggleKey]})}
          />
        </td>
        <td className={'table-center'} style={{verticalAlign: 'middle', width: '80%'}}>
          <ValidateErrorWrapper className={'mb-0'} message={validationErrors && validationErrors[fieldKey]}>
            <InputText
              required
              disabled={adRequest[toggleKey] !== true}
              hasError={validationErrors != null && validationErrors[fieldKey] != undefined}
              type={'number'}
              classNames={`form-control mb-0`}
              value={(adRequest[fieldKey] as string) || ''}
              onChange={(e) => updateAdRequest({[fieldKey]: e.currentTarget.value})}
            />
          </ValidateErrorWrapper>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header flex-wrap'}>
          <div className={'card-title'}>
            <FormattedMessage id={mode === 'EDIT' ? 'EDIT' : 'CREATE_ADS_REQUEST'} />
          </div>
        </div>
        <div className={`card-body`}>
          <ValidateErrorWrapper
            className={'mb-5'}
            message={validationErrors && validationErrors['youtube_channel_id']}
          >
            <InputSelect
              required
              value={adRequest.youtube_channel_id}
              hasError={validationErrors?.youtube_channel_id != undefined}
              placeholder={intl.formatMessage({id: 'CHANNELS'})}
              label={intl.formatMessage({id: 'CHANNEL'})}
              options={channels.map((ch) => ({label: ch.title, value: ch.id}))}
              onChange={(channelId) => updateAdRequest({...adRequest, youtube_channel_id: channelId as string})}
            />
          </ValidateErrorWrapper>

          <div className={'d-flex flex-row w-100'}>
            <div style={{flex: 1}}>
              <ValidateErrorWrapper
                className={'mr-5'}
                message={validationErrors && validationErrors['expected_views_count']}
              >
                <InputText
                  required
                  classNames={'form-control'}
                  hasError={validationErrors?.expected_views_count != undefined}
                  label={intl.formatMessage({id: 'EXPECTED_VIEWS_COUNT'})}
                  type={'number'}
                  value={adRequest.expected_views_count}
                  onChange={(e) => updateAdRequest({expected_views_count: e.currentTarget.value})}
                />
              </ValidateErrorWrapper>
            </div>
            <div style={{flex: 1}}>
              <ValidateErrorWrapper
                className={'mb-5 flex-1'}
                message={validationErrors && validationErrors.release_at}
              >
                <InputDatepicker
                  required
                  hasError={validationErrors != null && validationErrors.release_at != undefined}
                  label={intl.formatMessage({id: 'RELEASE_DATE'})}
                  selected={tryGetDateOrNull(adRequest.release_at)}
                  onChange={(date) => updateAdRequest({release_at: prepareDate(date) as NonNullable<string>})}
                />
              </ValidateErrorWrapper>
            </div>
          </div>

          <div>
            <InputCheckbox
              label={intl.formatMessage({id: 'HAS_TAX_ON_PROFITS'})}
              value={adRequest['has_tax_on_profits'] || false}
              onChange={() => updateAdRequest({has_tax_on_profits: !adRequest['has_tax_on_profits']})}
            />
            <ValidateErrorWrapper className={'w-lg-50'} message={validationErrors && validationErrors['tax_value']}>
              <InputRange
                required
                tooltip={'auto'}
                value={adRequest.tax_value || 0}
                tooltipLabel={(v) => intl.formatNumber(v, {style: 'percent'})}
                label={`${intl.formatMessage({id: 'TAX'})}: ${intl.formatNumber(adRequest.tax_value || 0, {
                  style: 'percent',
                })}`}
                onChange={(e) => updateAdRequest({tax_value: e})}
              />
            </ValidateErrorWrapper>
          </div>
          <div className={'table-responsive'}>
            <table className={'table table-bordered rounded'}>
              <thead>
                <tr>
                  <th>{intl.formatMessage({id: 'TITLE'})}</th>
                  <th>{intl.formatMessage({id: 'PRICE_WITHOUT_TAX'}) + ' (RUB)'}</th>
                </tr>
              </thead>
              <tbody>
                {renderBooleanDependencyField('integration_enable', 'integration_price', 'INTEGRATION_ENABLE')}

                {renderBooleanDependencyField(
                  'advanced_integration_enable',
                  'advanced_integration_price',
                  'ADVANCED_INTEGRATION_ENABLE',
                )}

                {renderBooleanDependencyField('post_enable', 'post_price', 'POST_ENABLE')}

                {renderBooleanDependencyField('pre_roll_enable', 'pre_roll_price', 'PRE_ROLL_ENABLE')}

                {renderBooleanDependencyField(
                  'product_placement_enable',
                  'product_placement_price',
                  'PRODUCT_PLACEMENT_ENABLE',
                )}

                {renderBooleanDependencyField(
                  'special_project_enable',
                  'special_project_price',
                  'SPECIAL_PROJECT_ENABLE',
                )}
              </tbody>
            </table>
          </div>

          <ValidateErrorWrapper message={validationErrors && validationErrors.comment}>
            <InputTextArea
              required
              hasError={validationErrors?.comment !== undefined}
              style={{height: '100px'}}
              placeholder={intl.formatMessage({id: 'ADS_REQUEST_COMMENT_PLACEHOLDER'})}
              label={intl.formatMessage({id: 'COMMENT'})}
              value={adRequest.comment}
              className={'form-control'}
              onChange={(e) => updateAdRequest({comment: e.currentTarget.value})}
            />
          </ValidateErrorWrapper>
        </div>
        <div className={'card-footer'}>
          <button onClick={handleSaveClick} className={'btn btn-lg btn-primary mr-2'}>
            {loadingSave && <Spinner className={'mr-1'} size={'sm'} animation={'border'} />}
            <FormattedMessage id={'SAVE'} />
          </button>
        </div>
      </div>
    </>
  );
};
