import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ConnectChannelsApi} from '../../../api/connect-channel-api/connect-channels-api';
import {IConnectChannelRequestDto} from '../../../api/connect-channel-api/IConnectChannelRequestDto';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {FilterRequestStatus} from '../../../api/connect-channel-api/FilterRequestStatus';
import {Spinner} from 'react-bootstrap';
import {tryGetPreviewOrStub} from '../../../utils/utils';
import {Routes} from '../../../../configs/routes';
import {useUserRole} from '../../../hooks/use-user-role';
import {useModalConnectChannel} from '../../../components/modals/connect-youtube-channel/connect-youtube-channel-modal-context';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';

const ICONS = {
  YOUTUBE: require('../../../images/png/youtube_social_icon_red.png'),
};

export const ConnectYoutubeChannelWidget: React.FC = () => {
  const [isAdmin, user] = useUserRole();
  const api = new ConnectChannelsApi();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [visibleConnectRequests, setVisibleConnectRequests] = useState(false);
  const [connectRequests, setConnectRequests] = useState<Array<IConnectChannelRequestDto>>([]);
  const {showConnectYoutubeChannelModal} = useModalConnectChannel();

  useEffect(() => {
    fetchConnectChannelsRequests().then();
  }, []);

  const fetchConnectChannelsRequests = async () => {
    try {
      setInfo(null);
      setError(null);
      setLoading(true);
      const filters = isAdmin ? {user_id: user.id} : {};
      const result = await api.getSelfConnectChannelRequest({filters});
      setConnectRequests(result.data.items);
      return result.data.items;
    } catch (e) {
      setConnectRequests([]);
    } finally {
      setLoading(false);
    }

    return [];
  };

  const handleAddChannelClick = async () => {
    const closeModalResult = await showConnectYoutubeChannelModal();
    if (closeModalResult.reason === CloseModalReason.OK) {
      await fetchConnectChannelsRequests();
    }
  };

  const renderInfo = (connectRequest: IConnectChannelRequestDto) => {
    if (connectRequest === null) {
      return <></>;
    }

    const textStyle =
      connectRequest.requestStatus === FilterRequestStatus.REJECTED
        ? 'text-danger'
        : connectRequest.requestStatus === FilterRequestStatus.APPROVED
        ? 'text-success'
        : 'text-primary';

    return (
      <>
        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
            <span className={'font-weight-bolder font-size-sm'}>
              <FormattedMessage id={'TITLE'} />
            </span>
            <div className={'d-flex align-items-center'}>
              <span className={'font-size-h3'}>
                <a
                  href={Routes.getExternalYoutubeChannelRoute(connectRequest.channelId)}
                  target={'_blank'}
                  className='font-weight-bolder font-size-h3'
                  rel='noreferrer'>
                  {connectRequest?.title}
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
            <span className={'font-weight-bolder font-size-sm'}>
              <FormattedMessage id={'STATUS'} />
            </span>
            <div className={'d-flex align-items-center'}>
              <span className={'font-size-h3 ' + textStyle}>
                <FormattedMessage id={'FILTER_REQUEST_STATUS_' + connectRequest.requestStatus} />
              </span>
            </div>
          </div>
        </div>
        {connectRequest.rejectReason && (
          <div className={'d-flex align-items-center mr-5 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'REJECT_REASON'} />
              </span>
              <div className={'d-flex align-items-center'}>
                <span className={'font-size-h6'}>{connectRequest.rejectReason}</span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderConnectRequests = () => {
    if (!visibleConnectRequests) {
      return null;
    }

    return connectRequests.map(connectRequest => (
      <div key={connectRequest.id} className={`card card-custom gutter-b`}>
        <div className='card-body'>
          <div className={'d-flex flex-wrap'}>
            <div className='symbol symbol-100 symbol-xxl-150 mr-4'>
              <img
                src={tryGetPreviewOrStub(connectRequest?.thumbnailsDTO).url}
                alt='image'
                className={'w-100 rounded'}
              />
            </div>
            <div>{renderInfo(connectRequest)}</div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <AlertCustom
        dismissible
        visible={info != null}
        text={info}
        type={'light-info'}
        iconClassName={'svg-icon-info'}
        onClose={() => setInfo(null)}
      />

      <AlertCustom
        dismissible
        visible={error != null}
        text={error}
        type={'light-danger'}
        iconClassName={'svg-icon-danger'}
        onClose={() => setError(null)}
      />

      <div className='card card-custom gutter-b'>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <div className='d-flex flex-column mr-5'>
              <div className='h4 mb-1'>
                <span className='svg-icon svg-icon-6x svg-icon-light mr-2'>
                  <img width={74} className={'mr-3 mb-3'} src={ICONS.YOUTUBE} alt={'YouTube-logo'} />
                </span>
                <FormattedMessage id={'ADD_YOUTUBE_CHANNEL'} />
              </div>
              <p>
                <FormattedMessage id={'ADD_YOUTUBE_CHANNEL_DESCRIPTION'} />
              </p>
            </div>
            <div className='flex-shrink-0'>
              <button
                disabled={loading}
                onClick={handleAddChannelClick}
                className='btn font-weight-bolder text-uppercase btn-light-primary py-4 px-6'>
                <FormattedMessage id={'ADD'} />
                {loading && <Spinner className={'ml-1 mb-1'} size={'sm'} animation={'border'} />}
              </button>
              {connectRequests.length > 0 && (
                <button
                  disabled={loading}
                  onClick={() => setVisibleConnectRequests(!visibleConnectRequests)}
                  className='btn font-weight-bolder text-uppercase btn-light-danger py-4 px-6 ml-2'>
                  <FormattedMessage id={'REQUESTS'} /> ({connectRequests.length})
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {renderConnectRequests()}
    </>
  );
};
