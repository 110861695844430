import React from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage} from 'react-intl';
import {IFinancialReportPeriodDto} from '../../../api/financial-report-api/IFinancialReportPeriodDto';
import {FinancialReportsForm} from '../../../pages/financial-reports/financial-reports-form';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';

interface IModalProps {
  visible: boolean;
  loading: boolean;

  error?: string | null;
  blockedOperationMessage?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  loadingPeriods: boolean;
  loadingContracts: boolean;

  availableMonths: Array<IFinancialReportPeriodDto>;
  selectedMonths: Array<IFinancialReportPeriodDto>;

  availableContracts: Array<ISelectValueDto>;
  selectedContract: ISelectValueDto | null;

  onSelectContract: (contract: ISelectValueDto) => void;
  onSelectPeriod: (period: IFinancialReportPeriodDto) => void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalGenerateFinancialReport: React.FC<IModalProps> = ({
  loading,
  availableMonths,
  availableContracts,
  selectedContract,
  selectedMonths,
  loadingContracts,
  loadingPeriods,
  error,
  blockedOperationMessage,
  visible,
  validationErrors,
  onSelectContract,
  onSelectPeriod,
  onHide,
  onOkClick,
}) => {
  const handleOkClick = () => {
    onOkClick();
  };
  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading || loadingContracts || loadingPeriods || !!blockedOperationMessage}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'GENERATE_REPORT'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <FinancialReportsForm
            error={error}
            validationErrors={validationErrors}
            loadingAvailablePeriods={loadingPeriods}
            loadingAvailableContracts={loadingContracts}
            availableMonths={availableMonths}
            selectedMonths={selectedMonths}
            availableContracts={availableContracts}
            selectedContract={selectedContract}
            onSelectContract={onSelectContract}
            onSelectPeriod={onSelectPeriod}
          />
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
