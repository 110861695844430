import {useRef} from 'react';
import {useBaseEndpointApi} from '../use-base-endpoint-api';
import {EntityId} from '../../api/base/BaseEntity';
import config from '../../../configs/config';
import {BaseItemsResponse} from '../../api/base/base-crud-api-response';

export type Jurisdiction = {
  id: number;
  code: string;
  title: string;
};

export function useJurisdictionsApi() {
  const {current: params} = useRef({prefixUrl: 'jurisdictions', isAdmin: false});
  const api = useBaseEndpointApi();
  const get = async (id: EntityId) => {
    return await api.getFile(`${config.BASE_API_URL}/${params.prefixUrl}/${id}`);
  };

  const getAll = async () => {
    return await api.get<BaseItemsResponse<Jurisdiction>>(`${params.prefixUrl}`);
  };

  const select = async () => {
    return await api.get<BaseItemsResponse<Jurisdiction>>(`${params.prefixUrl}/select`);
  };

  return {
    get,
    getAll,
    select,
  };
}
