import React from 'react';
import {Card, CardBody, CardTitle, CardToolbar} from '../../../components/card';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {BadgeCustom} from '../../../components/badge-custom';
import {useIntl} from '../../../hooks/use-intl';
import {ContractStatus, IContractDto} from '../../../api/contract-api/IContractDto';
import {ValueWithLabel} from '../../base/value-with-label';

type Props = {
  isReferral?: boolean;
  contract: IContractDto;
};

export const ContractCard: React.FC<Props> = ({contract, isReferral = false}) => {
  const intl = useIntl();
  return (
    <Card hideFooter>
      <CardTitle>
        {intl.formatMessage({id: isReferral ? 'CONTRACT_REFERRAL' : 'CONTRACT'})}:{' '}
        <Link to={AdminRoutes.getSpecifyContractRoute(contract.id)}>№{contract.compositeNumber}</Link>
        <BadgeCustom
          className={'ml-2 font-size-sm'}
          variant={contract.status === ContractStatus.ACTIVE ? 'success' : 'danger'}>
          {intl.formatMessage({id: 'CONTRACT_STATUS_' + contract.status})}
        </BadgeCustom>
      </CardTitle>
      <CardToolbar>
        <div className={'d-flex justify-content-center align-items-center'}>
          <BadgeCustom className={'font-size-sm mr-2'} variant={'success'}>
            {intl.formatDate(contract?.startedAt, {month: 'long', year: 'numeric', day: 'numeric'})}
          </BadgeCustom>
          <BadgeCustom className={'font-size-sm'} variant={'danger'}>
            {contract?.expiresAt
              ? intl.formatDate(contract.expiresAt, {
                  month: 'long',
                  year: 'numeric',
                  day: 'numeric',
                })
              : intl.formatMessage({id: 'NOT_APPLICABLE'})}
          </BadgeCustom>
        </div>
      </CardToolbar>
      <CardBody>
        <ValueWithLabel
          title={intl.formatMessage({id: 'CONTRACTOR'})}
          value={
            <>
              <Link to={AdminRoutes.getSpecifyContractorRoute(contract.contractorId)}>{contract.contractorName}</Link>
              <Link to={AdminRoutes.getSpecifyUserManagementRoute(contract.userId)} className={'ml-2'}>
                ({intl.formatMessage({id: 'USER'})})
              </Link>
            </>
          }
        />
      </CardBody>
    </Card>
  );
};
