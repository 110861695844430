import React, {CSSProperties} from 'react';

interface WidgetProps {
  children: any;
  className?: string;
  style?: CSSProperties;

  onClick?(): void;
}

export const WidgetBase: React.FC<WidgetProps> = ({className, style, onClick, children}) => {
  return <>
    <div
      className={`widget card card-stretch card-custom gutter-b ${onClick ? 'cursor-pointer' : ''} ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  </>;
};
