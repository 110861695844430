import React, {createContext, useContext, useState} from 'react';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';

type BaseListPageContextProps = {
  appliedQueryParams: IQueryParams;
  updateAppliedQueryParams: (params: IQueryParams) => void;
};

// @ts-ignore
const BaseListPageContext = createContext<BaseListPageContextProps>();

export const BaseListPageProvider = ({children}: any) => {
  const [queryParams, setQueryParams] = useState<IQueryParams>({});
  return (
    <BaseListPageContext.Provider
      value={{
        appliedQueryParams: queryParams,
        updateAppliedQueryParams: setQueryParams,
      }}>
      {children}
    </BaseListPageContext.Provider>
  );
};

export const useBaseListPage = () => {
  return useContext(BaseListPageContext);
};
