import React, {useEffect, useMemo, useState} from 'react';
import {AccountApi} from '../../../../api/account-api';
import {FinancialAccountType, IFinancialAccountDto} from '../../../../api/DTOs/IFinancialAccountDto';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../../api/Paginator';
import {StringParam, withDefault} from 'use-query-params';
import {FormattedMessage, useIntl} from 'react-intl';
import {FinancialAccountsTable} from './financial-accounts-table';
import {SelectApi} from '../../../../api/select-api';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useLoading} from '../../../../hooks/use-loading';
import {Filter, FilterType} from '../../../../components/filters/filters';
import {FilterBuilder} from '../../../../components/filters/filter-builder';
import {SelectsValuesDTOs} from '../../../../components/Inputs/InputSelect';
import {BaseListPage} from '../../../base/base-list-page';
import {useBaseListPage} from '../../../base/base-list-page-context';
import {useTransactionActionHandler} from '../../transactions/useTransactionActionHandler';
import {FinancialAccountAction, useFinancialAccountActionHandler} from './useFinancialAccountsActionHandler';

const defaultSortOptions: ISortDto = {
  field: SortField.TRANSACTION_OPERATION_COUNT,
  direction: SortDirection.DESC,
};

const ICONS = {
  DOWNLOAD: require('../../../../images/svg/Download.svg'),
};

export const FinancialAccountsPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = new AccountApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
    download: false,
  });

  const [financialAccounts, setFinancialAccounts] = useState<Array<IFinancialAccountDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({});

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'financial_accounts_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'UPDATED_FIRST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'UPDATED_LAST'})}`,
          value: {field: SortField.UPDATED_AT, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'INNER_FIRST'})}`,
          value: {field: SortField.TYPE, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'CACHE_FIRST'})}`,
          value: {field: SortField.TYPE, direction: SortDirection.ASC},
        },
        {
          label: `${intl.formatMessage({id: 'MORE_TRANSACTION_FIRST'})}`,
          value: {field: SortField.TRANSACTION_OPERATION_COUNT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'LESS_TRANSACTION_FIRST'})}`,
          value: {field: SortField.TRANSACTION_OPERATION_COUNT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'type',
          placeholder: intl.formatMessage({id: 'ACCOUNT_TYPE'}),
          queryConfig: {
            name: 'type',
            type: withDefault(StringParam, FinancialAccountType.INNER, false),
            clearableDefaultValue: true,
          },
          options: [
            {
              value: FinancialAccountType.INNER,
              label: intl.formatMessage({id: 'INNER_ACCOUNT'}),
            },
            {
              value: FinancialAccountType.CACHE,
              label: intl.formatMessage({id: 'CACHE_ACCOUNT'}),
            },
            {
              value: FinancialAccountType.MC_PAY,
              label: intl.formatMessage({id: 'MC_PAY_ACCOUNT'}),
            },
          ],
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'y_channel_id',
          placeholder: intl.formatMessage({id: 'YOUTUBE_CHANNELS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);
  const {handleTransactionAction} = useTransactionActionHandler();
  const {appliedQueryParams} = useBaseListPage();
  const {handleFinancialAccountActions} = useFinancialAccountActionHandler();

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchFinancialAccounts = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getFinancialAccounts(filters);
      setFinancialAccounts(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        contractor_id: (await selectApi.getContractors()).data.items,
        y_channel_id: (await selectApi.getYoutubeChannels()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const handleDownloadReportClick = async () => {
    startLoading('download');
    await handleFinancialAccountActions({
      type: FinancialAccountAction.DOWNLOAD,
      data: {queryParams: appliedQueryParams},
    });
    stopLoading('download');
  };

  const renderFinancialAccounts = () => {
    if (financialAccounts.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <FinancialAccountsTable accounts={financialAccounts} onSelectAction={handleTransactionAction} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchFinancialAccounts}
      toolbarConfig={[
        {
          type: 'BUTTON',
          loading: loadings.download,
          title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
          icon: ICONS.DOWNLOAD,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: handleDownloadReportClick,
        },
      ]}>
      {renderFinancialAccounts()}
    </BaseListPage>
  );
};
