import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalCreateUser} from './modal-create-user';
import {UserType} from '../../../api/DTOs/IUserDto';
import {UsersApi} from '../../../api/user-api/users-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {toast} from 'react-toastify';
import {useJurisdictionsApi} from '../../../hooks/apis/use-jurisdictions-api';

interface IModalCreateUserContext {
  modalCreateUserVisible: boolean;

  showCreateUserModal(): Promise<CloseModalEvent<number>>;
}

// @ts-ignore
const ModalCreateUserContext = createContext<IModalCreateUserContext>();

let closeResolver: ((data: CloseModalEvent<number>) => any) | null = null;
export const ModalCreateUserProvider = ({children}: any) => {
  const intl = useIntl();

  const jurisdictionsApi = useJurisdictionsApi();
  const api = new UsersApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [isAdmin, setAdminMode] = useState<boolean>(false);
  const [jurisdiction_id, setJurisdiction_id] = useState<string | number | null>(null);
  const [jurisdictions, setJurisdictions] = useState<any>([]);

  const showModal = async () => {
    setVisible(true);
    return new Promise<CloseModalEvent<number>>(resolve => {
      closeResolver = resolve;
    });
  };

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchSelectValues = useCallback(async () => {
    try {
      const res = (await jurisdictionsApi.select())?.data?.items ?? [];
      setJurisdictions(res.map(r => ({label: r.title, value: r.id})));
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  const handleChangeEmail = (email: string) => {
    setEmail(email);
  };

  const handleHideModal = () => {
    setVisible(false);
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
    setAdminMode(false);
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      const result = await api.createUser(
        email,
        password,
        passwordConfirmation,
        isAdmin ? UserType.ADMIN : UserType.USER,
        jurisdiction_id,
      );
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: result.data.item.id});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalCreateUserContext = {
    modalCreateUserVisible: visible,
    showCreateUserModal: showModal,
  };

  return (
    <ModalCreateUserContext.Provider value={value}>
      {children}
      <ModalCreateUser
        visible={visible}
        email={email}
        password={password}
        password_confirmation={passwordConfirmation}
        type={isAdmin ? UserType.ADMIN : UserType.USER}
        jurisdiction_id={jurisdiction_id}
        jurisdictions={jurisdictions}
        error={error}
        validationErrors={validationErrors}
        onJurisdictionChange={setJurisdiction_id}
        onEmailChange={handleChangeEmail}
        onPasswordChange={password => setPassword(password)}
        onPasswordConfirmationChange={password => setPasswordConfirmation(password)}
        onUserTypeChange={type => setAdminMode(type === UserType.ADMIN)}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalCreateUserContext.Provider>
  );
};

export const useModalCreateUser = () => {
  return useContext(ModalCreateUserContext);
};
