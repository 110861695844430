import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {IContractorDto} from '../../../../api/contractor-api/IContractorDto';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {AdminRoutes} from '../../../../../configs/routes';
import {Svg} from '../../../../images/svg';
import {ICONS} from '../../../../images/images';
import {TableUtils} from '../../../../components/table-utils';
import {BootstrapColor, BootstrapSizes} from '../../../../styles/styles';

interface IContractorsTableProps {
  contractors: Array<IContractorDto>;
}

export const ContractorsTable = ({contractors}: IContractorsTableProps) => {
  const intl = useIntl();
  const data = contractors;

  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: intl.formatMessage({id: 'ID'}),
        maxWidth: 35,
        minWidth: 30,
        accessor: (br: IContractorDto) => br,
        Cell: ({value}: {value: IContractorDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractorRoute(value.id)}>{value.id}</Link>,
          );
        },
      },
      {
        id: 'Name',
        Header: intl.formatMessage({id: 'NAME'}),
        maxWidth: 500,
        minWidth: 350,
        accessor: (br: IContractorDto) => br,
        Cell: ({value}: {value: IContractorDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractorRoute(value.id)}>{value.name}</Link>,
          );
        },
      },
      {
        id: 'Jurisdiction',
        Header: intl.formatMessage({id: 'JURISDICTION'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: IContractorDto) => br,
        Cell: ({value}: {value: IContractorDto}) => {
          return TableUtils.renderBaseContent(value.jurisdictionTitle ?? <span className={'ml-2'}>—</span>);
        },
      },
      {
        id: 'PaymentRequests',
        Header: intl.formatMessage({id: 'PAYOUTS'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IContractorDto) => br,
        Cell: ({value}: {value: IContractorDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {value.hasActivePaymentRequest ? (
                <Link to={AdminRoutes.getPaymentRequestsRoute({user_id: value.userId})}>
                  <Svg src={ICONS.DOLLAR} color={BootstrapColor.SUCCESS} size={BootstrapSizes.X2} />
                </Link>
              ) : (
                <span className={'ml-2'}>—</span>
              )}
            </>,
          );
        },
      },
      {
        id: 'Created_At',
        Header: intl.formatMessage({id: 'CREATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IContractorDto) => br,
        Cell: ({value}: {value: IContractorDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.createdAt)} {intl.formatTime(value.createdAt)}
            </>,
          );
        },
      },
      {
        id: 'Updated_at',
        Header: intl.formatMessage({id: 'UPDATED_AT'}),
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: IContractorDto) => br,
        Cell: ({value}: {value: IContractorDto}) => {
          return TableUtils.renderBaseContent(
            <>
              {intl.formatDate(value.updatedAt)} {intl.formatTime(value.updatedAt)}
            </>,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
