import {Reducer} from 'redux';
import {IUserDto} from '../../../api/DTOs/IUserDto';

export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';

export type AuthState = {
  user: IUserDto | null;
}

export interface IBaseAction {
  type: string;
}

export type ILogoutAction = IBaseAction

export function LogoutAction(): ILogoutAction {
  return {
    type: LOGOUT,
  };
}

export interface ISetUserAction extends IBaseAction {
  user: IUserDto | null,
}

export function SetUserAction(user: IUserDto): ISetUserAction {
  return {
    type: SET_USER,
    user,
  };
}

const initialAuthState: AuthState = {
  user: null,
};

export const AuthReducer: Reducer<AuthState,
  | ILogoutAction
  | ISetUserAction> = (state = initialAuthState,
                       action: ISetUserAction
                         | ILogoutAction) => {
  switch (action.type) {

    case LOGOUT: {
      // TODO: Change this code. Actions in reducer aren't allowed.
      return initialAuthState;
    }

    case SET_USER: {
      const {user} = action as ISetUserAction;
      return {...state, user};
    }

    default:
      return state;
  }
};
