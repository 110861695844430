import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {EditRequisitesForm} from './edit-requisites-form';
import {PartialNullable} from '../../../../types';
import {PaymentRequisites} from '../../../api/payment-requisite-api/payment-requisites-api';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {IUserDto} from '../../../api/DTOs/IUserDto';

interface IModalCreateContractProps {
  visible: boolean;
  user: IUserDto;
  fields: PartialNullable<PaymentRequisites>;
  onUpdate: UpdateFields<PaymentRequisites>;
  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  onHide(): void;

  onOkClick(): any;
}

export const ModalEditPaymentRequisites: React.FC<IModalCreateContractProps> = ({
  visible,
  fields,
  user,
  error,
  validationErrors,
  onUpdate,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        successButtonTitle={intl.formatMessage({id: 'EDIT'})}
        disabledButtons={loading}
        onSuccess={handleOkClick}
        onHide={onHide}
        size={'xl'}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'EDIT_PAYMENT_REQUISITES'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />

          <AlertCustom
            visible
            text={intl.formatMessage({id: 'NOTICE_ACCRUALS_IN_USD'})}
            type={'light-info'}
            iconClassName={'svg-icon-info'}
          />
          <EditRequisitesForm user={user} fields={fields} validationErrors={validationErrors} onChange={onUpdate} />
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
