import React from 'react';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {InputText} from '../../Inputs/InputText';
import {InputTextArea} from '../../Inputs/InputTextArea';
import {PaymentRequisites} from '../../../api/payment-requisite-api/payment-requisites-api';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {useIntl} from '../../../hooks/use-intl';
import {PartialNullable} from '../../../../types';
import {IUserDto} from '../../../api/DTOs/IUserDto';
import cn from 'classnames';

type Props = {
  user: IUserDto;
  fields: PartialNullable<PaymentRequisites>;
  validationErrors: ValidationErrorsType;
  onChange: UpdateFields<PartialNullable<PaymentRequisites>>;
};

export const EditRequisitesForm: React.FC<Props> = ({fields, user, onChange, validationErrors}) => {
  const intl = useIntl();
  const renderRuFields = () => {
    return (
      <div className="col-6">
        <ValidateErrorWrapper message={getValidationErrorMessage('ru_beneficiary_name', validationErrors)}>
          <InputText
            required
            value={fields.ru_beneficiary_name}
            hasError={!!getValidationErrorMessage('ru_beneficiary_name', validationErrors)}
            label={intl.formatMessage({id: 'NAME'}) + ' (ru)'}
            classNames={'form-control'}
            onChange={e => onChange({ru_beneficiary_name: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>

        <ValidateErrorWrapper message={getValidationErrorMessage('ru_beneficiary_address', validationErrors)}>
          <InputTextArea
            required
            hasError={!!getValidationErrorMessage('ru_beneficiary_address', validationErrors)}
            value={fields.ru_beneficiary_address}
            label={intl.formatMessage({id: 'ADDRESS'}) + ' (ru)'}
            className={'form-control'}
            onChange={e => onChange({ru_beneficiary_address: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>
      </div>
    );
  };

  const renderEnFields = () => {
    return (
      <div
        className={cn({
          'col-12': !user.isJurisdictionWithTwiceLanguageContract,
          'col-6': user.isJurisdictionWithTwiceLanguageContract,
        })}>
        <ValidateErrorWrapper message={getValidationErrorMessage('en_beneficiary_name', validationErrors)}>
          <InputText
            required
            value={fields.en_beneficiary_name}
            hasError={!!getValidationErrorMessage('en_beneficiary_name', validationErrors)}
            label={intl.formatMessage({id: 'NAME'}) + ' (en)'}
            classNames={'form-control'}
            onChange={e => onChange({en_beneficiary_name: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>

        <ValidateErrorWrapper message={getValidationErrorMessage('en_beneficiary_address', validationErrors)}>
          <InputTextArea
            required
            hasError={!!getValidationErrorMessage('en_beneficiary_address', validationErrors)}
            value={fields.en_beneficiary_address}
            label={intl.formatMessage({id: 'ADDRESS'}) + ' (en)'}
            className={'form-control'}
            onChange={e => onChange({en_beneficiary_address: e.currentTarget.value})}
          />
        </ValidateErrorWrapper>
      </div>
    );
  };

  return (
    <>
      <form className={'form'}>
        <div className={'row'}>
          {user.isJurisdictionWithTwiceLanguageContract && renderRuFields()}
          {renderEnFields()}
        </div>

        <div className={'row'}>
          <div className="form-group col-12">
            <ValidateErrorWrapper
              message={getValidationErrorMessage('beneficiary_bank_swift_number', validationErrors)}>
              <InputText
                required
                hasError={!!getValidationErrorMessage('beneficiary_bank_swift_number', validationErrors)}
                value={fields.beneficiary_bank_swift_number}
                label={intl.formatMessage({id: 'SWIFT_NUMBER'})}
                classNames={'form-control'}
                onChange={e => onChange({beneficiary_bank_swift_number: e.currentTarget.value})}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('beneficiary_account_number', validationErrors)}>
              <InputText
                required
                hasError={!!getValidationErrorMessage('beneficiary_account_number', validationErrors)}
                value={fields.beneficiary_account_number}
                label={intl.formatMessage({id: 'ACCOUNT_NUMBER'})}
                classNames={'form-control'}
                onChange={e => onChange({beneficiary_account_number: e.currentTarget.value})}
              />
            </ValidateErrorWrapper>
          </div>
        </div>
      </form>
    </>
  );
};
