import React, {lazy, Suspense, useMemo} from 'react';
import {Redirect, Switch} from 'react-router-dom';
import {ContentRoute, LayoutSplashScreen, MetronicSubheaderProvider} from '../_metronic/layout';
import {YoutubeChannelsPage} from './pages/youtube-channel/channels-page/youtube-channels-page';
import {YoutubeChannelPage} from './pages/youtube-channel/channel-page/youtube-channel-page';
import {YoutubeVideosPage} from './pages/youtube-video/videos-page/youtube-videos-page';
import {YoutubeVideoPage} from './pages/youtube-video/video-page/youtube-video-page';
import {ProfilePage} from './pages/profile/profile-page';
import {CommonRevenuesPage} from './pages/revenues/common-revenues-page';
import 'react-toastify/dist/ReactToastify.min.css';
import {ModalsProvider} from './components/modals/base-modal/modals-provider';
import {FinancialReportsPage} from './pages/financial-reports/financial-reports-page';
import {DashboardPage} from './pages/dashboard/dashboard-page';
import {FinancialAccountPage} from './pages/admin/financial-accounts/financial-account/financial-account-page';
import {InstructionsPage} from './pages/admin/instructions/instructions-page';
import {TicketsPages} from './pages/support/tickets-pages';
import {TicketCardPage} from './pages/support/ticket-card-page';
import ErrorBoundary from './error-boundary';
import {AdminRoutes, Routes} from '../configs/routes';
import {CreateOrEditAdvertisementRequest} from './pages/advertisements/create-or-edit-advertisement-request';
import {AdvertisementPage} from './pages/advertisements/advertisement-page';
import {AdvertisementsPage} from './pages/advertisements/advertisements-page';
import {AdminRoute} from './admin-route';
import {DashboardAdminPage} from './pages/admin/dashboard/dashboard-admin-page';
import {TransactionsPage} from './pages/admin/transactions/transactions-page';
import {NotFoundPage} from './pages/not-found-page';
import {UserActionsPage} from './pages/admin/user-actions/user-actions-page';
import {YoutubeFinancialReportsPage} from './pages/admin/youtube-financial-reports/youtube-financial-reports-page';
import {ToastContainer} from 'react-toastify';
import {ContractorsPage} from './pages/admin/contractors/contractors-page/contractors-page';
import {YoutubeIntegrationPage} from './pages/admin/youtube-integration/youtube-integration-page';
import {UserManagementPage} from './pages/admin/user-management/user-management-page';
import {PaymentRequestPage} from './pages/admin/payment-requests/payment-request-page';
import {UserPage} from './pages/admin/user-management/user-page';
import {ContractsPage} from './pages/admin/contracts/contracts-page/contracts-page';
import {ConnectChannelRequestsPage} from './pages/admin/connect-channel-requests/connect-channel-requests-page';
import {ContractPage} from './pages/admin/contracts/contract-page/contract-page';
import {PaymentRequestsPage} from './pages/admin/payment-requests/payment-requests-page';
import {ContractorPage} from './pages/admin/contractors/contractor-page/contractor-page';
import {TransactionPage} from './pages/admin/transactions/transaction-page';
import {FinancialAccountsPage} from './pages/admin/financial-accounts/financial-accounts/financial-accounts-page';
import {ConnectChannelRequestPage} from './pages/admin/connect-channel-requests/connect-channel-request-page';
import {MailingsPage} from './pages/admin/mailings/mailings-page';
import {MailingPage} from './pages/admin/mailings/mailing-page';
import {BaseListPageProvider} from './pages/base/base-list-page-context';
import {KnowledgeBasePage} from './pages/admin/knowledge-base/knowledge-base-page';
import {ApplicationSettingsPage} from './pages/admin/application-settings/application-settings-page';
import {DownloadsPage} from './pages/downloads/downloads-page';
import {useUserRole} from './hooks/use-user-role';
import {MediaCubeOperationsConfirmationPage} from './pages/admin/media-cube-transaction-states/media-cube-operations-confirmation-page';
import {MediaCubeOperationsConfirmationsPage} from './pages/admin/media-cube-transaction-states/media-cube-operations-confirmations-page';
import {CmsRevenueAdminPage} from './pages/admin/cms-revenue/cms-revenue-admin-page';

const CreateOrEditMailingPage = lazy(() => import('./pages/admin/mailings/create-or-edit-mailing-page'));

export default function BasePage() {
  const [isAdmin, user] = useUserRole();

  const redirectPath = useMemo(() => {
    if (!user.hasActiveChannel && !isAdmin) {
      return Routes.getProfileRoute();
    } else if (isAdmin) {
      return AdminRoutes.getDashboardRoute();
    } else {
      return Routes.getDashboardRoute();
    }
  }, [isAdmin]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <MetronicSubheaderProvider>
        <ModalsProvider>
          <ErrorBoundary>
            <BaseListPageProvider>
              <Switch>
                <Redirect exact from='/' to={redirectPath} />
                <ContentRoute
                  path={Routes.getYoutubeSpecifyChannelRoute(':channelId')}
                  component={YoutubeChannelPage}
                />
                <ContentRoute path={Routes.getYoutubeChannelsRoute()} component={YoutubeChannelsPage} />
                <ContentRoute path={Routes.getYoutubeSpecifyVideoRoute(':videoId')} component={YoutubeVideoPage} />
                <ContentRoute path={Routes.getYoutubeVideosRoute()} component={YoutubeVideosPage} />
                <ContentRoute path={Routes.getProfileRoute()} component={ProfilePage} />
                <ContentRoute path={Routes.getRevenuesPageRoute()} component={CommonRevenuesPage} />
                <ContentRoute path={Routes.getFinancialReportsRoute()} component={FinancialReportsPage} />
                <ContentRoute path={Routes.getDashboardRoute()} component={DashboardPage} />
                <ContentRoute path={Routes.getSupportTicketRoute(':id')} component={TicketCardPage} />
                <ContentRoute path={Routes.getSupportRoute()} render={() => <TicketsPages isAdmin={false} />} />
                <ContentRoute
                  path={Routes.getCreateAdvertisementRequestRoute()}
                  render={() => <CreateOrEditAdvertisementRequest mode={'CREATE'} />}
                />
                <ContentRoute
                  path={Routes.getEditAdvertisementRequestRoute(':id')}
                  render={() => <CreateOrEditAdvertisementRequest mode={'EDIT'} />}
                />
                <ContentRoute path={Routes.getSpecifyAdvertisementRequestRoute(':id')} component={AdvertisementPage} />
                <ContentRoute
                  path={Routes.getAdvertisementsRoute()}
                  render={() => <AdvertisementsPage isAdmin={false} />}
                />
                <ContentRoute path={Routes.getKnowledgeBaseRoute()} component={KnowledgeBasePage} />
                <AdminRoute
                  path={AdminRoutes.getSpecifyAdvertisementRequestRoute(':id')}
                  render={() => <AdvertisementPage isAdmin />}
                />
                <AdminRoute path={AdminRoutes.getAdvertisementRoute()} render={() => <AdvertisementsPage isAdmin />} />
                <AdminRoute path={AdminRoutes.getDashboardRoute()} component={DashboardAdminPage} />
                <AdminRoute path={AdminRoutes.getCMSRevenueRoute()} component={CmsRevenueAdminPage} />
                <AdminRoute path={AdminRoutes.getSpecifyContractRoute(':id')} component={ContractPage} />
                <AdminRoute path={AdminRoutes.getContractsRoute()} component={ContractsPage} />
                <AdminRoute path={AdminRoutes.getSpecifyContractorRoute(':id')} component={ContractorPage} />
                <AdminRoute path={AdminRoutes.getContractorsRoute()} component={ContractorsPage} />
                <AdminRoute path={AdminRoutes.getYoutubeIntegrationRoute()} component={YoutubeIntegrationPage} />
                <AdminRoute
                  path={AdminRoutes.getYoutubeFinancialReportsRoute()}
                  component={YoutubeFinancialReportsPage}
                />
                <AdminRoute path={AdminRoutes.getSpecifyUserManagementRoute(':id')} component={UserPage} />
                <AdminRoute path={AdminRoutes.getUserManagementRoute()} component={UserManagementPage} />
                <AdminRoute path={AdminRoutes.getUsersActionsRoute()} component={UserActionsPage} />
                <AdminRoute path={AdminRoutes.getSpecifyTransactionRoute(':id')} component={TransactionPage} />
                <AdminRoute path={AdminRoutes.getTransactionsRoute()} component={TransactionsPage} />
                <AdminRoute
                  path={AdminRoutes.getSpecifyFinancialAccountsRoute(':id')}
                  component={FinancialAccountPage}
                />
                <AdminRoute path={AdminRoutes.getFinancialAccountsRoute()} component={FinancialAccountsPage} />
                <AdminRoute path={AdminRoutes.getSpecifyPaymentRequestRoute(':id')} component={PaymentRequestPage} />
                <AdminRoute path={AdminRoutes.getPaymentRequestsRoute()} component={PaymentRequestsPage} />
                <AdminRoute
                  path={AdminRoutes.getSpecifyConnectChannelRequestRoute(':id')}
                  component={ConnectChannelRequestPage}
                />
                <AdminRoute
                  path={AdminRoutes.getConnectChannelRequestsRoute()}
                  component={ConnectChannelRequestsPage}
                />
                <AdminRoute path={AdminRoutes.getCreateMailingRoute()} component={CreateOrEditMailingPage} />
                <AdminRoute
                  path={AdminRoutes.getEditMailingRoute(':id')}
                  render={() => <CreateOrEditMailingPage mode={'edit'} />}
                />

                <AdminRoute
                  path={AdminRoutes.getSpecifyMediaCubeConfirmationRoute(':id')}
                  component={MediaCubeOperationsConfirmationPage}
                />
                <AdminRoute
                  path={AdminRoutes.getMediaCubeConfirmationRoute()}
                  component={MediaCubeOperationsConfirmationsPage}
                />

                <AdminRoute path={AdminRoutes.getSpecifyMailingRoute(':id')} component={MailingPage} />
                <AdminRoute path={AdminRoutes.getMailingsRoute()} component={MailingsPage} />
                <AdminRoute path={AdminRoutes.getApplicationSettingsRoute()} component={ApplicationSettingsPage} />
                <AdminRoute path={AdminRoutes.getInstructionsRoute()} component={InstructionsPage} />
                <AdminRoute path={AdminRoutes.getSupportTicketRoute(':id')} render={() => <TicketCardPage isAdmin />} />
                <AdminRoute path={AdminRoutes.getSupportRoute()} render={() => <TicketsPages isAdmin />} />
                <ContentRoute path={Routes.getDownloadRoute(':id?')} component={DownloadsPage} />
                <ContentRoute path={Routes.getNotFoundRoute()} component={NotFoundPage} />
                <Redirect to={Routes.getNotFoundRoute()} />
              </Switch>
              <ToastContainer />
            </BaseListPageProvider>
          </ErrorBoundary>
        </ModalsProvider>
      </MetronicSubheaderProvider>
    </Suspense>
  );
}
