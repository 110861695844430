import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './InputDatepicker.scss';
import {useIntl} from 'react-intl';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import cn from 'classnames';
import moment, {isDate} from 'moment';
import {AppLocale} from '../../../localization';
import * as Popper from '@popperjs/core';

registerLocale('en', en);
registerLocale('ru', ru);

export enum DateFormat {
  DD_MM_YYYY = 'DD_MM_YYYY',
  MM_YYYY = 'MM_YYYY',
  YYYY = 'YYYY',
}

export const DateLocalizedFormats: Record<DateFormat, Record<AppLocale, string>> = {
  [DateFormat.DD_MM_YYYY]: {ru: 'DD.MM.yyyy', en: 'MM/DD/yyyy'},
  [DateFormat.MM_YYYY]: {ru: 'MM.yyyy', en: 'MM/yyyy'},
  [DateFormat.YYYY]: {ru: 'yyyy', en: 'yyyy'},
};

interface IInputDatePickerProps {
  selected?: Date | null;
  startDate?: Date | null;
  selectsStart?: boolean;
  endDate?: Date | null;
  selectsEnd?: boolean;
  selectsRange?: boolean;
  value?: Date | null | string;
  className?: string;
  label?: string;
  descriptionLabel?: string;
  required?: boolean;
  hasError?: boolean;
  format?: DateFormat;
  placeholder?: string;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  dropdownMode?: 'select' | 'scroll';
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  showMonthYearDropdown?: boolean;
  calendarStartDay?: number;
  disabled?: boolean;
  clearable?: boolean;
  popperPlacement?: Popper.Placement;

  onChange(date: Date | null): void;
}

export const InputDatepicker: React.FC<IInputDatePickerProps> = ({
  value,
  required,
  selectsStart,
  startDate,
  endDate,
  selectsEnd,
  selectsRange,
  disabled,
  label,
  hasError,
  className,
  showMonthYearPicker,
  showYearPicker,
  calendarStartDay,
  showYearDropdown,
  showMonthYearDropdown,
  placeholder,
  popperPlacement,
  dropdownMode,
  showMonthDropdown,
  clearable,
  format,
  onChange,
  selected,
}) => {
  const intl = useIntl();

  const handleChange = (date: Date | null) => {
    onChange(date);
  };

  const dateFormat =
    format !== undefined
      ? DateLocalizedFormats[format][intl.locale as AppLocale]
      : intl.locale === 'ru'
      ? 'dd.MM.yyyy'
      : undefined;

  return (
    <div className={className}>
      {label && (
        <label id='aria-label' htmlFor='aria-example-input' className={'font-weight-bolder'}>
          {label} {required && <span className={'text-danger'}>*</span>}
        </label>
      )}
      <DatePicker
        isClearable={clearable}
        locale={intl.locale}
        selectsRange={selectsRange}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        showPopperArrow={false}
        selected={
          selected
            ? selected
            : isDate(value)
            ? value
            : value !== null && value !== undefined
            ? moment(value).toDate()
            : null
        }
        disabled={disabled}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        className={cn('form-control w-100', {
          'has-error': hasError,
        })}
        dropdownMode={dropdownMode}
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        popperPlacement={popperPlacement}
        showMonthYearDropdown={showMonthYearDropdown}
        showYearPicker={showYearPicker}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showMonthYearPicker}
        calendarStartDay={calendarStartDay}
        onChange={handleChange}
      />
    </div>
  );
};
