import React, {useEffect, useState} from 'react';
import './cookie-confirmation.scss';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';

const COOKIE_AGREE_KEY = 'cookie_agree';

export const CookieConfirmation: React.FC = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const hasAgree = localStorage.getItem(COOKIE_AGREE_KEY) === 'true';
    if (!hasAgree) {
      setVisible(true);
    }
  }, []);

  const handleAgreeClick = () => {
    localStorage.setItem(COOKIE_AGREE_KEY, 'true');
    setVisible(false);
  };

  return <div className={cn('cookie-confirmation', {show: visible})}>
    <div className={'w-100 d-flex align-items-center justify-content-center flex-wrap py-5 font-weight-bold'}>
      <div className={'mr-5 notice'}>
        <FormattedMessage id={'COOKIE_NOTICE'} />
      </div>
      <button onClick={handleAgreeClick} className={'btn btn-outline-primary'}>
        <FormattedMessage id={'I_AGREE'} />
      </button>
    </div>
  </div>;
};
