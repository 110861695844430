import {shallowEqual, useSelector} from 'react-redux';
import {IApplicationStore} from '../../redux/rootReducer';
import {IUserDto, UserType} from '../api/DTOs/IUserDto';

export function useUserRole(): [isAdmin: boolean, user: IUserDto] {
  const user = useSelector<IApplicationStore, IUserDto>((state) => {
    return state.auth.user as NonNullable<IUserDto>;
  }, shallowEqual);

  return [user.type === UserType.ADMIN, user];
}
