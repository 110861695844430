import React, {PropsWithChildren, useEffect, useMemo} from 'react';
import {FiltersSortsPanel} from '../../components/filters/filters-sorts-panel';
import {AggregationValues, PaginationComponent} from '../../components/pagination';
import {IPaginationInfo} from '../../api/Paginator';
import {Filter, FilterType} from '../../components/filters/filters';
import {NumberParam, withDefault} from 'use-query-params';
import {useFilters} from '../../components/filters/use-query-params-filters';
import {Toolbar, ToolBarItem} from '../../components/card-toolbar/Toolbar';
import {IQueryParams} from '../../api/DTOs/IFilterDtos';
import {useDeepCompareEffect} from '../../hooks/use-deep-compare-effect';
import {Preloader} from '../../components/preloader';
import {useBaseListPage} from './base-list-page-context';
import {isEmpty} from '../../utils/utils';
import {useTitle} from '../../hooks/use-title';
import cn from 'classnames';

type Props = {
  browserTitle?: string;
  pageTitle?: string;
  loading?: boolean;
  showMarketing?: boolean;
  renderMarketing?: () => JSX.Element;
  pageKey?: string;
  className?: {
    card?: string;
    cardHeader?: string;
    cardBody?: string;
    cardFooter?: string;
  };
  filtersConfig?: Array<Filter>;
  sortsConfig?: Array<Filter>;
  nonVisualFiltersConfig?: Array<Filter>;

  pagination?: {
    info: IPaginationInfo | null;
    aggregationValues?: AggregationValues;
  };
  toolbarConfig?: Array<ToolBarItem>;
  fetchData?: (params: IQueryParams) => Promise<any>;
};

export const BaseListPage: React.FC<PropsWithChildren<Props>> = ({
  browserTitle,
  children,
  filtersConfig,
  pageKey,
  className,
  nonVisualFiltersConfig,
  pageTitle,
  pagination,
  sortsConfig,
  toolbarConfig,
  loading,
  fetchData,
  renderMarketing,
  showMarketing,
}) => {
  useTitle(browserTitle);

  const {updateAppliedQueryParams} = useBaseListPage();
  const nonVisualFilterConfig = useMemo<Array<Filter>>((): any[] => {
    const concatValue: Filter[] = nonVisualFiltersConfig ?? [];
    return (
      [
        {
          type: FilterType.VIRTUAL,
          name: pageKey ?? 'page',
          queryConfig: {
            name: pageKey ?? 'page',
            type: withDefault(NumberParam, 1),
          },
        },
      ] as Filter[]
    ).concat(concatValue);
  }, [nonVisualFiltersConfig]);
  const {filtersQueriesNameValueMap, sortsQueriesNameValueMap, applyQueryParamsDto, updateAllFilters} = useFilters(
    filtersConfig || [],
    sortsConfig || [],
    nonVisualFilterConfig,
  );

  useDeepCompareEffect(() => {
    updateAppliedQueryParams(applyQueryParamsDto);
  }, [applyQueryParamsDto]);

  useDeepCompareEffect(
    () => {
      if (fetchData) {
        fetchData({...applyQueryParamsDto, [pageKey ?? 'page']: 1}).then();
      }
      updateAllFilters(pageKey ?? 'page', 1);
    },
    [applyQueryParamsDto.filters, applyQueryParamsDto.sort],
    true,
  );

  useEffect(() => {
    if (fetchData) {
      fetchData(applyQueryParamsDto).then();
    }
    // @ts-ignore
  }, [applyQueryParamsDto[pageKey ?? 'page'] as any]);

  useEffect(() => {
    return () => updateAppliedQueryParams({});
  }, []);

  const filtersNotApplied = isEmpty(applyQueryParamsDto.filters);
  if (renderMarketing && filtersNotApplied && showMarketing && loading) {
    return <Preloader />;
  }

  const renderTitleOrFilters = () => {
    return (
      <div className={'card-title'}>
        {pageTitle && <div className={'mr-4'}>{pageTitle}</div>}
        <FiltersSortsPanel
          filtersConfig={filtersConfig}
          sortsConfig={sortsConfig}
          filtersQueriesNameValueMap={filtersQueriesNameValueMap}
          sortsQueriesNameValueMap={sortsQueriesNameValueMap}
          onUpdate={updateAllFilters}
        />
      </div>
    );
  };

  const renderToolbar = () => {
    if (!toolbarConfig) {
      return null;
    }
    return <Toolbar className={'card-toolbar'} items={toolbarConfig} />;
  };

  const renderBody = () => {
    return loading ? <Preloader /> : children;
  };

  const renderPagination = () => {
    if (!pagination || !pagination.info) {
      return null;
    }

    return (
      <PaginationComponent
        threshold={4}
        paginator={pagination.info}
        aggregationValues={pagination.aggregationValues}
        onChange={pageNumber => {
          if (pageNumber == pagination?.info?.currentPageNumber || isNaN(pageNumber)) {
            return;
          }
          updateAllFilters(pageKey ?? 'page', pageNumber);
        }}
      />
    );
  };

  return (
    <>
      {renderMarketing && filtersNotApplied && showMarketing ? (
        renderMarketing()
      ) : (
        <div className={cn('card card-custom gutter-b', className?.card)}>
          <div className={cn('card-header', className?.cardHeader)} style={{flexWrap: 'nowrap'}}>
            {renderTitleOrFilters()}
            {renderToolbar()}
          </div>
          <div className={cn('card-body', className?.cardBody)}>{renderBody()}</div>
          <div className={cn('card-footer', className?.cardFooter)}>{renderPagination()}</div>
        </div>
      )}
    </>
  );
};
