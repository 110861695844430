import React from 'react';

interface InputCustomProps {
  message: string | Array<string> | null;
  className?: string;
  children?: any;
}

export const ValidateErrorWrapper: React.FC<InputCustomProps> = ({className, message, children}) => {
  const renderUnderlineMessage = () => {
    if (!message || message.length === 0) return null;

    if (Array.isArray(message) && message.length !== 1) {
      return (
        <ul className='fv-plugins-message-container pl-5'>
          {message.map((m, index) => {
            return (
              <li key={index} className='fv-help-block'>
                {m}
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <div className={'fv-plugins-message-container ' + className}>
        <div className='fv-help-block'>{Array.isArray(message) ? message[0] : message}</div>
      </div>
    );
  };

  return (
    <div className={'form-group fv-plugins-icon-container ' + className}>
      {children && children}
      {renderUnderlineMessage()}
    </div>
  );
};
