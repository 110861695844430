import React, {createContext, useContext, useState} from 'react';
import {ModalCreateContract} from './modal-create-contract';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {SelectApi} from '../../../api/select-api';
import {ContractsApi} from '../../../api/contract-api/contracts-api';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {prepareDate} from '../../../utils/utils';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {IValidationException} from '../../../api/exceptions/IValidationException';

interface IModalCreateContractContextValues {
  modalCreateContractVisible: boolean;

  showCreateContractModal(): Promise<CloseModalEvent<number>>;
}

// @ts-ignore
const ModalCreateContractContext = createContext<IModalCreateContractContextValues>();
const ModalCreateContractConsumer = ModalCreateContractContext.Consumer;

let closeResolver: (((data: CloseModalEvent<number>) => any) | null) = null;
export const ModalCreateContractProvider = ({children}: any) => {
  const intl = useIntl();

  const selectApi = new SelectApi();
  const contractApi = new ContractsApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [contractorsSelectValues, setContractorsSelectValues] = useState<Array<ISelectValueDto>>([]);

  const [contractorId, setContractorId] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const fetchContractors = async () => {
    try {
      const result = await selectApi.getContractors();
      setContractorsSelectValues(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleChangeContractor = (contractorId: number | null) => {
    setContractorId(contractorId);
  };

  const handleChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const handleCloseModal = async () => {
    setVisible(false);
    setEndDate(null);
    setStartDate(null);
    setContractorId(null);
    setError(null);
    setValidationError(null);
    if (closeResolver != null) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const showModal = async () => {
    setVisible(true);
    if (contractorsSelectValues.length === 0) {
      await fetchContractors();
    }

    return new Promise<CloseModalEvent<number>>((resolve) => {
      closeResolver = resolve;
    });
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      const result = await contractApi.createContract(contractorId,
        prepareDate(startDate),
        prepareDate(endDate),
      );
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: result.data.item.id});
      }
      await handleCloseModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalCreateContractContextValues = {
    modalCreateContractVisible: visible,
    showCreateContractModal: showModal,
  };

  return <ModalCreateContractContext.Provider value={value}>
    {children}
    <ModalCreateContract
      visible={visible}
      error={error}
      contractorId={contractorId}
      startDate={startDate}
      endDate={endDate}
      contractors={contractorsSelectValues}
      validationErrors={validationErrors}

      onHide={() => handleCloseModal()}
      onChangeContractor={handleChangeContractor}
      onChangeStartDate={handleChangeStartDate}
      onChangeEndDate={handleChangeEndDate}
      onOkClick={handleOkClick}
    />
  </ModalCreateContractContext.Provider>;
};

export const useModalCreateContract = () => {
  return useContext(ModalCreateContractContext);
};

export function withModalCreateContract(children: any) {
  return function CreateContractModalComponent() {
    return <ModalCreateContractConsumer>
      {children}
    </ModalCreateContractConsumer>;
  };
}
