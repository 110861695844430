import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../api/Paginator';
import {FormattedMessage, useIntl} from 'react-intl';
import {PaymentsApi} from '../../../api/payment-api/payments-api';
import {IPaymentRequestDto, PaymentRequestStatus} from '../../../api/payment-api/IPaymentRequestDto';
import {PaymentRequestsTable} from './payment-requests-table';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useLoading} from '../../../hooks/use-loading';
import {SelectApi} from '../../../api/select-api';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {SelectsValuesDTOs} from '../../../components/Inputs/InputSelect';
import {BaseListPage} from '../../base/base-list-page';
import {ICONS} from '../../../images/images';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {useModalAdminPaymentRequest} from '../../../components/modals/create-admin-payment-request-modal/admin-payment-request-modal-context';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const PaymentRequestsPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = new PaymentsApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
  });

  const [paymentRequests, setPaymentRequests] = useState<Array<IPaymentRequestDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const {showPaymentRequestModal} = useModalAdminPaymentRequest();

  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({
    status: [PaymentRequestStatus.COMPLETE, PaymentRequestStatus.REJECT, PaymentRequestStatus.IN_WORK].map(s => ({
      id: s,
      title: intl.formatMessage({id: 'PAYMENT_REQUEST_STATUS_' + s}),
    })),
  });

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'payment_requests_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'status',
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const handleCreatePaymentRequest = useCallback(async () => {
    const modalResult = await showPaymentRequestModal();
    if (modalResult.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_PAYMENT_REQUEST'}));
      fetchPaymentRequests().then();
    }
  }, []);

  const fetchPaymentRequests = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getPaymentRequests(filters);
      setPaymentRequests(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        user_id: (await selectApi.getUsers()).data.items,
        contractor_id: (await selectApi.getContractors()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const renderPaymentRequests = () => {
    if (paymentRequests.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <PaymentRequestsTable paymentRequests={paymentRequests} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      toolbarConfig={[
        {
          title: intl.formatMessage({id: 'CREATE'}),
          type: 'BUTTON',
          onClick: handleCreatePaymentRequest,
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
        },
      ]}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchPaymentRequests}>
      {renderPaymentRequests()}
    </BaseListPage>
  );
};
