import React, {createContext, PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalSignature} from './modal-signature';
import {AddendumPreview, ProfileApi} from '../../../api/signature-api/profile-api';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {useIntl} from 'react-intl';
import {Base64Signature} from '../../../api/signature-api/signatures-response-contracts';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {EntityId} from '../../../api/base/BaseEntity';
import {useLoading} from '../../../hooks/use-loading';

interface IModalSignatureContext {
  modalSignatureVisible: boolean;

  showSignatureModal(addendumId: EntityId, signature?: Base64Signature | null): Promise<CloseModalEvent<string | null>>;
}

// @ts-ignore
const ModalSignatureContext = createContext<IModalSignatureContext>();
let interval: NodeJS.Timeout;
let closeResolver: ((data: CloseModalEvent<string | null>) => unknown) | null = null;
export const ModalSignatureProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();

  const [loadings, startLoading, stopLoading] = useLoading({
    addendumPreview: true,
    canvas: true,
  });

  const api = new ProfileApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const signatureRef = useRef<any>(null);
  const [addendumId, setAddendumId] = useState<EntityId | null>(null);
  const [addendumPreview, setAddendumPreview] = useState<AddendumPreview | null>(null);

  useEffect(() => {
    if (addendumId && visible) {
      startLoading('addendumPreview');
      api
        .getAddendumPreview(addendumId)
        .then(res => {
          setAddendumPreview(res.data);
        })
        .finally(() => stopLoading('addendumPreview'));
    }
  }, [visible]);

  const checkExistSignatureRef = (signature?: Base64Signature) => {
    startLoading('canvas');
    interval = setInterval(() => {
      if (signatureRef.current != null) {
        handleExistSignatureRef(signature);
      }
    }, 300);
  };

  const handleExistSignatureRef = (signature?: Base64Signature) => {
    stopLoading('canvas');
    signatureRef.current._resizeCanvas();
    if (signature) {
      signatureRef.current.fromDataURL(signature);
    }
    clearInterval(interval);
  };

  const showModal = async (addendumId: EntityId, signature: Base64Signature) => {
    setAddendumId(addendumId);
    checkExistSignatureRef(signature);
    setVisible(true);

    return new Promise<CloseModalEvent<string | null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    setValidationError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      if (!addendumId) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Addendum ID not specified');
      }
      const signature = signatureRef.current.isEmpty() ? null : signatureRef.current.getCanvas().toDataURL('image/png');
      await api.updateSignatureForAddendum(addendumId, signature);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: null});
        closeResolver = null;
      }
      handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const handleClear = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const value: IModalSignatureContext = {
    modalSignatureVisible: visible,
    showSignatureModal: showModal,
  };

  return (
    <ModalSignatureContext.Provider value={value}>
      {children}
      <ModalSignature
        size={'xl'}
        visible={visible}
        error={error}
        addendumPreview={addendumPreview}
        validationErrors={validationErrors}
        sigRef={signatureRef}
        loadingPreview={loadings.addendumPreview}
        loadingCanvas={loadings.canvas}
        onClear={handleClear}
        onHide={handleHideModal}
        onOkClick={handleOkClick}
      />
    </ModalSignatureContext.Provider>
  );
};

export const useModalSignature = () => {
  return useContext(ModalSignatureContext);
};
