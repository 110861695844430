import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {REPORT_TYPE} from '../../../api/financial-report-api/IYoutubeFinancialBulkReportsDto';
import './financial-reports-table.scss';
import {ReportsInfo} from './reports-info';
import {Spinner} from 'react-bootstrap';
import {DefaultTable} from '../../../components/default-table';
import {BadgeCustom} from '../../../components/badge-custom';
import {TableUtils} from '../../../components/table-utils';

type Record = {
  fileName: string;
  type: REPORT_TYPE;
  cms_id: number;
  cms_name: string;
};

interface IFinancialReportsTableProps {
  reports: Array<Record>;

  onDownloadClick(type: REPORT_TYPE): boolean;
}

export const FinancialReportsTable = ({reports, onDownloadClick}: IFinancialReportsTableProps) => {
  const intl = useIntl();
  const data = reports;
  const columns = useMemo(() => {
    return [
      {
        id: 'FileName',
        Header: intl.formatMessage({id: 'FILE_NAME'}),
        maxWidth: 500,
        width: 400,
        minWidth: 400,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          return TableUtils.renderBaseContent(value.fileName, {className: 'file-name-cell font-weight-bolder'});
        },
      },
      {
        id: 'CMS',
        Header: 'CMS',
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          return TableUtils.renderBaseContent(value.cms_name, {className: 'file-name-cell font-weight-bolder'});
        },
      },
      {
        id: 'ReportType',
        Header: intl.formatMessage({id: 'REPORT_TYPE'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          const reportDescription = Object.values(ReportsInfo)
            .flat()
            .find(r => {
              return r.type === value.type;
            });

          if (!reportDescription) {
            return 'N/A';
          }

          return TableUtils.renderBaseContent(
            <div className={'w-100'}>
              <FormattedMessage tagName={'div'} id={reportDescription.humanDescription} />
              <BadgeCustom variant={'secondary'}>
                <span className={'text-muted'}>{value.type}</span>
              </BadgeCustom>
            </div>,
          );
        },
      },
      {
        id: 'Download',
        maxWidth: 100,
        minWidth: 100,
        accessor: (br: Record) => br,
        Cell: ({value}: {value: Record}) => {
          return <ActionButton type={value.type} onDownloadClick={onDownloadClick} />;
        },
      },
    ];
  }, []);

  return <DefaultTable data={data.filter(r => r.fileName !== null && r.fileName !== "")} columns={columns} />;
};

interface IActionButtonProps {
  type: REPORT_TYPE;

  onDownloadClick(type: REPORT_TYPE): boolean;
}

const ActionButton: React.FC<IActionButtonProps> = ({type, onDownloadClick}) => {
  const [loading, setLoading] = useState(false);
  const handleDownloadClick = async () => {
    try {
      setLoading(true);
      onDownloadClick(type);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={'font-weight-bolder d-flex justify-content-center align-items-center h-100'}>
        <button onClick={handleDownloadClick} className={'btn btn-secondary btn-sm'}>
          {loading ? (
            <Spinner className={'mr-1'} size={'sm'} animation={'border'} />
          ) : (
            <i className='flaticon-download icon-sm' />
          )}
          <FormattedMessage id={'DOWNLOAD'} />
        </button>
      </div>
    </>
  );
};
