import React, {useMemo} from 'react';
import {RevenueByMonths} from '../../../api/DTOs/RevenueByMonths';
import {DefaultTable} from '../../../components/default-table';
import {useIntl} from '../../../hooks/use-intl';
import {FormattedMessage} from 'react-intl';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {TableUtils} from '../../../components/table-utils';

interface RevenueByMonthsTableProps {
  items: Array<RevenueByMonths>;
}

export const RevenueByMonthsTable = ({items}: RevenueByMonthsTableProps) => {
  const intl = useIntl();
  const data = items;

  const columns = useMemo(() => {
    return [
      {
        id: 'Period',
        Header: intl.formatMessage({id: 'PERIOD'}),
        maxWidth: 80,
        minWidth: 80,
        accessor: (br: RevenueByMonths) => br,
        Cell: ({value}: {value: RevenueByMonths}) => {
          return TableUtils.renderBaseContent(
            CustomFormatter.formatDate_MM_YYYY(new Date(value.year, value.month - 1)),
          );
        },
      },
      {
        id: 'ADS',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'ADS'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_advertisement,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'ADS_copies',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'ADS_copies'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_advertisement_copies,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'YOUTUBE_PREMIUM',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'YOUTUBE_PREMIUM'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_subscription,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'PAID_FEATURES',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'PAID_FEATURES'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_paid_features,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'COMPENSATIONS',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'COMPENSATIONS'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_compensation,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'SHORTS',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'YOUTUBE_SHORTS'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_shorts,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'OTHER_REVENUES',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'OTHER_REVENUES'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_others,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
      {
        id: 'TOTAL',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'TOTAL'})),
        maxWidth: 70,
        minWidth: 70,
        accessor: (br: RevenueByMonths) => br.revenue_total,
        Cell: ({value}: {value: number}) => {
          return TableUtils.renderNumericContent(CustomFormatter.formatMoney(value));
        },
      },
    ];
  }, []);

  if (items.length === 0) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  return <DefaultTable data={data} columns={columns} style={{overflowX: 'auto'}} className={'d-block w-100'} />;
};
