import React from 'react';
import './block-ui.scss';
import SVG from 'react-inlinesvg';

type Props = {
  blocked: boolean;
  message?: string;
};

const ICONS = {
  SPINNER: require('../images/svg/Spinner.svg'),
};

export const BlockUi: React.FC<Props> = ({blocked, message}) => {
  if (!blocked) {
    return null;
  }
  return (
    <div className='block-ui-container'>
      <div className='block-ui-overlay' />
      <div className='block-ui-message-container'>
        {message}
        <div className='block-ui-message'>
          <span className={'svg-icon svg-icon-primary svg-icon-4x svg-spin'}>
            <SVG src={ICONS.SPINNER} />
          </span>
        </div>
      </div>
    </div>
  );
};
