import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {ApiRequestException} from '../../api/axios-instance';
import {
  ISupportTicketDto,
  SupportTicketStatus,
  SupportTicketType,
} from '../../api/support-tickets-api/ISupportTicketDto';
import {ISupportTicketMessage} from '../../api/DTOs/ISupportTicketMessage';
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl';
import {SupportTicketsApi} from '../../api/support-tickets-api/support-tickets-api';
import SVG from 'react-inlinesvg';
import {ErrorStub} from '../../components/error-stub';
import {AdminRoutes} from '../../../configs/routes';
import {shallowEqual, useSelector} from 'react-redux';
import {IApplicationStore} from '../../../redux/rootReducer';
import cn from 'classnames';
import {CustomFormatter} from '../../../localization/custom-formatter';
import {IUserDto} from '../../api/DTOs/IUserDto';
import {toast} from 'react-toastify';
import {Preloader} from '../../components/preloader';
import {AlertCustom} from '../../modules/Auth/component/alert';
import {LinkWithClickControl} from '../../components/link-with-click-control';
import {useModalConfirmAction} from '../../components/modals/confirm-modal/modal-confirm-action-context';
import {RichTextEditor} from '../../components/rich-text-editor/rich-text-editor';

import './ticket-card-page.scss';
import {useCustomBreadcrumbs} from '../../components/breadcrumbs/breadcrumbs-context';

interface IProps {
  isAdmin?: boolean;
}

const TEMP_MESSAGE_ID = -1;

const ICONS = {
  WARNING: require('../../images/svg/Warning.svg'),
  QUESTION: require('../../images/svg/Question.svg'),
  INFO: require('../../images/svg/Info-circle.svg'),
};

export const TicketCardPage: React.FC<IProps> = ({isAdmin}) => {
  const api = new SupportTicketsApi();
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const ticketId = Number(match.params.id);
  const userDto = useSelector<IApplicationStore, IUserDto>((
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      {auth}) => auth.user!,
    shallowEqual);

  const [ticket, setTicket] = useState<ISupportTicketDto | null>(null);
  const [messages, setMessages] = useState<Array<ISupportTicketMessage>>([]);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const {showConfirmActionModal} = useModalConfirmAction();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    const fetchAll = async () => {
      await fetchTicket();
      intervalId = setInterval(async () => {
        if (!loadingSendMessage) {
          await fetchTicket();
        }
      }, 2000);
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchAll();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (ticket) {
      setBreadcrumb(ticket.topic);
    }
  }, [ticket]);

  const fetchTicket = async () => {
    try {
      const response = await api.getTicket(Number(ticketId));
      setTicket(response.data.item);
      setMessages(response.data.relations.messages);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChangeMessage = (content: string) => {
    setMessage(content);
  };

  const handleSendMessage = async () => {
    try {
      setLoadingSendMessage(true);
      const tempMessage: Partial<ISupportTicketMessage> = {
        id: TEMP_MESSAGE_ID,
        message: message,
        authorId: userDto.id,
        authorName: userDto.email,
      };

      setMessages([tempMessage as ISupportTicketMessage, ...messages]);
      await api.sendMessage(ticketId, message);
      setMessage('');
      await fetchTicket();
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoadingSendMessage(false);
    }
  };

  const handleCloseAppeal = async () => {
    try {
      if (!await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_CLOSE_APPEAL'}))) {
        return;
      }

      setLoading(true);
      await api.closeTicket(ticketId);
      await fetchTicket();
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (!ticket || error) {
    return <ErrorStub error={error || intl.formatMessage({id: 'UNEXPECTED_ERROR'})} />;
  }

  const renderUnderTopicInfo = () => {
    return <div>
    <span className='font-weight-bold text-muted mr-5 font-size-lg'>
      <FormattedMessage id={'FROM'} />:&nbsp;
      <LinkWithClickControl
        disabled={!isAdmin}
        to={AdminRoutes.getSpecifyUserManagementRoute(ticket.authorId)}
        className='text-muted text-hover-primary font-weight-bolder'>
        {ticket.authorName}
      </LinkWithClickControl>
    </span>
      <span className='font-weight-bold text-muted font-size-lg'>
        <FormattedMessage id={'CREATED_AT'} />:&nbsp;
        <span className='font-weight-bolder text-gray-600'>
          <FormattedDate
            value={ticket.createdAt} hour={'numeric'}
            minute={'numeric'} month={'long'}
            day={'numeric'}
          />
        </span>
    </span>
    </div>;
  };

  const renderTicketHeader = () => {
    const icon = ticket.type === SupportTicketType.QUESTION
      ? [ICONS.QUESTION, 'warning']
      : ticket.type === SupportTicketType.PROBLEM
        ? [ICONS.WARNING, 'danger']
        : [ICONS.INFO, 'primary'];
    return <>
      <AlertCustom
        text={intl.formatMessage({id: 'APPEAL_WAS_CLOSED'})}
        type={'light-warning'}
        className={'mb-10'}
        iconClassName={'svg-icon-warning'}
        visible={ticket.status === SupportTicketStatus.CLOSED}
      />
      <div className={'d-flex align-items-center mb-12'}>
        <span className={`svg-icon svg-icon-4x svg-icon-${icon[1]} mr-3`}><SVG src={icon[0]} /></span>
        <div className='d-flex flex-column'>
          <h1 className='text-gray-800 font-weight-bold'>
            <span className={'text-muted'}><FormattedMessage
              id={'SUPPORT_TICKET_TYPE_' + ticket.type} />:</span> {ticket.topic}</h1>
          {renderUnderTopicInfo()}
        </div>
      </div>
    </>;
  };

  const renderForm = () => {
    return <div className={'mb-12'}>
      <RichTextEditor
        disabled={loadingSendMessage || ticket.status === SupportTicketStatus.CLOSED}
        placeholder={intl.formatMessage({id: 'WRITE_MESSAGE'})}
        content={message}
        className={'mb-5'}
        onChange={handleChangeMessage}
      />

      <button
        disabled={loadingSendMessage || ticket.status === SupportTicketStatus.CLOSED}
        onClick={handleSendMessage}
        className={'btn btn-primary'}>
        <FormattedMessage id={'SEND'} />
      </button>
      {ticket.status !== SupportTicketStatus.CLOSED
      && <button
        disabled={loadingSendMessage}
        onClick={handleCloseAppeal}
        className={'btn btn-light-danger ml-2'}>
        <FormattedMessage id={'CLOSE_APPEAL'} />
      </button>}
    </div>;
  };

  const renderMessages = () => {
    return messages.map(message => {
      const isSelfMessage = userDto.id === message.authorId;
      return <div
        key={message.id}
        className={cn('mb-10', {
          'ml-15': isSelfMessage,
          'mr-15': !isSelfMessage,
        })}>
        <div className={'card card-bordered w-100'}>
          <div className={'card-body'}>
            <div className={'w-100 d-flex flex-stack mb-8'}>
              <div className={'d-flex align-items-center'}>
                <div className={'symbol symbol-50px mr-5'}>
                  <div className={cn('symbol-label font-weight-bolder font-size-h1', {
                    'bg-light-success text-success': !isSelfMessage,
                    'bg-light-primary text-primary': isSelfMessage,
                  })}>
                    {message.authorName[0]}
                  </div>
                </div>
                <div className='d-flex flex-column fw-bold fs-5 text-gray-600 text-dark'>
                  <div className='d-flex align-items-center'>
                    <LinkWithClickControl
                      disabled={!isAdmin}
                      to={AdminRoutes.getSpecifyUserManagementRoute(message.authorId)}
                      className={cn('text-gray-800 font-weight-boldest text-hover-primary font-size-lg', {
                        'text-success': !isSelfMessage,
                        'text-primary': isSelfMessage,
                      })}>
                      {message.authorName}
                    </LinkWithClickControl>
                  </div>
                  <span className='text-muted font-weight-bold'>
                    {CustomFormatter.formatDateTimeWithFullMonth(message.createdAt)}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={'message-content font-weight-bold text-gray-700 m-0 font-size-lg'}
              dangerouslySetInnerHTML={{__html: message.message}}
            />
          </div>
        </div>
      </div>;
    });
  };

  const renderTicketBody = () => {
    return <div className={'mb-12'}>
      <div className={'mb-15 font-weight-normal text-gray-800'} style={{fontSize: '15px'}}>
        {messages.length > 0
        && <div
          className={'message-content ticket-body font-weight-bold text-gray-700 m-0 font-size-lg'}
          dangerouslySetInnerHTML={{__html: messages[messages.length - 1].message}}
        />}
      </div>
      <div className={'mb-15'}>
        {renderForm()}
        {renderMessages()}
      </div>
    </div>;
  };

  const renderTextWithIcon = (text: string, value: string | number, flaticon: string) => {
    return <>
      <div className='d-flex align-items-center mb-10 text-primary'>
        <i className={flaticon + ' text-primary icon-2x mr-5'} />
        <div className='d-flex flex-column'>
          <h5 className='text-gray-800 font-weight-bolder'>
            <FormattedMessage id={text} />
          </h5>
          <div className='font-weight-bolder font-size-h6'>
            <span>{value}</span>
          </div>
        </div>
      </div>
    </>;
  };

  const renderTicketRightBlock = () => {
    return <>
      <div className={'card-rounded bg-light-primary p-10 mb-15'}>
        {renderTextWithIcon(
          'STATUS',
          intl.formatMessage({id: 'SUPPORT_TICKET_STATUS_' + ticket.status}),
          ticket.status === SupportTicketStatus.OPEN
            ? 'flaticon2-checkmark'
            : 'flaticon2-cross',
        )}
        {renderTextWithIcon('MESSAGE_COUNT', messages?.length, 'flaticon-mail-1')}
        {renderTextWithIcon('TICKET_OPENED_AT', CustomFormatter.formatDateTimeWithFullMonth(messages[messages.length - 1]?.createdAt), 'flaticon-calendar-with-a-clock-time-tools')}
        {renderTextWithIcon('UPDATED_AT', CustomFormatter.formatDateTimeWithFullMonth(ticket?.updatedAt), 'flaticon-calendar-with-a-clock-time-tools')}
      </div>
    </>;
  };

  return <>
    <div className={'card'}>
      <div className={'card-body'}>
        <div className='d-flex flex-column flex-md-column-reverse flex-xl-row p-7'>
          <div className={'flex-lg-row-fluid mb-20 mb-xl-0 mr-10'}>
            <div className={'mb-0'}>
              {renderTicketHeader()}
              {renderTicketBody()}
            </div>
          </div>
          <div className={'flex-column flex-lg-row-auto w-100 max-w-xl-300px'}>
            {renderTicketRightBlock()}
          </div>
        </div>
      </div>
    </div>
  </>;
};

