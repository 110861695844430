import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, IntlShape} from 'react-intl';
import {AlertCustom} from '../component/alert';
import {IApplicationStore} from '../../../../redux/rootReducer';
import {SetUserAction} from '../_redux/auth-redux';
import {AuthApi} from '../../../api/auth-api';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {IUserDto} from '../../../api/DTOs/IUserDto';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {ApiRequestException} from '../../../api/axios-instance';
import {Routes} from '../../../../configs/routes';
import {IValidationException} from '../../../api/exceptions/IValidationException';

interface MapStateToProps {
  user: IUserDto | null;
}

interface MapDispatchToProps {
  intl: IntlShape,

  setUser(user: IUserDto): void;
}

const Login = ({intl, setUser}: MapStateToProps & MapDispatchToProps) => {
  const authApi = new AuthApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: Array<string>} | null>(null);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const sendLoginRequest = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      const result = await authApi.login(email, password);
      setUser(result.data.user);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as IValidationException).error_data.messages);
      } else if (err.errorType === EXCEPTION_TYPE.UNAUTHENTICATED_EXCEPTION) {
        setError(intl.formatMessage({id: 'LOGIN_ERROR'}));
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.LOGIN.SUB_TITLE' />
        </p>
      </div>

      <form className='form fv-plugins-bootstrap fv-plugins-framework'>
        <AlertCustom
          text={error}
          type={'light-danger'}
          iconClassName={'svg-icon-danger'}
          visible={error != null}
        />

        <ValidateErrorWrapper message={validationErrors && validationErrors['email']}>
          <InputText
            name={'email'}
            type={'email'}
            value={email}
            hasError={validationErrors?.email != undefined}
            onChange={(e) => setEmail(e.currentTarget.value)}
            label={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>

        <ValidateErrorWrapper message={validationErrors && validationErrors['password']}>
          <InputText
            name={'password'}
            type={'password'}
            value={password}
            hasError={validationErrors?.password != undefined}
            onChange={(e) => setPassword(e.currentTarget.value)}
            label={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            classNames={`form-control`}
          />
        </ValidateErrorWrapper>

        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link
            to={Routes.getForgotPasswordRoute()}
            className='text-dark-50 text-hover-primary my-3 mr-2'>
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
          </Link>
          <button
            onClick={sendLoginRequest}
            type='submit'
            disabled={loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
            <span><FormattedMessage id='AUTH.LOGIN.BUTTON' /></span>
            {loading && <span className='ml-3 spinner spinner-white' />}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({auth}: IApplicationStore) => {
  return {
    user: auth.user,
  };
};

const mapDispatchToProps = {
  setUser: SetUserAction,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
