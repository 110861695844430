import React from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import './modal-play-video.scss';

interface IModalPlayVideoProps {
  visible: boolean;
  title: string | null;
  videoId: string | null;

  onHide(): void;
}

export const ModalPlayVideo: React.FC<IModalPlayVideoProps> = (
  {
    visible,
    videoId,
    title,
    onHide,
  }) => {

  return <>
    <BaseModal
      size={'xl'}
      visible={visible}
      onHide={onHide}
    >
      <ModalHeaderSlot>
        <Modal.Title>
          {title}
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <div className={'d-flex justify-content-center video-container'}>
          <iframe
            frameBorder='0'
            scrolling='no'
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/${videoId}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com`}
          />
        </div>
      </ModalBodySlot>
    </BaseModal>
  </>;
};
