import React, {useEffect, useMemo, useState} from 'react';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../../api/DTOs/IFilterDtos';
import {IPaginationInfo} from '../../../../api/Paginator';
import {FormattedMessage, useIntl} from 'react-intl';
import {SelectApi} from '../../../../api/select-api';
import {ContractorsApi} from '../../../../api/contractor-api/contractors-api';
import {IContractorDto} from '../../../../api/contractor-api/IContractorDto';
import {ContractorsTable} from './contractors-table';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useLoading} from '../../../../hooks/use-loading';
import {Filter, FilterType} from '../../../../components/filters/filters';
import {FilterBuilder} from '../../../../components/filters/filter-builder';
import {SelectsValuesDTOs} from '../../../../components/Inputs/InputSelect';
import {BaseListPage} from '../../../base/base-list-page';
import {ICONS} from '../../../../images/images';
import {
  FinancialAccountAction,
  useFinancialAccountActionHandler,
} from '../../financial-accounts/financial-accounts/useFinancialAccountsActionHandler';
import {useBaseListPage} from '../../../base/base-list-page-context';
import {FinancialAccountType} from '../../../../api/DTOs/IFinancialAccountDto';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const ContractorsPage: React.FC<any> = () => {
  const intl = useIntl();

  const api = new ContractorsApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: true,
    downloadReport: false,
  });

  const [contractors, setContractors] = useState<Array<IContractorDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [selectValues, setSelectValues] = useState<SelectsValuesDTOs>({});
  const {appliedQueryParams} = useBaseListPage();
  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'channels_requests_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'y_channel_id',
          placeholder: intl.formatMessage({id: 'CHANNELS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);
  const {handleFinancialAccountActions} = useFinancialAccountActionHandler();

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchContractors = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getContractors(filters);
      setContractors(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const handleDownloadReportClick = async () => {
    startLoading('downloadReport');
    await handleFinancialAccountActions({
      type: FinancialAccountAction.DOWNLOAD,
      data: {
        queryParams: {
          ...appliedQueryParams,
          filters: {
            ...appliedQueryParams.filters,
            type: FinancialAccountType.INNER,
          },
        },
      },
    });
    stopLoading('downloadReport');
  };

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        user_id: (await selectApi.getUsers()).data.items,
        y_channel_id: (await selectApi.getYoutubeChannels()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const renderContractors = () => {
    if (contractors.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <ContractorsTable contractors={contractors} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchContractors}
      toolbarConfig={[
        {
          type: 'BUTTON',
          loading: loadings.downloadReport,
          title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
          icon: ICONS.DOWNLOAD,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: handleDownloadReportClick,
        },
      ]}>
      {renderContractors()}
    </BaseListPage>
  );
};
