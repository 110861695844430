import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {IResponseBase, IResponseMultipleContent} from '../response-contracts/base-response';
import {IAdvertisementRequestDto} from './IAdvertisementRequestDto';
import {IResponseAdvertisementRequest} from './advertisements-response-contract';

export class AdvertisementsApi extends BaseEndpoint {
  public async createAdvertisementsRequest(
    request: IAdvertisementRequestDto,
  ): Promise<IResponseBase<{item: IAdvertisementRequestDto}>> {
    const response = await this.post(`${config.BASE_API_URL}/planned-content`, {
      fields: {
        ...request,
      },
    });
    return response.data;
  }

  public async updateSelfAdvertisementsRequest(
    id: number,
    request: IAdvertisementRequestDto,
  ): Promise<IResponseBase<{item: IAdvertisementRequestDto}>> {
    const response = await this.post(`${config.BASE_API_URL}/planned-content/${id}`, {
      fields: {
        ...request,
      },
    });
    return response.data;
  }

  public async getSelfAdvertisementsRequests(
    params?: IQueryParams,
  ): Promise<IResponseMultipleContent<IAdvertisementRequestDto>> {
    const query = this.applyQueryParams(params);
    const response = await this.get(`${config.BASE_API_URL}/planned-content?${query}`);
    return response.data;
  }

  public async getSelfAdvertisementsRequest(id: number): Promise<IResponseAdvertisementRequest> {
    const response = await this.get(`${config.BASE_API_URL}/planned-content/${id}`);
    return response.data;
  }

  public async deleteSelfAdvertisementsRequest(id: number): Promise<IResponseBase> {
    const response = await this.delete(`${config.BASE_API_URL}/planned-content/${id}`);
    return response.data;
  }

  public async confirmAdvertisementsRequest(id: number): Promise<IResponseBase> {
    const response = await this.post(`${config.BASE_API_URL}/admin/planned-content/${id}/done`, {});
    return response.data;
  }

  public async rejectAdvertisementsRequest(id: number, reject_reason: string): Promise<IResponseBase> {
    const response = await this.post(`${config.BASE_API_URL}/admin/planned-content/${id}/reject`, {
      fields: {
        reject_reason,
      },
    });
    return response.data;
  }

  public async deleteUserAdvertisementsRequest(id: number): Promise<IResponseBase> {
    const response = await this.delete(`${config.BASE_API_URL}/admin/planned-content/${id}`);
    return response.data;
  }

  public async getUsersAdvertisementsRequests(
    params?: IQueryParams,
  ): Promise<IResponseMultipleContent<IAdvertisementRequestDto>> {
    const query = this.applyQueryParams(params);
    const response = await this.get(`${config.BASE_API_URL}/admin/planned-content?${query}`);
    return response.data;
  }

  public async getUserAdvertisementRequests(id: number): Promise<IResponseBase> {
    const response = await this.get(`${config.BASE_API_URL}/admin/planned-content/${id}`);
    return response.data;
  }
}
