import React from 'react';
import {IConnectChannelRequestDto} from '../../api/connect-channel-api/IConnectChannelRequestDto';
import cn from 'classnames';
import {Card, CardBody, CardTitle} from '../../components/card';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';
import {BootstrapColor, BootstrapSizes} from '../../styles/styles';
import {Preloader} from '../../components/preloader';
import {useIntl} from '../../hooks/use-intl';
import {Routes} from '../../../configs/routes';
import {FilterRequestStatus} from '../../api/connect-channel-api/FilterRequestStatus';
import {BadgeCustom} from '../../components/badge-custom';

type Props = {
  loading: boolean;
  connectChannelsRequests: IConnectChannelRequestDto[];
};

export const ProfileConnectChannelsRequests: React.FC<Props> = ({loading, connectChannelsRequests}) => {
  const intl = useIntl();
  return (
    <>
      <Card hideFooter className={{body: 'bg-light'}}>
        <CardTitle>
          <span className={'font-weight-bolder'}>{intl.formatMessage({id: 'CONNECT_CHANNELS_REQUESTS'})}</span>
        </CardTitle>
        <CardBody>
          {loading ? (
            <Preloader />
          ) : connectChannelsRequests.length > 0 ? (
            connectChannelsRequests.map(connectChannelsRequest => {
              const badgeVariant =
                connectChannelsRequest.requestStatus === FilterRequestStatus.REJECTED
                  ? 'danger'
                  : connectChannelsRequest.requestStatus === FilterRequestStatus.APPROVED
                  ? 'success'
                  : 'primary';
              return (
                <Card
                  hideFooter
                  hideHeader
                  key={connectChannelsRequest.id}
                  className={{body: cn('d-flex align-items-center justify-content-between')}}>
                  <CardBody>
                    <div className={'w-100'}>
                      <div className={'d-flex justify-content-between w-100'}>
                        <div>
                          <Svg
                            src={ICONS.YOUTUBE}
                            className={'mr-2'}
                            color={BootstrapColor.DANGER}
                            size={BootstrapSizes.LG}
                          />
                          <span className={'font-weight-boldest'}>
                            <a
                              target={'_blank'}
                              href={Routes.getExternalYoutubeChannelRoute(connectChannelsRequest.channelId)}
                              rel='noreferrer'>
                              {connectChannelsRequest.title}{' '}
                            </a>
                          </span>
                        </div>
                        <div>
                          <BadgeCustom variant={badgeVariant}>
                            {intl.formatMessage({id: `FILTER_REQUEST_STATUS_${connectChannelsRequest.requestStatus}`})}
                          </BadgeCustom>
                        </div>
                      </div>
                      {connectChannelsRequest.requestStatus === FilterRequestStatus.REJECTED && (
                        <div className={'text-danger'}>
                          <span className={'font-weight-bolder'}>{intl.formatMessage({id: 'REJECT_REASON'})}</span>:{' '}
                          {connectChannelsRequest.rejectReason}
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              );
            })
          ) : (
            <div className={'text-center'}>{intl.formatMessage({id: 'NOT_FOUND'})}</div>
          )}
        </CardBody>
      </Card>
    </>
  );
};
