export interface ISupportTicketDto {
  id: number;
  type: SupportTicketType;
  authorId: number;
  authorName: string;
  status: SupportTicketStatus;
  topic: string;
  createdAt: string;
  updatedAt: string;
}

export enum SupportTicketStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

// SupportTicketOpenReason
export enum SupportTicketType {
  WISH = 'WISH',
  QUESTION = 'QUESTION',
  PROBLEM = 'PROBLEM',
}
