import React from 'react';
import {Filter, Filters} from './filters';
import './filters-sorts-panel.scss';
import {UrlUpdateType} from 'use-query-params';

type Props = {
  filtersConfig?: Array<Filter>;
  filtersQueriesNameValueMap?: Record<string, any>;
  sortsQueriesNameValueMap?: Record<string, any>;
  sortsConfig?: Array<Filter>;
  onUpdate: (filterName: string, filterValue: string | Record<string, any>, urlUpdateType?: UrlUpdateType) => void;
};

export const FiltersSortsPanel: React.FC<Props> = ({
  filtersConfig,
  sortsConfig,
  filtersQueriesNameValueMap,
  sortsQueriesNameValueMap,
  onUpdate,
}) => {
  return (
    <div className={'filters-container'}>
      <Filters items={filtersConfig ?? []} filterNameValueMap={filtersQueriesNameValueMap ?? {}} onChange={onUpdate} />
      <Filters items={sortsConfig ?? []} filterNameValueMap={sortsQueriesNameValueMap ?? {}} onChange={onUpdate} />
    </div>
  );
};
