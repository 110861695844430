import React from 'react';
import {Download} from '../../api/DTOs/Download';
import {EntityId} from '../../api/base/BaseEntity';
import {useLoading} from '../../hooks/use-loading';
import {Card, CardBody} from '../../components/card';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {ICONS} from '../../images/images';
import {useIntl} from "react-intl";

type Props = {
  item: Download;
  onDownload: (id: EntityId) => Promise<void>;
};
export const DownloadCard: React.FC<Props> = ({item, onDownload}) => {
  const [loadings, startLoading, stopLoading] = useLoading({
    downloading: false,
  });

  const intl = useIntl();

  const handleDownload = async () => {
    startLoading('downloading');
    onDownload(item.id).finally(() => stopLoading('downloading'));
  };

  return (
    <Card key={item.id} hideFooter hideHeader>
      <CardBody>
        <div className={'d-flex flex-row justify-content-between align-items-center'}>
          <div className={'d-flex flex-row align-items-center'}>
            <div className={'mr-5'}>
              <span className='symbol symbol-65 symbol-light-success'>
                <span className='symbol-label font-size-h1 font-weight-bold'>{item.file_extension}</span>
              </span>
            </div>
            <div>
              <div className={'font-weight-boldest font-size-lg'}>{item.title}</div>
              <div className={'font-size-sm text-muted'}>{item.file_name}</div>
              <div
                className={'font-size-sm text-muted'}>{intl.formatDate(item.created_at)} {intl.formatTime(item.created_at)}</div>
            </div>
          </div>
          <Toolbar
            items={[
              {
                type: 'ICON',
                loading: loadings.downloading,
                icon: ICONS.DOWNLOAD,
                className: 'svg-icon-secondary svg-icon-2x cursor-pointer',
                onClick: handleDownload,
              },
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
};
