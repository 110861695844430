import React, {SyntheticEvent} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import cn from 'classnames';

type IProps = {
  disabled: boolean;
} & LinkProps

export const LinkWithClickControl: React.FC<IProps> = (props) => {
  const handleClick = (e: SyntheticEvent) => {
    if (props.disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  if (props.disabled) {
    return <span className={cn(props.className)}>
      {props.children}
    </span>;
  }

  return <>
    <Link
      to={props.to}
      className={cn(props.className)}
      onClick={handleClick}>
      {props.children}
    </Link>
  </>;
};
