import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {AuthApi} from '../../../api/auth-api';
import {SetUserAction} from '../_redux/auth-redux';
import {IApplicationStore} from '../../../../redux/rootReducer';
import {AlertCustom} from '../component/alert';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputText} from '../../../components/Inputs/InputText';
import {InputCheckbox} from '../../../components/Inputs/InputCheckbox';
import {IUserDto} from '../../../api/DTOs/IUserDto';
import {ApiRequestException} from '../../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {Routes} from '../../../../configs/routes';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useLoading} from '../../../hooks/use-loading';
import './registration.scss';
import {RegistrationConfirmation} from './registration-confirmation';
import {InputSelect} from '../../../components/Inputs/InputSelect';
import {toast} from 'react-toastify';
import {useJurisdictionsApi} from '../../../hooks/apis/use-jurisdictions-api';

interface MapStateToProps {
  user: IUserDto | null;
}

interface MapDispatchToProps {
  intl: any;

  setUser(user: IUserDto): void;
}

const Registration = (props: MapStateToProps & MapDispatchToProps) => {
  const intl = useIntl();

  const api = useJurisdictionsApi();
  const authApi = new AuthApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    submitRegister: false,
    submitConfirmationCode: false,
    select: true,
  });

  const [error, setError] = useState<string | null>(null);
  const [errorRegisterConfirmation, setRegisterConfirmationError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: Array<string>} | null>(null);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [jurisdiction_id, setJurisdiction_id] = useState<string | number | null>(null);
  const [password_confirmation, setPasswordConfirmation] = useState<string>('');
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [showCodeConfirmation, setShowCodeConfirmation] = useState(false);
  const [jurisdictions, setJurisdictions] = useState<any>([]);

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchSelectValues = useCallback(async () => {
    try {
      startLoading('select');
      const res = (await api.select())?.data?.items ?? [];
      setJurisdictions(res.map(r => ({label: r.title, value: r.id})));
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  }, []);

  const sendRegisterRequest = async (e: React.MouseEvent<HTMLElement>, isAgain = false) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      startLoading('submitRegister');
      setValidationErrors(null);
      setError(null);
      await authApi.sendRegistrationRequest(email, password, password_confirmation, jurisdiction_id);
      setShowCodeConfirmation(true);
    } catch (e) {
      const err = e as ApiRequestException;
      if (isAgain) {
        setErrorForCodeBlock(err);
        return;
      }

      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submitRegister');
    }
  };

  const sendConfirmationCode = async (confirmationCode: string) => {
    try {
      startLoading('submitConfirmationCode');
      setValidationErrors(null);
      setRegisterConfirmationError(null);
      const result = await authApi.register(email, password, password_confirmation, confirmationCode, jurisdiction_id);
      props.setUser(result.data.user);
    } catch (e) {
      setErrorForCodeBlock(e as ApiRequestException);
    } finally {
      stopLoading('submitConfirmationCode');
    }
  };

  const setErrorForCodeBlock = (err: ApiRequestException) => {
    if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
      if (Object.keys((err.innerException as IValidationException).error_data.messages).includes('code')) {
        setRegisterConfirmationError(intl.formatMessage({id: 'CONFIRMATION_CODE_IS_INVALID'}));
      } else {
        setRegisterConfirmationError(intl.formatMessage({id: 'CREDENTIALS_ARE_OUT_OF_DATE'}));
      }
    } else {
      setRegisterConfirmationError(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const handleCloseConfirmRegistrationBlock = () => {
    setError(null);
    setValidationErrors(null);
    setJurisdiction_id(null);
    setRegisterConfirmationError(null);
    setPassword('');
    setPasswordConfirmation('');
    setShowCodeConfirmation(false);
  };

  const renderRegistrationBlock = () => {
    return (
      <>
        <div className='text-center mb-10 mb-lg-20'>
          <h3 className='font-size-h1'>
            <FormattedMessage id='AUTH.REGISTER.TITLE' />
          </h3>
          <p className='text-muted font-weight-bold'>
            <FormattedMessage id='AUTH.REGISTER.DESC' />
          </p>
        </div>
        <form className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
          <AlertCustom text={error} type={'light-danger'} iconClassName={'svg-icon-danger'} visible={error != null} />

          <ValidateErrorWrapper message={validationErrors && validationErrors['email']}>
            <InputText
              name={'email'}
              type={'email'}
              value={email}
              hasError={validationErrors?.email != undefined}
              onChange={e => setEmail(e.currentTarget.value)}
              label={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={validationErrors && validationErrors['jurisdiction_id']}>
            <InputSelect
              value={jurisdiction_id}
              hasError={validationErrors?.jurisdiction_id != undefined}
              options={jurisdictions ?? []}
              onChange={value => setJurisdiction_id(value)}
              label={intl.formatMessage({id: 'AUTH.INPUT.JURISDICTION'})}
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.JURISDICTION'})}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={validationErrors && validationErrors['password']}>
            <InputText
              name={'password'}
              type={'password'}
              value={password}
              hasError={validationErrors?.password != undefined}
              onChange={e => setPassword(e.currentTarget.value)}
              label={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>

          <ValidateErrorWrapper message={validationErrors && validationErrors['password_confirmation']}>
            <InputText
              name={'password_confirmation'}
              type={'password'}
              label={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
              value={password_confirmation}
              hasError={validationErrors?.password_confirmation != undefined}
              onChange={e => setPasswordConfirmation(e.currentTarget.value)}
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
              classNames={`form-control`}
            />
          </ValidateErrorWrapper>

          <InputCheckbox
            required
            name='acceptTerms'
            value={acceptTerms}
            label={() => (
              <Link to={Routes.getUserAgreementRoute()} target='_blank' className='mr-1' rel='noopener noreferrer'>
                <FormattedMessage id='AUTH.REGISTER.AGREEMENT' />
              </Link>
            )}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />

          <div className='form-group d-flex flex-wrap flex-center'>
            <button
              type='submit'
              onClick={sendRegisterRequest}
              disabled={loadings.submitRegister || !acceptTerms}
              className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'>
              <span>
                <FormattedMessage id='BUTTONS.SUBMIT' />
              </span>
              {loadings.submitRegister && <span className='ml-3 spinner spinner-white' />}
            </button>

            <Link to={Routes.getLoginRoute()}>
              <button type='button' className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'>
                <FormattedMessage id='BUTTONS.CANCEL' />
              </button>
            </Link>
          </div>
        </form>
      </>
    );
  };

  return (
    <div className='login-form login-signin' style={{display: 'block'}}>
      {showCodeConfirmation ? (
        <RegistrationConfirmation
          loading={loadings.submitConfirmationCode || loadings.submitRegister}
          error={errorRegisterConfirmation}
          email={email}
          onChange={() => setRegisterConfirmationError(null)}
          onClose={handleCloseConfirmRegistrationBlock}
          onSendCode={sendConfirmationCode}
          onSendCodeToMailAgain={e => sendRegisterRequest(e, true)}
        />
      ) : (
        renderRegistrationBlock()
      )}
    </div>
  );
};

const mapStateToProps = ({auth}: IApplicationStore) => {
  return {
    user: auth.user,
  };
};

const mapDispatchToProps = {
  setUser: SetUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
