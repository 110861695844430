import React, {useCallback, useState} from 'react';
import cn from 'classnames';
import {Card, CardBody} from '../../components/card';
import {Svg} from '../../images/svg';
import {ICONS} from '../../images/images';
import {BootstrapColor, BootstrapSizes} from '../../styles/styles';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {IAddendumForUserDto} from '../../api/addendum-api/IAddendumDto';
import {useIntl} from '../../hooks/use-intl';
import {EntityId} from '../../api/base/BaseEntity';

type Props = {
  addendum: IAddendumForUserDto;
  onDownloadClick: (addendumId: EntityId) => Promise<void>;
  onChangeSignature: (addendum: IAddendumForUserDto) => Promise<void>;
};

export const ProfileAddendumComponent: React.FC<Props> = ({addendum, onChangeSignature, onDownloadClick}) => {
  const intl = useIntl();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadClick = useCallback(async (addendumId: EntityId) => {
    try {
      setIsDownloading(true);
      await onDownloadClick(addendumId);
    } finally {
      setIsDownloading(false);
    }
  }, []);

  return (
    <>
      <Card
        key={addendum.id}
        className={{body: cn('d-flex align-items-center justify-content-between')}}
        hideFooter
        hideHeader>
        <CardBody>
          <div>
            <Svg src={ICONS.FILE} className={'mr-2'} color={BootstrapColor.DARK} size={BootstrapSizes.LG} />
            <span className={'font-weight-bolder'}>
              {intl.formatMessage({id: 'CONTRACT_ON_CHANNEL'})}: {addendum.youtubeChannelTitle}{' '}
            </span>
          </div>
          <Toolbar
            className={'align-items-center'}
            items={[
              {
                type: 'BUTTON',
                title: intl.formatMessage({
                  id: addendum.signature_base64 != null ? 'SIGNED' : 'SET_SIGNATURE',
                }),
                className: cn('btn btn-sm', 'mr-2', {
                  'btn-success': addendum.signature_base64 !== null,
                  'btn-danger': addendum.signature_base64 == null,
                }),
                onClick: () => (addendum.signature_base64 != null ? null : onChangeSignature(addendum)),
              },
              {
                type: 'ICON',
                loading: isDownloading,
                disabled: isDownloading,
                icon: ICONS.DOWNLOAD,
                className: cn('svg-icon-primary svg-icon-lg', 'cursor-pointer'),
                onClick: () => handleDownloadClick(addendum.id),
              },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
};
