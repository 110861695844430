import React, {useEffect, useMemo, useState} from 'react';
import {ContractsApi} from '../../../../api/contract-api/contracts-api';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../../api/DTOs/IFilterDtos';
import {IContractDto} from '../../../../api/contract-api/IContractDto';
import {IPaginationInfo} from '../../../../api/Paginator';
import {ISelectValueDto} from '../../../../api/DTOs/ISelectValueDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {ContractsTable} from './contracts-table';
import {SelectApi} from '../../../../api/select-api';
import {useModalCreateContract} from '../../../../components/modals/create-contract/modal-create-contract-context';
import {ErrorStub} from '../../../../components/error-stub';
import {ApiRequestException} from '../../../../api/axios-instance';
import {CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {useHistory} from 'react-router-dom';
import {AdminRoutes} from '../../../../../configs/routes';
import {toast} from 'react-toastify';
import {useLoading} from '../../../../hooks/use-loading';
import {Filter, FilterType} from '../../../../components/filters/filters';
import {FilterBuilder} from '../../../../components/filters/filter-builder';
import {BaseListPage} from '../../../base/base-list-page';
import {useContractActionHandler} from './useContractActionHandler';

const ICONS = {
  PLUS: require('../../../../images/svg/Plus.svg'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const ContractsPage: React.FC<any> = () => {
  const intl = useIntl();
  const history = useHistory();
  const api = new ContractsApi();
  const selectApi = new SelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    select: false,
  });

  const {handleContractorAction} = useContractActionHandler();
  const [error, setError] = useState<string | null>(null);
  const [selectValues, setSelectValues] = useState<{
    [key: string]: Array<ISelectValueDto>;
  }>({});
  const [contracts, setContracts] = useState<Array<IContractDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const {showCreateContractModal} = useModalCreateContract();

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'contracts_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: loadings.select,
      allSelectValues: selectValues,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'contractor_id',
          placeholder: intl.formatMessage({id: 'CONTRACTORS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'y_channel_id',
          placeholder: intl.formatMessage({id: 'CHANNELS'}),
        },
      ],
    });
  }, [selectValues, loadings.select]);

  useEffect(() => {
    fetchSelectValues().then();
  }, []);

  const fetchContracts = async (filters?: IQueryParams) => {
    try {
      startLoading('page');
      const result = await api.getContracts(filters);
      setContracts(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const fetchSelectValues = async () => {
    try {
      startLoading('select');
      setSelectValues({
        ...selectValues,
        contractor_id: (await selectApi.getContractors()).data.items,
        user_id: (await selectApi.getUsers()).data.items,
        y_channel_id: (await selectApi.getYoutubeChannels()).data.items,
      });
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('select');
    }
  };

  const handleShowCreateContractModal = async () => {
    const result = await showCreateContractModal();
    if (result.reason === CloseModalReason.OK && result.payload != undefined) {
      history.push(AdminRoutes.getSpecifyContractRoute(result.payload));
    }
  };

  const renderContracts = () => {
    if (contracts.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <ContractsTable onContractAction={handleContractorAction} contracts={contracts} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchContracts}
      toolbarConfig={[
        {
          type: 'BUTTON',
          title: intl.formatMessage({id: 'CREATE_CONTRACT'}),
          icon: ICONS.PLUS,
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: handleShowCreateContractModal,
        },
      ]}>
      {renderContracts()}
    </BaseListPage>
  );
};
