import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {ContractsApi} from '../../../../api/contract-api/contracts-api';
import {ContractStatus, IContractDto} from '../../../../api/contract-api/IContractDto';
import {IAddendumDto} from '../../../../api/addendum-api/IAddendumDto';
import {FormattedMessage} from 'react-intl';
import {IContractorDto} from '../../../../api/contractor-api/IContractorDto';
import SVG from 'react-inlinesvg';
import {useModalCreateAddendum} from '../../../../components/modals/create-addendum/modal-create-addendum-context';
import {AddendumsTable} from './addendums-table';
import {useModalEditContract} from '../../../../components/modals/edit-contract/modal-edit-contract-context';
import {ErrorStub} from '../../../../components/error-stub';
import {Preloader} from '../../../../components/preloader';
import {ApiRequestException} from '../../../../api/axios-instance';
import {useCustomBreadcrumbs} from '../../../../components/breadcrumbs/breadcrumbs-context';
import {AdminRoutes} from '../../../../../configs/routes';
import {Toolbar} from '../../../../components/card-toolbar/Toolbar';
import {useAddendumActionHandler} from './useAddendumActionHandler';
import {useContractActionHandler} from '../contracts-page/useContractActionHandler';
import {TIMESTAMP_CONFIGS, Timestamps} from '../../../../components/timestamps';
import {BootstrapColor} from '../../../../styles/styles';
import {useIntl} from '../../../../hooks/use-intl';
import {CloseModalReason} from '../../../../components/modals/base-modal/CloseModalEvent';
import {useFilePicker} from 'use-file-picker';
import {AddendumApi} from '../../../../api/addendum-api/addendum-api';
import {EntityId} from '../../../../api/base/BaseEntity';
import {toast} from 'react-toastify';

const ICONS = {
  PLUS: require('../../../../images/svg/Plus.svg'),
  EDIT: require('../../../../images/svg/Edit.svg'),
  DOWNLOAD: require('../../../../images/svg/Download.svg'),
};

export enum AddendumActions {
  EDIT = 'EDIT',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD_CUSTOM_TEMPLATE = 'UPLOAD_CUSTOM_TEMPLATE',
  DOWNLOAD_CUSTOM_TEMPLATE = 'DOWNLOAD_CUSTOM_TEMPLATE',
  DELETE_CUSTOM_TEMPLATE = 'DELETE_CUSTOM_TEMPLATE',
}

export enum ContractAction {
  DOWNLOAD = 'DOWNLOAD',
}

export const ContractPage: React.FC = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();

  const api = new ContractsApi();
  const addendumApi = new AddendumApi();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const contractId = match.params.id;
  const [activeTemplateUploadAddendumId, setActiveTemplateUploadAddendumId] = useState<EntityId | null>(null);
  const [contract, setContract] = useState<IContractDto | null>(null);
  const [addendums, setAddendums] = useState<Array<IAddendumDto>>([]);
  const [contractor, setContractor] = useState<IContractorDto | null>(null);
  const {handleContractorAction} = useContractActionHandler();
  const {showEditContractModal} = useModalEditContract();
  const {showCreateAddendumModal} = useModalCreateAddendum();
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const {openFilePicker, plainFiles, clear} = useFilePicker({
    accept: '.docx',
  });

  useEffect(() => {
    const fetchAll = async () => {
      await fetchContract();
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchAll();
  }, []);

  useEffect(() => {
    if (contract?.compositeNumber) {
      setBreadcrumb(contract.compositeNumber);
    }
  }, [contract?.compositeNumber]);

  useEffect(() => {
    if (plainFiles.length === 0 || activeTemplateUploadAddendumId === null) {
      return;
    }

    addendumApi
      .updateTemplateFile(activeTemplateUploadAddendumId, plainFiles[0])
      .then(() => {
        toast.success(intl.formatMessage({id: 'SUCCESS_TEMPLATE_UPLOAD'}));
        void fetchContract();
      })
      .catch(() => toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'})))
      .finally(() => {
        setActiveTemplateUploadAddendumId(null);
        clear();
      });
  }, [plainFiles, activeTemplateUploadAddendumId]);

  const fetchContract = async () => {
    try {
      setLoading(true);
      const result = await api.getContract(Number(contractId));
      setContractor(result.data.relations.contractor);
      setAddendums(result.data.relations.addendums);
      setContract(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const {handleAddendumActions} = useAddendumActionHandler(fetchContract);

  const handleAddendumActionsProxy = (addendum: IAddendumDto, action: AddendumActions) => {
    if (action === AddendumActions.UPLOAD_CUSTOM_TEMPLATE) {
      setActiveTemplateUploadAddendumId(addendum.id);
      openFilePicker();
    }

    return handleAddendumActions(addendum, action);
  };

  const handleOpenEditModalClick = async () => {
    if (!contractor) {
      return;
    }

    await showEditContractModal(Number(contractId), {
      title: contractor.name,
      id: contractor.id.toString(),
    });
    await fetchContract();
  };

  const handleOpenCreateAddendumModalClick = async () => {
    const closeModalResult = await showCreateAddendumModal(contractId);
    if (closeModalResult.reason === CloseModalReason.OK) {
      await fetchContract();
    }
  };

  const handleOpenGenerateReport = async () => {
    if (contract) {
      await handleContractorAction(contract, ContractAction.DOWNLOAD);
    }
  };

  const renderAddendumTable = () => {
    if (addendums.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return (
      <AddendumsTable
        onSelect={(addendum, action) => handleAddendumActionsProxy(addendum, action)}
        addendums={addendums}
      />
    );
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className={'d-flex'}>
            <div className={'flex-grow-1'}>
              <div className={'d-flex align-items-center justify-content-between flex-wrap'}>
                <div>
                  <div className={'d-flex'}>
                    <span className={'font-size-h4 font-weight-boldest'}>
                      <FormattedMessage id={'CONTRACT_NUMBER'} />: {contract?.compositeNumber}
                    </span>
                    <span
                      className='svg-icon svg-icon-lg svg-icon-light-secondary cursor-pointer ml-2'
                      onClick={handleOpenEditModalClick}>
                      <SVG src={ICONS.EDIT} />
                    </span>
                  </div>
                  {contractor?.userId && (
                    <Toolbar
                      items={[
                        {
                          title: intl.formatMessage({id: 'DOWNLOAD_XLSX_REPORT'}),
                          type: 'BUTTON',
                          icon: ICONS.DOWNLOAD,
                          className: 'btn btn-sm my-2 font-weight-bolder text-uppercase btn-light-success',
                          onClick: handleOpenGenerateReport,
                        },
                      ]}
                    />
                  )}
                </div>

                <Timestamps
                  items={[
                    {
                      title: intl.formatMessage({id: 'CONTRACT_START_DATE'}),
                      date: contract?.startedAt ?? null,
                      format: TIMESTAMP_CONFIGS.DEFAULT,
                      color: BootstrapColor.SUCCESS,
                    },
                    {
                      title: intl.formatMessage({id: 'CONTRACT_END_DATE'}),
                      date: contract?.expiresAt ?? null,
                      format: TIMESTAMP_CONFIGS.DEFAULT,
                      color: BootstrapColor.DANGER,
                    },
                  ]}
                />
              </div>
              <div className={'separator separator-solid my-7'} />
              <div className={'d-flex align-items-center flex-wrap'}>
                <div className={'item d-flex align-items-center flex-lg-fill mr-5 my-1'}>
                  <div className={'d-flex flex-column text-dark-75'}>
                    <span className={'font-weight-bolder font-size-sm'}>
                      <FormattedMessage id={'STATUS'} />
                    </span>
                    <span
                      className={`font-weight-bolder font-size-h5 ${
                        contract?.status === ContractStatus.ACTIVE ? 'text-success' : 'text-danger'
                      }`}>
                      <FormattedMessage id={contract?.status ? 'CONTRACT_STATUS_' + contract.status : '-'} />
                    </span>
                  </div>
                </div>
                <div className={'item d-flex align-items-center flex-lg-fill mr-5 my-1'}>
                  <div className={'d-flex flex-column text-dark-75'}>
                    <span className={'font-weight-bolder font-size-sm'}>
                      <FormattedMessage id={'CONTRACTOR_NAME'} />
                    </span>
                    <span className={'font-weight-bolder font-size-h5'}>
                      <Link
                        to={
                          contract?.contractorId ? AdminRoutes.getSpecifyContractorRoute(contract.contractorId) : '#'
                        }>
                        {contractor?.name || 'N/A'}
                      </Link>
                    </span>
                  </div>
                </div>
                <div className={'item d-flex align-items-center flex-lg-fill mr-5 my-1'}>
                  <div className={'d-flex flex-column text-dark-75'}>
                    <span className={'font-weight-bolder font-size-sm'}>
                      <FormattedMessage id={'USER_NAME'} />
                    </span>
                    <span className={'font-weight-bolder font-size-h5'}>{contractor?.userName || 'N/A'}</span>
                  </div>
                </div>
              </div>

              <div className={'separator separator-solid my-7'} />
              <div className={'font-size-h4 font-weight-boldest'}>
                <FormattedMessage id={'ADDENDUM_LIST'} />
              </div>
              <Toolbar
                items={[
                  {
                    title: intl.formatMessage({id: 'CREATE_ADDENDUM'}),
                    type: 'BUTTON',
                    icon: ICONS.PLUS,
                    className: 'btn btn-sm my-2 font-weight-bolder text-uppercase btn-light-success',
                    onClick: handleOpenCreateAddendumModalClick,
                  },
                ]}
              />
            </div>
          </div>
          {renderAddendumTable()}
        </div>
        <label id={'label-file-dummy'} htmlFor={'file-dummy'} style={{display: 'none'}}></label>
      </div>
    </>
  );
};
