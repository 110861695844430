import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../configs/routes';
import {IContractorDto} from '../api/contractor-api/IContractorDto';

interface IProps {
  contractor: IContractorDto | null;
}

export const ContractorInfo: React.FC<IProps> = ({contractor}) => {
  const intl = useIntl();

  if (contractor == null) {
    return null;
  }

  return <>
    <div className={'font-size-h4 font-weight-boldest my-5'}>
      <FormattedMessage id={'CONTRACTOR'} />
    </div>
    <div className={'row'}>
      <div className={'col-xl-4 col-md-12'}>
        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
                  <span className={'font-weight-bolder font-size-sm'}>
                    <FormattedMessage id={'CONTRACTOR_NAME'} />
                  </span>
            <Link to={AdminRoutes.getSpecifyContractorRoute(contractor.id)}>
              <span className={'font-size-h5'}>{contractor?.name}</span>
            </Link>
          </div>
        </div>
      </div>

      <div className={'col-xl-4 col-md-12'}>
        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
            <span className={'font-weight-bolder font-size-sm'}><FormattedMessage id={'CREATED_AT'} /></span>
            <span
              className={'font-size-h5'}>{intl.formatDate(contractor.createdAt) + ' ' + intl.formatTime(contractor.updatedAt)}</span>
          </div>
        </div>
      </div>

      <div className={'col-xl-4 col-md-12'}>
        <div className={'d-flex align-items-center mr-5 my-1'}>
          <div className={'d-flex flex-column text-dark-75'}>
            <span className={'font-weight-bolder font-size-sm'}><FormattedMessage id={'UPDATED_AT'} /></span>
            <span
              className={'font-size-h5'}>{intl.formatDate(contractor.updatedAt) + ' ' + intl.formatTime(contractor.updatedAt)}</span>
          </div>
        </div>
      </div>
    </div>
  </>;
};
