import React from 'react';
import {PaymentRequisitesHistory} from '../../../api/payment-requisite-api/IPaymentRequisitesDto';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {useToggle} from '../../../hooks/use-toggle';
import {Svg} from '../../../images/svg';
import {ICONS} from '../../../images/images';
import {BootstrapColor} from '../../../styles/styles';
import {useIntl} from '../../../hooks/use-intl';

type Props = {
  history: Array<PaymentRequisitesHistory>
}


export const PaymentRequisitesHistoryBlock: React.FC<Props> = ({history}) => {
  return <>
    <table className={'table table-striped'}>
      <thead>
      <td>Автор</td>
      <td>Изменения</td>
      </thead>
      <tbody>
      {history?.reverse().map(historyRecord => {
        return <tr key={historyRecord.id}>
          <PaymentRequisitesHistoryItem item={historyRecord} />
        </tr>;
      })}
      </tbody>
    </table>

  </>;
};

const PaymentRequisitesHistoryItem: React.FC<{item: PaymentRequisitesHistory}> = ({item: historyRecord}) => {
  const intl = useIntl();
  const [visible, toggleVisible] = useToggle();

  return (
    <>
      <td width={'40%'}>
        <span className='mr-3 font-weight-bolder'>
          {historyRecord.author_id && (
            <span className={'text-muted'}>
                <Link
                  to={AdminRoutes.getSpecifyUserManagementRoute(historyRecord.author_id)}>
                  {historyRecord.author_name}
                </Link>
              </span>
          )}
        </span>
        <br />
        <span className='text-muted text-right'>
              {CustomFormatter.formatDateTimeWithFullMonth(historyRecord.created_at)}
          </span>
      </td>
      <td>
        <table className={'table w-100 mb-0 table-borderless table-no-striped'}>
          <tbody>
          <tr>
            <td onClick={toggleVisible} className={'font-weight-bolder'}>
              <Svg
                className={'mr-3 cursor-pointer'}
                src={visible ? ICONS.MINUS : ICONS.PLUS}
                color={BootstrapColor.PRIMARY}
              />
              <span className={'text-primary'}>
                {intl.formatMessage({id: visible ? 'HIDE' : 'SHOW_ALL'})}
              </span>
            </td>
          </tr>
          {historyRecord.requisites && visible && (
            Object.entries(historyRecord.requisites).map(([key, value]) => {
              return (
                <tr key={key}>
                  <td width={'40%'}>{intl.formatMessage({id: `REQUISITES_HISTORY_${key}`})}</td>
                  <td>{value}</td>
                </tr>
              );
            })
          )}
          </tbody>
        </table>
      </td>
    </>
  );
};



