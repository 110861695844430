import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import {useModalCreateSupportTicket} from '../../components/modals/create-support-ticket-modal/modal-create-support-ticket-context';
import {SupportTicketsApi} from '../../api/support-tickets-api/support-tickets-api';
import {IPaginationInfo} from '../../api/Paginator';
import {ISupportTicketDto} from '../../api/support-tickets-api/ISupportTicketDto';
import {ErrorStub} from '../../components/error-stub';
import {TicketsTable} from './tickets-table';
import SVG from 'react-inlinesvg';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {BaseListPage} from '../base/base-list-page';
import {ApiRequestException} from '../../api/axios-instance';

interface IProps {
  isAdmin?: boolean;
}

const ICONS = {
  PLUS: require('../../images/svg/Plus.svg'),
};

const ILLUSTRATIONS = {
  WRITE: require('../../images/illustration/Write.png'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const TicketsPages: React.FC<IProps> = ({isAdmin}) => {
  const intl = useIntl();

  const api = new SupportTicketsApi();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tickets, setTickets] = useState<Array<ISupportTicketDto>>([]);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const {showCreateSupportTicketModal} = useModalCreateSupportTicket();

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'tickets_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      filters: [
        {
          type: FilterType.SELECT,
          name: 'status',
          placeholder: intl.formatMessage({id: 'TICKET_STATUS'}),
          options: [
            {
              label: intl.formatMessage({id: 'OPEN_TICKETS'}),
              value: 'OPEN',
            },
            {
              label: intl.formatMessage({id: 'CLOSED_TICKETS'}),
              value: 'CLOSED',
            },
          ],
        },
      ],
    });
  }, []);

  const fetchTickets = async (filters: IQueryParams) => {
    try {
      setLoading(true);
      const response = await api.getTickets(filters);
      setTickets(response.data.items);
      setPaginationInfo(response.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCreateTicket = async () => {
    await showCreateSupportTicketModal();
  };

  const renderSupportTickets = () => {
    if (tickets.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <TicketsTable isAdmin={isAdmin} tickets={tickets} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  const renderMarketingBlock = () => {
    return (
      <div className='card'>
        <div className='card-body p-0'>
          <div className='card-px text-center pt-20 mt-10'>
            <h2 className='font-weight-boldest mt-10'>
              <FormattedMessage id={'TICKETS_NOT_FOUND'} />
            </h2>
            <p className='text-gray-400 font-weight-bolder mb-10 save-line-and-tabulation'>
              <FormattedMessage id={'CREATE_TICKET_MARKETING_DESCRIPTION'} />
            </p>
            <button onClick={handleCreateTicket} className={'btn font-weight-bolder text-uppercase btn-primary'}>
              <span className={`svg-icon svg-icon-light-primary svg-icon-2x mr-2`}>
                <SVG src={ICONS.PLUS} />
              </span>
              <FormattedMessage id={'CREATE_TICKET'} />
            </button>
          </div>
          <div className='text-center px-4'>
            <img className={'mw-100 mh-45vh'} src={ILLUSTRATIONS.WRITE} alt='nones' />
          </div>
        </div>
      </div>
    );
  };

  return (
    <BaseListPage
      loading={loading}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      toolbarConfig={[
        {
          type: 'BUTTON',
          icon: ICONS.PLUS,
          title: intl.formatMessage({id: 'CREATE_TICKET'}),
          className: 'btn font-weight-bolder text-uppercase btn-light-success',
          onClick: handleCreateTicket,
        },
      ]}
      pagination={{info: paginationInfo}}
      fetchData={fetchTickets}
      showMarketing={tickets?.length === 0}
      renderMarketing={renderMarketingBlock}>
      {renderSupportTickets()}
    </BaseListPage>
  );
};
