import React, {useEffect, useState} from 'react';
import {useApplicationSettingsApi} from '../../../hooks/apis/use-application-settings-api';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {ApplicationSettings} from '../../../api/DTOs/ApplicationSettings';
import {PartialNullable} from '../../../../types';
import {useLoading} from '../../../hooks/use-loading';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {isValidationException} from '../../../api/api-utils';
import {SelectOptions} from '../../../components/Inputs/InputSelect';
import {LocalizationKey, useIntl} from '../../../hooks/use-intl';
import {SelectApi} from '../../../api/select-api';
import {Card, CardBody, CardTitle} from '../../../components/card';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {ValidateErrorWrapper} from '../../../components/Inputs/ValidateErrorWrapper';
import {InputCheckbox} from '../../../components/Inputs/InputCheckbox';
import {toast} from 'react-toastify';
import {ICONS} from '../../../images/images';
import {InputMultiSelect} from '../../../components/Inputs/InputMultiSelect';
import {UserType} from '../../../api/DTOs/IUserDto';

const usersPrivileges: Array<{
  key: keyof ApplicationSettings;
  title: LocalizationKey;
}> = [
  {
    key: 'advertisement_requests_users_ids',
    title: 'ADS_REQUEST_NOTICE_RECIPIENT',
  },
  {
    key: 'change_payment_requisites_users_ids',
    title: 'CHANGE_PAYMENT_REQUISITES_NOTICE_RECIPIENT',
  },
  {
    key: 'youtube_channel_requests_users_ids',
    title: 'CONNECT_CHANNEL_REQUEST_NOTICE_RECIPIENT',
  },
  {
    key: 'payment_requests_users_ids',
    title: 'PAYMENT_REQUEST_NOTICE_RECIPIENT',
  },
  {
    key: 'tickets_users_ids',
    title: 'SUPPORT_TICKET_NOTICE_RECIPIENT',
  },
  {
    key: 'mc_pay_integrations_users_ids',
    title: 'MC_PAY_INTEGRATION_NOTICE_RECIPIENT',
  },
  {
    key: 'import_youtube_data_users_ids',
    title: 'IMPORT_YOUTUBE_DATA_RECIPIENT',
  },
];

export const ApplicationSettingsPage: React.FC = () => {
  const intl = useIntl();
  const selectApi = new SelectApi();
  const [selectUsers, setSelectUsers] = useState<SelectOptions>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>();
  const [loadings, startLoading, stopLoading] = useLoading({settings: true, users: true, update: false});
  const api = useApplicationSettingsApi();
  const [settings, setSettings, updateSettingsFields] = useAdvancedState<PartialNullable<ApplicationSettings>>(
    {},
    setValidationErrors,
  );

  useEffect(() => {
    Promise.all([fetchSettings(), fetchUsers()]).then();
  }, []);

  const fetchSettings = async () => {
    try {
      startLoading('settings');
      const {
        data: {item},
      } = await api.getAll();
      setSettings(item);
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
    } finally {
      stopLoading('settings');
    }
  };

  const fetchUsers = async () => {
    try {
      startLoading('users');
      const {
        data: {items},
      } = await selectApi.getUsers({filters: {type: UserType.ADMIN}});
      setSelectUsers(items.map(i => ({value: i.id, label: i.title})));
    } finally {
      stopLoading('users');
    }
  };

  const handleUpdate = async () => {
    try {
      startLoading('update');
      const patchedSettings = {} as PartialNullable<ApplicationSettings>;
      const selectUsersIds = selectUsers.map(u => u.value);
      Object.entries(settings).forEach(([key, value]) => {
        if (Array.isArray(value) && key.includes('users_ids')) {
          // @ts-ignore
          patchedSettings[key] = value.filter(v => v != null && selectUsersIds.includes(v));
        } else {
          // @ts-ignore
          patchedSettings[key] = value;
        }
      });
      const {
        data: {item},
      } = await api.update(patchedSettings);
      setSettings(item);
      toast.success(intl.formatMessage({id: 'SUCCESS_UPDATE_SETTINGS'}));
    } catch (e) {
      if (isValidationException(e)) {
        setValidationErrors(e.innerException.error_data.messages);
      }
    } finally {
      stopLoading('update');
    }
  };

  const handleChangeSelectValue = (field: keyof ApplicationSettings) => {
    return (value: SelectOptions) => {
      updateSettingsFields({[field]: value?.map(v => v.value) ?? []});
    };
  };

  return (
    <Card hideFooter>
      <CardTitle>{intl.formatMessage({id: 'APPLICATION_SETTINGS'})}</CardTitle>
      <CardBody>
        <div className={'max-w-lg-550px'}>
          <>
            {usersPrivileges.map(u => {
              return (
                <ValidateErrorWrapper key={u.key} message={getValidationErrorMessage(u.key, validationErrors)}>
                  <InputMultiSelect
                    label={intl.formatMessage({id: u.title})}
                    placeholder={''}
                    isLoading={loadings.users}
                    hasError={!!getValidationErrorMessage(u.key, validationErrors)}
                    className={'mb-2'}
                    //@ts-ignore
                    defaultValues={settings[u.key]}
                    options={selectUsers}
                    onChange={handleChangeSelectValue(u.key)}
                  />
                </ValidateErrorWrapper>
              );
            })}
            <ValidateErrorWrapper
              message={getValidationErrorMessage('need_send_transaction_notification', validationErrors)}>
              <InputCheckbox
                label={intl.formatMessage({id: 'NEED_SEND_TRANSACTION_EMAIL_NOTIFICATION'})}
                value={settings.need_send_transaction_notification ?? false}
                onChange={() =>
                  updateSettingsFields({
                    need_send_transaction_notification: !settings.need_send_transaction_notification,
                  })
                }
              />
            </ValidateErrorWrapper>
            <ValidateErrorWrapper message={getValidationErrorMessage('enable_mc_pay_integrations', validationErrors)}>
              <InputCheckbox
                label={intl.formatMessage({id: 'MC_PAY_INTEGRATION_ENABLE'})}
                value={settings.enable_mc_pay_integrations ?? false}
                onChange={() =>
                  updateSettingsFields({
                    enable_mc_pay_integrations: !settings.enable_mc_pay_integrations,
                  })
                }
              />
            </ValidateErrorWrapper>
          </>
          <div className={'text-center my-5'}>
            <Toolbar
              items={[
                {
                  loading: loadings.update,
                  disabled: loadings.update,
                  icon: ICONS.DOWNLOAD,
                  type: 'BUTTON',
                  title: intl.formatMessage({id: 'SAVE'}),
                  className: 'btn btn-light-primary',
                  onClick: handleUpdate,
                },
              ]}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
