import React from 'react';
import './widget.scss';
import {WidgetBase} from './widget-base';

interface IWidgetProps {
  title: string;
  actionButton?: JSX.Element;
  className?: string;
  background?: string;

  onClick?: () => void;
}

export const WidgetWithActionButton: React.FC<IWidgetProps> = (
  {
    className,
    background,
    title,
    actionButton,
    onClick,
  }) => {
  return <WidgetBase
    onClick={onClick}
    className={`bgi-no-repeat bgi-size-contain ${className}`}
    style={{
      backgroundImage: `url(${background})`, backgroundPosition: 'calc(100% + 0.5rem) 100%',
    }}>
    <div className='card-body d-flex align-items-center'>
      <div>
        <h3 className={'font-weight-bolder'}>{title}</h3>
        {actionButton && actionButton}
      </div>
    </div>
  </WidgetBase>;
};
