import React from 'react';
import {getLocaleConfig, useLang} from './metronic-i18n';
import {createIntl, createIntlCache, IntlProvider} from 'react-intl';
import en from './messages/en';
import ru from './messages/ru';

export enum AppLocale {
  RU = 'ru',
  EN = 'en',
}

const allMessages = {
  en,
  ru,
};

const cache = createIntlCache();
export const vanillaIntl = createIntl(
  {
    locale: getLocaleConfig().selectedLang,
    defaultLocale: 'ru',
    messages: allMessages[getLocaleConfig().selectedLang],
  },
  cache,
);

export function I18nProvider({children}: any) {
  const locale = useLang();
  // @ts-ignore
  const messages = allMessages[locale];
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
