import {BaseEndpoint} from '../base-endpoint';
import {IResponseBase} from '../response-contracts/base-response';
import config from '../../../configs/config';
import {IDashboardDataDto} from './IDashboardDataDto';
import {IDashboardAdminDataDto} from './IDashboardAdminDataDto';

export type RevenueDataDto = {
  revenue: string | number;
  month: number;
  year: number;
};

export type CmsRevenueDataDto = {
  cmsRevenue1: RevenueDataDto[];
  cmsRevenue2: RevenueDataDto[];
};

export class DashboardApi extends BaseEndpoint {
  public async getUserDashboard(): Promise<IResponseBase<IDashboardDataDto>> {
    const response = await this.get<IResponseBase>(`${config.BASE_API_URL}/dashboard`);
    return response.data;
  }

  public async getAdminDashboard(): Promise<IResponseBase<IDashboardAdminDataDto>> {
    const response = await this.get<IResponseBase>(`${config.BASE_API_URL}/admin/dashboard`);
    return response.data;
  }

  public async getCmsRevenues(): Promise<IResponseBase<CmsRevenueDataDto>> {
    const response = await this.get<IResponseBase>(`${config.BASE_API_URL}/admin/cms-revenues`);
    return response.data;
  }
}
