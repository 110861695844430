import React, {createContext, useContext, useState} from 'react';
import {ConfirmModalOptions, ModalConfirmAction} from './modal-confirm';


interface IModalConfirmActionContext {
  modalConfirmActionVisible: boolean;

  showConfirmActionModal(confirmationMessage: string, description?: string | null, options?: ConfirmModalOptions): Promise<boolean>;
}

// @ts-ignore
const ModalConfirmActionContext = createContext<IModalConfirmActionContext>();

let closeResolver: ((value: (boolean | PromiseLike<boolean>)) => void) | null = null;
export const ModalConfirmActionProvider = ({children}: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [options, setOptions] = useState<ConfirmModalOptions>({});

  const showModal = async (message: string, description?: string, opts?: ConfirmModalOptions) => {
    setConfirmationMessage(message);
    if (description) {
      setDescription(description);
    }

    if (opts) {
      setOptions(opts);
    }

    setVisible(true);
    return new Promise<boolean>((resolve) => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setOptions({});
    setDescription('');
    setConfirmationMessage('');
    if (closeResolver) {
      closeResolver(false);
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    if (closeResolver) {
      closeResolver(true);
      closeResolver = null;
    }
    await handleHideModal();
  };

  const value: IModalConfirmActionContext = {
    modalConfirmActionVisible: visible,
    showConfirmActionModal: showModal,
  };

  return <ModalConfirmActionContext.Provider value={value}>
    {children}
    <ModalConfirmAction
      options={options}
      confirmationMessage={confirmationMessage}
      description={description}
      visible={visible}

      onOkClick={handleOkClick}
      onHide={handleHideModal}
    />
  </ModalConfirmActionContext.Provider>;
};

export const useModalConfirmAction = () => {
  return useContext(ModalConfirmActionContext);
};
