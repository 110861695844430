import {BaseEndpoint} from './base-endpoint';
import {ISelectResponse} from './response-contracts/yotutube-channel-contracts';
import config from '../../configs/config';
import {IQueryParams} from './DTOs/IFilterDtos';

export class SelectApi extends BaseEndpoint {
  public async getAccounts(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/admin/accounts/select`);
    return response.data;
  }

  public async getJurisdictions(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/admin/jurisdictions/select`);
    return response.data;
  }

  public async getAllCMS(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/cms/select`);
    return response.data;
  }

  public async getAddendums(params?: IQueryParams): Promise<ISelectResponse> {
    const queries = this.applyQueryParams(params);
    const response = await this.getSelectValues(`${config.BASE_API_URL}/admin/addendums/select?${queries}`);
    return response.data;
  }

  public async getUsers(params?: IQueryParams): Promise<ISelectResponse> {
    const queries = this.applyQueryParams(params);
    const response = await this.getSelectValues(`${config.BASE_API_URL}/admin/users/select?${queries}`);
    return response.data;
  }

  public async getContractors(params?: IQueryParams): Promise<ISelectResponse> {
    const queries = this.applyQueryParams(params);
    const response = await this.getSelectValues(`${config.BASE_API_URL}/admin/contractors/select?${queries}`);
    return response.data;
  }

  public async getAdminContracts(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/admin/contracts/select`);
    return response.data;
  }

  public async getYoutubeChannels(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/youtube-channels/select`);
    return response.data;
  }

  public async getSelfContracts(): Promise<ISelectResponse> {
    const response = await this.getSelectValues(`${config.BASE_API_URL}/contracts/select`);
    return response.data;
  }
}
