import {IContractDto} from '../../../../api/contract-api/IContractDto';
import {ContractAction} from '../contract-page/contract-page';
import {
  useModalGenerateFinancialReport
} from '../../../../components/modals/generate-financial-report/modal-generate-financial-report-context';

export function useContractActionHandler() {
  const {showGenerateFinancialReportModal} = useModalGenerateFinancialReport();
  const handle = async (contract: IContractDto, action: ContractAction) => {
    switch (action) {
      case ContractAction.DOWNLOAD: {
        if (!contract.userId) {
          return;
        }

        await showGenerateFinancialReportModal(contract.userId, {
          id: contract.id.toString(),
          title: contract.compositeNumber,
        });
      }
        break;
      default:
        return;
    }
  };
  return {
    handleContractorAction: handle,
  };
}
