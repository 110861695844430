import React from 'react';
import {DropdownCustomButton} from './dropdown';

type Action<T extends string> = {
  title: string;
  value: T;
};

type Props<T, Y extends string> = {
  rowDto: T;
  actions: Array<Action<Y>>;
  onSelect: (rowDto: T, action: Y) => Promise<any>;
};

export function RowActionButton<T, Y extends string>({rowDto, actions, onSelect}: Props<T, Y>) {
  const handleChange = async (action: Y) => {
    await onSelect(rowDto, action);
  };

  return (
    <DropdownCustomButton
      label={'•••'}
      className={'btn btn-xs btn-secondary font-weight-bolder'}
      onSelect={action => handleChange(action as any)}
      values={actions}
    />
  );
}
