import React, {useCallback, useEffect, useState} from 'react';
import {useModalSignature} from '../../components/modals/signature-modal/modal-signature-context';
import {IAddendumForUserDto} from '../../api/addendum-api/IAddendumDto';
import {toast} from 'react-toastify';
import {useLoading} from '../../hooks/use-loading';
import {ProfileApi} from '../../api/signature-api/profile-api';
import {useIntl} from '../../hooks/use-intl';
import {EntityId} from '../../api/base/BaseEntity';
import {saveAs} from 'file-saver';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {ProfileAddendumComponent} from './profile-addendum-component';
import {Card, CardBody, CardTitle} from '../../components/card';
import {Preloader} from '../../components/preloader';
import {NumberParam, useQueryParams} from 'use-query-params';

export const ProfileAddendumsBlock: React.FC = () => {
  const intl = useIntl();
  const profileApi = new ProfileApi();

  const [query, setQuery] = useQueryParams({
    signAddendumId: NumberParam,
  });

  const {showSignatureModal} = useModalSignature();
  const [addendums, setAddendums] = useState<IAddendumForUserDto[]>([]);
  const [loadings, startLoading, stopLoading] = useLoading({
    addendums: true,
  });

  useEffect(() => {
    fetchAddendums().then();
  }, []);

  useEffect(() => {
    if (query.signAddendumId != null && addendums.length !== 0) {
      const addendum = addendums.find(a => a.id == query.signAddendumId && a.signature_base64 == null);
      if (addendum != null) {
        handleSignatureClick(addendum).finally(() => setQuery({signAddendumId: undefined}));
      }
    }
  }, [addendums]);

  const fetchAddendums = async () => {
    try {
      startLoading('addendums');
      const result = await profileApi.getAddendums();
      setAddendums(result.data.items);
    } catch (e) {
      toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('addendums');
    }
  };

  const handleDownloadClick = useCallback(async (addendumId: EntityId) => {
    try {
      const result = await profileApi.downloadAddendum(addendumId);
      saveAs(result.content, result.fileName);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  const handleSignatureClick = useCallback(async (addendum: IAddendumForUserDto) => {
    try {
      const {reason} = await showSignatureModal(addendum.id, addendum.signature_base64);
      if (reason === CloseModalReason.OK) {
        setQuery({signAddendumId: undefined});
        await fetchAddendums();
      }
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  }, []);

  return (
    <Card hideFooter className={{body: 'bg-light'}}>
      <CardTitle>
        <span className={'font-weight-bolder'}>{intl.formatMessage({id: 'CONTRACTS'})}</span>
      </CardTitle>
      <CardBody>
        {loadings.addendums ? (
          <Preloader />
        ) : addendums.length > 0 ? (
          addendums.map(addendum => {
            return (
              <ProfileAddendumComponent
                key={addendum.id}
                addendum={addendum}
                onDownloadClick={handleDownloadClick}
                onChangeSignature={handleSignatureClick}
              />
            );
          })
        ) : (
          <div className={'text-center'}>{intl.formatMessage({id: 'NOT_FOUND'})}</div>
        )}
      </CardBody>
    </Card>
  );
};
