import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {EntityId} from '../../../api/base/BaseEntity';
import {ModalApproveConnectChannelRequest} from './approve-connect-channel-requests-modal';
import {
  ApproveConnectChannelRequestFields,
  ConnectChannelsApi,
} from '../../../api/connect-channel-api/connect-channels-api';
import {prepareDate, ValidationErrorsType} from '../../../utils/utils';
import {useLoading} from '../../../hooks/use-loading';
import {SelectApi} from '../../../api/select-api';
import {toast} from 'react-toastify';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';

type OpenModalParams = {
  connectChannelRequestId: EntityId;
};

interface ContextProps {
  modalApproveConnectChannelRequestVisible: boolean;

  showApproveConnectChannelRequestModal(props: OpenModalParams): Promise<CloseModalEvent>;
}

// @ts-ignore
const ModalEditPaymentRequisitesContext = createContext<ContextProps>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalApproveConnectChannelRequestsProvider = ({children}: any) => {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    fetchCms: true,
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [connectChannelRequestId, setConnectChannelRequestId] = useState<EntityId | null>(null);

  const api = new ConnectChannelsApi();
  const selectApi = new SelectApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] =
    useState<ValidationErrorsType<ApproveConnectChannelRequestFields>>(null);
  const [fields, setFields, updateFields] = useAdvancedState<ApproveConnectChannelRequestFields>({});
  const [CMSes, setCMSes] = useState<ISelectValueDto[]>([]);
  const showModal = async ({connectChannelRequestId}: OpenModalParams) => {
    setVisible(true);
    if (connectChannelRequestId) {
      setConnectChannelRequestId(connectChannelRequestId);
    }

    return new Promise<CloseModalEvent<null>>(resolve => {
      fetchCMS().then();
      closeResolver = resolve;
    });
  };

  const fetchCMS = async () => {
    try {
      startLoading('fetchCms');
      const response = await selectApi.getAllCMS();
      setCMSes(response.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('fetchCms');
    }
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    setValidationError(null);
    setFields({});

    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      if (connectChannelRequestId == null) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Required specify connect channel request ID');
      }
      setError(null);
      await api.approve(connectChannelRequestId, {
        ...fields,
        contract_started_at: prepareDate(fields.contract_started_at),
      });
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages as any);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: ContextProps = {
    modalApproveConnectChannelRequestVisible: visible,
    showApproveConnectChannelRequestModal: showModal,
  };

  return (
    <ModalEditPaymentRequisitesContext.Provider value={value}>
      {children}
      <ModalApproveConnectChannelRequest
        fields={fields}
        isCmsLoading={loadings.fetchCms}
        CMSes={CMSes}
        onUpdate={updateFields}
        visible={visible}
        error={error}
        validationErrors={validationErrors}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalEditPaymentRequisitesContext.Provider>
  );
};

export const useModalApproveConnectChannelRequest = () => {
  return useContext(ModalEditPaymentRequisitesContext);
};
