import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ChangePassword} from '../../modules/Auth/pages/change-password';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {FormattedMessage, useIntl} from 'react-intl';
import SVG from 'react-inlinesvg';
import {PaymentRequisites, PaymentRequisitesApi} from '../../api/payment-requisite-api/payment-requisites-api';
import {ApiRequestException} from '../../api/axios-instance';
import {toast} from 'react-toastify';
import {Preloader} from '../../components/preloader';
import {PaymentRequisitesInfo} from '../../components/payment-requisites-info';
import {useLoading} from '../../hooks/use-loading';
import {ProfileAddendumsBlock} from './profile-addendums-block';
import {useModalEditPaymentRequisites} from '../../components/modals/edit-requisites/modal-edit-requisites-admin-context';
import {WidgetFirst} from '../../components/dashboard-widgets/widget-first';
import {ICONS} from '../../images/images';
import {useHistory} from 'react-router-dom';
import {useModalConnectChannel} from '../../components/modals/connect-youtube-channel/connect-youtube-channel-modal-context';
import {Routes} from '../../../configs/routes';
import {useUserRole} from '../../hooks/use-user-role';
import {ConnectChannelsApi} from '../../api/connect-channel-api/connect-channels-api';
import {IConnectChannelRequestDto} from '../../api/connect-channel-api/IConnectChannelRequestDto';
import {ProfileConnectChannelsRequests} from './profile-connect-channels-requests';
import {FilterRequestStatus} from '../../api/connect-channel-api/FilterRequestStatus';

export const ProfilePage: React.FC<any> = () => {
  const intl = useIntl();
  const [isAdmin, user] = useUserRole();
  const history = useHistory();
  const paymentRequisitesApi = useMemo(() => new PaymentRequisitesApi(), []);
  const connectChannelsApi = useMemo(() => new ConnectChannelsApi(), []);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    signature: true,
    addendums: true,
    paymentRequisites: true,
    connectChannelsRequests: true,
  });

  const [paymentRequisites, setPaymentRequisites] = useState<PaymentRequisites | null>(null);
  const [connectChannelsRequests, setConnectChannelsRequests] = useState<IConnectChannelRequestDto[]>([]);
  const {showEditPaymentRequisitesAdminModal} = useModalEditPaymentRequisites();
  const {showConnectYoutubeChannelModal} = useModalConnectChannel();

  useEffect(() => {
    Promise.all([fetchPaymentRequisites(), fetchChannelRequests()]).then();
  }, []);

  const fetchPaymentRequisites = useCallback(async () => {
    try {
      startLoading('paymentRequisites');
      const result = await paymentRequisitesApi.getSelfPaymentRequisites();
      setPaymentRequisites(result.data.item);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('paymentRequisites');
    }
  }, []);

  const fetchChannelRequests = useCallback(async () => {
    if (isAdmin) {
      return;
    }
    try {
      startLoading('connectChannelsRequests');
      const result = await connectChannelsApi.getSelfConnectChannelRequest();
      setConnectChannelsRequests(result.data.items.filter(r => r.requestStatus !== FilterRequestStatus.APPROVED));
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('connectChannelsRequests');
    }
  }, []);

  const handleAddChannelClick = useCallback(async () => {
    const closeModalResults = await showConnectYoutubeChannelModal();
    if (closeModalResults.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_CREATE_CONNECT_CHANNEL_REQUEST'}));
      fetchChannelRequests().then();
    }
  }, []);

  const handleChangePaymentRequisites = useCallback(async () => {
    const result = await showEditPaymentRequisitesAdminModal({requisites: paymentRequisites, user});

    if (result.reason === CloseModalReason.OK) {
      await fetchPaymentRequisites();
    }
  }, [paymentRequisites]);

  const renderPaymentRequisites = () => {
    return (
      <>
        <div className={'card card-custom gutter-b'}>
          <div className={'card-header py-3'}>
            <div className='card-title'>
              <h3 className='card-label font-weight-bolder text-dark'>
                <FormattedMessage id={'PAYMENT_REQUISITES'} />
                <span
                  onClick={handleChangePaymentRequisites}
                  className={`svg-icon svg-icon-lg svg-icon-light-secondary ml-2 cursor-pointer`}>
                  <SVG src={ICONS.EDIT} />
                </span>
              </h3>
            </div>
          </div>
          <div className={`card-body`}>
            {loadings.paymentRequisites ? (
              <Preloader />
            ) : (
              <PaymentRequisitesInfo paymentRequisites={paymentRequisites} />
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!isAdmin && (
        <div className={'row'}>
          <div className={'col-xl-4 col-md-12'}>
            <WidgetFirst
              icon={ICONS.YOUTUBE}
              value={intl.formatMessage({id: 'JOINING'})}
              subtitle={intl.formatMessage({id: 'SEND_JOINING_REQUEST'})}
              style={'danger'}
              onClick={handleAddChannelClick}
            />
          </div>
          <div className={'col-xl-4 col-md-12'}>
            <WidgetFirst
              icon={ICONS.DOLLAR}
              value={intl.formatMessage({id: 'PROMOTION'})}
              subtitle={intl.formatMessage({id: 'ADS_INTEGRATION'})}
              style={'success'}
              onClick={() => history.push(Routes.getAdvertisementsRoute())}
            />
          </div>
          <div className={'col-xl-4 col-md-12'}>
            <WidgetFirst
              icon={ICONS.CHECK}
              value={intl.formatMessage({id: 'EXPERIENCE'})}
              subtitle={intl.formatMessage({id: 'KNOWLEDGE_BASE'})}
              style={'primary'}
            />
          </div>
        </div>
      )}
      <div className={'row'}>
        <div className={'col-md-6'}>
          {renderPaymentRequisites()}
          <ProfileAddendumsBlock />
        </div>
        <div className={'col-md-6'}>
          {!isAdmin && connectChannelsRequests.length !== 0 && (
            <ProfileConnectChannelsRequests
              loading={loadings.connectChannelsRequests}
              connectChannelsRequests={connectChannelsRequests}
            />
          )}
          <ChangePassword />
        </div>
      </div>
    </>
  );
};
