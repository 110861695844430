import React, {useMemo} from 'react';
import {FormattedNumber, useIntl} from 'react-intl';
import {IAddendumDto} from '../../../../api/addendum-api/IAddendumDto';
import {Link} from 'react-router-dom';
import {DefaultTable} from '../../../../components/default-table';
import {RowActionButton} from '../../../../components/table-actions-button';
import {AddendumActions} from './contract-page';
import {AdminRoutes, Routes} from '../../../../../configs/routes';
import {tryGetPreviewOrStub} from '../../../../utils/utils';
import {TableUtils} from '../../../../components/table-utils';
import {Svg} from '../../../../images/svg';
import {ICONS} from '../../../../images/images';
import {BootstrapColor, BootstrapSizes} from '../../../../styles/styles';

interface Props {
  addendums: Array<IAddendumDto>;
  onSelect: (addendum: IAddendumDto, action: AddendumActions) => Promise<any>;
}

export const AddendumsTable = ({addendums, onSelect}: Props) => {
  const intl = useIntl();
  const data = addendums;

  const handleSelect = async (rowDto: IAddendumDto, action: AddendumActions) => {
    await onSelect(rowDto, action);
  };

  const columns = useMemo(() => {
    return [
      {
        id: 'Id',
        Header: 'ID',
        maxWidth: 50,
        minWidth: 50,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(value.id);
        },
      },
      {
        id: 'Number',
        Header: '№',
        maxWidth: 50,
        minWidth: 50,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(value.number);
        },
      },
      {
        id: 'Channel',
        Header: intl.formatMessage({id: 'CHANNEL'}),
        maxWidth: 50,
        minWidth: 100,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={Routes.getYoutubeSpecifyChannelRoute(value.youtubeChannelId)}>
              <img
                src={tryGetPreviewOrStub(value.youtubeChannelThumbnails).url}
                alt='image'
                className={'w-50 rounded'}
              />
            </Link>,
          );
        },
      },
      {
        id: 'Header',
        Header: '',
        maxWidth: 250,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={Routes.getYoutubeSpecifyChannelRoute(value.youtubeChannelId)}>
              <span className={'font-weight-bolder'}>{value.youtubeChannelTitle}</span>
            </Link>,
          );
        },
      },
      {
        id: 'Contractor_Name',
        Header: intl.formatMessage({id: 'CONTRACTOR'}),
        maxWidth: 250,
        minWidth: 250,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(
            <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractorId)}>{value.contractorName}</Link>,
          );
        },
      },
      {
        id: 'HasSignature',
        Header: intl.formatMessage({id: 'SIGNATURE'}),
        maxWidth: 100,
        minWidth: 100,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(
            value.signature_base64 !== null ? (
              <Svg src={ICONS.CHECK} color={BootstrapColor.SUCCESS} size={BootstrapSizes.XL} />
            ) : (
              <Svg src={ICONS.CROSS} color={BootstrapColor.DANGER} size={BootstrapSizes.XL} />
            ),
          );
        },
      },
      {
        id: 'IsReferral',
        Header: intl.formatMessage({id: 'REFERRAL'}),
        maxWidth: 100,
        minWidth: 100,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(
            value.isReferral ? (
              <Svg src={ICONS.CHECK} color={BootstrapColor.SUCCESS} size={BootstrapSizes.XL} />
            ) : (
              <Svg src={ICONS.MINUS} color={BootstrapColor.PRIMARY} size={BootstrapSizes.XL}/>
            ),
          );
        },
      },
      {
        id: 'HasCustomTemplate',
        Header: intl.formatMessage({id: 'HAS_CUSTOM_TEMPLATE'}),
        maxWidth: 100,
        minWidth: 100,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: { value: IAddendumDto }) => {
          return TableUtils.renderBaseContent(
            value.hasCustomTemplate ? (
              <Svg src={ICONS.CHECK} color={BootstrapColor.SUCCESS} size={BootstrapSizes.XL}/>
            ) : (
              <Svg src={ICONS.MINUS} color={BootstrapColor.PRIMARY} size={BootstrapSizes.XL}/>
            ),
          );
        },
      },
      {
        id: 'Rate',
        Header: TableUtils.renderHeaderForNumericContent(intl.formatMessage({id: 'RATE_PERCENT_SHORT'})),
        maxWidth: 100,
        minWidth: 100,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderNumericContent(<FormattedNumber value={value.ratePercent * 100} />);
        },
      },
      {
        id: 'Validity',
        Header: intl.formatMessage({id: 'VALIDITY_PERIOD'}),
        maxWidth: 150,
        minWidth: 150,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          return TableUtils.renderBaseContent(
            `${intl.formatDate(value?.startedAt)} - ${value?.expiresAt ? intl.formatDate(value.expiresAt) : 'N/A'}`,
          );
        },
      },
      {
        id: 'Button',
        maxWidth: 80,
        minWidth: 80,
        forceApplySize: true,
        accessor: (br: IAddendumDto) => br,
        Cell: ({value}: {value: IAddendumDto}) => {
          const actions = [
            {
              title: intl.formatMessage({id: 'EDIT'}),
              value: AddendumActions.EDIT,
            },
            {
              title: intl.formatMessage({id: 'DOWNLOAD'}),
              value: AddendumActions.DOWNLOAD,
            },
          ];

          if (value.signature_base64 === null) {
            actions.push({
              title: intl.formatMessage({id: 'UPLOAD_CUSTOM_TEMPLATE'}),
              value: AddendumActions.UPLOAD_CUSTOM_TEMPLATE,
            });
          }

          if (value.hasCustomTemplate) {
            actions.push(
              {
                title: intl.formatMessage({id: 'DOWNLOAD_CUSTOM_TEMPLATE'}),
                value: AddendumActions.DOWNLOAD_CUSTOM_TEMPLATE,
              },
              {
                title: intl.formatMessage({id: 'DELETE_CUSTOM_TEMPLATE'}),
                value: AddendumActions.DELETE_CUSTOM_TEMPLATE,
              },
            );
          }

          return TableUtils.renderActionButton(
            <RowActionButton rowDto={value} actions={actions} onSelect={handleSelect} />,
          );
        },
      },
    ];
  }, []);

  return <DefaultTable data={data} columns={columns} />;
};
