import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage, ValidationErrorsType} from '../../../utils/utils';
import {PartialNullable} from '../../../../types';
import {CreateConnectYoutubeChannelRequestFields} from '../../../api/connect-channel-api/connect-channels-api';
import {InputTextArea} from '../../Inputs/InputTextArea';
import {InputText} from '../../Inputs/InputText';
import {EditRequisitesForm} from '../edit-requisites/edit-requisites-form';
import {UpdateFields} from '../../../hooks/use-advanced-state';
import {Preloader} from '../../preloader';
import {useUserRole} from '../../../hooks/use-user-role';

interface IModalCreateContractProps {
  loadingPaymentRequisites: boolean;
  visible: boolean;
  hasPaymentRequisites: boolean;
  fields: PartialNullable<CreateConnectYoutubeChannelRequestFields>;
  error?: string | null;
  validationErrors?: ValidationErrorsType;
  onChange: UpdateFields<CreateConnectYoutubeChannelRequestFields>;

  onHide(): void;

  onOkClick(): any;
}

export const ModalConnectYoutubeChannel: React.FC<IModalCreateContractProps> = ({
  loadingPaymentRequisites,
  visible,
  fields,
  validationErrors,
  error,
  hasPaymentRequisites,
  onChange,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [, user] = useUserRole();
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        size={hasPaymentRequisites ? 'md' : 'xl'}
        disabledButtons={loading || loadingPaymentRequisites}
        successButtonTitle={intl.formatMessage({id: 'CONNECT'})}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'CONNECT_YOUTUBE_CHANNEL'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={error != null}
            />
            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('y_channel_url', validationErrors)}>
                <InputText
                  required
                  value={fields.y_channel_url}
                  label={intl.formatMessage({id: 'YOUTUBE_CHANNEL_URL'})}
                  placeholder={intl.formatMessage({id: 'YOUTUBE_CHANNEL_URL_PLACEHOLDER'})}
                  classNames={'form-control'}
                  onChange={v => onChange({y_channel_url: v.currentTarget.value})}
                />
              </ValidateErrorWrapper>
              <ValidateErrorWrapper message={getValidationErrorMessage('comment', validationErrors)}>
                <InputTextArea
                  className={'form-control'}
                  label={intl.formatMessage({id: 'COMMENT'})}
                  value={fields.comment}
                  onChange={value => onChange({comment: value.currentTarget.value})}
                />
              </ValidateErrorWrapper>
              {loadingPaymentRequisites ? (
                <Preloader />
              ) : (
                !hasPaymentRequisites && (
                  <>
                    <h2 className={'font-weight-bold my-5'}>{intl.formatMessage({id: 'PAYMENT_REQUISITES'})}</h2>
                    <EditRequisitesForm
                      user={user}
                      fields={fields}
                      validationErrors={validationErrors}
                      onChange={onChange}
                    />
                  </>
                )
              )}
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
