import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Preloader} from '../../components/preloader';
import {FormattedDate, FormattedMessage, useIntl} from 'react-intl';
import {AccountApi} from '../../api/account-api';
import {Currency} from '../../api/transaction-api/Transaction';
import {ITransactionOperationDto} from '../../api/DTOs/ITransactionOperationDto';
import {TransactionOperationsTable} from './transaction-operations-table';
import {IPaginationInfo} from '../../api/Paginator';
import {PaginationComponent} from '../../components/pagination';
import {NumberParam, withDefault} from 'use-query-params';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../api/DTOs/IFilterDtos';
import SVG from 'react-inlinesvg';
import {Spinner} from 'react-bootstrap';
import {useModalPaymentRequest} from '../../components/modals/create-payment-request-modal/payment-request-modal-context';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {toast} from 'react-toastify';
import {IBadRequestException} from '../../api/exceptions/IBadRequestException';
import {ApiRequestException} from '../../api/axios-instance';
import {CustomFormatter} from '../../../localization/custom-formatter';
import {useLoading} from '../../hooks/use-loading';
import {Filter, FilterType} from '../../components/filters/filters';
import {FilterBuilder} from '../../components/filters/filter-builder';
import {useFilters} from '../../components/filters/use-query-params-filters';
import {useDeepCompareEffect} from '../../hooks/use-deep-compare-effect';
import {FiltersSortsPanel} from '../../components/filters/filters-sorts-panel';
import {AddendumApi} from '../../api/addendum-api/addendum-api';
import {IAddendumForUserDto} from '../../api/addendum-api/IAddendumDto';
import {SimplifiedAddendumsTable} from './simplified-addendums-table';
import {RevenueApi} from '../../api/revenue-api';
import {YoutubeFinancialRevenueByMonthsReport} from '../../api/DTOs/RevenueByMonths';
import {RevenuesDetails} from './revenues-details';
import {useBaseListPage} from '../base/base-list-page-context';
import {useLocation} from 'react-router';
import {PaymentsApi} from '../../api/payment-api/payments-api';
import {
  IPaymentRequestDto,
  PaymentRequestDestination,
  PaymentRequestStatus,
} from '../../api/payment-api/IPaymentRequestDto';
import {BaseListPage} from '../base/base-list-page';
import {Card, CardBody} from '../../components/card';
import {BadgeCustom} from '../../components/badge-custom';
import cn from 'classnames';

const ICONS = {
  DOLLAR: require('../../images/svg/Dollar.svg'),
  MONEY: require('../../images/svg/Money.svg'),
};

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const InnerRevenuesPage: React.FC = () => {
  const intl = useIntl();

  const {search} = useLocation();
  const api = useMemo(() => new AccountApi(), []);
  const paymentRequestApi = useMemo(() => new PaymentsApi(), []);
  const revenueApi = useMemo(() => new RevenueApi(), []);
  const addendumApi = useMemo(() => new AddendumApi(), []);

  const [loadings, startLoading, stopLoading] = useLoading({
    balance: true,
    revenueInfo: true,
    operations: true,
    addendum: true,
    paymentRequests: true,
  });

  const [errorOperations, setErrorOperations] = useState<string | null>(null);
  const [errorBalance, setErrorBalance] = useState<string | null>(null);
  const [revenues, setRevenues] = useState<YoutubeFinancialRevenueByMonthsReport | null>(null);
  const [infoAboutBalance, setInfoAboutBalance] = useState<{
    balance: number;
    balance_withdrawal_available: number;
    currency: Currency;
  } | null>(null);
  const [addendums, setAddendums] = useState<Array<IAddendumForUserDto>>([]);
  const [operations, setOperations] = useState<Array<ITransactionOperationDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);
  const [paymentsRequests, setPaymentRequests] = useState<IPaymentRequestDto[]>([]);
  const [paymentRequestsPaginationInfo, setPaymentRequestsPaginationInfo] = useState<IPaginationInfo | null>(null);

  const {showPaymentRequestModal} = useModalPaymentRequest();
  const {appliedQueryParams} = useBaseListPage();

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'revenues_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const nonVisualFilterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return [
      {
        type: FilterType.VIRTUAL,
        name: 'page',
        queryConfig: {
          name: 'page',
          type: withDefault(NumberParam, 1),
        },
      },
    ];
  }, []);
  const {sortsQueriesNameValueMap, applyQueryParamsDto, updateAllFilters} = useFilters(
    [],
    sortConfig,
    nonVisualFilterConfig,
  );

  useDeepCompareEffect(() => {
    updateAllFilters('page', 1);
    fetchOperations({...applyQueryParamsDto, page: 1}).then();
  }, [applyQueryParamsDto.filters, applyQueryParamsDto.sort]);

  useDeepCompareEffect(() => {
    fetchOperations(applyQueryParamsDto).then();
  }, [applyQueryParamsDto.page]);

  useEffect(() => {
    const withdrawalAmount = new URLSearchParams(search).get('mc_pay_withdrawal_amount');
    if (withdrawalAmount != null) {
      handleCreatePaymentRequest(withdrawalAmount as any).then();
    }
  }, []);

  useEffect(() => {
    fetchPaymentsRequests({}).then();
  }, []);

  useEffect(() => {
    const fetchAll = async () => {
      await Promise.all([fetchAddendums(), fetchBalance(), fetchRevenues()]);
    };
    fetchAll().then();
  }, []);

  useEffect(() => {
    fetchRevenues().then();
  }, [appliedQueryParams?.filters?.channels]);

  const fetchPaymentsRequests = useCallback(async (filters: IQueryParams) => {
    startLoading('paymentRequests');
    paymentRequestApi
      .getUserPaymentRequests({...filters, page: filters?.paymentRequestPage})
      .then(res => {
        setPaymentRequests(res.data.items);
        setPaymentRequestsPaginationInfo(res.data.paginator);
      })
      .catch(() => toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'})))
      .finally(() => stopLoading('paymentRequests'));
  }, []);

  const getStatusColor = useCallback((status: PaymentRequestStatus) => {
    if (status === PaymentRequestStatus.IN_WORK) {
      return 'primary';
    }

    if (status === PaymentRequestStatus.COMPLETE) {
      return 'success';
    }

    if (status === PaymentRequestStatus.REJECT) {
      return 'danger';
    }
  }, []);

  const fetchAddendums = async () => {
    try {
      startLoading('addendum');
      const result = await addendumApi.getForUser();
      setAddendums(result.data.items);
    } finally {
      stopLoading('addendum');
    }
  };

  const fetchRevenues = async () => {
    try {
      startLoading('revenueInfo');
      const result = await revenueApi.getRevenuesByMonths({
        filters: {y_channel_id: appliedQueryParams?.filters?.channels},
      });
      setRevenues(result.data.item);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('revenueInfo');
    }
  };

  const fetchBalance = async () => {
    try {
      startLoading('balance');
      setErrorBalance(null);
      const result = await api.getBalance();
      setInfoAboutBalance({
        balance: result.data.balance,
        balance_withdrawal_available: result.data.balance_withdrawal_available,
        currency: result.data.currency,
      });
    } catch (e) {
      const err = e as IBadRequestException;
      if (err.error_message) {
        setErrorBalance(err.error_message);
      }

      setErrorBalance(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('balance');
    }
  };

  const fetchOperations = async (filters?: IQueryParams) => {
    try {
      startLoading('operations');
      setErrorOperations(null);
      const result = await api.getTransactionOperations(filters);
      setOperations(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setErrorOperations(err.errorMessage);
      } else {
        setErrorOperations(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('operations');
    }
  };

  const handleCreatePaymentRequest = async (mcPayWithdrawalAmount: number | string | null = null) => {
    const result = await showPaymentRequestModal({
      mcPayWithdrawalAmount,
      totalAvailableWithdrawalAmount:
        infoAboutBalance?.balance_withdrawal_available != null
          ? Math.floor(infoAboutBalance.balance_withdrawal_available * 100) / 100
          : null,
    });
    if (result.reason === CloseModalReason.OK) {
      toast.success(intl.formatMessage({id: 'SUCCESS_PAYMENT_REQUEST'}));
      await fetchPaymentsRequests({});
    }
  };

  const renderOperations = () => {
    if (operations.length === 0 && !errorOperations) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    if (errorOperations) {
      return <div className={'text-center'}>{errorOperations}</div>;
    }

    return <TransactionOperationsTable operations={operations} />;
  };

  const renderAddendums = () => {
    if (addendums.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }
    return <SimplifiedAddendumsTable addendums={addendums} />;
  };
  const renderBalanceBlock = () => {
    const balanceWithCurrency = CustomFormatter.formatMoney(
      infoAboutBalance?.balance != null ? Math.floor(infoAboutBalance.balance * 100) / 100 : null,
      infoAboutBalance?.currency,
    );

    return (
      <>
        <div className='card card-custom gutter-b bg-info p-0'>
          <div className='card-body p-0'>
            <div className='d-flex align-items-center justify-content-between p-4 flex-wrap flex-xl-nowrap'>
              <div className='d-flex flex-column mr-5'>
                <div className='h6 text-light mb-1'>
                  <span className='svg-icon svg-icon-4x svg-icon-light mr-2'>
                    <SVG src={ICONS.DOLLAR} />
                  </span>
                  <FormattedMessage id={'CURRENT_BALANCE'} />:{errorBalance && errorBalance}
                  {loadings.balance && !errorBalance ? (
                    <span className={'ml-3'}>
                      {' '}
                      <Spinner animation='border' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </Spinner>{' '}
                    </span>
                  ) : (
                    <span className={'ml-2 font-weight-boldest'}>{balanceWithCurrency}</span>
                  )}
                </div>
              </div>
              <div>
                <button
                  disabled={loadings.balance}
                  onClick={() => handleCreatePaymentRequest()}
                  className={'btn btn-light-info'}>
                  <span className='svg-icon svg-icon-light-info mr-1'>
                    <SVG src={ICONS.MONEY} />
                  </span>
                  <FormattedMessage id={'PAYMENT_REQUEST'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAddendumsBlock = () => {
    return (
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header flex-wrap'}>
          <div className={'card-title'}>
            <FormattedMessage id={'CHANNELS_PERCENT_RATES'} />
          </div>
        </div>
        <div className={`card-body`}>{loadings.addendum ? <Preloader /> : renderAddendums()}</div>
      </div>
    );
  };

  const renderOperationsBlock = () => {
    return (
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header flex-wrap'}>
          <div className={'card-title'}>
            <div className={'mr-4'}>
              <FormattedMessage id={'OPERATIONS_OF_ACCOUNTS'} />
            </div>
            <FiltersSortsPanel
              sortsConfig={sortConfig}
              sortsQueriesNameValueMap={sortsQueriesNameValueMap}
              onUpdate={updateAllFilters}
            />
          </div>
        </div>
        <div className={`card-body`}>{loadings.operations ? <Preloader /> : renderOperations()}</div>
        <div className={'card-footer'}>
          {paginationInfo && (
            <PaginationComponent
              threshold={4}
              paginator={paginationInfo}
              onChange={pageNumber => updateAllFilters('page', pageNumber)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderRevenueDetailsBlock = () => {
    return (
      <RevenuesDetails
        showFilters={addendums.length > 1}
        revenues={revenues}
        loading={loadings.revenueInfo}
        channels={addendums.map(a => ({id: a.youtubeChannelId, title: a.youtubeChannelTitle}))}
      />
    );
  };

  return (
    <>
      {renderBalanceBlock()}
      {paymentsRequests.length != 0 && (
        <BaseListPage
          pageKey={'paymentRequestPage'}
          fetchData={fetchPaymentsRequests}
          loading={loadings?.paymentRequests}
          className={{cardBody: cn('bg-light')}}
          pageTitle={intl.formatMessage({id: 'PAYMENT_REQUESTS'})}
          pagination={{info: paymentRequestsPaginationInfo}}>
          {paymentsRequests.map(pr => {
            return (
              <Card
                key={pr.id}
                className={{
                  card: cn('border'),
                  body: 'd-flex align-items-center justify-content-between p-6',
                }}
                hideHeader
                hideFooter>
                <CardBody>
                  <div className={'d-flex align-items-center'}>
                    <div className={'h2 mb-0 mr-3 font-weight-boldest'}>
                      {CustomFormatter.customFormatMoney({sum: pr.value})}
                    </div>
                    <BadgeCustom variant={pr.destination === PaymentRequestDestination.TO_MCPAY ? 'info' : 'primary'}>
                      {intl.formatMessage({id: 'PAYMENT_REQUEST_DESTINATION_' + pr.destination})}
                    </BadgeCustom>

                    <BadgeCustom className={'ml-3'} variant={getStatusColor(pr.status)}>
                      {intl.formatMessage({id: 'PAYMENT_REQUEST_STATUS_' + pr.status})}
                    </BadgeCustom>

                    {pr.reject_reason && <div className={'ml-3 text-danger'}>{pr.reject_reason}</div>}
                  </div>
                  <div>
                    <BadgeCustom variant={'secondary'}>
                      <FormattedDate value={pr.created_at} dateStyle={'medium'} />
                    </BadgeCustom>
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </BaseListPage>
      )}
      {renderRevenueDetailsBlock()}
      {renderOperationsBlock()}
      {renderAddendumsBlock()}
    </>
  );
};
