import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {AdvertisementsApi} from '../../api/advertisement-api/advertisements-api';
import {AdsStatus, IAdvertisementRequestDto} from '../../api/advertisement-api/IAdvertisementRequestDto';
import {ApiRequestException} from '../../api/axios-instance';
import SVG from 'react-inlinesvg';
import {AdminRoutes, Routes} from '../../../configs/routes';
import {toast} from 'react-toastify';
import {Preloader} from '../../components/preloader';
import {ErrorStub} from '../../components/error-stub';
import {
  useModalRejectReasonAdsRequest,
} from '../../components/modals/reject-reason/modal-reject-reason-ads-request-context';
import {CloseModalReason} from '../../components/modals/base-modal/CloseModalEvent';
import {CustomFormatter} from '../../../localization/custom-formatter';
import cn from 'classnames';
import {IYoutubeChannelDto} from '../../api/DTOs/IYoutubeChannelDto';
import {tryGetPreviewOrStub} from '../../utils/utils';
import {useCustomBreadcrumbs} from '../../components/breadcrumbs/breadcrumbs-context';
import {useModalConfirmAction} from '../../components/modals/confirm-modal/modal-confirm-action-context';
import {TIMESTAMP_CONFIGS, Timestamps} from '../../components/timestamps';
import {BootstrapColor} from '../../styles/styles';

interface IProps {
  isAdmin?: boolean;
}

type DependencyField = {
  toggleKey: keyof IAdvertisementRequestDto;
  fieldKey: keyof IAdvertisementRequestDto;
  toggleLabel?: string;
  fieldLabel?: string;
};

const ICONS = {
  EDIT: require('../../images/svg/Edit.svg'),
  TRASH: require('../../images/svg/Trash.svg'),
  CROSS: require('../../images/svg/Close.svg'),
  CHECK: require('../../images/svg/Check.svg'),
};

export const AdvertisementPage: React.FC<IProps> = ({isAdmin}) => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const adRequestId = Number(match.params.id);

  const api = new AdvertisementsApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [advertisementRequest, setAdvertisementRequest] = useState<IAdvertisementRequestDto | null>(null);
  const [youtubeChannel, setYoutubeChannel] = useState<IYoutubeChannelDto | null>(null);
  const {showRejectReasonAdsRequestModal} = useModalRejectReasonAdsRequest();
  const {setBreadcrumb} = useCustomBreadcrumbs();
  const {showConfirmActionModal} = useModalConfirmAction();

  // @ts-ignore
  const arrays = useMemo<Array<DependencyField>>(() => {
    const a = [
      {
        toggleKey: 'integration_enable',
        fieldKey: 'integration_price',
        toggleLabel: 'INTEGRATION_ENABLE',
      },
      {
        toggleKey: 'advanced_integration_enable',
        fieldKey: 'advanced_integration_price',
        toggleLabel: 'ADVANCED_INTEGRATION_ENABLE',
      },
      {
        toggleKey: 'post_enable',
        fieldKey: 'post_price',
        toggleLabel: 'POST_ENABLE',
      },
      {
        toggleKey: 'pre_roll_enable',
        fieldKey: 'pre_roll_price',
        toggleLabel: 'PRE_ROLL_ENABLE',
      },
      {
        toggleKey: 'product_placement_enable',
        fieldKey: 'product_placement_price',
        toggleLabel: 'PRODUCT_PLACEMENT_ENABLE',
      },
      {
        toggleKey: 'special_project_enable',
        fieldKey: 'special_project_price',
        toggleLabel: 'SPECIAL_PROJECT_ENABLE',
      },
    ];
    if (advertisementRequest != null) {
      // @ts-ignore
      a.sort((current, prev) => {
        const currentFlag = advertisementRequest[current.toggleKey as keyof IAdvertisementRequestDto];
        const prevFlag = advertisementRequest[prev.toggleKey as keyof IAdvertisementRequestDto];
        if (currentFlag === prevFlag) {
          return 0;
        }

        if (!currentFlag && prevFlag) {
          return 1;
        }

        return -1;
      });
    }
    return a;
  }, [advertisementRequest]);

  useEffect(() => {
    const fetchAll = async () => {
      await fetchAdRequest();
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchAll();
  }, []);

  useEffect(() => {
    if (advertisementRequest) {
      setBreadcrumb(advertisementRequest.youtube_channel_title);
    }
  }, [advertisementRequest]);

  const fetchAdRequest = async () => {
    try {
      setLoading(true);
      setError(null);
      const result = await api.getSelfAdvertisementsRequest(adRequestId);
      setAdvertisementRequest(result?.data?.item);
      setYoutubeChannel(result?.data?.relations?.youtube_channel);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = async () => {
    history.push(Routes.getEditAdvertisementRequestRoute(adRequestId));
  };

  const handleDeleteClick = async () => {
    if (!(await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_DELETE_ADS_REQUEST'})))) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      isAdmin
        ? await api.deleteUserAdvertisementsRequest(adRequestId)
        : await api.deleteSelfAdvertisementsRequest(adRequestId);
      history.push(Routes.getAdvertisementsRoute());
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  const handleApproveClick = async () => {
    try {
      if (!(await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_APPROVE_ADS_REQUEST'})))) {
        return;
      }

      setLoading(true);
      setError(null);
      await api.confirmAdvertisementsRequest(adRequestId);
      await fetchAdRequest();
      toast.success(intl.formatMessage({id: 'SUCCESS_ADS_REQUEST_CONFIRM'}));
    } catch (e) {
      const err = e as ApiRequestException;
      toast.error(err.errorMessage || e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  const handleRejectClick = async () => {
    const result = await showRejectReasonAdsRequestModal(adRequestId);
    if (result.reason === CloseModalReason.OK) {
      await fetchAdRequest();
      toast.warning(intl.formatMessage({id: 'SUCCESS_ADS_REQUEST_REJECT'}));
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (advertisementRequest === null || error) {
    return <ErrorStub error={error || intl.formatMessage({id: 'NOT_FOUND'})} />;
  }

  const renderInfo = () => {
    return (
      <div>
        <div className='row mb-11'>
          <div className='col-sm-4'>
            <div className='font-weight-bold text-gray-600 mb-1'>
              <FormattedMessage id={'TAX'} />
            </div>
            <div className='font-weight-bolder text-gray-800'>
              {CustomFormatter.formatPercent(advertisementRequest.tax_value)}
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='font-weight-bold text-gray-600 mb-1'>
              <FormattedMessage id={'HAS_TAX_ON_PROFITS'} />
            </div>
            <div className='font-weight-bolder  text-gray-800 d-flex align-items-center flex-wrap'>
              <span className='pe-2'>{advertisementRequest.has_tax_on_profits ? '✔' : '—'}</span>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='font-weight-bold text-gray-600 mb-1'>
              <FormattedMessage id={'EXPECTED_VIEWS_COUNT'} />
            </div>
            <div className='font-weight-bolder  text-gray-800 d-flex align-items-center flex-wrap'>
              <span className='pe-2'>
                <FormattedNumber value={advertisementRequest.expected_views_count} />
              </span>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className='col-12 table-responsive border-bottom mb-9'>
            <table className='table mb-3'>
              <thead>
              <tr className='border-bottom font-weight-bolder text-muted'>
                <th className='min-w-175px'>
                  <FormattedMessage id={'TITLE'} />
                </th>
                <th className='min-w-70px'>
                  <FormattedMessage id={'PRICE_WITHOUT_TAX'} /> (RUB)
                </th>
              </tr>
              </thead>
              <tbody>
              {arrays.map(item => {
                const [title, price] = [
                  intl.formatMessage({id: item.toggleLabel}),
                  CustomFormatter.formatMoney(advertisementRequest[item.fieldKey] as number, 'RUB', '—'),
                ];
                return (
                  <tr key={title} className={'font-weight-bolder text-gray-700'}>
                    <td className={'py-5'}>
                      <div className={'d-flex align-items-center '}>
                        <i
                          className={cn('fa fa-genderless mr-2', {
                            'text-success': price !== '—',
                            'text-danger': price === '—',
                          })}
                        />
                        {title}
                      </div>
                    </td>
                    <td className={'py-5'}>{price}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={'row'}>
          <div className='col-12'>
            <div className='font-weight-bold text-gray-600 mb-1'>
              <FormattedMessage id={'COMMENT'} />
            </div>
            <div className='font-weight-bolder text-gray-800 save-line-and-tabulation'>
              {advertisementRequest.comment ?? '—'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div>
        {!isAdmin && (
          <button className='btn btn-sm font-weight-bolder text-uppercase btn-secondary mr-5' onClick={handleEditClick}>
            <span className={`svg-icon svg-icon-secondary svg-icon-2x`}>
              <SVG src={ICONS.EDIT} />
            </span>
            <FormattedMessage id={'EDIT'} />
          </button>
        )}
        <button
          className='btn btn-sm font-weight-bolder text-uppercase btn-light-danger mr-5'
          onClick={handleDeleteClick}>
          <span className={`svg-icon svg-icon-light-danger svg-icon-2x`}>
            <SVG src={ICONS.TRASH} />
          </span>
          <FormattedMessage id={'DELETE'} />
        </button>
        {isAdmin && (
          <>
            <button
              disabled={!advertisementRequest?.canReject}
              className='btn btn-sm font-weight-bolder text-uppercase btn-light-danger mr-5'
              onClick={handleRejectClick}>
              <span className={`svg-icon svg-icon-light-danger svg-icon-2x`}>
                <SVG src={ICONS.CROSS} />
              </span>
              <FormattedMessage id={'REJECT'} />
            </button>
            <button
              disabled={!advertisementRequest?.canDone}
              className='btn btn-sm font-weight-bolder text-uppercase btn-light-success'
              onClick={handleApproveClick}>
              <span className={`svg-icon svg-icon-light-success svg-icon-2x`}>
                <SVG src={ICONS.CHECK} />
              </span>
              <FormattedMessage id={'APPROVE'} />
            </button>
          </>
        )}
      </div>
    );
  };

  const statusTextStyle =
    advertisementRequest.status === AdsStatus.REJECTED
      ? 'text-danger'
      : advertisementRequest.status === AdsStatus.DONE
        ? 'text-success'
        : 'text-primary';

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={`card-body`}>
          <div className={'d-flex'}>
            <div className={'flex-grow-1'}>
              <div className={'d-flex align-items-start justify-content-between flex-wrap'}>
                <div className={'d-flex flex-row'}>
                  {youtubeChannel && (
                    <div className={'mr-5'}>
                      <Link to={Routes.getYoutubeSpecifyChannelRoute(youtubeChannel.id)}>
                        <div className='symbol symbol-sm-150 symbol-lg-150'>
                          <img src={tryGetPreviewOrStub(youtubeChannel.thumbnails).url} alt='image' />
                        </div>
                      </Link>
                    </div>
                  )}
                  <div className={'d-flex flex-column'}>
                    <p className={'font-size-h4 font-weight-boldest mb-1'}>
                      <Link to={Routes.getYoutubeSpecifyChannelRoute(advertisementRequest.youtube_channel_id)}>
                        <span className={'font-size-h4 font-weight-boldest'}>
                          {advertisementRequest.youtube_channel_title}
                        </span>
                      </Link>
                    </p>
                    {isAdmin && (
                      <p className={'font-size-h5 font-size-sm-h5 font-weight-boldest text-muted'}>
                        <Link to={AdminRoutes.getSpecifyUserManagementRoute(advertisementRequest.user_id)}>
                          <span className={'font-size-h4 font-weight-boldest'}>{advertisementRequest.user_name}</span>
                        </Link>
                      </p>
                    )}
                    <p className={'font-size-h6 font-weight-bolder mb-0'}>
                      <FormattedMessage id={'STATUS'} />
                      :&nbsp;
                      <span className={statusTextStyle}>
                        <FormattedMessage id={'ADS_REQUEST_STATUS_' + advertisementRequest.status} />
                      </span>
                    </p>
                  </div>
                </div>
                <Timestamps
                  items={[
                    {
                      title: intl.formatMessage({id: 'RELEASE_DATE'}),
                      date: advertisementRequest.release_at,
                      format: TIMESTAMP_CONFIGS.DEFAULT,
                      color: BootstrapColor.PRIMARY,
                    },
                    {
                      title: intl.formatMessage({id: 'CREATED_AT'}),
                      date: advertisementRequest.created_at,
                      format: TIMESTAMP_CONFIGS.DEFAULT,
                      color: BootstrapColor.SUCCESS,
                    },
                    {
                      title: intl.formatMessage({id: 'UPDATED_AT'}),
                      date: advertisementRequest.updated_at,
                      format: TIMESTAMP_CONFIGS.DEFAULT,
                      color: BootstrapColor.PRIMARY,
                    },
                  ]}
                />
              </div>
              <div className={'separator separator-solid my-7'} />
              {renderButtons()}
              <div className={'separator separator-solid my-7'} />
              {renderInfo()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
