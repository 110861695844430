import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import store from '../../redux/store';
import {Store} from 'redux';
import {getLocaleConfig} from '../../localization';
import {EXCEPTION_TYPE, IBaseException} from './exceptions/IBaseException';
import {LogoutAction} from '../modules/Auth';
import {IBadRequestException} from './exceptions/IBadRequestException';

export interface ApiRequestException {
  errorMessage: string;
  errorType: EXCEPTION_TYPE;
  innerException: IBaseException;
}

const NETWORK_ERROR_MESSAGE = 'Network Error';

const handleBeforeSendRequest = (store: Store, config: AxiosRequestConfig) => {
  const {selectedLang} = getLocaleConfig();

  if (selectedLang) {
    config.headers['User-Language'] = selectedLang;
  }

  return config;
};

const handleResponseError = (error: {
  response: {status: number; data: never; headers: Record<string, unknown>};
  toJSON: () => {message: string; name: string; stack: string};
}) => {
  error.response?.headers && checkCurrentUserIsChange(error.response.headers);

  const ownError: ApiRequestException | Record<string, unknown> = {};
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch(LogoutAction());
    }

    if (error.response.data) {
      const err = error.response.data as IBaseException;
      if (
        err.error_type === EXCEPTION_TYPE.NOT_FOUND_HTTP_EXCEPTION ||
        err.error_type === EXCEPTION_TYPE.UNKNOWN_SERVER_EXCEPTION ||
        err.error_type === EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION
      ) {
        ownError.errorType = err.error_type;
        ownError.errorMessage = (err as IBadRequestException).error_message;
        ownError.innerException = err;
      } else if (err.error_type === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        ownError.errorType = err.error_type;
        ownError.errorMessage = null;
        ownError.innerException = err;
      } else if (err.error_type === EXCEPTION_TYPE.UNAUTHENTICATED_EXCEPTION) {
        ownError.errorType = err.error_type;
        ownError.innerException = err;
      }
    }
  } else {
    const errorAsJson = error.toJSON();
    if (errorAsJson.message === NETWORK_ERROR_MESSAGE) {
      ownError.errorType = EXCEPTION_TYPE.NETWORK_ERROR;
    } else {
      ownError.errorType = EXCEPTION_TYPE.UNKNOWN_EXCEPTION;
    }
  }
  return Promise.reject(ownError);
};

/**
 * Первоначальная обработка успешно завершившегося запроса.
 * @param response
 */
const handleResponseSuccess = (response: AxiosResponse) => {
  checkCurrentUserIsChange(response.headers);
  return response;
};

const checkCurrentUserIsChange = (responseHeaders: any) => {
  try {
    const currentUser = store.getState().auth.user;
    const currentUserIdFromHeaders = responseHeaders['user-id'] ?? null;
    if (currentUser != null && currentUserIdFromHeaders != null && currentUserIdFromHeaders != currentUser.id) {
      document.location.replace('/');
    }
  } catch (e) {
    console.error('CheckCurrentUserChangeException', e);
  }
};

function setupAxios(axios: AxiosInstance, store: Store) {
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    config => handleBeforeSendRequest(store, config),
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
}

let axiosInstance: AxiosInstance | null = null;

/**
 * Получить сконфигурированный экземпляр axios'а.
 */
export const getPreparedAxiosInstance = (): AxiosInstance => {
  if (axiosInstance == null) {
    axiosInstance = axios;
    setupAxios(axiosInstance, store);
  }

  return axiosInstance;
};
