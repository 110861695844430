import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {Base64Signature} from './signatures-response-contracts';
import {IResponseBase} from '../response-contracts/base-response';
import {BaseItemsResponse} from '../base/base-crud-api-response';
import {IAddendumForUserDto} from '../addendum-api/IAddendumDto';
import {EntityId} from '../base/BaseEntity';


export type AddendumPreview = {
  addendum_as_html: string | null;
}

export class ProfileApi extends BaseEndpoint {
  /**
   * Обновить подпись текущему пользователю.
   * @param addendumId
   * @param signature  Подпись.
   */
  public async updateSignatureForAddendum(addendumId: EntityId, signature: Base64Signature): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/profile/addendums/${addendumId}/update-signature`,
      {
        fields: {
          signature,
        },
      },
    );

    return response.data;
  }

  public async getAddendums(): Promise<BaseItemsResponse<IAddendumForUserDto>> {
    const response = await this.get<BaseItemsResponse<IAddendumForUserDto>>(`${config.BASE_API_URL}/profile/addendums`);
    return response.data;
  }

  public async getAddendumPreview(addendumId: EntityId): Promise<IResponseBase<AddendumPreview>> {
    const response = await this.get(`${config.BASE_API_URL}/profile/addendums/${addendumId}/document-preview`);
    return response.data;
  }

  public async downloadAddendum(addendumId: EntityId) {
    const response = await this.getFile(`${config.BASE_API_URL}/profile/addendums/${addendumId}/download-document`);
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }
}
