import {CompositeFilter, DateFilter, DatePeriodFilter, Filter, FilterType, SelectFilter, TextFilter} from './filters';
import {ISortDto} from '../../api/DTOs/IFilterDtos';
import {mapSelectValuesDTOsToSelectOptions, SelectOptions, SelectsValuesDTOs} from '../Inputs/InputSelect';
import {vanillaIntl as Intl} from '../../../localization/I18nProvider';
import {JsonParam, withDefault} from 'use-query-params';

type BuildSortPayload = {
  name: string;
  defaultOptions: ISortDto;
  options: SelectOptions<ISortDto>;
};

type FilterTypeForBuilder = (
  | (Omit<SelectFilter, 'options' | 'loading' | 'clearable'> & {options?: SelectOptions})
  | Omit<DatePeriodFilter, 'loading'>
  | Omit<TextFilter, 'loading'>
  | Omit<CompositeFilter, 'loading'>
  | Omit<DateFilter, 'loading' | 'clearable'>
) & {visible?: boolean};

type BuildFilterPayload = {
  filters: Array<FilterTypeForBuilder>;
  loadingSelect?: boolean;
  allSelectValues?: SelectsValuesDTOs;
};

export class FilterBuilder {
  public static buildSort({options, defaultOptions, name}: BuildSortPayload): Array<SelectFilter> {
    return [
      {
        type: FilterType.SELECT,
        name: name,
        placeholder: Intl.formatMessage({id: 'SORT_FIELD'}),
        extractFields: true,
        queryConfig: {
          name: name,
          type: withDefault(JsonParam, {
            field: defaultOptions.field,
            direction: defaultOptions.direction,
          }),
        },
        stringToValue: selectedValue => {
          if (!selectedValue) {
            return null;
          }
          return JSON.parse(selectedValue as string);
        },
        valueToString: value => {
          if (!value) {
            return null;
          }

          return JSON.stringify(value);
        },
        options: options,
      },
    ];
  }

  public static buildFilter({filters, allSelectValues, loadingSelect}: BuildFilterPayload): Array<Filter> {
    return filters
      .filter(filter => (filter.visible !== undefined && filter.visible) || filter.visible === undefined)
      .map(filter => {
        switch (filter.type) {
          case FilterType.SELECT: {
            return {
              ...filter,
              clearable: true,
              loading: loadingSelect,
              options:
                filter.options ??
                mapSelectValuesDTOsToSelectOptions(filter.name, allSelectValues as NonNullable<SelectsValuesDTOs>),
            } as SelectFilter;
          }
          case FilterType.TEXT:
          case FilterType.PERIOD:
          case FilterType.DATE:
          case FilterType.COMPOSITE: {
            return {
              ...filter,
            } as Filter;
          }
          default:
            throw new Error(`Builder can not build filter: \n${JSON.stringify(filter)}`);
        }
      });
  }
}
