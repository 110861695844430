import React, {useCallback, useEffect, useState} from 'react';
import {MediaCubeGroupedConfirmationOperation, useMediaCubeStatesApi} from '../../../hooks/apis/useMediaCubeStatesApi';
import {Table} from '../../../components/table';
import {TableUtils} from '../../../components/table-utils';
import {Link} from 'react-router-dom';
import {AdminRoutes} from '../../../../configs/routes';
import {BaseListPage} from '../../base/base-list-page';
import {useIntl} from '../../../hooks/use-intl';
import {useLoading} from '../../../hooks/use-loading';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {toast} from 'react-toastify';
import {ICONS} from '../../../images/images';

export const MediaCubeOperationsConfirmationsPage: React.FC = () => {
  const intl = useIntl();

  const api = useMediaCubeStatesApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    confirm: false,
  });
  const [data, setData] = useState<MediaCubeGroupedConfirmationOperation[]>([]);
  const {showConfirmActionModal} = useModalConfirmAction();

  useEffect(() => {
    startLoading('page');
    api
      .getUnconfirmedOperations()
      .then(res => setData(res.data.items))
      .finally(() => stopLoading('page'));
  }, []);

  const handleClickConfirmAll = useCallback(async () => {
    if (!(await showConfirmActionModal(intl.formatMessage({id: 'CONFIRM_MC_PAY_OPERATIONS_CONFIRMATION_MESSAGE'})))) {
      return;
    }

    try {
      startLoading('confirm');
      await api.postConfirmAllOperations();
      toast.success(intl.formatMessage({id: 'SUCCESS_CONFIRM_MC_PAY_OPERATIONS'}));
      startLoading('page');
      api
        .getUnconfirmedOperations()
        .then(res => setData(res.data.items))
        .finally(() => stopLoading('page'));
    } catch (e) {
      toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('confirm');
    }
  }, []);

  return (
    <BaseListPage
      pageTitle={intl.formatMessage({id: 'MC_PAY_WITHDRAWAL_REQUEST'})}
      loading={loadings.page}
      toolbarConfig={[
        {
          type: 'BUTTON',
          visible: (data ?? []).length > 0,
          icon: ICONS.CHECK,
          loading: loadings.confirm,
          disabled: loadings.confirm,
          className: 'btn btn-success text-uppercase',
          title: intl.formatMessage({id: 'CONFIRM'}),
          onClick: handleClickConfirmAll,
        },
      ]}>
      <Table
        data={data ?? []}
        columns={[
          {
            id: 'Contractor',
            Header: intl.formatMessage({id: 'CONTRACTOR'}),
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(
                <Link to={AdminRoutes.getSpecifyContractorRoute(value.contractor_id)}>
                  {value.contractor_calculated_name}
                </Link>,
              );
            },
          },
          {
            id: 'Sum',
            Header: intl.formatMessage({id: 'SUM'}),
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(
                CustomFormatter.customFormatMoney({
                  sum: value.calculated_sum,
                }),
              );
            },
          },
          {
            id: 'Operations',
            Header: intl.formatMessage({id: 'TRANSACTIONS'}),
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(value.operations_count);
            },
          },
          {
            id: 'goTo',
            forceApplySize: true,
            maxWidth: 100,
            Header: '',
            Cell: ({value}) => {
              return TableUtils.renderBaseContent(
                <Link to={AdminRoutes.getSpecifyMediaCubeConfirmationRoute(value.contractor_id)}>
                  {intl.formatMessage({id: 'GO'})}
                </Link>,
              );
            },
          },
        ]}
      />
    </BaseListPage>
  );
};
