import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {TransactionAction} from './transactions-page';
import {useIntl} from '../../../hooks/use-intl';
import {AdminRoutes} from '../../../../configs/routes';
import {useModalCreateTransaction} from '../../../components/modals/create-transaction/modal-create-transaction-context';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {useModalRejectReasonTransaction} from '../../../components/modals/reject-reason/modal-reject-reason-transaction-context';
import {useHistory} from 'react-router-dom';
import {TransactionsApi} from '../../../api/transaction-api/transactions-api';
import {saveAs} from 'file-saver';
import {IQueryParams} from '../../../api/DTOs/IFilterDtos';
import {useLoading} from '../../../hooks/use-loading';
import {useBaseListPage} from '../../base/base-list-page-context';
import {useModalIncomeReport} from '../../../components/modals/channel-transaction-income-report-modal/modal-channel-transactions-income-report-context';

export type HandleTransactionActionPayload =
  | HandleTransactionCreatePayload
  | HandleTransactionDownloadPayload
  | HandleTransactionManagementPayload
  | HandleTransactionDownloadNewReport;

export type HandleTransactionDownloadPayload = {
  type: TransactionAction.REPORT;
  data: {
    filters?: Record<string, any>;
  };
};

export type HandleTransactionDownloadNewReport = {
  type: TransactionAction.INCOME_REPORT;
};

export type HandleTransactionCreatePayload = {
  type: TransactionAction.DEBIT | TransactionAction.WITHDRAWING | TransactionAction.TAX | TransactionAction.INCOME;
  data: {
    contractorId?: number;
    contractorName?: string;
  };
};

export type HandleTransactionManagementPayload = {
  type: TransactionAction.CONFIRM_ALL | TransactionAction.CONFIRM | TransactionAction.REJECT | TransactionAction.CANCEL;
  data: {
    transactionId: number;
    filter?: IQueryParams;
  };
};

export function useTransactionActionHandler(fetch?: (params: IQueryParams) => Promise<any>) {
  const intl = useIntl();
  const [loadings, startLoading, stopLoading] = useLoading({
    report: false,
  });
  const api = new TransactionsApi();
  const history = useHistory();
  const {showConfirmActionModal} = useModalConfirmAction();
  const {showCreateTransactionModal} = useModalCreateTransaction();
  const {showRejectReasonTransactionModal} = useModalRejectReasonTransaction();
  const {showModalIncomeReport} = useModalIncomeReport();
  const {appliedQueryParams} = useBaseListPage();

  const handle = async (payload: HandleTransactionActionPayload): Promise<any> => {
    try {
      switch (payload.type) {
        case TransactionAction.REPORT: {
          try {
            startLoading('report');
            const result = await api.download({filters: payload.data?.filters ?? {}});
            saveAs(result.content, result.fileName);
          } catch (e) {
            toast.error(intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
          } finally {
            stopLoading('report');
          }
          break;
        }
        case TransactionAction.TAX:
        case TransactionAction.DEBIT:
        case TransactionAction.INCOME:
        case TransactionAction.WITHDRAWING: {
          const result = await showCreateTransactionModal(
            payload.type,
            payload.data?.contractorId && payload.data?.contractorName
              ? {
                  id: payload.data.contractorId,
                  name: payload.data.contractorName,
                }
              : undefined,
          );
          if (result.reason === CloseModalReason.OK && result.payload) {
            history.push(AdminRoutes.getSpecifyTransactionRoute(result.payload));
          }
          break;
        }
        case TransactionAction.CONFIRM_ALL: {
          if (
            await showConfirmActionModal(
              intl.formatMessage({id: 'CONFIRM_TRANSACTION'}),
              intl.formatMessage({id: 'CONFIRM_TRANSACTION_DESCRIPTION'}),
            )
          ) {
            await api.confirmMultipleTransactions(appliedQueryParams);
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case TransactionAction.REJECT:
          if (
            (
              await showRejectReasonTransactionModal({
                transactionId: payload.data.transactionId,
                type: payload.type,
              })
            ).reason === CloseModalReason.OK
          ) {
            toast.success(intl.formatMessage({id: 'REJECT_TRANSACTION_SUCCESS'}));
            await fetch?.(appliedQueryParams);
          }
          break;
        case TransactionAction.CONFIRM: {
          if (
            await showConfirmActionModal(
              intl.formatMessage({id: 'CONFIRM_TRANSACTION'}),
              intl.formatMessage({id: 'CONFIRM_TRANSACTION_DESCRIPTION'}),
            )
          ) {
            await api.confirmTransaction(payload.data.transactionId);
            toast.success(intl.formatMessage({id: 'CONFIRM_TRANSACTION_SUCCESS'}));
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case TransactionAction.CANCEL: {
          const closeModalResult = await showRejectReasonTransactionModal({
            transactionId: payload.data.transactionId,
            type: payload.type,
            modalConfiguration: {
              title: 'DESCRIBE_CANCEL_REASON',
              reasonInputKey: 'cancel_reason',
              reasonInputLabel: 'CANCEL_REASON',
            },
          });

          if (closeModalResult.reason === CloseModalReason.OK) {
            toast.success(intl.formatMessage({id: 'CANCEL_TRANSACTION_SUCCESS'}));
            await fetch?.(appliedQueryParams);
          }
          break;
        }
        case TransactionAction.INCOME_REPORT: {
          const closeModalResult = await showModalIncomeReport();
          if (closeModalResult.reason === CloseModalReason.OK) {
            toast.success(intl.formatMessage({id: 'SUCCESS_UPLOAD_REPORTS'}));
          }
          break;
        }
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  return {
    reportLoading: loadings.report,
    handleTransactionAction: handle,
  };
}
