import React, {useCallback, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {InputSelect} from '../../Inputs/InputSelect';
import {InputDatepicker} from '../../Inputs/InputDatepicker';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {
  getDateWithEndDay,
  getDateWithStartDay,
  getValidationErrorMessage,
  ValidationErrorsType,
} from '../../../utils/utils';
import {PartialNullable, UpdateEntity} from '../../../../types';
import {CreateAddendumFormState} from './modal-create-addendum-context';
import {InputText} from '../../Inputs/InputText';
import {InputCheckbox} from '../../Inputs/InputCheckbox';
import {FileDropzone} from '../../dropzone';

interface IModalCreateContractProps {
  visible: boolean;
  channels: Array<ISelectValueDto>;

  fields: PartialNullable<CreateAddendumFormState>;

  error?: string | null;
  validationErrors?: ValidationErrorsType;

  onChange: UpdateEntity<CreateAddendumFormState>;
  file: File | null;
  onSelectFile: (file: File | null) => void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalCreateAddendum: React.FC<IModalCreateContractProps> = ({
  visible,
  channels,
  fields,
  validationErrors,
  error,
  onChange,
  onHide,
  file,
  onSelectFile,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  const handleChangeRatePercent = useCallback((value: string | number | null) => {
    if (value == '' && value == null) {
      onChange({
        rate_percent: null as any,
      });
      return;
    }

    let preparedValue = '';
    const parts = value?.toString().split('.') ?? [];
    if (parts.length === 2) {
      preparedValue = parts[0] + '.' + parts[1].slice(0, 1);
    } else {
      preparedValue = parts[0];
    }

    onChange({
      rate_percent: preparedValue as any,
    });
  }, []);

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        disabledButtons={loading}
        successButtonTitle={intl.formatMessage({id: 'CREATE'})}
        onSuccess={handleOkClick}
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'CREATE_ADDENDUM'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <form className={'form'}>
            <AlertCustom
              text={error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
            <div className='form-group'>
              <ValidateErrorWrapper message={getValidationErrorMessage('y_channel_id', validationErrors)}>
                <InputSelect
                  id={'y_channel_id'}
                  required
                  isClearable
                  label={intl.formatMessage({id: 'CHANNEL'})}
                  placeholder={''}
                  isLoading={channels.length === 0}
                  hasError={!!getValidationErrorMessage('y_channel_id', validationErrors)}
                  className={'mb-2'}
                  value={fields.y_channel_id}
                  options={channels.map(channel => ({
                    value: channel.id,
                    label: channel.title,
                  }))}
                  onChange={value => onChange({y_channel_id: value})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('started_at', validationErrors)}>
                <InputDatepicker
                  showMonthYearPicker
                  hasError={!!getValidationErrorMessage('started_at', validationErrors)}
                  selected={fields.started_at}
                  required
                  label={intl.formatMessage({id: 'CONTRACT_START_DATE'})}
                  className={'mb-2'}
                  onChange={date => onChange({started_at: getDateWithStartDay(date)})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('expires_at', validationErrors)}>
                <InputDatepicker
                  showMonthYearPicker
                  selected={fields.expires_at}
                  hasError={!!getValidationErrorMessage('expires_at', validationErrors)}
                  label={intl.formatMessage({id: 'CONTRACT_END_DATE'})}
                  className={'mb-2'}
                  onChange={date => onChange({expires_at: getDateWithEndDay(date)})}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('rate_percent', validationErrors)}>
                <InputText
                  required
                  maxNumber={100}
                  minNumber={0}
                  step={0.5}
                  type={'number'}
                  label={`${intl.formatMessage({id: 'RATE_PERCENT'})}: ${fields.rate_percent ?? 0}%`}
                  classNames={'w-100 form-control'}
                  value={fields.rate_percent ?? 0}
                  onChange={e => handleChangeRatePercent(e.currentTarget.value)}
                />
              </ValidateErrorWrapper>

              <ValidateErrorWrapper message={getValidationErrorMessage('is_referral', validationErrors)}>
                <InputCheckbox
                  required
                  value={fields.is_referral ?? false}
                  label={intl.formatMessage({id: 'IS_REFERRAL'})}
                  onChange={() => onChange({is_referral: !fields.is_referral})}
                />
              </ValidateErrorWrapper>

              {file && (
                <>
                  {file.name}
                  <button className={'btn btn-sm btn-danger'} onClick={() => onSelectFile(null)}>
                    {intl.formatMessage({id: 'DELETE'})}
                  </button>
                </>
              )}
              <FileDropzone
                accept={['.xlsx']}
                onFileChange={files => {
                  if (files.length > 0) {
                    onSelectFile(files[0]);
                  }
                }}
              />
            </div>
          </form>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
