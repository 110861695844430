import React from 'react';
import Select from 'react-select';
import './InputSelect.scss';
import {ISelectValueDto} from '../../api/DTOs/ISelectValueDto';

export const mapSelectValuesDTOsToSelectOptions = (
  selectName: string,
  allSelectsValues: SelectsValuesDTOs,
): SelectOptions => {
  if (allSelectsValues[selectName]) {
    return allSelectsValues[selectName]?.map(selectValueDto => {
      return {
        label: selectValueDto.title,
        value: selectValueDto.id,
      };
    });
  }
  return [];
};
export type SelectOptions<T = string | number> = Array<{label: string; value: T, icon?: string}>;
export type SelectsValuesDTOs = Record<string, Array<ISelectValueDto>>;

interface Props {
  id?: string;
  label?: string;
  ariaLabel?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  value?: string | number | null;
  options: SelectOptions;
  className?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;

  onChange(value: string | number | null, id: string | undefined): void;
}

export const InputSelect = ({
  id,
  options,
  isClearable,
  isLoading,
  placeholder,
  ariaLabel,
  onChange,
  className,
  hasError,
  label,
  value,
  required,
  disabled,
}: Props) => {
  const onChangeInternal = (e: any) => {
    onChange(e?.value ?? null, id);
  };

  // Намерено не используется строгое сравнение, чтобы можно было адекватно сравнивать "200" и 200.
  const selectedValue = (value && options.filter(item => item.value == value)) || null;

  return (
    <div className={className + ' select-container'}>
      {label && (
        <label id='aria-label' htmlFor='aria-example-input' className={'font-weight-bolder'}>
          {label} {required && <span className={'text-danger'}>*</span>}
        </label>
      )}

      <Select
        classNamePrefix={'react-select'}
        value={selectedValue}
        aria-label={ariaLabel}
        options={options}
        isClearable={isClearable}
        isLoading={isLoading}
        placeholder={placeholder}
        isDisabled={disabled}
        menuPosition={'fixed'}
        onChange={onChangeInternal}
        styles={{
          control: styles => ({
            ...styles,
            ...(hasError
              ? {
                  borderColor: '#DC3545 !important',
                  boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
                }
              : {}),
          }),
        }}
      />
    </div>
  );
};
