import React from 'react';
import {AlertCustom} from '../../modules/Auth/component/alert';
import {ValidateErrorWrapper} from '../../components/Inputs/ValidateErrorWrapper';
import {getValidationErrorMessage} from '../../utils/utils';
import {FormattedMessage} from 'react-intl';
import {Preloader} from '../../components/preloader';
import {InputCheckbox} from '../../components/Inputs/InputCheckbox';
import {IFinancialReportPeriodDto} from '../../api/financial-report-api/IFinancialReportPeriodDto';
import {InputSelect} from '../../components/Inputs/InputSelect';
import {useIntl} from '../../hooks/use-intl';
import {ISelectValueDto} from '../../api/DTOs/ISelectValueDto';
import cn from 'classnames';

type Props = {
  loadingAvailablePeriods: boolean;
  loadingAvailableContracts: boolean;
  error?: string | null;
  blockedOperationMessage?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  loadingPeriods?: boolean;
  loadingContracts?: boolean;

  availableMonths: Array<IFinancialReportPeriodDto>;
  selectedMonths: Array<IFinancialReportPeriodDto>;

  availableContracts: Array<ISelectValueDto>;
  selectedContract: ISelectValueDto | null;

  onSelectContract: (contract: ISelectValueDto) => void;
  onSelectPeriod: (period: IFinancialReportPeriodDto) => void;
  className?: string;
};

export const FinancialReportsForm: React.FC<Props> = ({
  loadingAvailablePeriods,
  loadingAvailableContracts,
  blockedOperationMessage,
  validationErrors,
  availableMonths,
  selectedContract,
  selectedMonths,
  error,
  availableContracts,
  onSelectContract,
  onSelectPeriod,
  className,
}) => {
  const intl = useIntl();

  const renderContracts = () => {
    if (availableContracts.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return (
      <InputSelect
        isLoading={loadingAvailableContracts}
        disabled={availableContracts.length === 1}
        placeholder={intl.formatMessage({id: 'CONTRACT'})}
        value={selectedContract?.id}
        options={availableContracts.map(c => ({label: c.title, value: c.id}))}
        onChange={contractId => onSelectContract(availableContracts.find(c => c.id == contractId) as ISelectValueDto)}
      />
    );
  };

  return (
    <>
      <form className={cn('form', className)}>
        <AlertCustom
          text={error || blockedOperationMessage || ''}
          type={'light-danger'}
          iconClassName={'svg-icon-danger'}
          visible={!!error || !!blockedOperationMessage}
        />

        <div className={'mb-5'}>
          <>
            <div className={'font-size-h4 font-weight-boldest mb-5'}>
              <ValidateErrorWrapper message={getValidationErrorMessage('contract_id', validationErrors)}>
                <FormattedMessage id={'CONTRACTS'} />
              </ValidateErrorWrapper>
            </div>
            {renderContracts()}
          </>
        </div>

        <div>
          <div className={'font-size-h4 font-weight-boldest'}>
            <ValidateErrorWrapper message={getValidationErrorMessage('months_id', validationErrors)}>
              <FormattedMessage id={'MONTHS'} />
            </ValidateErrorWrapper>
          </div>
          {loadingAvailablePeriods ? (
            <Preloader />
          ) : (
            availableMonths.map((p, index) => {
              return (
                <InputCheckbox
                  key={index}
                  label={intl.formatDate(new Date(p.year, p.month - 1), {
                    month: 'long',
                    year: 'numeric',
                  })}
                  value={selectedMonths.includes(p)}
                  onChange={() => onSelectPeriod(p)}
                />
              );
            })
          )}
        </div>
      </form>
    </>
  );
};
