import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {
  IResponseConnectChannelRequest,
  IResponseConnectChannelRequests,
  ResponseSearchChannel,
} from './connect-channels-response-contracts';
import {IResponseBase} from '../response-contracts/base-response';
import {PartialNullable} from '../../../types';
import {PaymentRequisites} from '../payment-requisite-api/payment-requisites-api';
import {EntityId} from '../base/BaseEntity';

export type CreateConnectYoutubeChannelRequestFields = {
  y_channel_url: string;
  comment?: string | null;
} & PartialNullable<PaymentRequisites>;

export type ApproveConnectChannelRequestFields = {
  cms_id: number;
  rate_percent: number;
  contract_started_at: string | Date;
};

export class ConnectChannelsApi extends BaseEndpoint {
  //#region Admin endpoints
  public async getUsersConnectChannelRequests(filter?: IQueryParams): Promise<IResponseConnectChannelRequests> {
    const query = this.applyQueryParams(filter);
    const response = await this.get<IResponseConnectChannelRequests>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests?${query}`,
    );
    return response.data;
  }

  public async getUserConnectChannelRequest(id: number): Promise<IResponseConnectChannelRequest> {
    const response = await this.get<IResponseConnectChannelRequest>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests/${id}`,
    );
    return response.data;
  }

  public async approve(
    id: EntityId,
    fields: PartialNullable<ApproveConnectChannelRequestFields>,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests/${id}/approve`,
      {fields},
    );
    return response.data;
  }

  public async reject(id: number, reject_reason: string | null): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/youtube-channels-requests/${id}/reject`,
      {
        fields: {
          reject_reason,
        },
      },
    );
    return response.data;
  }

  //#endregion Admin endpoints

  //#region User endpoints
  public async getSelfConnectChannelRequest(params?: IQueryParams): Promise<IResponseConnectChannelRequests> {
    const queryParams = this.applyQueryParams(params);
    const response = await this.get<IResponseConnectChannelRequests>(
      `${config.BASE_API_URL}/youtube-channels-requests?${queryParams}`,
    );
    return response.data;
  }

  public async createConnectChannelRequest(
    fields: PartialNullable<CreateConnectYoutubeChannelRequestFields>,
  ): Promise<IResponseConnectChannelRequest> {
    const response = await this.post(`${config.BASE_API_URL}/youtube-channels-requests`, {
      fields,
    });
    return response.data;
  }

  public async searchYoutubeChannel(title: string) {
    const response = await this.get<ResponseSearchChannel>(
      `${config.BASE_API_URL}/youtube-channels-requests/search/${title}`,
    );
    return response.data;
  }

  //#endregion User endpoints
}
