import {IRemoteExceptionDescription} from './IRemoteExceptionDescription';

export enum EXCEPTION_TYPE {
  VALIDATION_EXCEPTION = 'VALIDATION_EXCEPTION',
  UNAUTHENTICATED_EXCEPTION = 'UNAUTHENTICATED_EXCEPTION',
  NOT_FOUND_HTTP_EXCEPTION = 'NOT_FOUND_HTTP_EXCEPTION',
  UNKNOWN_SERVER_EXCEPTION = 'UNKNOWN_SERVER_EXCEPTION',
  BAD_REQUEST_EXCEPTION = 'BAD_REQUEST_EXCEPTION',
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNKNOWN_EXCEPTION = 'UNKNOWN_EXCEPTION',
}

/**
 * Ошибка, которая приходит в результате упавшего запроса.
 */
export interface IBaseException {
  error_data?: unknown;
  error_type: EXCEPTION_TYPE;
  exception: IRemoteExceptionDescription;
}
