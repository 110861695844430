import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

export interface Props {
  to: string;
  title: string;
  classNames?: string;
}

export const SubMenuItem: React.FC<Props> = (props: Props) => {
  return (
    <li className={`menu-item ${props.classNames}`} aria-haspopup='true'>
      <NavLink className='menu-link' to={props.to}>
        <i className='menu-bullet menu-bullet-dot'>
          <span />
        </i>
        <span className='menu-text'><FormattedMessage id={props.title} /></span>
      </NavLink>
    </li>
  );
};

export default SubMenuItem;
