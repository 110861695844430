import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {InputSelect} from '../../Inputs/InputSelect';
import {InputDatepicker} from '../../Inputs/InputDatepicker';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getValidationErrorMessage} from '../../../utils/utils';

interface IModalCreateContractProps {
  visible: boolean;
  contractorId: number | null;
  contractors: Array<ISelectValueDto>;
  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  startDate: Date | null;
  endDate: Date | null;

  onHide(): void;

  onOkClick(): any;

  onChangeContractor(contractorId: string | number | null): void;

  onChangeStartDate(date: Date | null): void;

  onChangeEndDate(date: Date | null): void;
}

export const ModalCreateContract: React.FC<IModalCreateContractProps> = (
  {
    contractorId,
    visible,
    startDate,
    endDate,
    contractors,
    validationErrors,
    error,
    onHide,
    onChangeContractor,
    onChangeStartDate,
    onChangeEndDate,
    onOkClick,
  }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  return <>
    <BaseModal
      visible={visible}
      loading={loading}
      disabledButtons={loading}
      onSuccess={handleOkClick}
      onHide={onHide}
    >
      <ModalHeaderSlot>
        <Modal.Title>
          <FormattedMessage id={'CREATE_CONTRACT'} />
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <div className='form-group'>
            <ValidateErrorWrapper message={getValidationErrorMessage('contractor_id', validationErrors)}>
              <InputSelect
                id={'contractor_id'}
                required
                isClearable
                label={intl.formatMessage({id: 'CONTRACTOR'})}
                placeholder={''}
                isLoading={contractors.length === 0}
                hasError={!!getValidationErrorMessage('contractor_id', validationErrors)}
                className={'mb-2'}
                value={contractorId}
                options={contractors.map(contractor => ({
                  value: contractor.id,
                  label: contractor.title,
                }))}
                onChange={onChangeContractor}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('started_at', validationErrors)}>
              <InputDatepicker
                hasError={!!getValidationErrorMessage('started_at', validationErrors)}
                selected={startDate}
                required
                label={intl.formatMessage({id: 'CONTRACT_START_DATE'})}
                className={'mb-2'}
                onChange={onChangeStartDate}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('expires_at', validationErrors)}>
              <InputDatepicker
                selected={endDate}
                hasError={!!getValidationErrorMessage('expires_at', validationErrors)}
                label={intl.formatMessage({id: 'CONTRACT_END_DATE'})}
                className={'mb-2'}
                onChange={onChangeEndDate}
              />
            </ValidateErrorWrapper>
          </div>
        </form>
        <AlertCustom
          visible={true}
          type={'light-info'}
          iconClassName={'svg-icon-info'}
          text={intl.formatMessage({id: 'CONTRACT_NUMBER_BE_AUTOGENERATED'})}
        />
      </ModalBodySlot>
    </BaseModal>
  </>;
};
