import React from 'react';

interface InputCustomProps {
  value: string | number | boolean;
  label?: string | (() => any);
  name?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;

  onChange(params: any): any;
}

export const InputCheckbox = (
  {
    className,
    name,
    onChange,
    value, label, required, disabled,
  }: InputCustomProps) => {
  if (label) {
    return <div className={`form-group ${className}`}>
      <label className={`checkbox checkbox-outline checkbox-outline-2x ${disabled ? 'checkbox-disabled' : ''}`}>
        <input
          type='checkbox'
          disabled={disabled}
          className={className}
          name={name}
          checked={Boolean(Number(value))}
          onChange={onChange}
        />
        <span />
        <p className={'m-0 ml-2 p-0 font-weight-bolder'}>
          {typeof label === 'function' ? label() : label} {required && <span className={'text-danger'}>*</span>}
        </p>
      </label>
    </div>;
  }

  return (<>
      <label className={'checkbox checkbox-outline checkbox-outline-2x'}>
        <input
          type='checkbox'
          name={name}
          checked={Boolean(Number(value))}
          onChange={onChange}
        />
        <span />
      </label>
    </>
  );
};
