import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IResponseBase, IResponseMultipleContent} from '../response-contracts/base-response';
import {IAddendumForUserDto} from './IAddendumDto';
import {EntityId} from '../base/BaseEntity';
import {PartialNullable} from '../../../types';

export class AddendumApi extends BaseEndpoint {
  public async getForUser(): Promise<IResponseMultipleContent<IAddendumForUserDto>> {
    const response = await this.get(`${config.BASE_API_URL}/addendums`);
    return response.data;
  }

  public async createAddendum(
    fields: PartialNullable<{
      contract_id: EntityId | null;
      started_at: Date | string | null;
      expires_at: Date | string | null | undefined;
      y_channel_id: EntityId | null;
      rate_percent: number;
      is_referral: boolean;
    }>,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/addendums`, {
      fields,
    });
    return response.data;
  }

  public async checkHasTransactions(addendumId: number): Promise<IResponseBase<{item: boolean}>> {
    const response = await this.get<IResponseBase<{item: boolean}>>(
      `${config.BASE_API_URL}/admin/addendums/${addendumId}/has-transactions`,
    );
    return response.data;
  }

  public async editAddendum(
    addendumId: number | null,
    started_at: Date | string | null,
    expires_at: Date | string | null | undefined,
    y_channel_id: string | null,
    rate_percent: number,
    is_referral: boolean | null,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/addendums/${addendumId}`, {
      fields: {
        started_at,
        expires_at,
        y_channel_id,
        rate_percent,
        is_referral,
      },
    });

    return response.data;
  }

  public async editExpiresAt(addendumId: number, expires_at: Date | string | null | undefined): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/addendums/${addendumId}/update-expires-at`,
      {
        fields: {
          expires_at,
        },
      },
    );

    return response.data;
  }

  public async download(addendumId: number): Promise<{fileName: string; content: Blob}> {
    const response = await this.getFile(`${config.BASE_API_URL}/admin/addendums/${addendumId}/download-document`);
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }

  public async updateTemplateFile(addendumId: EntityId, templateFile: File) {
    const formData = new FormData();
    formData.append('fields[file]', templateFile);
    return this.postFile(`${config.BASE_API_URL}/admin/addendums/${addendumId}/template`, formData);
  }

  public async deleteTemplateFile(addendumId: EntityId) {
    return this.delete(`${config.BASE_API_URL}/admin/addendums/${addendumId}/template`);
  }

  public async getTemplateFile(addendumId: EntityId) {
    const response = await this.getFile(`${config.BASE_API_URL}/admin/addendums/${addendumId}/template`);
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }
}
