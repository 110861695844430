import React from 'react';
import {ContentRoute} from '../../../../_metronic/layout';
import {ReportsUploadPage} from './reports-upload-page';
import {Switch} from 'react-router-dom';
import {ReportsDownloadPage} from './reports-download-page';
import {AdminRoutes} from '../../../../configs/routes';

export const YoutubeFinancialReportsPage: React.FC<any> = () => {

  const renderContent = () => {
    return (<>
      <div className='col-xxl-12'>
        <Switch>
          <ContentRoute path={AdminRoutes.getUploadYoutubeFinancialReports()} component={ReportsUploadPage} />
          <ContentRoute path={AdminRoutes.getYoutubeFinancialReportsRoute()} component={ReportsDownloadPage} />
        </Switch>
      </div>
    </>);
  };

  return <>
    {renderContent()}
  </>;
};
