import React from 'react';
import {Dropdown, Spinner} from 'react-bootstrap';

export interface IDropdownItem {
  title: string;
  value: string | number;
  disabled?: boolean;
}

interface IDropdownProps {
  label: string;
  values: Array<IDropdownItem>;

  id?: string;
  loading?: boolean;
  className?: string;
  asToggle?: any;
  asMenu?: any;

  onSelect(value: string, id?: string | number): void;
}

export const DropdownCustomButton: React.FC<IDropdownProps> = ({
  onSelect,
  id,
  className,
  label,
  values,
  loading,
  asMenu,
  asToggle,
}) => {
  const handleChangeSelector = (value: string | null, e: React.SyntheticEvent<any>) => {
    if (value !== null) {
      onSelect(value, e.currentTarget.id);
    }
  };

  return (
    <Dropdown id={id} onSelect={handleChangeSelector} drop='down'>
      <Dropdown.Toggle as={asToggle} className={`${className}`}>
        {label} {loading && <Spinner className={'ml-2'} size={'sm'} animation={'border'} />}
      </Dropdown.Toggle>
      <Dropdown.Menu as={asMenu}>
        {values.map(v => {
          return (
            <Dropdown.Item
              disabled={v.disabled}
              key={v.value}
              className='navi-item navi-link'
              eventKey={v.value.toString()}>
              <div className='navi-link'>
                <span className='navi-text'>{v.title}</span>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
