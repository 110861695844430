import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {PaymentsApi} from '../../../api/payment-api/payments-api';
import {ModalAdminPaymentRequest} from './admin-payment-request-modal';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {ValidationErrorsType} from '../../../utils/utils';
import {useLoading} from '../../../hooks/use-loading';
import {SelectApi} from '../../../api/select-api';
import {EntityId} from '../../../api/base/BaseEntity';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {FinancialAccountType} from '../../../api/DTOs/IFinancialAccountDto';
import {toast} from 'react-toastify';
import {AccountApi} from '../../../api/account-api';

interface IModalPaymentRequestProps {
  modalPaymentRequestVisible: boolean;

  showPaymentRequestModal(): Promise<CloseModalEvent<null>>;
}

export type ModalPaymentRequestField = {
  contractor_id: EntityId;
  contractors: ISelectValueDto[];
  balance: any;
  error: string;
  validationErrors: ValidationErrorsType;
  value: string | number;
  visible: boolean;
};

// @ts-ignore
const ModalAdminPaymentRequestContext = createContext<IModalPaymentRequestProps>();

let closeResolver: ((data: CloseModalEvent<null>) => unknown) | null = null;
export const ModalAdminPaymentRequestProvider: React.FC = ({children}: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const selectApi = new SelectApi();
  const api = useMemo(() => new PaymentsApi(), []);
  const accountsApi = useMemo(() => new AccountApi(), []);
  const [loadings, startLoading, stopLoading] = useLoading({createRequest: false, balance: false});
  const [state, , updateState, resetState] = useAdvancedState<ModalPaymentRequestField>({
    visible: false,
  });

  useEffect(() => {
    if (state.contractor_id != null) {
      fetchBalance(state.contractor_id).then();
    }
  }, [state.contractor_id]);

  const fetchContractors = useCallback(() => {
    selectApi
      .getContractors({filters: {mc_pay: 'HAS_CREDENTIALS'}})
      .then(r => updateState({contractors: r.data.items}));
  }, []);

  const fetchBalance = useCallback(async (contractorId: EntityId) => {
    try {
      startLoading('balance');
      const {
        data: {items},
      } = await accountsApi.getFinancialAccounts({
        filters: {
          contractor_id: contractorId,
          type: FinancialAccountType.INNER,
        },
      });

      if (items.length > 0) {
        updateState({
          balance: {
            balance: items[0].balance,
            unconfirmedBalance: items[0].waitingConfirmBalance,
          },
        });
      }
    } catch (e) {
      updateState({balance: null});
      const err = e as ApiRequestException;
      toast.error(e.message || err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      stopLoading('balance');
    }
  }, []);

  const showModal = async () => {
    updateState({
      visible: true,
    });

    fetchContractors();

    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    resetState();
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      updateState({error: null, validationErrors: null});
      await api.createAdminPaymentRequest(state);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        updateState({
          validationErrors: (err.innerException as IValidationException).error_data.messages,
        });
      } else {
        updateState({error: err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'})});
      }
    }
  };

  const value: IModalPaymentRequestProps = {
    modalPaymentRequestVisible: state.visible ?? false,
    showPaymentRequestModal: showModal,
  };

  return (
    <ModalAdminPaymentRequestContext.Provider value={value}>
      {children}
      <ModalAdminPaymentRequest
        loadings={loadings}
        state={state}
        updateState={updateState}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalAdminPaymentRequestContext.Provider>
  );
};

export const useModalAdminPaymentRequest = () => {
  return useContext(ModalAdminPaymentRequestContext);
};
