import {BaseEndpoint} from '../base-endpoint';
import {IQueryParams} from '../DTOs/IFilterDtos';
import config from '../../../configs/config';
import {IResponseContract, IResponseContracts} from './contracts-response-contract';
import {IResponseBase} from '../response-contracts/base-response';
import {IContractDto} from './IContractDto';

export class ContractsApi extends BaseEndpoint {
  public async getContracts(filters?: IQueryParams): Promise<IResponseContracts> {
    const query = this.applyQueryParams(filters);
    const response = await this.get<IResponseContracts>(`${config.BASE_API_URL}/admin/contracts?${query}`);
    return response.data;
  }

  public async getContract(contractId: number): Promise<IResponseContract> {
    const response = await this.get<IResponseContract>(`${config.BASE_API_URL}/admin/contracts/${contractId}`);
    return response.data;
  }

  public async createContract(contractorId: number | null, startDate: Date | string | null, endDate: Date | string | null): Promise<IResponseBase<{item: IContractDto}>> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/contracts`, {
      fields: {
        contractor_id: contractorId,
        started_at: startDate,
        expires_at: endDate,
      },
    });
    return response.data;
  }

  public async editContract(contractId: number | null, startDate: Date | string | null, endDate: Date | string | null): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/contracts/${contractId}`, {
      fields: {
        started_at: startDate,
        expires_at: endDate,
      },
    });

    return response.data;
  }
}
