import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../../../configs/routes';

import './user-agreement.scss';
import {useIntl} from '../../../hooks/use-intl';
import {AppLocale} from '../../../../localization';

export const UserAgreement: React.FC = () => {
  const intl = useIntl();
  return (
    <div className='text-center my-10 my-lg-20 w-100 px-5'>
      <h3 className='font-size-h1'>
        <FormattedMessage id='USER_AGREEMENT' />
      </h3>
      <div className={'user-agreement-wrapper my-5'}>
        <iframe frameBorder={0} src={Routes.getUserAgreementContentRoute(intl.locale as AppLocale)} />
      </div>
      <div>
        <div>
          <a target={'_blank'} href={'https://www.youtube.com/t/terms'} rel='noreferrer'>
            YouTube Terms Of Services
          </a>
        </div>
        <div>
          <a target={'_blank'} href={'https://policies.google.com/privacy'} rel='noreferrer'>
            Google Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};
