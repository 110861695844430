import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IResponsePaymentRequisites} from './payment-requisites-response-contracts';
import {IResponseBase} from '../response-contracts/base-response';
import {EntityId} from '../base/BaseEntity';
import {BaseItemsResponse} from '../base/base-crud-api-response';
import {PaymentRequisitesHistory} from './IPaymentRequisitesDto';
import {PartialNullable} from '../../../types';

export type PaymentRequisites = {
  ru_beneficiary_name: string;
  ru_beneficiary_address: string;

  en_beneficiary_name: string;
  en_beneficiary_address: string;

  beneficiary_bank_swift_number: string;
  beneficiary_account_number: string;
};

export class PaymentRequisitesApi extends BaseEndpoint {
  /**
   * Получение реквизитов пользователя от имени администратора.
   * @param userId Идентификатор пользователя.
   */
  public async getUserPaymentRequisites(userId: EntityId): Promise<IResponsePaymentRequisites> {
    const response = await this.get<IResponsePaymentRequisites>(
      `${config.BASE_API_URL}/admin/users/${userId}/payment-requisites`,
    );
    return response.data;
  }

  /**
   * Получение истории изменения реквизитов пользователя.
   * @param userId Идентификатор пользователя.
   */
  public async getUserPaymentRequisitesHistory(userId: EntityId): Promise<BaseItemsResponse<PaymentRequisitesHistory>> {
    const response = await this.get(`${config.BASE_API_URL}/admin/users/${userId}/payment-requisites-history`);
    return response.data;
  }

  /**
   * Получение реквизитов залогиненного пользователя.
   */
  public async getSelfPaymentRequisites(): Promise<IResponsePaymentRequisites> {
    const response = await this.get<IResponsePaymentRequisites>(`${config.BASE_API_URL}/profile/payment-requisites`);
    return response.data;
  }

  /**
   * Обновление реквизитов пользователя от имени администратора.
   * @param userId Идентификатор пользователя.
   * @param fields
   */
  public async updateUserPaymentRequisites(
    userId: EntityId,
    fields: PartialNullable<PaymentRequisites>,
  ): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/users/${userId}/payment-requisites`, {
      fields,
    });
    return response.data;
  }

  /**
   * Обновление реквизитов залогиненного пользователя.
   * @param fields
   */
  public async updateSelfPaymentRequisites(fields: PartialNullable<PaymentRequisites>): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/profile/payment-requisites`, {
      fields,
    });
    return response.data;
  }
}
