import React, {useCallback} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {FormattedMessage, useIntl} from 'react-intl';
import {InputSelect} from '../../Inputs/InputSelect';
import {InputDatepicker} from '../../Inputs/InputDatepicker';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {getDateWithEndDay, getDateWithStartDay, getValidationErrorMessage} from '../../../utils/utils';
import {Preloader} from '../../preloader';
import {InputText} from '../../Inputs/InputText';
import {InputCheckbox} from '../../Inputs/InputCheckbox';

interface IModalCreateContractProps {
  visible: boolean;
  fullEditMode: boolean;
  ratePercent: number;
  channelId: string | null;
  channels: Array<ISelectValueDto>;
  startDate: Date | null;
  endDate: Date | null;
  loading?: boolean;
  isReferral?: boolean;

  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  onHide(): void;

  onOkClick(): any;

  onChangeChannel(channelId: string | number | null): void;

  onChangeRatePercent(rate: number): void;

  onChangeStartDate(date: Date | null): void;

  onChangeReferral(isReferral: boolean): void;

  onChangeEndDate(date: Date | null): void;
}

export const ModalEditAddendum: React.FC<IModalCreateContractProps> = ({
  channelId,
  loading,
  ratePercent,
  visible,
  startDate,
  isReferral,
  endDate,
  channels,
  validationErrors,
  error,
  fullEditMode,
  onHide,
  onChangeChannel,
  onChangeStartDate,
  onChangeEndDate,
  onChangeReferral,
  onChangeRatePercent,
  onOkClick,
}) => {
  const intl = useIntl();

  const handleChangeRatePercent = useCallback((value: string | number | null) => {
    if (value == '' && value == null) {
      onChangeRatePercent(0);
      return;
    }

    let preparedValue: string;
    const parts = value?.toString().split('.') ?? [];
    if (parts.length === 2) {
      preparedValue = parts[0] + '.' + parts[1].slice(0, 1);
    } else {
      preparedValue = parts[0];
    }

    onChangeRatePercent(preparedValue as any);
  }, []);
  const renderForm = () => {
    return (
      <>
        <AlertCustom
          text={intl.formatMessage({id: 'NOTICE_EDIT_RESTRICTED'})}
          type={'light-warning'}
          iconClassName={'svg-icon-warning'}
          visible={!fullEditMode}
        />

        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error !== null}
          />

          <div className='form-group'>
            <ValidateErrorWrapper message={getValidationErrorMessage('y_channel_id', validationErrors)}>
              <InputSelect
                id={'y_channel_id'}
                required
                disabled={!fullEditMode}
                isClearable
                label={intl.formatMessage({id: 'CHANNEL'})}
                placeholder={''}
                isLoading={channels.length === 0}
                hasError={!!getValidationErrorMessage('y_channel_id', validationErrors)}
                className={'mb-2'}
                value={channelId}
                options={channels.map(channel => ({
                  value: channel.id,
                  label: channel.title,
                }))}
                onChange={onChangeChannel}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('started_at', validationErrors)}>
              <InputDatepicker
                required
                showMonthYearPicker
                disabled={!fullEditMode}
                hasError={!!getValidationErrorMessage('started_at', validationErrors)}
                selected={startDate}
                label={intl.formatMessage({id: 'CONTRACT_START_DATE'})}
                className={'mb-2'}
                onChange={date => onChangeStartDate(getDateWithStartDay(date))}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('expires_at', validationErrors)}>
              <InputDatepicker
                showMonthYearPicker
                selected={endDate}
                hasError={!!getValidationErrorMessage('expires_at', validationErrors)}
                label={intl.formatMessage({id: 'CONTRACT_END_DATE'})}
                className={'mb-2'}
                onChange={date => onChangeEndDate(getDateWithEndDay(date))}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('rate_percent', validationErrors)}>
              <InputText
                required
                disabled={!fullEditMode}
                maxNumber={100}
                minNumber={0}
                step={0.5}
                type={'number'}
                label={`${intl.formatMessage({id: 'RATE_PERCENT'})}: ${ratePercent ?? 0}%`}
                classNames={'w-100 form-control'}
                value={ratePercent ?? 0}
                onChange={e => handleChangeRatePercent(e.currentTarget.value)}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('is_referral', validationErrors)}>
              <InputCheckbox
                required
                value={isReferral ?? false}
                label={intl.formatMessage({id: 'IS_REFERRAL'})}
                onChange={() => onChangeReferral(!(isReferral ?? false))}
              />
            </ValidateErrorWrapper>
          </div>
        </form>
      </>
    );
  };

  return (
    <BaseModal
      visible={visible}
      successButtonTitle={intl.formatMessage({id: 'EDIT'})}
      onSuccess={onOkClick}
      onHide={onHide}>
      <ModalHeaderSlot>
        <Modal.Title>
          <FormattedMessage id={'EDIT_ADDENDUM'} />
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>{loading ? <Preloader /> : renderForm()}</ModalBodySlot>
    </BaseModal>
  );
};
