import React from 'react';
import SVG from 'react-inlinesvg';
import './alert.scss';
import cn from 'classnames';

interface AlertProps {
  text: string | null | React.ReactNode;
  type: AlertType;
  visible: boolean;

  icon?: string;
  className?: string;
  iconClassName?: string;
  dismissible?: boolean;

  onClose?(): void;
}

type AlertType = 'danger' | 'warning' | 'light-warning' | 'light-info' | 'light-danger' | 'success' | 'light-success';

const ICONS = {
  CHECK: require('../../../images/svg/Check.svg'),
  WARNING: require('../../../images/svg/Warning.svg'),
  INFO: require('../../../images/svg/Info-circle.svg'),
};

export const AlertCustom = ({
  text,
  type,
  visible,
  icon,
  className,
  dismissible,
  iconClassName,
  onClose,
}: AlertProps) => {
  if (!visible) return null;

  const getIcon = () => {
    if (icon) return icon;
    switch (type) {
      case 'danger':
      case 'warning':
      case 'light-danger':
      case 'light-warning':
        return ICONS.WARNING;
      case 'light-info':
        return ICONS.INFO;
      case 'success':
      case 'light-success':
        return ICONS.CHECK;
      default:
        return ICONS.INFO;
    }
  };

  const selectedIcon = getIcon();
  return (
    <div className={cn('alert alert-custom fade show', className, `alert-${type}`)} role='alert'>
      {selectedIcon && (
        <div className='alert-icon'>
          <span className={`svg-icon svg-icon-3x ${iconClassName}`}>
            <SVG src={selectedIcon} />
          </span>
        </div>
      )}
      <div className='alert-text font-weight-bolder'>{text}</div>
      {dismissible && (
        <div className='alert-close'>
          <button onClick={onClose} type='button' className='close' data-dismiss='alert' aria-label='Close'>
            <span aria-hidden='true'>
              <i className='ki ki-close' />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
