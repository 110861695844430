import React, {createContext, useContext, useState} from 'react';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ModalPreviewHtmlContent} from './modal-preview-html-content';

interface IModalPreviewHtmlContext {
  showPreviewHtmlModal(html: string): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalPreviewHtmlContext = createContext<IModalPreviewHtmlContext>();

let closeResolver: (((data: CloseModalEvent<null>) => any) | null) = null;
export const ModalPreviewHtmlProvider = ({children}: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [html, setHtml] = useState<string | null>(null);

  const showModal = async (html: string) => {
    setVisible(true);
    setHtml(html);
    return new Promise<CloseModalEvent<null>>((resolve) => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setHtml(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const value: IModalPreviewHtmlContext = {
    showPreviewHtmlModal: showModal,
  };

  return <ModalPreviewHtmlContext.Provider value={value}>
    {children}
    <ModalPreviewHtmlContent
      visible={visible}
      htmlContent={html}

      onHide={handleHideModal}
    />
  </ModalPreviewHtmlContext.Provider>;
};

export const useModalPreviewHtml = () => {
  return useContext(ModalPreviewHtmlContext);
};
