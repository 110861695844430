import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {ValidateErrorWrapper} from '../../Inputs/ValidateErrorWrapper';
import {InputText} from '../../Inputs/InputText';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import {InputGroupRadio} from '../../Inputs/InputGroupRadio';
import {SupportTicketType} from '../../../api/support-tickets-api/ISupportTicketDto';
import {RichTextEditor} from '../../rich-text-editor/rich-text-editor';
import {getValidationErrorMessage} from '../../../utils/utils';

interface IModalCreateSupportTicket {
  visible: boolean;
  topic: string;
  message: string;
  ticketType: SupportTicketType;

  error?: string | null;
  validationErrors?: {[key: string]: Array<string>} | null;

  onCloseModal(): void;

  onSendButtonClick(): any,

  onChangeTicketTheme(theme: string): void;

  onChangeTicketText(text: string): void;

  onChangeTicketType(type: SupportTicketType): void;
}

export const ModalCreateSupportTicket: React.FC<IModalCreateSupportTicket> = (
  {
    visible,
    topic,
    ticketType,
    message,

    error,
    validationErrors,

    onCloseModal,
    onSendButtonClick,
    onChangeTicketTheme,
    onChangeTicketText,
    onChangeTicketType,
  }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const radioItems = [
    {
      label: intl.formatMessage({id: 'SUPPORT_TICKET_TYPE_' + SupportTicketType.WISH}),
      value: SupportTicketType.WISH,
    },
    {
      label: intl.formatMessage({id: 'SUPPORT_TICKET_TYPE_' + SupportTicketType.PROBLEM}),
      value: SupportTicketType.PROBLEM,
    },
    {
      label: intl.formatMessage({id: 'SUPPORT_TICKET_TYPE_' + SupportTicketType.QUESTION}),
      value: SupportTicketType.QUESTION,
    },
  ];

  const handleSendButtonClick = async () => {
    setLoading(true);
    await onSendButtonClick();
    setLoading(false);
  };

  const handleChangeTicketTheme = (e: any) => {
    onChangeTicketTheme(e.currentTarget.value);
  };

  const handleChangeTicketText = (content: string) => {
    onChangeTicketText(content);
  };

  return <>
    <BaseModal
      visible={visible}
      loading={loading}
      disabledButtons={loading}
      onSuccess={handleSendButtonClick}
      onHide={onCloseModal}
    >
      <ModalHeaderSlot>
        <Modal.Title>
          <FormattedMessage id={'CREATE_TICKET'} />
        </Modal.Title>
      </ModalHeaderSlot>
      <ModalBodySlot>
        <form className={'form'}>
          <AlertCustom
            text={error || ''}
            type={'light-danger'}
            iconClassName={'svg-icon-danger'}
            visible={error != undefined}
          />
          <div className='form-group'>
            <ValidateErrorWrapper message={getValidationErrorMessage('topic', validationErrors)}>
              <InputText
                required
                value={topic}
                label={intl.formatMessage({id: 'TICKET_TOPIC'})}
                hasError={!!getValidationErrorMessage('topic', validationErrors)}
                classNames={'form-control py-3 px-6 mr-2'}
                onChange={handleChangeTicketTheme}
              />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('message', validationErrors)}>
              <RichTextEditor onChange={handleChangeTicketText} content={message} />
            </ValidateErrorWrapper>

            <ValidateErrorWrapper message={getValidationErrorMessage('type', validationErrors)}>
              <InputGroupRadio
                required
                label={intl.formatMessage({id: 'SUPPORT_TICKET_REASON'})}
                selected={ticketType}
                items={radioItems}
                onChange={onChangeTicketType}
              />
            </ValidateErrorWrapper>
          </div>
        </form>
      </ModalBodySlot>
    </BaseModal>
  </>;
};
