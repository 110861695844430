import {useCallback, useState} from 'react';

export function useToggle(defaultValue = false): [boolean, () => void, (value: boolean) => void] {
  const [toggleValue, setValue] = useState<boolean>(defaultValue);
  const toggle = useCallback(() => {
    setValue(prevState => !prevState);
  }, []);

  return [toggleValue, toggle, setValue];
}
