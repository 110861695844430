import React, {createContext, useContext, useState} from 'react';
import {ApiRequestException} from '../../../api/axios-instance';
import {ModalChooseUsers} from './modal-choose-users';
import {useIntl} from 'react-intl';
import {SelectApi} from '../../../api/select-api';
import {ISelectValueDto} from '../../../api/DTOs/ISelectValueDto';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';


interface IModalChooseUsersContext {
  modalChooseUsersVisible: boolean;

  showChooseUsersModal(): Promise<CloseModalEvent<Array<ISelectValueDto>>>;
}

// @ts-ignore
const ModalChooseUsersContext = createContext<IModalChooseUsersContext>();

let closeResolver: (((data: CloseModalEvent<Array<ISelectValueDto>>) => any) | null) = null;
export const ModalChooseUsersProvider = ({children}: any) => {
  const intl = useIntl();

  const api = new SelectApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<ISelectValueDto>>([]);
  const [filterPattern, setFilterPattern] = useState<string>();
  const [selectedUsers, setSelectedUsers] = useState<Array<ISelectValueDto>>([]);

  const getAppliedUsers = () => {

    if (filterPattern) {
      return users.filter(u => u.title.includes(filterPattern));
    }
    return users;
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const result = await api.getUsers();
      setUsers(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    } finally {
      setLoading(false);
    }
  };

  const showModal = async () => {
    setVisible(true);
    if (users.length === 0) {
      await fetchUsers();
    }

    return new Promise<CloseModalEvent<Array<ISelectValueDto>>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users);
    }
  };

  const handleSelectUser = (selectedUser: ISelectValueDto) => {
    if (selectedUsers.includes(selectedUser)) {
      const excludedUsers = selectedUsers.filter(u => u.id !== selectedUser.id);
      setSelectedUsers(excludedUsers);
    } else {
      setSelectedUsers([...selectedUsers, selectedUser]);
    }
  };

  const handleFilterChange = (filter: string) => {
    setFilterPattern(filter);
  };

  const handleHideModal = () => {
    setVisible(false);
    setUsers([]);
    setSelectedUsers([]);
    setFilterPattern('');
    setError(null);
    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
    }
    closeResolver = null;
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK, payload: selectedUsers});
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
    }
  };

  const value: IModalChooseUsersContext = {
    modalChooseUsersVisible: visible,
    showChooseUsersModal: showModal,
  };

  return <ModalChooseUsersContext.Provider value={value}>
    {children}
    <ModalChooseUsers
      loadingUsers={loading}
      filterPattern={filterPattern}
      visible={visible}
      error={error}
      users={getAppliedUsers()}
      selectedUsers={selectedUsers}

      onFilterChange={handleFilterChange}
      onSelectAll={handleSelectAll}
      onSelectUser={handleSelectUser}
      onHide={handleHideModal}
      onOkClick={handleOkClick}

    />
  </ModalChooseUsersContext.Provider>;
};

export const useModalChooseUsers = () => {
  return useContext(ModalChooseUsersContext);
};
