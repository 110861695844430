import React, {useMemo} from 'react';
import {AsideMenu} from './aside-menu';
import {useHtmlClassService} from '../../../_metronic/layout';
import {Brand} from './brand';

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses('aside', true),
    };
  }, [uiService]);

  return (<div id='kt_aside'
               className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>
      <Brand />
      <div id='kt_aside_menu_wrapper' className='aside-menu-wrapper flex-column-fluid'>
        <AsideMenu />
      </div>
    </div>
  );
}
