import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FinancialReportApi} from '../../api/financial-report-api/financial-report-api';
import {IFinancialReportPeriodDto} from '../../api/financial-report-api/IFinancialReportPeriodDto';
import {ApiRequestException} from '../../api/axios-instance';
import {EXCEPTION_TYPE} from '../../api/exceptions/IBaseException';
import './financial-reports-page.scss';
import {ISelectValueDto} from '../../api/DTOs/ISelectValueDto';
import {SelectApi} from '../../api/select-api';
import {IValidationException} from '../../api/exceptions/IValidationException';
import {useLoading} from '../../hooks/use-loading';
import {FinancialReportsForm} from './financial-reports-form';
import {AlertCustom} from '../../modules/Auth/component/alert';
import {Toolbar} from '../../components/card-toolbar/Toolbar';

export const FinancialReportsPage: React.FC = () => {
  const intl = useIntl();

  const api = new FinancialReportApi();
  const selectApi = new SelectApi();
  const [loadings, startLoading, stopLoading] = useLoading({
    submitGenerate: false,
    period: true,
    contract: true,
  });

  const [visibleInfoAlert, setVisibleInfoAlert] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: Array<string>} | null>(null);

  const [contracts, setContracts] = useState<Array<ISelectValueDto>>([]);
  const [availablePeriods, setAvailablePeriods] = useState<Array<IFinancialReportPeriodDto>>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<Array<IFinancialReportPeriodDto>>([]);
  const [selectedContract, setSelectedContract] = useState<ISelectValueDto | null>(null);

  useEffect(() => {
    void Promise.all([fetchAvailableMonth(), fetchContracts()]);
  }, []);

  const fetchAvailableMonth = async () => {
    try {
      stopLoading('period');
      const result = await api.getReportPeriods();
      setSelectedPeriods([result.data.items[0]]);
      setAvailablePeriods(result.data.items);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('period');
    }
  };

  const fetchContracts = async () => {
    try {
      startLoading('contract');
      const result = await selectApi.getSelfContracts();
      setContracts(result.data.items);
      if (result.data.items.length > 0) {
        setSelectedContract(result.data.items[0]);
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('contract');
    }
  };

  const sendGenerateReportRequest = async () => {
    try {
      setValidationErrors(null);
      setError(null);
      startLoading('submitGenerate');
      setVisibleInfoAlert(false);
      await api.sendSelfGenerateReportRequest(
        selectedContract?.id ?? null,
        selectedPeriods.map(i => i.id),
      );
      setVisibleInfoAlert(true);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationErrors((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('submitGenerate');
    }
  };

  const handleGenerateClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await sendGenerateReportRequest();
  };

  const handleCheckboxPeriodClick = (period: IFinancialReportPeriodDto) => {
    if (selectedPeriods.includes(period)) {
      const excludedPeriods = selectedPeriods.filter(p => p.id !== period.id);
      setSelectedPeriods(excludedPeriods);
    } else {
      setSelectedPeriods([...selectedPeriods, period]);
    }
  };

  const handleCheckboxContractClick = (contract: ISelectValueDto) => {
    if (contract === selectedContract) {
      setSelectedContract(null);
    } else {
      setSelectedContract(contract);
    }
  };

  return (
    <>
      <div className={'card card-custom gutter-b'}>
        <div className={'card-header py-3'}>
          <div className='card-title'>
            <h3 className='card-label font-weight-bolder text-dark'>
              <FormattedMessage id={'GENERATE_REPORT'} />
            </h3>
          </div>
        </div>
        <div className={`card-body`}>
          <AlertCustom
            dismissible
            onClose={() => setVisibleInfoAlert(false)}
            text={intl.formatMessage({id: 'SUCCESS_SEND_GENERATE_REPORT_REQUEST'})}
            type={'light-info'}
            iconClassName={'svg-icon-info'}
            visible={visibleInfoAlert}
          />

          <FinancialReportsForm
            error={error}
            validationErrors={validationErrors}
            loadingAvailablePeriods={loadings.period}
            loadingAvailableContracts={loadings.contract}
            availableMonths={availablePeriods}
            selectedMonths={selectedPeriods}
            availableContracts={contracts}
            selectedContract={selectedContract}
            className={'w-xl-50 w-xxl-30'}
            onSelectContract={handleCheckboxContractClick}
            onSelectPeriod={handleCheckboxPeriodClick}
          />
          <Toolbar
            className={'w-100 justify-content-center mt-5'}
            items={[
              {
                loading: loadings.submitGenerate,
                type: 'BUTTON',
                disabled: Object.values(loadings).some(item => item),
                title: intl.formatMessage({id: 'GENERATE'}),
                className: 'btn btn-primary',
                onClick: handleGenerateClick,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
