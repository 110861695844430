import React from 'react';
import cn from 'classnames';
import SVG from 'react-inlinesvg';
import {BootstrapColor, BootstrapSizes} from '../styles/styles';

type Props = {
  src: string;
  visible?: boolean;
  spin?: boolean;
  color?: BootstrapColor;
  size?: BootstrapSizes;
  className?: string;
  onClick?: ()=> void;
};

export function Svg({src, color, size, spin, className, visible, onClick}: Props) {
  if (visible !== undefined && !visible) {
    return null;
  }

  return (
    <span
      onClick={onClick}
      className={cn('svg-icon', className, {
        [`svg-icon-${color}`]: color,
        [`svg-icon-${size}`]: size,
        [`svg-spin`]: spin,
      })}>
      <SVG src={src} />
    </span>
  );
}
