import {ReportsProcessingStatus} from '../../app/api/financial-report-api/IYoutubeFinancialBulkReportsDto';
import {ContractStatus} from '../../app/api/contract-api/IContractDto';
import {PaymentRequestDestination, PaymentRequestStatus} from '../../app/api/payment-api/IPaymentRequestDto';
import {FilterRequestStatus} from '../../app/api/connect-channel-api/FilterRequestStatus';
import {
  McPayCreateTransactionStatus,
  TransactionStatus,
  TransactionType,
} from '../../app/api/transaction-api/Transaction';
import {SupportTicketStatus, SupportTicketType} from '../../app/api/support-tickets-api/ISupportTicketDto';
import {AdsStatus} from '../../app/api/advertisement-api/IAdvertisementRequestDto';
import {ArticleStatus} from '../../app/api/knowledge-base-api/IArticleDto';

export default {
  'BUTTONS.SUBMIT': 'Отправить',
  'BUTTONS.CANCEL': 'Отменить',
  'AUTH.GENERAL.WELCOME': 'Добро пожаловать в Play Network',
  'AUTH.GENERAL.WELCOME_SUBTITLE': 'Развитая экосистема для создателей контента',
  'AUTH.GENERAL.NO_ACCOUNT': 'Нет аккаунта?',
  'AUTH.GENERAL.ALREADY_HAVE_ACCOUNT': 'Уже есть аккаунт?',
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Зарегистрироваться',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Забыли пароль',
  'AUTH.GENERAL.BACK_BUTTON': 'Назад',
  'AUTH.LOGIN.TITLE': 'Войти в аккаунт',
  'AUTH.LOGIN.SUB_TITLE': 'Введите электронную почту и пароль',
  'AUTH.LOGIN.BUTTON': 'Войти',
  'AUTH.FORGOT.TITLE': 'Забыли пароль?',
  'AUTH.FORGOT.DESC': 'Введите email, чтобы сбросить пароль',
  'AUTH.REGISTER.TITLE': 'Регистрация',
  'AUTH.REGISTER.DESC': 'Для создания аккаунта заполните форму',
  'AUTH.REGISTER.AGREEMENT': 'Я соглашаюсь с Политикой конфиденциальности при регистрации и создании личного кабинета.',
  'AUTH.REGISTER.UNEXPECTED_ERROR': 'Что-то пошло не так, попробуйте позже',
  'AUTH.INPUT.EMAIL': 'Электронная почта',
  'AUTH.INPUT.PASSWORD': 'Пароль',
  'AUTH.INPUT.JURISDICTION': 'Страна действия юрисдикции',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Повторите пароль',
  MC_PAY_INTEGRATION_ENABLED: 'Включена',
  MC_PAY_INTEGRATION_DISABLED: 'Выключена',

  ID: 'ID',
  NAME: 'Имя',
  USER: 'Пользователь',
  ADMIN: 'Администратор',
  USER_PROFILE: 'Профиль',
  LOGIN_ERROR: 'Некорректная почта или пароль',

  SUBMIT: 'Отправить',
  CANCEL: 'Отменить',
  NOT_FOUND: 'Список пуст',
  DELETE: 'Удалить',
  BLOCK: 'Заблокировать',
  UNBLOCK: 'Разблокировать',
  DOWNLOAD: 'Скачать',
  TRY_AGAIN: 'Повторить обработку',
  TRY_DOWNLOAD_AGAIN: 'Загрузить снова',
  CREATE_CONTRACT: 'Создать договор',
  CLOSE: 'Закрыть',
  EDIT: 'Редактировать',
  CREATE: 'Создать',
  CLEAR: 'Очистить',

  YOUTUBE_CHANNELS: 'YouTube каналы',
  YOUTUBE_CHANNEL: 'YouTube канал',
  YOUTUBE_CHANNEL_URL: 'Ссылка на YouTube канал',
  YOUTUBE_CHANNEL_URL_PLACEHOLDER: 'Вставьте ссылку на ваш YouTube канал',
  YOUTUBE_ALL_VIDEOS: 'YouTube видео',
  USER_MANAGEMENT: 'Пользователи',
  TRANSACTIONS: 'Транзакции',
  KNOWLEDGE_BASE: 'База знаний',
  ACCOUNT: 'Счёт',
  PAYOUTS: 'Выплаты',
  REVENUES: 'Доходы',
  REVENUE_DYNAMIC: 'Динамика доходов за последние 6 месяцев',
  CONTRACTS: 'Договоры',
  CONTRACT: 'Договор',
  CONTRACT_REFERRAL: 'Договор реферала',
  OPERATIONS_OF_ACCOUNTS: 'Операции по счету',

  CARDS: 'Карточки',
  TABLE: 'Таблица',

  SORT_FIELD: 'Сортировка',
  CHANNELS: 'Каналы',
  CHANNELS_PERCENT_RATES: 'Информация о подключенных каналах',

  TITLE: 'Название',
  LIKES: 'Лайки',
  DISLIKES: 'Дизлайки',
  VIEWS: 'Просмотры',
  COMMENTS: 'Комментарии',
  SUBSCRIBERS: 'Подписчики',
  NEW_FIRST: 'Сначала новые',
  OLD_FIRST: 'Сначала старые',
  UPDATED_FIRST: 'Сначала обновлённые',
  UPDATED_LAST: 'Обновлённые в конце',
  INNER_FIRST: 'Сначала внутренние счета',
  CACHE_FIRST: 'Сначала денежные счета',

  ASC: 'по возрастанию',
  DESC: 'по убыванию',

  VIDEOS: 'Видео',
  METRICS: 'Метрики',

  LAST7DAYS: 'Последние 7 дней',
  LAST14DAYS: 'Последние 14 дней',
  LAST28DAYS: 'Последние 28 дней',
  LAST90DAYS: 'Последние 90 дней',

  WEEK: 'Неделя',
  QUARTER: 'Квартал',
  MONTH: 'Месяц',
  YEAR: 'Год',

  SUPPORT: 'Поддержка',
  LOGOUT: 'Выйти',

  RESET_PASSWORD: 'Сбросить пароль',
  SUCCESS_SENT_RESET_PASSWORD_EMAIL:
    'На указанный Вами адрес эл.почты отправлено письмо с инструкциями для восстановления пароля',
  SUCCESS_SEND_GENERATE_REPORT_REQUEST: 'В ближайшее время отчёт будет сгенерирован и отправлен на Вашу эл.почту',
  NOT_FOUND_THIS_ACCOUNT: 'Не удалось найти данный аккаунт',
  UNEXPECTED_ERROR: 'Что-то пошло не так, попробуйте позже',

  CREATE_NEW_PASSWORD: 'Создайте новый пароль',
  PROFILE: 'Профиль',
  CHANGE_PASSWORD: 'Сменить пароль',
  SUCCESS_CHANGE_PASSWORD: 'Пароль успешно изменён',
  NEW_PASSWORD: 'Новый пароль',
  CONFIRM_NEW_PASSWORD: 'Подтвердите новый пароль',
  CURRENT_PASSWORD: 'Текущий пароль',

  CONNECT: 'Подключиться',
  CONNECTED: 'Подключена',
  JOINING: 'Присоединение',
  PROMOTION: 'Продвижение',
  ADS_INTEGRATION: 'Рекламная интеграция',
  SEND_JOINING_REQUEST: 'Оставьте заявку на подключение YouTube канал',
  INTEGRATE_WITH_YOUTUBE: 'Интеграция с YouTube',
  EXPERIENCE: 'Опыт',
  INTEGRATE_WITH_YOUTUBE_DESCRIPTION:
    'Предоставьте доступ к google-аккаунту для того, чтобы активировать основную функциональность',
  SUCCESS_COMPLETE_YOUTUBE_INTEGRATION: 'Интеграция прошла успешно',
  ERROR_COMPLETE_YOUTUBE_INTEGRATION: 'Не удалось выполнить интеграцию',

  SUCCESS_UPLOAD_REPORTS: 'Выгрузка отчётов успешно завершена',
  ERROR_UPLOAD_REPORTS: 'Во время выгрузки отчётов произошла ошибка',

  ADMINISTRATE: 'Администрирование',
  YOUTUBE_INTEGRATION: 'Интеграция с YouTube',
  DASHBOARD: 'Панель управления',

  EXPIRED: 'Истекает',
  TOKEN_TYPE: 'Тип токена',

  FINANCIAL_REPORTS: 'Финансовые отчёты',
  FINANCIAL_REPORT: 'Финансовый отчёт',
  REPORTS_UPLOAD: 'Загрузить',
  YOUTUBE_FINANCIAL_REPORTS: 'Импорт финансовых отчётов',
  REPORT_PERIOD: 'Период загрузки',
  LABEL_PERIOD: 'Выберите период загрузки',
  LABEL_CMS: 'Выберите CMS',
  DROP_FILES: 'Для загрузки переместите файл сюда, либо нажмите',
  GO: 'Перейти',

  FILE_NAME: 'Имя файла',
  SIZE: 'Размер',
  REPORT_TYPE: 'Тип',
  CREATED_AT: 'Создано',
  UPDATED_AT: 'Обновлено',
  BLOCKED_AT: 'Заблокировано',
  USER_TYPE: 'Тип',
  EMAIL: 'Email',
  STATUS: 'Статус',
  ACTIONS: 'Действия',
  GET_USERS_ERROR: 'Неожиданная ошибка при получении пользователей',
  CONFIRM_DELETE_USER: 'Вы уверены, что хотите удалить пользователя?',
  CONFIRM_BLOCK_USER: 'Вы уверены, что хотите заблокировать пользователя?',
  CONFIRM_UNBLOCK_USER: 'Вы уверены, что хотите разблокировать пользователя?',
  CONFIRM_RESET_PASSWORD_USER: 'Вы уверены, что хотите сбросить пароль пользователя?',
  SUCCESS_COMPLETE_ACTION_WITH_USER: 'Операция успешно завершена',
  ERROR_COMPLETE_ACTION_WITH_USER: 'Не удалось выполнить операцию',

  YOUTUBE_REPORT_TYPE_VIDEO_SUMMARY: 'Доход от рекламы → Сводный отчет о видео',
  YOUTUBE_REPORT_TYPE_CLAIM_SUMMARY: 'Доход от рекламы → Сводный отчет о заявках',
  YOUTUBE_REPORT_TYPE_RED_RAWDATA_VIDEO: 'Доход от подписки → Отчет о немузыкальных видео',
  YOUTUBE_REPORT_TYPE_RED_MUSIC_RAWDATA_VIDEO: 'Доход от подписки → Отчет о видео',
  YOUTUBE_REPORT_TYPE_ADJ_VIDEO_SUMMARY: 'Доход от рекламы: компенсационные выплаты → Сводный отчет о видео',
  YOUTUBE_REPORT_TYPE_ADJ_CLAIM_SUMMARY: 'Доход от рекламы: компенсационные выплаты → Сводный отчет о заявках',
  YOUTUBE_REPORT_TYPE_ECOMMERCE_PAID_FEATURES: 'Доход от платных функций → Отчет о платных функциях',
  YOUTUBE_REPORT_TYPE_SHORTS_ADS:
    'Доход от рекламы в YouTube Shorts -> Сводный видеоотчет о доходе от рекламы в Youtube Shorts',
  YOUTUBE_REPORT_TYPE_SHORTS_SUBSCRIPTION:
    'Доход от подписок в YouTube Shorts -> Сводный видеоотчет о доходе от подписок в Youtube Shorts',

  [ReportsProcessingStatus.CREATED]: 'Создан',
  [ReportsProcessingStatus.IN_WORK]: 'В обработке',
  [ReportsProcessingStatus.ERROR]: 'Ошибка',
  [ReportsProcessingStatus.PROCESSED]: 'Обработан',

  CONTRACTOR: 'Контрагент',
  CONTRACTORS: 'Контрагенты',
  USERS: 'Пользователи',
  ACCOUNTS: 'Счета',
  SUM: 'Сумма',
  FROM: 'От кого',
  TO: 'Куда',

  TOGGLE_MENU: 'Показать/Скрыть меню',

  CURRENT_BALANCE: 'Текущий баланс',
  DESCRIPTION: 'Описание',
  ORIGINAL_DESCRIPTION: 'Оригинальное описание',
  FINANCIAL_ACCOUNTS: 'Финансовые счета',
  BALANCE: 'Баланс',
  ACCOUNT_TYPE: 'Тип счёта',
  INNER_ACCOUNT: 'Внутренний счёт',
  CACHE_ACCOUNT: 'Денежный счёт',
  MC_PAY_ACCOUNT: 'McPay счёт',
  CONTRACT_NUMBER: 'Номер',
  VALIDITY_PERIOD: 'Срок действия',
  CONTRACT_START_DATE: 'Начало действия',
  CONTRACT_END_DATE: 'Окончание действия',
  CONTRACT_NUMBER_BE_AUTOGENERATED: 'Номер договора будет сгенерирован автоматически',
  CHANNEL_NAME: 'Название канала',
  CHANNEL: 'Канал',
  ADDENDUM_NUMBER: 'Номер',
  ADDENDUM_LIST: 'Список приложений',
  RATE_PERCENT: 'Процентная ставка',
  RATE_PERCENT_SHORT: 'Ставка (%)',
  CREATE_ADDENDUM: 'Создать приложение',
  ADDENDUM: 'Приложение',
  ADDENDUMS: 'Приложения',
  SOURCE: 'Источник',
  CONTRACTOR_EMAIL: 'Email контрагента',
  CONTRACTOR_NAME: 'Имя контрагента',
  USER_NAME: 'Имя пользователя',
  EDIT_CONTRACT: 'Редактировать договор',
  EDIT_CONTRACTOR: 'Редактировать контрагента',
  CREATE_USER: 'Создать пользователя',
  EDIT_USER: 'Редактировать пользователя',
  PASSWORD: 'Пароль',
  PASSWORD_CONFIRMATION: 'Подтвердите пароль',
  ADMIN_PRIVILEGES: 'Привилегии администратора',
  USERS_ACTIONS: 'Действия пользователей',
  STATUS_CODE: 'Статус код',
  SHOW_MORE: 'Показать больше',
  AGENT: 'Агент',
  TIME: 'Время',
  TYPE: 'Тип',
  PAYMENT_REQUEST: 'Запросить выплату',
  MC_PAY_PAYMENT_REQUEST: 'Запросить выплату в mcpay.io',
  PAYMENT_REQUESTS: 'Запросы на выплату',
  SUCCESS_PAYMENT_REQUEST: 'Запрос на выплату успешно создан',
  SEND: 'Отправить',
  PERFORM: 'Выполнить',
  REJECT: 'Отклонить',
  REJECT_REASON: 'Причина отказа',
  DESCRIBE_REJECT_REASON: 'Опишите причину отказа',
  DESCRIBE_CANCEL_REASON: 'Опишите причину отмены',
  CANCEL_REASON: 'Причина отмены',
  OPERATION_WAS_CANCELED: 'Данная операция была отменена',
  ADD_YOUTUBE_CHANNEL: 'Добавление YouTube-канала',
  ADD_YOUTUBE_CHANNEL_DESCRIPTION:
    'Предоставьте доступ сервисам Playnetwork, чтобы помочь вашему YouTube-каналу развиваться.',
  ADD: 'Добавить',
  ADD_CHANNEL_REQUESTS: 'Запросы на подключение YouTube-каналов',
  PUBLISHED_AT: 'Опубликовано',
  APPROVE: 'Подтвердить',
  PERIOD: 'Период',
  ACCOUNT_OPERATIONS: 'Финансовые операции',
  ADS: 'Реклама',
  ADS_copies: 'Реклама (Копии)',
  COMPENSATIONS: 'Компенсации',
  YOUTUBE_PREMIUM: 'YouTube Premium',
  PAID_FEATURES: 'Платные функции',
  OTHER_REVENUES: 'Прочие доходы',
  YOUTUBE_SHORTS: 'Shorts',
  TOTAL: 'Итого',

  MIN_PAYOUT_AMOUNT_IS: 'Минимальная сумма для выплаты составляет',
  OF: 'из',
  DRAW_SIGNATURE: 'Распишитесь',
  SIGN: 'Расписаться',
  SIGNATURE: 'Подпись',
  REQUISITES: 'Реквизиты',
  REPORT: 'Отчёт',
  PAYMENT_REQUISITES: 'Платёжные реквизиты',
  UPDATE_HISTORY: 'История обновлений',
  SWIFT_NUMBER: 'SWIFT/BIC/Routing',
  ACCOUNT_NUMBER: 'Номер счёта',
  MONTHS: 'Месяцы ',
  GENERATE_REPORT: 'Генерация финансового отчёта',
  GENERATE: 'Сгенерировать',
  EDIT_PAYMENT_REQUISITES: 'Редактировать платёжные реквизиты',
  ADDRESS: 'Адрес',
  SHOW_REQUESTS: 'Показать мои запросы',
  CONNECT_CHANNELS_REQUESTS: 'Запросы на подключение YouTube каналов',
  SUCCESS_SENT_CONNECT_CHANNEL_REQUEST:
    'Запрос на подключение канала успешно создан. В ближайшее время он будет обработан администратором.',
  ERROR_SENT_CONNECT_CHANNEL_REQUEST:
    'Подключение канала не удалось. Убедитесь, что данный канал не был подключен ранее, либо попробуйте снова.',
  NOTICE_EDIT_RESTRICTED:
    'Редактирование некоторых полей заблокировано, т.к по данному приложению существуют транзакции.',
  EDIT_ADDENDUM: 'Редактировать приложение',
  TRANSACTION_SUM_PREVIOUS_MONTH: 'Сумма транзакций за предыдущий месяц',
  TRANSACTION_SUM_CURRENT_MONTH: 'Сумма транзакций за текущий месяц',
  NOTICE_ACCRUALS_IN_USD: 'Для начисления дохода требуется указать USD счет',
  UNCONFIRMED_BALANCE: 'Неподтверждённый баланс',
  DOWNLOAD_XLSX_REPORT: 'Выгрузить отчёт XLSX',
  DOWNLOAD_XLSX_REPORT_ON_TRANSACTIONS: 'Выгрузить отчёт XLSX по транзакциям',
  CHANNEL_TRANSACTIONS_INCOME_REPORT: 'Сформировать отчет по доходам каналов',
  REQUESTS: 'Запросы',
  CREATE_TRANSACTION: 'Создать транзакцию',
  CONFIRM_ALL_TRANSACTIONS: 'Подтвердить все транзакции',
  CREATE_ACTION_WITHDRAWING_TRANSACTION: 'Создать транзакцию на вывод',
  CREATE_ACTION_DEBIT_TRANSACTION: 'Создать транзакцию на списание',
  CREATE_ACTION_TAX_TRANSACTION: 'Создать транзакцию на списание налога',
  CREATE_ACTION_INCOME_TRANSACTION: 'Создать транзакцию-начисление',
  CONFIRM_TRANSACTION: 'Вы уверены, что хотите выполнить подтверждение транзакции?',
  CONFIRM_TRANSACTION_DESCRIPTION: 'Пользователь получит уведомление о начислении на его счет.',
  CONFIRM_TRANSACTION_SUCCESS: 'Транзакция успешно выполнена',
  REJECT_TRANSACTION_SUCCESS: 'Транзакция успешно отклонена',
  CANCEL_TRANSACTION_SUCCESS: 'Транзакция успешно отменена',
  INSTRUCTIONS: 'Инструкции',
  ADVERTISEMENT: 'Размещение рекламы',
  SAVE: 'Сохранить',
  DEPENDENT_TRANSACTIONS: 'Зависимые транзакции',
  DEPENDS_ON: 'Зависит от',

  MAILINGS: 'Рассылки',
  DASHBOARD_VIDEOS_COUNT: 'Кол-во видео',
  DASHBOARD_CHANNELS_COUNT: 'Кол-во каналов',
  DASHBOARD_CHANNEL_CONNECT_REQUESTS: 'Кол-во запросов на подключение канала',
  DASHBOARD_HAS_SIGNATURE: 'Подпись указана',
  DASHBOARD_HASNT_SIGNATURE: 'Подпись не указана',
  DASHBOARD_HAS_PAYMENT_REQUISITES: 'Платежные реквизиты указаны',
  DASHBOARD_HASNT_PAYMENT_REQUISITES: 'Платежные реквизиты не указаны',
  DASHBOARD_USERS_COUNT: 'Кол-во пользователей',
  DASHBOARD_TODAY_COUNT: 'За сегодня',
  DASHBOARD_IN_WORK_COUNT: 'В работе',
  DASHBOARD_PAYMENT_REQUEST_IN_WORK_SUM: 'Текущая сумма запросов',
  DASHBOARD_CHANNELS_WITHOUT_ADDENDUM: 'Каналы не связанные с приложением',
  DASHBOARD_ERRORS_COUNT: 'Кол-во ошибок за сегодня',
  DASHBOARD_CONTRACTORS_INNER_ACCOUNTS_BALANCE_AMOUNT: 'Сумма задолженности перед контрагентами',
  DASHBOARD_CMS1_CLEAN_REVENUE: 'CMS Playnetwork, чистый доход',
  DASHBOARD_CMS2_CLEAN_REVENUE: 'CMS Playnetwork TV, чистый доход',
  DASHBOARD_CLEAN_REVENUE: 'Итого чистый доход в последнем отчете по 2-м CMS',
  DASHBOARD_TRANSACTION_WAITING_CONFIRM_COUNT: 'Кол-во транзакций ожидающих подтверждения',
  DASHBOARD_TRANSACTION_MANAGEMENT: 'Управление транзакциями',
  DASHBOARD_PAYMENTS_MANAGEMENT: 'Управление выплатами',
  DASHBOARD_USERS_CURRENT_MONTH_COUNT: 'В текущем месяце',
  OOPS: 'Упс',
  SOMETHING_WENT_WRONG: 'Что-то пошло не так',
  PAGE_NOT_FOUND: 'Страница не найдена',
  EMPTY_REPORT_CANNOT_CREATE_FINANCIAL_REPORT: 'Из-за отсутствия договоров выгрузка финансового отчёта невозможна',
  TOTAL_TRANSACTIONS_SUM: 'Всего транзакций на сумму',
  SUBJECT: 'Тема',
  CHOOSE_USERS: 'Выберите пользователей',
  CHOOSE_ALL: 'Выбрать всё',
  CREATE_MAILING: 'Создать рассылку',
  MULTILINGUAL: 'Мультиязычность',
  MARKDOWN: 'Markdown',
  HTML: 'HTML',
  PREVIEW: 'Предпросмотр',
  SUCCESS_SEND_MAILING: 'Рассылка успешно осуществлена',
  EDIT_MAILING: 'Редактировать рассылку',
  CONFIRM_DELETE_MAILING: 'Вы уверены, что хотите удалить рассылку?',
  EMPTY: 'Пусто',

  HAS_TAX_ON_PROFITS: 'Отметьте, если вы работаете с НДС',
  INTEGRATION_ENABLE: 'Интеграция',
  INTEGRATION_PRICE: 'Стоимость интеграции',
  ADVANCED_INTEGRATION_ENABLE: 'Расширенная интеграция',
  ADVANCED_INTEGRATION_PRICE: 'Стоимость расширенной интеграции',
  POST_ENABLE: 'Разрешение на размещение',
  POST_PRICE: 'Стоимость размещения',
  PRE_ROLL_ENABLE: 'Пре-ролл',
  PRE_ROLL_PRICE: 'Стоимость пре-ролла',
  PRODUCT_PLACEMENT_ENABLE: 'Продакт-плейсмент',
  PRODUCT_PLACEMENT_PRICE: 'Стоимость продакт-плейсмента',
  SPECIAL_PROJECT_ENABLE: 'Специальный проект',
  SPECIAL_PROJECT_PRICE: 'Стоимость специального проекта',
  EXPECTED_VIEWS_COUNT: 'Ожидаемое кол-во просмотров',
  TAX: 'Налог на доходы',
  RELEASE_DATE: 'Планируемая дата',
  COMMENT: 'Комментарий',
  CHOSEN: 'Выбрано',
  CONFIRM_DELETE_ADS_REQUEST: 'Вы уверены, что хотите удалить запрос на размещение рекламы?',
  ADVERTISEMENT_REQUESTS: 'Запросы на размещение рекламы',
  SUCCESS_ADS_REQUEST_REJECT: 'Запрос на размещение рекламы успешно отклонён',
  SUCCESS_ADS_REQUEST_CONFIRM: 'Запрос на размещение рекламы успешно подтверждён',
  PRICE: 'Стоимость',
  PRICE_WITHOUT_TAX: 'Стоимость без налога',
  CREATE_ADS_REQUEST: 'Создать запрос на размещение рекламы',
  ADS_REQUEST_COMMENT_PLACEHOLDER:
    'Расскажите подробнее о будущем контенте:\n\nПланируемая тематика ролика, Приглашённые гости\nОжидаемое кол-во просмотров, Примечания',
  ADS_REQUESTS_NOT_FOUND: 'Найдем рекламодателей для вашего контента!',
  CONFIRM_APPROVE_ADS_REQUEST: 'Вы уверены, что хотите подтвердить запрос на рекламу?',
  CREATE_ADS_REQUEST_MARKETING_DESCRIPTION:
    'Этот раздел предназначен для того, чтобы сообщать нам о свободных рекламных слотах.\n\n' +
    'Вместе c <playersteamlink>Players</playersteamlink> и другими партнерами мы поможем в поиске спонсоров \n' +
    'на грядущий контент, а так же разместим информацию в <playerscontentbotlink>Players Content Bot.</playerscontentbotlink>',
  COOKIE_NOTICE: 'Для повышения удобства сайта мы используем файлы Cookie.',
  I_AGREE: 'Согласиться',

  CREATE_TICKET: 'Создать обращение',
  TICKET_STATUS: 'Статус обращений',
  OPEN_TICKETS: 'Открытые',
  CLOSED_TICKETS: 'Закрытые',
  TICKET_TOPIC: 'Тема обращения',
  TICKET_TEXT: 'Текст сообщения',
  TICKET_AUTHOR: 'Автор',
  OPEN_SUPPORT_TICKET: 'Открыть обращение',
  TICKETS_NOT_FOUND: 'У вас нет открытых обращений в поддержку',
  CREATE_TICKET_MARKETING_DESCRIPTION:
    'Если у вас есть вопрос, проблема или предложение\nСвяжитесь с нами, путём создания обращения.\nМы обязательно рассмотрим его.',
  SUPPORT_TICKET_REASON: 'Причина обращения',
  WRITE_MESSAGE: 'Напишите сообщение',
  MESSAGE_COUNT: 'Кол-во сообщений',
  TICKET_OPENED_AT: 'Открыто',
  CONFIRM_CLOSE_APPEAL: 'Вы уверены, что хотите закрыть обращение?',
  CLOSE_APPEAL: 'Закрыть обращение',
  APPEAL_WAS_CLOSED: 'Обращение было закрыто',
  UPDATE_CLIENT_NOTICE: 'Сайт был обновлён. Чтобы получить изменения, обновите страницу.',
  CONFIRM: 'Подтвердить',
  ATTENTION: 'Внимание',
  REFRESH: 'Обновить',
  REVOKE_ACCESS: 'Отозвать доступ к YouTube-аккаунту',
  REVOKE_ACCESS_DESCRIPTION:
    'Если вы хотите отозвать доступ к своему YouTube аккаунту, то можете сделать это на <permissonslink>странице управления доступом к Google-аккаунту</permissonslink>.',
  SHOW_ALL: 'Показать всё',
  HIDE: 'Скрыть',
  CHANNEL_HIDDEN_FROM_YOUTUBE:
    'Данный канал более не находится в многоканальной сети.\nДля того, чтобы выгрузить отчет о доходах пройдите в раздел <redirect>Финансовые отчеты.</redirect>',
  HIDDEN_CHANNELS_AND_VIDEO_FROM_YOUTUBE:
    'Данный канал не находится в многоканальной сети, также данное видео было скрыто.\nДля того, чтобы выгрузить отчет о доходах пройдите в раздел <redirect>Финансовые отчеты.</redirect>',
  VIDEO_HIDDEN_FROM_YOUTUBE:
    'Данное видео было скрыто.\nДля того, чтобы выгрузить отчет о доходах пройдите в раздел <redirect>Финансовые отчеты.</redirect>',
  NOTICE: 'Внимание',
  CHANNEL_NOT_AVAILABLE: 'Канал недоступен',
  NOT_APPLICABLE: 'Н/Д',
  RESET_PASSWORD_TOKEN_EXPIRED: 'Токен сброса пароля устарел. Пожалуйста, запросите восстановление пароля заново.',
  REGISTRATION_CODE_CONFIRMATION: 'Подтверждение регистрации',
  REGISTRATION_CODE_CONFIRMATION_DESC: 'На почту <email></email> отправлено письмо с кодом подтверждения',
  CODE_DIDNT_COME: 'Не пришёл код?',
  SEND_AGAIN: 'Отправить ещё раз',
  BACK_TO_REGISTER: 'Вернуться к регистрации',
  CONFIRMATION_CODE_IS_INVALID: 'Код подтверждения недействителен',
  CREDENTIALS_ARE_OUT_OF_DATE: 'Данные учётной записи устарели. Повторите регистрацию',
  ACCESSIBILITY: 'Доступность',
  ACTIVE: 'Действующие',
  INACTIVE: 'Не действующие',
  ACTIVE_VIDEO: 'Доступные',
  INACTIVE_VIDEO: 'Скрытые',
  PERIOD_START_DATE: 'Период от',
  PERIOD_END_DATE: 'Период до',
  TAX_PERIOD: 'Налоговый период',
  CREATE_NEW_CHAPTER: 'Создать новый раздел',
  DRAFT: 'Черновик',
  SAVE_AS_DRAFT: 'Сохранить как черновик',
  EDIT_CHAPTER: 'Редактировать раздел',
  CREATE_CHAPTER: 'Создать раздел',
  CREATE_ARTICLE: 'Создать статью',
  CONFIRM_DELETE_ARTICLE: 'Вы уверены, что хотите удалить статью?',
  CONFIRM_DELETE_CHAPTER: 'Вы уверены, что хотите удалить раздел?',
  KNOWLEDGE_BASE_DESCRIPTION:
    'Здесь находятся ответы на главные вопросы о Личном кабинете Play Network и ваших возможностях в экосистеме.\n' +
    'Если вы не нашли в списке нужный вопрос или вам не подошел ответ, обращайтесь напрямую в <supportlink>службу поддержки</supportlink>.\n' +
    'Мы всегда рады вам помочь!',
  NOT_AVAILABLE: 'Недоступно',
  NOT_SPECIFIED: 'Не указано',
  SPECIFY_HEIGHT: 'Укажите высоту (px)',
  SPECIFY_WIDTH:
    'Укажите ширину (по умолчанию задаётся в px, чтобы задать значение в процентах, в конце значения укажите "%")',
  APPLICATION_SETTINGS: 'Настройки системы',
  SUCCESS_UPDATE_SETTINGS: 'Настройки успешно обновлены',

  ADS_REQUEST_NOTICE_RECIPIENT: 'Получатель уведомлений о новых рекламных запросах',
  CONNECT_CHANNEL_REQUEST_NOTICE_RECIPIENT: 'Получатель уведомлений о запросах на подключение канала',
  CHANGE_PAYMENT_REQUISITES_NOTICE_RECIPIENT: 'Получатель уведомлений об изменениях платежных реквизитов пользователей',
  PAYMENT_REQUEST_NOTICE_RECIPIENT: 'Получатель уведомлений о запросах на выплату',
  SUPPORT_TICKET_NOTICE_RECIPIENT: 'Получатель уведомлений о новых обращениях в тех.поддержку',
  NEED_SEND_TRANSACTION_EMAIL_NOTIFICATION: 'Уведомление пользователей о создании транзакций',
  DOWNLOADS: 'Загрузки',
  DOWNLOADING: 'Загрузка',
  SUCCESS_DOWNLOAD_FILE: 'Файл успешно загружен',
  GENERATE_INCOME_REPORT: 'Выгрузить отчёт по доходам каналов',
  CONTRACT_ON_CHANNEL: 'Договор для канала',
  NEED_SIGNATURE_NOTICE: 'Требуется подписание',
  RE_SIGNATURE: 'Переподписать',
  SET_SIGNATURE: 'Подписать',
  SIGNED: 'Подписано',
  SIGNATURE_HERE: 'Распишитесь здесь',
  APPROVE_CONNECT_CHANNEL_REQUEST: 'Подтверждение подключения канала',
  CONNECT_YOUTUBE_CHANNEL: 'Подключение YouTube канала',
  NOTICE_APPROVE_CONNECT_CHANNEL_REQUEST:
    'Для подтверждения присоединения канала требуется указать дополнительные данные',
  SUCCESS_APPROVE_CONNECT_CHANNEL: 'Заявка на подключение канала успешно одобрена',
  SUCCESS_CREATE_CONNECT_CHANNEL_REQUEST: 'Заявка на подключение канала успешно создана',
  MORE_TRANSACTION_FIRST: 'Сначала с транзакциями',
  LESS_TRANSACTION_FIRST: 'Сначала без транзакций',

  CASH_OUT: 'Вывести деньги',
  MC_PAY_FILTER: 'Интеграция с mcpay',
  FOR_MC_PAY: 'Интеграция с mcpay',
  NOT_FOR_MC_PAY: 'Нет интеграции с mcpay',
  MC_PAY_STATUS: 'Статус в mcpay',
  ['MC_PAY_STATUS_' + McPayCreateTransactionStatus.WAITING_SEND_TO_MC_PAY]: 'Ожидает отправки',
  ['MC_PAY_STATUS_' + McPayCreateTransactionStatus.ERROR]: 'Обработано с ошибкой',
  ['MC_PAY_STATUS_' + McPayCreateTransactionStatus.SUCCESS]: 'Успешно обработано',
  MC_PAY_REGISTER: 'Авторизоваться',
  MC_PAY_ALREADY_REGISTER: 'Успешно авторизованы',
  MC_PAY_DESCRIPTION: 'Надежная платежная система mcpay.io',
  MC_PAY_DESCRIPTION_LIST_ITEM_ONE: 'Моментальный вывод денег',
  MC_PAY_DESCRIPTION_LIST_ITEM_SECOND: 'Доступ ко всей истории транзакций',
  MC_PAY_DESCRIPTION_LIST_ITEM_THIRD: 'Вывод средств 12 различными способами',
  MC_PAY_DESCRIPTION_LIST_ITEM_FOURTH: 'Может взиматься комиссия провайдера',
  PN_TAX_INFO: 'Комиссия за перевод',
  MC_PAY_INTEGRATION_NOTICE_RECIPIENT: 'Получатель уведомлений о взаимодействии с mcpay.io',
  IMPORT_YOUTUBE_DATA_RECIPIENT: 'Получатель уведомлений об импорте данных из YouTube',

  MC_PAY_WITHDRAWAL_REQUEST: 'Подтверждение вывода в McPay',
  CONFIRM_MC_PAY_OPERATIONS_CONFIRMATION_MESSAGE: 'Вы уверены, что хотите подтвердить отправку операций в MC PAY?',
  SUCCESS_CONFIRM_MC_PAY_OPERATIONS: 'Операции в ближайшее время будут отправлены в MC PAY',
  WITHDRAWAL_TO_MC_PAY_NO_OWNER_USER_ATTENTION: 'Извините, взаимодействие с mcpay.io доступно только управляющему',
  MC_PAY_INTEGRATION_TEMP_UNAVAILABLE:
    'Ведутся технические работы. Интеграция с mcpay.io временно недоступна, попробуйте позже',
  MC_PAY_INTEGRATION_ENABLE: 'Интеграция с mcpay.io',

  MC_PAY_AUTH_STATE: 'Состояние авторизации пользователя',
  MC_PAY_AUTH_STATES: 'McPay auth-статусы',
  BALANCE_FROM_MC_PAY: 'Отображается баланс из системы mcpay.io',
  BALANCE_FROM_PN: 'Отображается баланс из системы Play Network',

  JURISDICTION: 'Юрисдикция',

  PAYMENT_REQUEST_DESTINATION: 'Счет поступления',
  INPUT_PLACEHOLDER_PAYMENT_REQUEST_DESTINATION: 'Выберите счет, куда поступят деньги',
  SUCCESS_CONFIRM_PAYMENT_REQUEST: 'Запрос на выплату успешно подтвержден',
  SUCCESS_REJECT_PAYMENT_REQUEST: 'Запрос на выплату успешно отклонен',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.BY_REQUISITES]: 'По реквизитам',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.TO_MCPAY]: 'В mcpay.io',
  WITHDRAWAL_SUM_WITHOUT_FEE: 'Поступит на ваш счет',
  ['ADS_REQUEST_STATUS_' + AdsStatus.IN_WORK]: 'В работе',
  ['ADS_REQUEST_STATUS_' + AdsStatus.REJECTED]: 'Отклонено',
  ['ADS_REQUEST_STATUS_' + AdsStatus.DONE]: 'Подтверждено',

  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.WISH]: 'Пожелание',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.PROBLEM]: 'Проблема',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.QUESTION]: 'Вопрос',

  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.OPEN]: 'Открыто',
  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.CLOSED]: 'Закрыто',

  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.IN_WORK]: 'В работе',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.COMPLETE]: 'Завершено',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.REJECT]: 'Отклонено',
  ['CONTRACT_STATUS_' + ContractStatus.ACTIVE]: 'Активный',
  ['CONTRACT_STATUS_' + ContractStatus.EXPIRED]: 'Недействительный',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.IN_WORK]: 'Ожидает модерации',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.REJECTED]: 'Отклонено',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.APPROVED]: 'Подтверждено',
  ['TRANSACTION_STATUS_' + TransactionStatus.CONFIRMED]: 'Подтверждено',
  ['TRANSACTION_STATUS_' + TransactionStatus.REJECTED]: 'Отклонено',
  ['TRANSACTION_STATUS_' + TransactionStatus.WAITING_CONFIRM]: 'Ожидает',
  ['TRANSACTION_STATUS_' + TransactionStatus.CANCELED]: 'Отменено',

  ['ARTICLE_STATUS_' + ArticleStatus.DRAFT]: 'Черновик',
  ['ARTICLE_STATUS_' + ArticleStatus.PUBLISHED]: 'Опубликовано',

  ['TRANSACTION_TYPE_' + TransactionType.PAYMENT_OF_TAX]: 'Удержание налога',
  ['TRANSACTION_TYPE_' + TransactionType.PAYMENT_YOUTUBE_REVENUE_FOR_CONTRACTOR]: 'Выплата вознаграждения',
  ['TRANSACTION_TYPE_' + TransactionType.WITHDRAWAL]: 'Вывод денежных средств',
  ['TRANSACTION_TYPE_' + TransactionType.ADJUSTMENT_BALANCE]: 'Корректировка баланса',
  ['TRANSACTION_TYPE_' + TransactionType.PAYMENT_FROM_CONTRACTOR_TO_PLAYNETWORK]: 'Оплата услуг контрагентом',
  ['TRANSACTION_TYPE_' + TransactionType.FROM_YOUTUBE_TO_PLAYNETWORK]: 'Вознаграждение от Youtube',
  ['TRANSACTION_TYPE_' + TransactionType.WITHDRAWAL_TO_MC_PAY]: 'Вывод в mcpay.io',

  ['REQUISITES_HISTORY_' + 'beneficiary_account_number']: 'Номер счёта',
  ['REQUISITES_HISTORY_' + 'beneficiary_bank_swift_number']: 'SWIFT/BIC/Routing',
  ['REQUISITES_HISTORY_' + 'en_beneficiary_address']: 'Адрес (en)',
  ['REQUISITES_HISTORY_' + 'ru_beneficiary_address']: 'Адрес (ru)',
  ['REQUISITES_HISTORY_' + 'en_beneficiary_name']: 'Имя (en)',
  ['REQUISITES_HISTORY_' + 'ru_beneficiary_name']: 'Имя (ru)',

  USER_AGREEMENT: 'Пользовательское соглашение',
  REPORT_GROUP_ADS: 'Доход от рекламы',
  REPORT_GROUP_SUBSCRIPTION: 'Доход от подписок',
  REPORT_GROUP_COMPENSATION: 'Доход от компенсаций',
  REPORT_GROUP_SHORTS: 'Доход от YouTube Shorts',
  REPORT_GROUP_OTHER: 'Другое',
  IS_REFERRAL: 'Выплата рефереру',
  WITH_REFERRAL: 'Только реферерам',
  WITHOUT_REFERRAL: 'Только авторам',
  NOT_COPIES: 'Только не копии',
  ONLY_COPIES: 'Только по копиям',
  COPIES: 'Копии/Не копии',
  IS_COPY: 'Выплата по копиям',
  REFERRAL: 'Реферал',
  CMS_REVENUE: 'Доход по CMS',
  UPLOAD_CUSTOM_TEMPLATE: 'Загрузить кастомный шаблон',
  DELETE_CUSTOM_TEMPLATE: 'Удалить кастомный шаблон',
  DOWNLOAD_CUSTOM_TEMPLATE: 'Скачать кастомный шаблон',
  HAS_CUSTOM_TEMPLATE: 'Шаблон',
  SUCCESS_TEMPLATE_UPLOAD: 'Шаблон успешно загружен',
} as const;
