import {BaseEndpoint} from '../base-endpoint';
import config from '../../../configs/config';
import {IQueryParams} from '../DTOs/IFilterDtos';
import {IResponseBase} from '../response-contracts/base-response';
import {ITransactionResponse, ITransactionsResponse} from './transactions-response-contracts';
import {TransactionAction} from '../../pages/admin/transactions/transactions-page';
import {PartialNullable} from '../../../types';
import {
  ModalChannelTransactionsIncomeReportFormState
} from '../../components/modals/channel-transaction-income-report-modal/modal-channel-transactions-income-report';

export const TRANSACTION_TYPE_ROUTE_MAP: Record<string, string> = {
  [TransactionAction.TAX]: 'create-transaction-to-tax-office',
  [TransactionAction.DEBIT]: 'create-transaction-to-playnetwork',
  [TransactionAction.WITHDRAWING]: 'create-withdrawal',
  [TransactionAction.INCOME]: 'create-transaction-to-contractor',
};

export type CreateTransactionRequest = {
  contractor_id: string | number;
  value: number;
  description: string;
  period_year: number;
  period_month: number;
  addendum_id: string | number;
};

export class TransactionsApi extends BaseEndpoint {
  public async getTransactions(queryParams?: IQueryParams): Promise<ITransactionsResponse> {
    const query = this.applyQueryParams(queryParams);
    const response = await this.get<ITransactionsResponse>(`${config.BASE_API_URL}/admin/transactions?${query}`);
    return response.data;
  }

  public async getTransaction(transactionId: number): Promise<ITransactionResponse> {
    const response = await this.get<ITransactionResponse>(`${config.BASE_API_URL}/admin/transactions/${transactionId}`);
    return response.data;
  }

  public async confirmMultipleTransactions(queryParams?: IQueryParams): Promise<IResponseBase> {
    const query = this.applyQueryParams(queryParams);
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/transactions/confirm-multiple?${query}`,
      {},
    );
    return response.data;
  }

  public async confirmTransaction(transactionId: number): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/transactions/${transactionId}/confirm`,
      {},
    );
    return response.data;
  }

  public async rejectTransaction(transactionId: number, reject_reason: string): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/transactions/${transactionId}/reject`,
      {
        fields: {
          reject_reason,
        },
      },
    );
    return response.data;
  }

  public async cancelTransaction(transactionId: number, cancel_reason: string): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/transactions/${transactionId}/cancel`,
      {
        fields: {
          cancel_reason,
        },
      },
    );
    return response.data;
  }

  public async download(filters?: IQueryParams): Promise<{fileName: string; content: Blob}> {
    const query = this.applyQueryParams(filters);
    const response = await this.getFile(`${config.BASE_API_URL}/admin/transactions/excel-report?${query}`);
    const attachmentName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    return {fileName: attachmentName, content: response.data};
  }

  public async generateChannelTransactionsIncomeReport(fields: PartialNullable<ModalChannelTransactionsIncomeReportFormState>): Promise<IResponseBase> {
    const response = await this.post<IResponseBase>(
      `${config.BASE_API_URL}/admin/transactions/channel-transactions-excel-report`,
      {
        fields: fields,
      },
    );
    return response.data;
  }

  public async createTransaction(routePostfix: string, payload: PartialNullable<CreateTransactionRequest>) {
    const response = await this.post<IResponseBase>(`${config.BASE_API_URL}/admin/transactions/${routePostfix}`, {
      fields: payload,
    });
    return response.data;
  }
}
