import React from 'react';
import MenuItemComponent, {MenuItem} from './menu-item-component';
import {FormattedMessage} from 'react-intl';

export interface MenuSection {
  title: string;
  is_protected?: boolean;
  exact?: boolean;
  items?: Array<MenuItem>;
}

export interface Props {
  item: MenuSection;
}

const MenuSectionComponent: React.FC<Props> = ({item}: Props) => {
  return (
    <>
      <li className='menu-section '>
        <h4 className='menu-text'>
          <FormattedMessage id={item.title} />
        </h4>
        <i className='menu-icon flaticon-more-v2' />
      </li>
      {
        item.items &&
        item.items.map(menuSectionItem =>
          <MenuItemComponent key={menuSectionItem.to} item={menuSectionItem} />)
      }
    </>
  );

};

export default MenuSectionComponent;
