import React, {useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BaseModal, ModalBodySlot, ModalHeaderSlot} from '../base-modal/base-modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertCustom} from '../../../modules/Auth/component/alert';
import SignaturePad from 'react-signature-canvas';
import './modal-signature.scss';
import {Preloader} from '../../preloader';
import {AddendumPreview} from '../../../api/signature-api/profile-api';
import {useOnScreenOnce} from '../../../hooks/use-on-screen';

interface IModalSignatureProps {
  size?: 'sm' | 'lg' | 'xl';
  error?: string | null;
  loadingCanvas?: boolean;
  loadingPreview?: boolean;
  visible: boolean;
  validationErrors?: {[key: string]: Array<string>} | null;
  sigRef: React.Ref<any>;
  addendumPreview: AddendumPreview | null;

  onClear(): void;

  onHide(): void;

  onOkClick(): any;
}

export const ModalSignature: React.FC<IModalSignatureProps> = ({
  error,
  loadingCanvas,
  loadingPreview,
  visible,
  sigRef,
  onClear,
  size,
  addendumPreview,
  onHide,
  onOkClick,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleOkClick = async () => {
    setLoading(true);
    await onOkClick();
    setLoading(false);
  };

  const ref = useRef<any>();
  const isSignatureVisible = useOnScreenOnce(ref, !visible);

  return (
    <>
      <BaseModal
        size={size}
        visible={visible}
        loading={loading}
        successButtonTitle={intl.formatMessage({id: 'SIGN'})}
        disabledButtons={loading || !isSignatureVisible}
        onSuccess={handleOkClick}
        dialogClassName='fullscreenmodal'
        onHide={onHide}>
        <ModalHeaderSlot>
          <Modal.Title>
            <FormattedMessage id={'DRAW_SIGNATURE'} />
          </Modal.Title>
        </ModalHeaderSlot>
        <ModalBodySlot>
          <div className={'w-100 h-100'}>
            {loadingPreview ? (
              <Preloader />
            ) : (
              <iframe width={'100%'} height={'100%'} srcDoc={addendumPreview?.addendum_as_html ?? undefined} />
            )}
          </div>
          <form className={'form mt-10'}>
            <AlertCustom
              text={error || ''}
              type={'light-danger'}
              iconClassName={'svg-icon-danger'}
              visible={error != undefined}
            />
          </form>
          <div ref={ref} className='form-group d-flex flex-column align-items-center'>
            <h5>{intl.formatMessage({id: 'SIGNATURE_HERE'})}:</h5>
            {loadingCanvas && <Preloader />}
            <div className={`signature ${loadingCanvas ? 'd-none' : ''}`}>
              <SignaturePad clearOnResize={true} canvasProps={{className: 'canvas'}} ref={sigRef} />
            </div>
            <button onClick={onClear} className={'btn btn-light-warning mt-5'}>
              <FormattedMessage id={'CLEAR'} />
            </button>
          </div>
        </ModalBodySlot>
      </BaseModal>
    </>
  );
};
