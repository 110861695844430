import {useEffect, useState} from "react";

export function useOnScreen(ref: any) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  })

  return isIntersecting
}

export function useOnScreenOnce(ref: any, resetFlag?: boolean) {
  const [isOnceVisible, setIsOnceVisible] = useState(false);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible) {
      setIsOnceVisible(true);
    }
  }, [isVisible])

  useEffect(() => {
    if (resetFlag) {
      setIsOnceVisible(false);
    }
  }, [resetFlag])

  return isOnceVisible
}
