import React, {createContext, useContext, useState} from 'react';
import {EXCEPTION_TYPE} from '../../../api/exceptions/IBaseException';
import {CloseModalEvent, CloseModalReason} from '../base-modal/CloseModalEvent';
import {ApiRequestException} from '../../../api/axios-instance';
import {useIntl} from 'react-intl';
import {ModalEditPaymentRequisites} from './modal-edit-requisites';
import {PaymentRequisites, PaymentRequisitesApi} from '../../../api/payment-requisite-api/payment-requisites-api';
import {IValidationException} from '../../../api/exceptions/IValidationException';
import {useAdvancedState} from '../../../hooks/use-advanced-state';
import {EntityId} from '../../../api/base/BaseEntity';
import {IUserDto} from '../../../api/DTOs/IUserDto';

type OpenModalParams = {
  userId?: EntityId;
  user: IUserDto;
  requisites: PaymentRequisites | null;
};

interface IModalAdminEditPaymentRequisitesContext {
  modalEditPaymentRequisitesVisible: boolean;

  showEditPaymentRequisitesAdminModal(props: OpenModalParams): Promise<CloseModalEvent<null>>;
}

// @ts-ignore
const ModalEditPaymentRequisitesContext = createContext<IModalAdminEditPaymentRequisitesContext>();

let closeResolver: ((data: CloseModalEvent<null>) => any) | null = null;
export const ModalAdminEditPaymentRequisitesProvider = ({children}: any) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const [userId, setUserId] = useState<EntityId | null>(null);
  const [user, setUser] = useState<IUserDto | null>(null);

  const api = new PaymentRequisitesApi();
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationError] = useState<{[key: string]: Array<string>} | null>(null);
  const [fields, setFields, updateFields] = useAdvancedState<PaymentRequisites>({});

  const showModal = async ({userId, requisites, user}: OpenModalParams) => {
    setVisible(true);
    if (userId) {
      setUserId(userId);
    }

    if (requisites) {
      setFields(requisites);
    }

    if (user) {
      setUser(user);
    }
    return new Promise<CloseModalEvent<null>>(resolve => {
      closeResolver = resolve;
    });
  };

  const handleHideModal = () => {
    setVisible(false);
    setError(null);
    setValidationError(null);
    setFields({});

    if (closeResolver) {
      closeResolver({reason: CloseModalReason.HIDE});
      closeResolver = null;
    }
  };

  const handleOkClick = async () => {
    try {
      setError(null);
      userId != null
        ? await api.updateUserPaymentRequisites(userId, fields)
        : await api.updateSelfPaymentRequisites(fields);
      if (closeResolver) {
        closeResolver({reason: CloseModalReason.OK});
        closeResolver = null;
      }
      await handleHideModal();
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorType === EXCEPTION_TYPE.VALIDATION_EXCEPTION) {
        setValidationError((err.innerException as IValidationException).error_data.messages);
      } else {
        setError(err.errorMessage || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    }
  };

  const value: IModalAdminEditPaymentRequisitesContext = {
    modalEditPaymentRequisitesVisible: visible,
    showEditPaymentRequisitesAdminModal: showModal,
  };

  return (
    <ModalEditPaymentRequisitesContext.Provider value={value}>
      {children}
      <ModalEditPaymentRequisites
        user={user as IUserDto}
        fields={fields}
        onUpdate={updateFields}
        visible={visible}
        error={error}
        validationErrors={validationErrors}
        onOkClick={handleOkClick}
        onHide={handleHideModal}
      />
    </ModalEditPaymentRequisitesContext.Provider>
  );
};

export const useModalEditPaymentRequisites = () => {
  return useContext(ModalEditPaymentRequisitesContext);
};
