import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {Preloader} from '../../../components/preloader';
import {CloseModalReason} from '../../../components/modals/base-modal/CloseModalEvent';
import {ErrorStub} from '../../../components/error-stub';
import {ApiRequestException} from '../../../api/axios-instance';
import {toast} from 'react-toastify';
import {useCustomBreadcrumbs} from '../../../components/breadcrumbs/breadcrumbs-context';
import {Transaction, TransactionStatus} from '../../../api/transaction-api/Transaction';
import {
  useModalRejectReasonTransaction
} from '../../../components/modals/reject-reason/modal-reject-reason-transaction-context';
import {TransactionsApi} from '../../../api/transaction-api/transactions-api';
import {IAddendumDto} from '../../../api/addendum-api/IAddendumDto';
import {AdminRoutes, Routes} from '../../../../configs/routes';
import {CustomFormatter} from '../../../../localization/custom-formatter';
import {useModalConfirmAction} from '../../../components/modals/confirm-modal/modal-confirm-action-context';
import {Toolbar} from '../../../components/card-toolbar/Toolbar';
import {useLoading} from '../../../hooks/use-loading';
import {capitalizeFirstLetter, tryGetPreviewOrStub} from '../../../utils/utils';
import {TIMESTAMP_CONFIGS, Timestamps} from '../../../components/timestamps';
import {BootstrapColor} from '../../../styles/styles';
import {Card, CardBody, CardTitle} from '../../../components/card';
import {TransactionsTable} from './transactions-table';
import {useTransactionActionHandler} from './useTransactionActionHandler';

const ICONS = {
  CHECK: require('../../../images/svg/Check.svg'),
  CROSS: require('../../../images/svg/Close.svg'),
  ARROW: require('../../../images/svg/Arrow-right.svg'),
  UNDO: require('../../../images/svg/Undo.svg'),
};

export const TransactionPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{id: string}>();
  const transactionId = Number(match.params.id);
  const api = new TransactionsApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
    action: false,
  });

  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [addendum, setAddendum] = useState<IAddendumDto | null>(null);
  const [dependentTransactions, setDependentTransactions] = useState<Array<Transaction>>([]);
  const [dependsOn, setDependsOn] = useState<Transaction | null>(null);

  const {showConfirmActionModal} = useModalConfirmAction();
  const {showRejectReasonTransactionModal} = useModalRejectReasonTransaction();
  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchTransactionInfo();
  }, [transactionId]);

  useEffect(() => {
    if (transaction) {
      setBreadcrumb(
        `${CustomFormatter.formatMoney(transaction.value, transaction.currency)} → ${transaction.toContractorName}`,
      );
    }
  }, [transaction]);

  const fetchTransactionInfo = async () => {
    try {
      startLoading('page');
      const result = await api.getTransaction(transactionId);
      setTransaction(result.data.item);
      setAddendum(result.data.relations.addendum);
      setDependentTransactions(result.data.relations.dependentTransactions);
      setDependsOn(result.data.relations.dependsOn);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };
  const {handleTransactionAction} = useTransactionActionHandler(fetchTransactionInfo);

  const handleConfirmClick = async () => {
    try {
      startLoading('action');
      if (
        await showConfirmActionModal(
          intl.formatMessage({id: 'CONFIRM_TRANSACTION'}),
          intl.formatMessage({id: 'CONFIRM_TRANSACTION_DESCRIPTION'}),
        )
      ) {
        await api.confirmTransaction(transactionId);
        await fetchTransactionInfo();
      }
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        toast.error(err.errorMessage);
      } else {
        toast.error(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('action');
    }
  };

  const handleRejectClick = async () => {
    try {
      startLoading('action');
      const result = await showRejectReasonTransactionModal({transactionId, type: 'REJECT'});
      if (result.reason === CloseModalReason.OK) {
        await fetchTransactionInfo();
      }
    } finally {
      stopLoading('action');
    }
  };

  const handleCancelClick = async () => {
    try {
      startLoading('action');
      const result = await showRejectReasonTransactionModal({
        transactionId,
        type: 'CANCEL',
        modalConfiguration: {
          title: 'DESCRIBE_CANCEL_REASON',
          reasonInputKey: 'cancel_reason',
          reasonInputLabel: 'CANCEL_REASON',
        },
      });
      if (result.reason === CloseModalReason.OK) {
        await fetchTransactionInfo();
      }
    } finally {
      stopLoading('action');
    }
  };

  if (loadings.page) {
    return <Preloader />;
  }

  if (error) {
    return <ErrorStub error={error} />;
  }

  if (transaction === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'} />
      </div>
    );
  }

  const renderAccountInfo = () => {
    return (
      <>
        <div className={'font-size-h4 font-weight-boldest my-3'}>
          <FormattedMessage id={'ACCOUNTS'} />
        </div>
        <div className={'row'}>
          <div className={'col-xl-5 col-md-12 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'FROM'} />
                :&nbsp;
                <Link to={AdminRoutes.getSpecifyContractorRoute(transaction.fromContractorId)}>
                  {transaction.fromContractorName}
                </Link>
              </span>
              <span className={'font-size-h5'}>{transaction.fromAccountName}</span>
            </div>
          </div>

          <div className={'col-xl-5 col-md-12 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'TO'} />
                :&nbsp;
                <Link to={AdminRoutes.getSpecifyContractorRoute(transaction.toContractorId)}>
                  {transaction.toContractorName}
                </Link>
              </span>
              <span className={'font-size-h5'}>{transaction.toAccountName}</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAddendumInfo = () => {
    if (addendum === null) {
      return null;
    }

    return (
      <>
        <div className={'separator separator-solid my-7'} />
        <div className={'font-size-h4 font-weight-boldest my-3'}>
          <FormattedMessage id={'ADDENDUM'} />
        </div>
        <div className={'row align-items-center'}>
          <div className={'col-xl-4 col-md-12 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-size-h5'}>
                <Link to={Routes.getYoutubeSpecifyChannelRoute(addendum.youtubeChannelId)}>
                  {addendum.youtubeChannelThumbnails && (
                    <img
                      className={'rounded w-50px mr-1'}
                      src={tryGetPreviewOrStub(addendum.youtubeChannelThumbnails).url}
                      alt={'preview'}
                    />
                  )}
                  {addendum.youtubeChannelTitle}
                </Link>
              </span>
            </div>
          </div>

          <div className={'col-xl-4 col-md-12 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'RATE_PERCENT'} />
              </span>
              <span className={'font-size-h5'}>
                <FormattedNumber value={addendum.ratePercent} style={'percent'} />
              </span>
            </div>
          </div>

          <div className={'col-xl-4 col-md-12 my-1'}>
            <div className={'d-flex flex-column text-dark-75'}>
              <span className={'font-weight-bolder font-size-sm'}>
                <FormattedMessage id={'VALIDITY_PERIOD'} />
              </span>
              <span className={'font-size-h5'}>
                {intl.formatDate(addendum.startedAt, {
                  month: 'long',
                  year: 'numeric',
                  day: 'numeric',
                })}{' '}
                -{' '}
                {addendum.expiresAt
                  ? intl.formatDate(addendum.expiresAt, {
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric',
                  })
                  : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderButtons = () => {
    return (
      <>
        <div className={'separator separator-solid my-7'} />
        <Toolbar
          items={[
            {
              title: intl.formatMessage({id: 'CONFIRM'}),
              type: 'BUTTON',
              icon: ICONS.CHECK,
              disabled: loadings.action || !transaction?.canConfirm,
              className: 'btn btn-light-success font-weight-bolder',
              onClick: handleConfirmClick,
            },
            {
              title: intl.formatMessage({id: 'REJECT'}),
              type: 'BUTTON',
              icon: ICONS.CROSS,
              className: 'btn btn-light-danger font-weight-bolder',
              disabled: loadings.action || !transaction?.canReject,
              onClick: handleRejectClick,
            },
            {
              title: intl.formatMessage({id: 'CANCEL'}),
              type: 'BUTTON',
              icon: ICONS.UNDO,
              className: 'btn btn-light-warning font-weight-bolder',
              disabled: loadings.action || !transaction?.canCancel,
              onClick: handleCancelClick,
            },
          ]}
        />
      </>
    );
  };

  const renderDepends = () => {
    if (dependentTransactions.length === 0 && dependsOn === null) {
      return null;
    }

    const haveDepends = dependentTransactions.length !== 0;
    return (
      <Card hideFooter>
        <CardTitle>
          <FormattedMessage id={haveDepends ? 'DEPENDENT_TRANSACTIONS' : 'DEPENDS_ON'} />
        </CardTitle>
        <CardBody>
          <TransactionsTable
            transactions={haveDepends ? dependentTransactions : [dependsOn as Transaction]}
            aggregationValues={null}
            onSelectAction={handleTransactionAction}
          />
        </CardBody>
      </Card>
    );
  };

  const renderDescriptions = () => {
    const descriptionIsEqual = transaction.description === transaction.localizedDescription;

    if (descriptionIsEqual) {
      return <p className={'font-size-sm font-weight-bold mb-1'}>
        <FormattedMessage id={'DESCRIPTION'} />: <span>{transaction.description}</span>
      </p>;
    }

    return <>
      <p className={'font-size-sm font-weight-bold mb-1'}>
        <FormattedMessage id={'ORIGINAL_DESCRIPTION'} />: <span>{transaction.description}</span>
      </p>
      <p className={'font-size-sm font-weight-bold mb-1'}>
        <FormattedMessage id={'DESCRIPTION'} />: <span>{transaction.localizedDescription}</span>
      </p>
    </>;
  };

  let statusTextColorVariant;
  switch (transaction.status) {
    case TransactionStatus.CANCELED:
      statusTextColorVariant = 'warning';
      break;
    case TransactionStatus.CONFIRMED:
      statusTextColorVariant = 'success';
      break;
    case TransactionStatus.WAITING_CONFIRM:
      statusTextColorVariant = 'primary';
      break;
    case TransactionStatus.REJECTED:
      statusTextColorVariant = 'danger';
      break;
  }

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex flex-column'}>
              <p className={'font-size-h4 font-weight-boldest mb-1'}>
                <FormattedMessage id={'SUM'} />
                :&nbsp;
                <FormattedNumber value={transaction.value} style={'currency'} currency={transaction.currency} />
              </p>
              <p className={'font-size-h5 font-weight-bolder mb-1'}>
                <FormattedMessage id={'STATUS'} />:{' '}
                <span className={'text-' + statusTextColorVariant}>
                  <FormattedMessage id={'TRANSACTION_STATUS_' + transaction.status} />
                </span>
              </p>
              {transaction.periodMonth !== null && transaction.periodYear !== null && (
                <p className={'font-size-h5 font-weight-bolder mb-1'}>
                  <FormattedMessage id={'PERIOD'} />:{' '}
                  <span>
                    {capitalizeFirstLetter(
                      intl.formatDate(new Date(transaction.periodYear, transaction.periodMonth - 1), {
                        month: 'long',
                        year: 'numeric',
                      }),
                    )}
                  </span>
                </p>
              )}
              {transaction.rejectReason && (
                <p className={'font-size-h6'}>
                  <FormattedMessage id={'REJECT_REASON'} />:{' '}
                  <span className={'text-muted'}>{transaction.rejectReason}</span>
                </p>
              )}
              {transaction.cancelReason && (
                <p className={'font-size-h6'}>
                  <FormattedMessage id={'CANCEL_REASON'} />:{' '}
                  <span className={'text-muted'}>{transaction.cancelReason}</span>
                </p>
              )}
              {renderDescriptions()}
            </div>
            <Timestamps
              items={[
                {
                  title: intl.formatMessage({id: 'CREATED_AT'}),
                  date: transaction.createdAt,
                  format: TIMESTAMP_CONFIGS.DEFAULT,
                  color: BootstrapColor.SUCCESS,
                },
              ]}
            />
          </div>
          {renderAccountInfo()}
          {renderAddendumInfo()}
          {renderButtons()}
        </div>
      </div>
      {renderDepends()}
    </>
  );
};
